import {ReactNode} from 'react';
import {WorkflowTable} from '../workspace/WorkflowTable/WorkflowTable';
import {useHistory, useLocation} from 'react-router';
import {HorizontalBox, Space, SpringSpace} from '../../base/components/HorizontalBox/HorizontalBox';
import {FindWorkflowsVariables} from '../../__generated__/FindWorkflows';
import {WorkflowSort, WorkflowStatus} from '../../__generated__/globalTypes';
import {SelectBox} from '../../base/components/SelectBox/SelectBox';
import {useWorkspaceId} from '../../base/hooks/useWorkspaceId';
import {LargeTab, LargeTabBar} from '../../base/components/TabBar';
import {isPrototype} from './WorkflowRoute/StepPanel';
import {WorkflowGalleryContainer} from '../workspace/WorkflowGallary';
import {tw} from 'twind';
import {Indicator} from '../../base/components/Indicator/Indicator';
import * as React from 'react';

const renderEmpty = (message: string): ReactNode => (
  <div className={tw`text-center text-lightText my-16`}>{message}</div>
);

const renderLoading = () => <div className={tw`my-16 flex justify-center`}><Indicator /></div>;

function getWorkflowStatusParam(params: URLSearchParams, statuses: WorkflowStatus[]): WorkflowStatus | 'all' {
  const status = params.get('status');

  if (statuses.includes(status as any) || status === 'all') {
    return status as WorkflowStatus;
  }

  return statuses[0];
}

function getSortParam(params: URLSearchParams): WorkflowSort {
  const sort = params.get('sort');

  switch (sort) {
    case 'updatedAt':
      return sort as WorkflowSort.updatedAt;
    case 'createdAt':
      return sort as WorkflowSort.createdAt;
  }

  return WorkflowSort.updatedAt; // default value
}

const tabs = [
  {
    label: 'ワークフロー',
    status: 'published',
    activeColor: '#9F7AEA',
    activeTextColor: '#553C9A',
  },
  {
    label: '処理中',
    status: 'running',
    activeColor: '#ED8936',
    activeTextColor: '#C05621',
  },
  {
    label: '完了',
    status: 'finished',
    activeColor: '#38A169',
    activeTextColor: '#276749',
  },
  {
    label: '下書き',
    status: 'draft',
    activeColor: '#4299E1',
    activeTextColor: '#2C5282',
  },
  {
    label: 'アーカイブ',
    status: 'archived',
    activeColor: '#718096',
    activeTextColor: '#24303D',
  },
] as const;

export const instanceStatuses: WorkflowStatus[] = [
  WorkflowStatus.running,
  WorkflowStatus.finished,
];

export const prototypeStatuses: WorkflowStatus[] = [
  WorkflowStatus.published,
  WorkflowStatus.draft,
  WorkflowStatus.archived,
];

interface Props {
  statuses: WorkflowStatus[];
  title?: string;
}

export function WorkflowsRoute(props: Props) {
  const {statuses, title = 'ワークフロー'} = props;
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = getWorkflowStatusParam(searchParams, statuses);
  const sort = getSortParam(searchParams);

  const isActiveStatus = (_status: string) => () => status === _status;

  const variables: FindWorkflowsVariables = {
    limit: 20,
    ownerId: searchParams.get('owner') ?? undefined,
    sourceWorkflowId: searchParams.get('source') ?? undefined,
    statuses: status === 'all' ? statuses : [status],
    workspaceId,
    sort,
    createdAt: {},
  };

  return (
    <div>
      <HorizontalBox>
        <h1 className={tw`font-semiBold text-xl`}>{title}</h1>
      </HorizontalBox>

      <Space height="8px" />

      <LargeTabBar style={{position: 'sticky', top: '0', zIndex: 1}}>
        {statuses.map(status => {
          const tab = tabs.find(tab => tab.status === status);

          if (!tab) {
            return null;
          }

          return (
            <LargeTab
              key={tab.status}
              activeColor={tab.activeColor}
              activeTextColor={tab.activeTextColor}
              to={"?status=" + tab.status}
              isActive={isActiveStatus(tab.status)}
            >
              {tab.label}
            </LargeTab>
          );
        })}
        <LargeTab to="?status=all" isActive={() => isActiveStatus('all')() || !status}>
          全て
        </LargeTab>
      </LargeTabBar>

      <Space height="16px" />


      {status && status !== 'all' && isPrototype(status) ? (
        <WorkflowGalleryContainer
          renderLoading={() =>
            <div className={tw`flex justify-center my-16`}><Indicator delay /></div>
          }
          renderHeader={totalCount => (
            <div
              className={tw`flex items-center`}>
              <div className={tw`text-superLightText text-base`}>
                {totalCount}件のワークフローが見つかりました
              </div>
              <SpringSpace />
              <SelectBox
                value={sort}
                onChange={(event) => {
                  const params = new URLSearchParams(location.search);
                  params.set('sort', event.currentTarget.value);
                  history.push({search: '?' + params.toString()});
                }}>
                <option value="updatedAt">更新順</option>
                <option value="createdAt">作成順</option>
              </SelectBox>
            </div>
          )}
          sort={sort}
          status={status} />
      ) : (
        <WorkflowTable
          variables={variables}
          renderEmpty={() => renderEmpty('ワークフローはありません')}
          renderLoading={renderLoading}
          enableLoadMore
          renderHeader={totalCount => <div className={tw`flex items-center mb-2`}>
            <div className={tw`text-base text-superLightText`}>
              {totalCount}件のワークフローが見つかりました
            </div>
            <SpringSpace />
            <SelectBox
              value={sort}
              onChange={(event) => {
                const params = new URLSearchParams(location.search);
                params.set('sort', event.currentTarget.value);
                history.push({search: '?' + params.toString()});
              }}>
              <option value="updatedAt">更新順</option>
              <option value="createdAt">作成順</option>
            </SelectBox>
          </div>}
        />
      )}
    </div>
  );
}

