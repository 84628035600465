import {ReactComponent as NotFoundImage} from './not-found.svg';
import {pageStyle, ContentBox, ContentHeaderText} from '../../../app/public/Public';
import {tw} from 'twind';

const style = {
  minHeight: 'calc(100vh - 48px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

interface Props {
  title?: string;
}

export function NotFound({title = 'ページが見つかりませんでした'}: Props) {
  return (
    <div className={pageStyle} style={style}>
      <ContentBox>
        <div style={{textAlign: 'center'}}>
          <div style={{margin: '0'}}>
            <NotFoundImage className={tw`mx-auto`} />
          </div>

          <ContentHeaderText style={{margin: '24px 0 0 0'}}>{title}</ContentHeaderText>
        </div>
      </ContentBox>
    </div>
  );
}
