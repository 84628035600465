import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {ReactNode} from 'react';
import {FetchSelf} from '../../../__generated__/FetchSelf';
import {
  Settings,
  Box,
  Users,
  UserPlus,
  Circle,
  ArrowRightCircle,
  Check,
  PlusCircle,
  User,
  ChevronDown,
} from 'react-feather';
import {
  CountWorkspaceWorkflows,
} from '../../../__generated__/CountWorkspaceWorkflows';
import {MinimumButton, QueitButton} from '../../../base/components/Button/Button';
import {Space, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {FetchFavorites} from '../../../__generated__/FetchFavorites';
import {WorkflowSummaryFragment} from '../../../__generated__/WorkflowSummaryFragment';
import {useToast} from '../../../base/components/Toast/Toast';
import {useHover} from '../../../base/hooks/useHover';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {ToggleFavoriteWorkflow, ToggleFavoriteWorkflowVariables} from '../../../__generated__/ToggleFavoriteWorkflow';
import {toggleFavoriteWorkflowMutation} from '../../../gqls/workflowMutations';
import {fetchFavorites, fetchSelfQuery} from '../../../gqls/selfQueries';
import {NakedWorkflowIcon} from '../../../base/components/WorkflowIcon/WorkflowIcon';
import {colors, fontSizes, fontWeights} from 'theme';
import {NaviBlock} from './NaviBlock';
import {Tooltip} from '../../../base/components/Tooltip/Tooltip';
import {Badge} from '../../../base/components/Badge';
import {css} from '@emotion/css';
import {tw} from 'twind';
import {useOptionsMenu} from '../../../base/components/OptionsMenu/hooks';
import {MenuItem, MenuSeparator, MenuHeader} from '../../../base/components/OptionsMenu/OptionsMenu';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';
import {useHistory} from 'react-router';
import {useCreateWorkspace} from '../../common/useCreateWorkspace';
import {TextPlaceholder, CirclePlaceholder} from '../../../base/components/LoadingPlaceholder';

interface Props {
  favs: FetchFavorites;
  workspace: FetchSelf['self']['workspaces'][number];
  countData?: CountWorkspaceWorkflows;
}

export function WorkspacePanel({workspace, favs, countData}: Props) {

  return (
    <div>

      <div className={tw`flex mt-1 text-left w-full items-center mb-4`}>
        <div className={tw`flex-grow-1`}>
          <div
            className={css`
              white-space: nowrap;
              font-size: ${fontSizes.sm};
              font-weight: ${fontWeights.bold};
              color: ${colors.superLightText};
            `}>
            ワークスペース
          </div>
          <h3 className={tw`font-bold text-base`}>{workspace.name}</h3>
        </div>
        <Space width="8px" />
        <WorkspaceMenu />
      </div>

      <NaviBlock to={`/workspaces/${workspace.id}/workflows/start`}>
        <ArrowRightCircle size={16} color={colors.darkText} style={{flexShrink: 0}} strokeWidth={'1.5px'} />
        <Space width="8px" />
        <span style={{whiteSpace: 'nowrap'}}>ワークフローを開始…</span>
      </NaviBlock>

      {favs.favorites.totalCount > 0 && (
        <>
          <SectionHeader>ショートカット</SectionHeader>
          {favs.favorites.items.map((workflow) => (
            <ShortcutWorkflow workflow={workflow} key={workflow.id} />
          ))}
        </>
      )}

      <SectionHeader>ワークフロー</SectionHeader>

      <NaviBlock to={`/workspaces/${workspace.id}/workflows?status=published`}>
        <Box size={16} color={colors.darkText} style={{flexShrink: 0}} strokeWidth={'1.5px'} />
        <Space width="8px" />
        <span style={{whiteSpace: 'nowrap', flexGrow: 1}}>ワークフロー</span>
        {countData?.workflowsCount ? <Badge>{countData.workflowsCount.published}</Badge> : ''}
      </NaviBlock>

      <div className={tw`ml-0`}>
        <NaviBlock to={`/workspaces/${workspace.id}/instances?status=running`}>
          <Space width="3px" />
          <StatusBall color={'#ED8936'} />
          <Space width="11px" />
          <div
            className={css`
              color: #c05621;
              flex-grow: 1;
              white-space: nowrap;
            `}>
            処理中
          </div>
          {countData?.workflowsCount ? <Badge>{countData.workflowsCount.running}</Badge> : ''}
        </NaviBlock>
        <NaviBlock to={`/workspaces/${workspace.id}/instances?status=finished`}>
          <Space width="3px" />
          <StatusBall color={'#38A169'} />
          <Space width="11px" />
          <div
            className={css`
              color: #276749;
              flex-grow: 1;
              white-space: nowrap;
            `}>
            完了
          </div>
          {countData?.workflowsCount ? <Badge>{countData.workflowsCount.finished}</Badge> : ''}
        </NaviBlock>
      </div>

      {/*
      <NaviBlock to={`/workspaces/${workspace.id}/workflows?status=draft`}>
        <StatusBall color={'#4299E1'} />
        <Space width="8px" />
        <div className={css`color: #2C5282; flex-grow: 1; white-space: nowrap; `}>下書き</div>
        {countData ? <Badge>{countData?.workspace?.draftWorkflowCount}</Badge> : ''}
      </NaviBlock>
      <NaviBlock to={`/workspaces/${workspace.id}/workflows?status=published`}>
        <StatusBall color={'#9F7AEA'} />
        <Space width="8px" />
        <div className={css`color: #553C9A; flex-grow: 1; white-space: nowrap; `}>公開</div>
        {countData ? <Badge>{countData?.workspace?.publishedWorkflowCount}</Badge> : ''}
      </NaviBlock>
      <NaviBlock to={`/workspaces/${workspace.id}/workflows?status=archived`}>
        <StatusBall color={'#718096'} />
        <Space width="8px" />
        <div className={css`color: #24303D; flex-grow: 1; white-space: nowrap; `}>アーカイブ</div>
        {countData ? <Badge>{countData?.workspace?.archivedWorkflowCount}</Badge> : ''}
      </NaviBlock>
      */}

      {/*
          <NaviBlock to={`/workspaces/${workspace.id}/settings/members`}>
            <Users size={16} stroke={colors.darkText} style={{flexShrink: 0}} strokeWidth={'1.5px'} />
            <Space width="8px" />
            <span className={tw`whitespace-nowrap flex-grow-1`}>メンバー</span>
            {workspace ? <Badge>{workspace.members.totalCount}</Badge> : null}
          </NaviBlock>
          */}

      <SectionHeader>ワークスペース</SectionHeader>

      <NaviBlock to={`/workspaces/${workspace.id}/settings/general`}>
        <Settings size={16} stroke={colors.darkText} style={{flexShrink: 0}} strokeWidth={'1.5px'} />
        <Space width="8px" />
        <span style={{whiteSpace: 'nowrap'}}>設定</span>
      </NaviBlock>
      <NaviBlock to={`/workspaces/${workspace.id}/settings/members`}>
        <Users size={16} stroke={colors.darkText} style={{flexShrink: 0}} strokeWidth={'1.5px'} />
        <Space width="8px" />
        <span className={tw`whitespace-nowrap flex-grow-1`}>メンバー</span>
        {workspace ? <Badge>{workspace.members.totalCount}</Badge> : null}
      </NaviBlock>
      <NaviBlock to={`/workspaces/${workspace.id}/settings/invite`}>
        <UserPlus size={16} stroke={colors.darkText} style={{flexShrink: 0}} strokeWidth={'1.5px'} />
        <Space width="8px" />
        <span style={{whiteSpace: 'nowrap'}}>招待する</span>
      </NaviBlock>

      <SectionHeader>個人</SectionHeader>

      <NaviBlock to={`/workspaces/${workspace.id}/settings/personal`} exact>
        <User size={16} stroke={colors.darkText} style={{flexShrink: 0}} strokeWidth={'1.5px'} />
        <Space width="8px" />
        <span style={{whiteSpace: 'nowrap'}}>アカウントと設定</span>
      </NaviBlock>
    </div>
  );
}

function StatusBall(props: {color: string}) {
  return <Circle strokeWidth={0} fill={props.color} size={10} />;
}

function ShortcutWorkflow({workflow}: {workflow: WorkflowSummaryFragment}) {
  const toast = useToast();
  const [ref, isHover] = useHover();

  const [toggle, {loading}] = useAppMutation<ToggleFavoriteWorkflow, ToggleFavoriteWorkflowVariables>(
    toggleFavoriteWorkflowMutation,
    {
      onError() {
        toast.error('ショートカット解除に失敗しました');
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: fetchFavorites,
          variables: {
            workspaceId: workflow.workspace!.id,
          },
        },
      ],
    }
  );

  const removeFromFavorites = () => {
    toggle({
      variables: {
        workflowId: workflow.id,
        favorite: false,
      },
    });
  };

  if (loading) {
    return null;
  }

  return (
    <div ref={ref as any}>
      <NaviBlock to={`/workspaces/${workflow.workspace!.id}/workflows/${workflow.id}`}>
        <NakedWorkflowIcon color={colors.lightText} workflow={workflow} style={{flex: '0 0 auto'}} />
        <Space width="8px" />
        <span
          className={css`
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}>
          {workflow.name}
        </span>
        {typeof workflow.count === 'number' && (
          <span
            className={css`
              color: ${colors.superLightText};
              font-weight: ${fontWeights.bold};
              font-size: ${fontSizes.base};
              white-space: nowrap;
            `}>
            &nbsp;＃{workflow.count}
          </span>
        )}
        <SpringSpace />
        <Tooltip text="ショートカットから外す">
          <MinimumButton
            onClick={(event) => {
              event.preventDefault();
              removeFromFavorites();
            }}
            style={{visibility: isHover ? 'visible' : 'hidden', flex: '0 0 auto'}}>
            ✗
          </MinimumButton>
        </Tooltip>
      </NaviBlock>
    </div>
  );
}

function SectionHeader({children}: {children: ReactNode}) {
  return (
    <h3
      style={{
        padding: '0',
        margin: '16px 0 4px 0',
        fontSize: fontSizes.sm,
        whiteSpace: 'nowrap',
        lineHeight: 1,
        color: colors.superLightText,
        fontWeight: fontWeights.bold,
      }}>
      {children}
    </h3>
  );
}

function WorkspaceMenu() {
  const workspaceId = useWorkspaceId();
  const {data} = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-first',
    onError() {
      // do nothing
    },
  });

  const {node} = useOptionsMenu({
    button: (
      <QueitButton>
        <ChevronDown size={16} strokeWidth={"1.5px"} />
      </QueitButton>
    ),
    menu(close) {
      return (
        <>
          <MenuHeader>ワークスペース</MenuHeader>
          {data &&
            data.self.workspaces.map((workspace) => {
              const icon = workspaceId === workspace.id ? <Check size={12} strokeWidth={3} /> : undefined;

              return (
                <MenuItem
                  key={workspace.id}
                  icon={icon}
                  onClick={() => {
                    close();
                    history.push(`/workspaces/${workspace.id}`);
                  }}>
                  {workspace.name}
                </MenuItem>
              );
            })}
          <MenuSeparator />
          <MenuItem
            icon={<PlusCircle size={16} strokeWidth={"1.5px"} />}
            onClick={() => {
              close();
              createWorkspace.run();
            }}>
            新しいワークスペース…
          </MenuItem>
        </>
      );
    },
  });

  const history = useHistory();
  const createWorkspace = useCreateWorkspace();

  return <>{node}</>;
}

export function LoadingWorkspacePanel() {
  return (<div>
    <div><TextPlaceholder>.....</TextPlaceholder></div>
    <div><TextPlaceholder>.........</TextPlaceholder></div>

    <Space height="16px" />

    <NaviBlock>
      <CirclePlaceholder size={16} />
      <Space width="8px" />
      <TextPlaceholder>.....</TextPlaceholder>
    </NaviBlock>

    <NaviBlock>
      <CirclePlaceholder size={16} />
      <Space width="8px" />
      <TextPlaceholder>...</TextPlaceholder>
    </NaviBlock>

    <NaviBlock>
      <CirclePlaceholder size={16} />
      <Space width="8px" />
      <TextPlaceholder>......</TextPlaceholder>
    </NaviBlock>

    <NaviBlock>
      <CirclePlaceholder size={16} />
      <Space width="8px" />
      <TextPlaceholder>.....</TextPlaceholder>
    </NaviBlock>

    <Space height="16px" />

    <NaviBlock>
      <Box size={16} stroke={colors.superLightText} style={{flexShrink: 0}} />
      <Space width="8px" />
      <TextPlaceholder>....</TextPlaceholder>
    </NaviBlock>

    <NaviBlock>
      <Users size={16} stroke={colors.superLightText} style={{flexShrink: 0}} />
      <Space width="8px" />
      <TextPlaceholder>.....</TextPlaceholder>
    </NaviBlock>

    <NaviBlock>
      <UserPlus size={16} stroke={colors.superLightText} style={{flexShrink: 0}} />
      <Space width="8px" />
      <TextPlaceholder>...</TextPlaceholder>
    </NaviBlock>

    <NaviBlock>
      <Settings size={16} stroke={colors.superLightText} style={{flexShrink: 0}} />
      <Space width="8px" />
      <TextPlaceholder>......</TextPlaceholder>
    </NaviBlock>
  </div>);
}
