import * as React from 'react';
import {Loading} from '../../common/Loading';
import {EmptyWorkflows} from './EmptyWorkflows';
import {loadMoreButtonStyle, Table, Th} from '../../../base/components/WorkflowTable';
import {ColumnName, Self} from './types';
import {WorkflowRow} from './WorkflowRow';
import {findWorkflowsQuery} from '../../../gqls/workflowQueries';
import {StepFragment} from '../../../__generated__/StepFragment';
import {FindWorkflows, FindWorkflowsVariables} from '../../../__generated__/FindWorkflows';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {WorkflowSummaryFragment} from '../../../__generated__/WorkflowSummaryFragment';
import {tw} from 'twind';
import {ReactNode} from 'react';

function LoadFailure() {
  return <div className={tw`text-center color-lightText py-2`}>読み込みに失敗しました</div>;
}


export const defaultColumns: ColumnName[] = [
  'icon',
  'name',
  'status',
  'sourceWorkflow',
  'owner',
  'progress',
  'createdAt',
  'updatedAt',
];

interface WorkflowTableProps {
  variables: Partial<FindWorkflowsVariables>;
  renderEmpty?: () => ReactNode;
  renderLoading?: () => ReactNode;
  columns?: ColumnName[];
  enableLoadMore?: boolean;
  renderHeader?: (totalCount: number) => ReactNode;
}

export const WorkflowTable = (props: WorkflowTableProps) => {
  const {variables, renderEmpty, renderLoading, columns = defaultColumns, enableLoadMore, renderHeader = () => null} = props;
  const mergedVariables: FindWorkflowsVariables = {
    limit: 20,
    ...variables,
  };

  const {data, loading, error, fetchMore, networkStatus} = useAppQuery<FindWorkflows, FindWorkflowsVariables>(
    findWorkflowsQuery,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: mergedVariables,
      errorPolicy: 'ignore',
      onError(error) {
        // do nothing
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const {workflows} = data || {workflows: null};
  const refetching = networkStatus === 3;

  if (loading && !refetching && !data) {
    return (
      <>
        {renderLoading?.()}
        <Loading />
      </>
    );
  }

  if (error && !workflows) {
    return <LoadFailure />;
  }

  if (!workflows) {
    return <div />;
  }

  const {items, hasNext, endCursor} = workflows;

  if (items.length === 0) {
    return renderEmpty ? <>{renderEmpty()}</> : <EmptyWorkflows />;
  }

  const loadMore = () => {
    fetchMore({
      variables: {
        ...mergedVariables,
        after: endCursor,
      },
    });
  };

  const limit = typeof mergedVariables.limit === 'number' ? mergedVariables.limit : 20;

  return (
    <>
      {data?.workflows.totalCount && renderHeader(data.workflows.totalCount)}
      <InnerWorkflowTable
        totalCount={data?.workflows.totalCount ?? 0}
        workflows={items}
        columns={columns}
        self={(data || {}).self}
      />
      {enableLoadMore && error && <LoadFailure />}
      {enableLoadMore && hasNext && <LoadMore onClick={loadMore} loading={loading} limit={limit} />}
      {enableLoadMore && !hasNext && items.length > limit && <End />}
    </>
  );
};

function InnerWorkflowTable({
  workflows,
  steps,
  columns,
}: {
  totalCount: number;
  self?: Self;
  workflows: WorkflowSummaryFragment[];
  steps?: StepFragment[];
  columns: ColumnName[];
}) {
  const headerCellMap: {[Key in ColumnName]: React.ReactNode} = {
    icon: <th key="icon">&nbsp;</th>,
    status: <Th key="status">ステータス</Th>,
    name: <Th key="name">名前{steps ? ' / ステップ名' : ''}</Th>,
    progress: <Th key="progress">進捗</Th>,
    workflow: <Th key="workflow">ワークフロー</Th>,
    sourceWorkflow: <Th key="sourceWorkflow">元のワークフロー</Th>,
    owner: <Th key="owner">作成者</Th>,
    workspace: <Th key="workspace">ワークスペース</Th>,
    createdAt: <Th key="createdAt">作成日</Th>,
    updatedAt: <Th key="updatedAt">更新日</Th>,
    menu: <Th key="menu" />,
  };

  return (
    <>
      <Table>
        <tbody>
        <tr>{columns.map((columnName) => headerCellMap[columnName])}</tr>
        {workflows.map((workflow, index) => (
          <WorkflowRow
            key={workflow.id}
            workflow={workflow}
            step={steps ? steps[index] : undefined}
            columns={columns}
          />
        ))}
        </tbody>
      </Table>
    </>
  );
}

function LoadMore({limit, loading, ...props}: any) {
  const content = loading ? '読み込み中...' : `次の${limit}件を読み込む`;

  return (
    <>
      {loading && <Loading />}
      <button className={loadMoreButtonStyle} {...props} disabled={loading}>
        {content}
      </button>
    </>
  );
}

function End() {
  return (
    <button className={loadMoreButtonStyle} disabled={true}>
      これ以上ワークフローはありません
    </button>
  );
}
