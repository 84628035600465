import {useHistory} from 'react-router';
import {useOptionsMenu} from '../../base/components/OptionsMenu/hooks';
import {MenuHeader, MenuItem, MenuSeparator} from '../../base/components/OptionsMenu/OptionsMenu';
import {fetchSelfQuery} from '../../gqls/selfQueries';
import {Check, LogOut, Settings} from 'react-feather';
import {useWorkspaceId} from '../../base/hooks/useWorkspaceId';
import {useAppQuery} from '../../base/hooks/useAppQuery';
import {FetchSelf} from '../../__generated__/FetchSelf';
import {useCreateWorkspace} from './useCreateWorkspace';
import {QueitButton} from '../../base/components/Button/Button';
import {MoreHorizontal} from 'react-feather';

interface Props {
  includeWorkspaceMenu: boolean;
}

export function UserDetailIconMenu({includeWorkspaceMenu}: Props) {
  const workspaceId = useWorkspaceId();
  const {data} = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-first',
    onError() {
      // do nothing
    },
  });

  const {node} = useOptionsMenu({
    button: (
      <QueitButton>
        <MoreHorizontal size={16} strokeWidth={"1.5px"} />
      </QueitButton>
    ),
    menu(close) {
      return (
        <>
          <MenuItem
            icon={<Settings size={16} />}
            onClick={(event) => {
              close();
              history.push(`/workspaces/${workspaceId}/settings/personal`);
            }}>
            アカウントと設定…
          </MenuItem>
          {includeWorkspaceMenu && (
            <>
              <MenuItem
                onClick={() => {
                  close();
                  createWorkspace.run();
                }}>
                新しいワークスペース…
              </MenuItem>
              <MenuSeparator />
              <MenuHeader>ワークスペース</MenuHeader>
              {data &&
                data.self.workspaces.map((workspace) => {
                  const icon = workspaceId === workspace.id ? <Check size={16} /> : undefined;

                  return (
                    <MenuItem
                      key={workspace.id}
                      icon={icon}
                      onClick={() => {
                        close();
                        history.push(`/workspaces/${workspace.id}`);
                      }}>
                      {workspace.name}
                    </MenuItem>
                  );
                })}
            </>
          )}
          <MenuSeparator />
          <MenuItem
            icon={<LogOut size={16} />}
            onClick={() => {
              close();
              history.push('/logout');
            }}>
            ログアウト
          </MenuItem>
        </>
      );
    },
  });

  const history = useHistory();
  const createWorkspace = useCreateWorkspace();

  return node;
}
