import {Page, ContentBox} from '../Public';
import {State} from './StateContainer';
import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Initial} from './Initial';

export const NewUserRoute = () => {
  const [state, setState] = useState<State>({
    component: Initial,
    token: '',
    userName: '',
    userPassword: '',
    email: '',
  });

  const Content = state.component;

  return (
    <Page>
      <Helmet>
        <title>アカウントの作成</title>
      </Helmet>

      <ContentBox>
        <Content state={state} setState={setState} />
      </ContentBox>
    </Page>
  );
};
