import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

interface CTAButtonVariant {
  buttonColor: string;
  activeButtonColor: string;
}

const ctaButtonVariants = {
  green: {
    buttonColor: 'hsl(141deg 61% 34%)',
    activeButtonColor: 'hsl(141deg 61% 29%)',
  },
} as const;

function variant(key: keyof CTAButtonVariant, defaultValue: string) {
  return ({variant}: {variant?: keyof typeof ctaButtonVariants}) => {
    if (variant) {
      const values = ctaButtonVariants[variant];
      return key in values ? values[key] : defaultValue;
    }
    return defaultValue;
  };
}

export const SmallCtaButton = styled.button<{variant?: keyof typeof ctaButtonVariants}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  &:link,
  &:visited {
    letter-spacing: 0.08em;
    appearance: none;
    display: inline-flex;
    background-color: ${variant('buttonColor', colors.accentButton)};
    color: white;
    font-size: ${fontSizes.sm};
    font-weight: ${fontWeights.semiBold};
    padding: 0 8px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    vertical-align: middle;
    line-height: 1;

    font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;

    @media (-webkit-min-device-pixel-ratio: 2),
      (min--moz-device-pixel-ratio: 2),
      (min-resolution: 2dppx),
      (min-resolution: 192dpi) {
      font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
  }

  &:active {
    background-color: ${variant('activeButtonColor', colors.darkAccentButton)};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;

export const SmallDarkButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  &:link,
  &:visited {
    letter-spacing: 0.08em;
    appearance: none;
    display: inline-flex;
    background-color: ${colors.blackText};
    color: white;
    font-size: ${fontSizes.sm};
    font-weight: ${fontWeights.semiBold};
    padding: 0 8px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    vertical-align: middle;
    line-height: 1;

    font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;

    @media (-webkit-min-device-pixel-ratio: 2),
      (min--moz-device-pixel-ratio: 2),
      (min-resolution: 2dppx),
      (min-resolution: 192dpi) {
      font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
  }

  &:active {
    color: ${colors.superLightText};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;

export const SmallButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  &:link,
  &:visited {
    letter-spacing: 0.08em;
    appearance: none;
    display: inline-flex;
    color: ${colors.darkText};
    font-size: ${fontSizes.sm};
    font-weight: ${fontWeights.normal};
    padding: 0 8px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    background-color: transparent;
    border: 1px solid hsla(222, 30%, 50%, 20%);
    line-height: 1;
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    border: 1px solid hsla(222, 30%, 50%, 30%);
    background-color: hsla(222, 22%, 88%, 0.4);
  }

  &:active {
    background-color: hsl(222, 22%, 88%);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
    color: #bbb;
  }
`;

export const SmallLightCtaButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  &:link,
  &:visited {
    letter-spacing: 0.08em;
    appearance: none;
    display: inline-flex;
    justify-content: center;
    background-color: white;
    color: ${colors.darkAccent};
    font-size: ${fontSizes.sm};
    font-weight: ${fontWeights.normal};
    padding: 0 8px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    border: 1px solid ${colors.lightAccent};
    line-height: 1;
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
  }

  &:active {
    color: ${colors.darkAccent};
    background-color: ${colors.superLightAccent};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const SmallQuietButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  &:link,
  &:visited {
    letter-spacing: 0.08em;
    appearance: none;
    display: inline-flex;
    font-size: ${fontSizes.sm};
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    word-break: keep-all;
    border: 1px solid transparent;
    background-color: transparent;
    color: ${colors.darkText};
    font-weight: ${fontWeights.normal};
    padding: 0 8px;
    text-align: center;
    box-shadow: none;
    line-height: 1;
  }

  &:hover {
    border: 1px solid hsla(222, 30%, 50%, 20%);
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:active {
    color: ${colors.darkText};
    background-color: #f2f2f2;
    border: 1px solid hsla(222, 30%, 50%, 20%);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
    color: #bbb;
  }
`;

export const SmallDangerButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  &:link,
  &:visited {
    letter-spacing: 0.08em;
    appearance: none;
    display: inline-flex;
    background-color: ${colors.dangerBackground};
    color: white;
    font-size: ${fontSizes.sm};
    font-weight: ${fontWeights.normal};
    padding: 0 8px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    border: 1px solid transparent;
    line-height: 1;

    font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;

    @media (-webkit-min-device-pixel-ratio: 2),
      (min--moz-device-pixel-ratio: 2),
      (min-resolution: 2dppx),
      (min-resolution: 192dpi) {
      font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    background-color: #c22525;
  }

  &:active {
    background-color: ${colors.darkDangerBackground};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;

export const ExLargeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: ${colors.blackText};
  border-radius: 6px;
  color: white;
  text-align: center;
  height: 42px;
  font-size: 15px;
  font-weight: ${fontWeights.bold};
  border: 1px solid rgba(0, 0, 0, 0.04);
  margin: 8px 0 8px 0;
  cursor: pointer;
  letter-spacing: 0.08em;
  line-height: 1;
  width: 100%;

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:active {
    background-color: ${colors.darkAccent};
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  &:link,
  &:visited,
  &:active {
    color: white;
  }
`;

export const Button = styled(SmallButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: ${fontSizes.base};
  }
`;

export const LightCtaButton = styled(SmallLightCtaButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: ${fontSizes.base};
  }
`;

export const QueitButton = styled(SmallQuietButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: ${fontSizes.base};
  }
`;

export const SubtleQueitButton = styled(SmallQuietButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: ${fontSizes.base};
    color: ${colors.darkText};
  }

  &:hover {
    color: ${colors.blackText};
    border-color: transparent;
    background-color: hsla(222, 30%, 50%, 10%);
  }

  &:active {
    color: ${colors.blackText};
    border-color: transparent;
    background-color: hsla(222, 30%, 50%, 10%);
  }
`;

export const SmallSubtleQuietButton = styled(SmallQuietButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.sm};
    color: ${colors.darkText};
  }

  &:hover {
    color: ${colors.blackText};
  }

  &:active {
    color: ${colors.blackText};
  }
`;

export const SubtleQuietButton = styled(SmallSubtleQuietButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: ${fontSizes.base};
  }
`;

export const CtaButton = styled(SmallCtaButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: ${fontSizes.base};
  }
`;

export const DarkButton = styled(SmallDarkButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    background-color: ${colors.blackText};
    font-size: ${fontSizes.base};
  }
`;

export const DangerButton = styled(SmallDangerButton)`
  &,
  &:link,
  &:visited {
    height: 30px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: ${fontSizes.base};
  }
`;

export const MinimumButton = styled.button`
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  border-radius: 4px;
  line-height: 1;
  height: 22px;
  width: 22px;
  padding: 0;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-sizing: border-box;
  margin: 0;
  color: ${colors.darkText};
  font-weight: ${fontWeights.normal};
  transition: none;
  vertical-align: middle;

  &:hover {
    background-color: hsl(212, 5%, 92%);
    transition: none;
  }

  &:focus {
    outline: none;
  }

  &:active {
    transition: none;
    background-color: hsl(212, 8%, 88%);
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export const LargeButton = styled(SmallButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.semiBold};
    height: 36px;
    border-radius: 5px;
    padding: 0 10px;
  }
`;

export const LargeCtaButton = styled(SmallCtaButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    height: 36px;
    border-radius: 5px;
    padding: 0 10px;
  }
`;

export const LargeLightCtaButton = styled(SmallLightCtaButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    height: 36px;
    border-radius: 5px;
    padding: 0 10px;
  }
`;

export const LargeDangerButton = styled(SmallDangerButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    height: 36px;
    border-radius: 5px;
    padding: 0 10px;
  }
`;

export const LargeDarkButton = styled(DarkButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    height: 36px;
    border-radius: 5px;
    padding: 0 10px;
  }
`;

export const LargeQuietButton = styled(SmallQuietButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    height: 36px;
    border-radius: 5px;
    padding: 0 10px;
  }
`;

export const LargeSubtleQuietButton = styled(SmallSubtleQuietButton)`
  &,
  &:link,
  &:visited {
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    height: 36px;
    border-radius: 5px;
    padding: 0 10px;
  }
`;
