import {Link} from 'react-router-dom';
import {useState} from 'react';
import {Indicator} from '../../base/components/Indicator/Indicator';
import {run, isEmail, isEmpty} from '../../base/validators';
import {css} from '@emotion/css';
import {Page, ContentBox, ContentHeaderText, P, Errors, FieldLabel, ErrorItem, A} from './Public';
import {Helmet} from 'react-helmet-async';
import {requestPasswordResetMutation} from '../../gqls/passwordResetMutations';
import {Field, LargeTextInput} from '../../base/components/Form/Form';
import {ExLargeButton} from '../../base/components/Button/Button';
import {useAppMutation} from '../../base/hooks/useAppMutation';
import {RequestPasswordReset, RequestPasswordResetVariables} from '../../__generated__/RequestPasswordReset';
import {fontWeights} from 'theme';
import {HR} from '../../base/components/settings/SettingLayout';
import {tw} from 'twind';

interface Props {
  title: string;
}

interface State {
  email: string;
  errors: string[];
  done: boolean;
}

export const ForgotPassword = ({title}: Props) => {
  const [state, setState] = useState<State>({
    email: '',
    errors: [],
    done: false,
  });
  const {email, errors, done} = state;

  const onChangeEmail = (event: FIXME) => setState({...state, email: event.target.value});

  const [requestPasswordReset, mutateResult] = useAppMutation<RequestPasswordReset, RequestPasswordResetVariables>(
    requestPasswordResetMutation,
    {
      onError() {
        setState({...state, errors: ['何らかのエラーが起きました。']});
      },
      onCompleted() {
        setState({...state, done: true});
      },
    }
  );
  const sending = mutateResult.loading;

  const send = async (event: FIXME) => {
    event.preventDefault();
    const errors = run(validate);

    if (errors.length > 0) {
      return setState({...state, errors});
    }

    setState({...state, errors: []});
    requestPasswordReset({variables: {email}});
  };

  const validate = function* (): Generator<string> {
    if (isEmpty(email)) {
      return yield 'メールアドレスを入力してください。';
    }

    if (!isEmail(email)) {
      yield 'メールアドレスを正しく入力してください。';
    }
  };

  if (done) {
    return (
      <Page>
        <ContentBox>
          <ContentHeaderText>メールを送信しました</ContentHeaderText>

          <P>
            <strong className={sentMailStyle}>{email}</strong>
            宛にパスワードを再設定するためのリンクを記載したメールを送信しました。
          </P>

          <div style={{textAlign: 'center'}}>
            <Link to="/login" component={A}>
              ログインヘ戻る
            </Link>
          </div>
        </ContentBox>
      </Page>
    );
  }

  return (
    <Page>
      <Helmet>
        <title>パスワードを忘れた</title>
      </Helmet>
      <ContentBox>
        <form onSubmit={send}>
          <ContentHeaderText>{title}</ContentHeaderText>

          <P>メールアドレスを入力して下さい。パスワードを再設定するリンクのメールを送信します。</P>

          <FieldLabel>メールアドレス</FieldLabel>

          <LargeTextInput
            autoFocus
            type="text"
            placeholder="user@example.com"
            value={email}
            onChange={onChangeEmail}
            disabled={sending}
          />

          <Errors>
            {errors.map((error) => (
              <ErrorItem key={error}>{error}</ErrorItem>
            ))}
          </Errors>

          <Field>
            <ExLargeButton type="submit" disabled={sending}>
              {sending ? <Indicator modifier="white" /> : '送信'}
            </ExLargeButton>
          </Field>

          <HR />

          <div className={tw`text-center my-4`}>
            <Link to="/login" component={A}>
              ログインに戻る
            </Link>
          </div>
        </form>
      </ContentBox>
    </Page>
  );
};

export const ForgotPasswordRoute = ForgotPassword;

const sentMailStyle = css`
  font-weight: ${fontWeights.bold};
  color: #0d1b27;
`;
