import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {colors, fontWeights} from 'theme';

export const WorkflowHeaderBlock = styled.div`
  height: 52px;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid ${colors.border};
  box-sizing: border-box;
  padding: 0 12px;
`;

export const WorkflowHeaderPlacholder = styled.div`
  height: 52px;
  width: 100%;
  content: '';
`;

export const WorkflowNameTitle = styled.span`
  font-size: 16px;
  color: ${colors.blackText};
  font-weight: ${fontWeights.semiBold};
`;

export const BackButton = styled(Link)`
  appearance: none;
  background-color: transparent;
  border: none;
  width: 20px;
  position: relative;
  border-radius: 4px;
  margin: 0 4px 0 0;
  height: 32px;

  &::after {
    height: 8px;
    width: 8px;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    border-top: 1px solid ${colors.darkText};
    border-left: 1px solid ${colors.darkText};
    transform: translate(-30%, -50%) rotate(-45deg);
  }

  &:active::after {
    border-top: 1px solid ${colors.blackText};
    border-left: 1px solid ${colors.blackText};
  }

  &:hover {
    background-color: hsl(217, 10%, 95%);
  }

  &:active {
    background-color: hsl(217, 10%, 91%);
  }

  &:focus {
    outline: none;
  }
`;
