import {css} from '@emotion/css';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {colors} from 'theme';
import {tw} from 'twind';
import {HorizontalBox, Space, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {NakedWorkflowIcon} from '../../../base/components/WorkflowIcon/WorkflowIcon';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';
import {UserIconMenu} from '../../common/UserIconMenu';

const runs = [
  {id: '2', number: 32, name: '7月分の給与支払い', currentStep: '給与振込', workflow: {id: '', name: '給与支払い', icon: null}},
  {id: '1', number: 33, name: '6月分の給与支払い', currentStep: '給与計算', workflow: {id: '', name: '給与支払い', icon: null}},
  {id: '0', number: 34, name: '5月分の給与支払い(修正)', currentStep: '終了', workflow: {id: '', name: '給与支払い', icon: 'fe:dollarSign'}},
];

export const WorkflowRunsRoute = () => {
  const workspaceId = useWorkspaceId();
  const Separator = useCallback(() => <hr className={css`margin-left: 48px; border-color: ${colors.lightBorder}`} />, []);

  return (
    <div>
      <HorizontalBox>
        <h1 className={tw`font-semiBold text-xl`}>起動したワークフロー</h1>
        <SpringSpace />
        <UserIconMenu workspaceId={workspaceId} />
      </HorizontalBox>

      <Space height="8px" />
      <div className={tw`text-superLightText text-base`}>
        {runs.length}件のワークフローが見つかりました
      </div>
      <Space height="16px" />

      <Separator />

      {runs.map(run => {
        return (
          <React.Fragment key={run.id}>
            <div className={tw`py-3`}>
              <HorizontalBox>
                <Space width="8px" />
                <NakedWorkflowIcon workflow={run.workflow} size={28} strokeWidth={1} />
                <Space width="12px" />
                <div>
                  <HorizontalBox>
                    <Link to={`/workspaces/${workspaceId}/workflow_runs/${run.id}`}
                      className={tw`font-semiBold text-base text-black link:text-black visited:text-black hover:text-darkAccent`}>
                        {run.name}
                      <span
                        className={tw`font-semiBold text-superLightText`}>
                          &nbsp;#{run.number}
                      </span>
                    </Link>

                    <Space width="12px" />
                    → {run.currentStep}
                  </HorizontalBox>
                  <div>
                    <Link
                      className={tw`text-sm`}
                      to={`/workspaces/${workspaceId}/workflows/${run.workflow.id}`}>
                      {run.workflow.name}ワークフロー
                    </Link>
                  </div>
                </div>
              </HorizontalBox>
            </div>
            <Separator />
          </React.Fragment>
        );
      })}
    </div>
  );
}
