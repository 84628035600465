import {Route, Switch, useParams} from 'react-router-dom';
import {LoadingWorkflow} from './LoadingWorkflow';
import {css, cx} from '@emotion/css';
import {Helmet} from 'react-helmet-async';
import {findWorkflowQuery} from '../../../gqls/workflowQueries';
import {FindWorkflow, FindWorkflowVariables} from '../../../__generated__/FindWorkflow';
import {StepBalloon} from './StepBalloon';
import {LightStepPanel, StepPanelRoute} from './StepPanel';
import {NewStepContainer} from './NewStepContainer';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {NotFoundRoute} from '../../NotFoundRoute';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {WorkflowHeader} from './WorkflowHeader';
import {useToast} from '../../../base/components/Toast/Toast';
import {WorkflowDescriptionPanel} from './WorkflowDescriptionPanel';
import {cache} from '../../../base/modules/apolloClient';
import {Fragment, useEffect} from 'react';
import {colors} from 'theme';
import {Field, Label} from '../../../base/components/Form/Form';
import {EyeCatch} from './EyeCatch';
import {LoadingErrorBlock} from '../../../base/LoadingErrorBlock';
import {pusherClient} from '../../../base/modules/pusherClient';
import {WorkflowPreview} from './WorkflowPreview';
import {tw} from 'twind';
import {CustomNavLink} from '../../common/CustomNavLink';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';

export const WorkflowRoute = () => {
  const {workflowId} = useParams<{workflowId: string}>();
  const toast = useToast();
  const {data, error, loading, refetch} = useAppQuery<FindWorkflow, FindWorkflowVariables>(findWorkflowQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {id: workflowId},
    onError() {
      toast.error('ワークフローの取得に失敗しました');
    },
  });

  useEffect(() => {
    const channel = pusherClient.subscribe(`workflow-${workflowId}`);
    channel.bind('update', () => {
      refetch();
    });

    return () => {
      channel.disconnect();
    };
  }, [workflowId, refetch]);

  useResetCollapseEffect(workflowId);

  if (error) {
    return <LoadingErrorBlock>ワークフローの取得に失敗しました</LoadingErrorBlock>;
  }

  if (loading || !data) {
    return <LoadingWorkflow />;
  }

  const {workflow} = data;

  if (!workflow) {
    return <NotFoundRoute />;
  }

  return (
    <>
      <Helmet>
        <title>{workflow.name}</title>
      </Helmet>
      <WorkflowContent workflow={workflow} />
    </>
  );
};

interface ContentProps {
  workflow: WorkflowFragment;
}

const WorkflowContent = ({workflow}: ContentProps) => {
  const {steps} = workflow;
  const findStep = (stepId: string) => steps.find((step) => step.id === stepId);

  const isEditable = workflow.canEdit;
  const workspaceId = useWorkspaceId();

  return (
    <>
      <WorkflowHeader workflow={workflow} />

      <div className={css`width: 320px; position: fixed;`}>
        {false && !workflow.collapse && (
          <Switch>

            <Route exact path="/workspaces/:workspaceId/workflows/:workflowId/preview">{null}</Route>
            <Route>
              <div
                className={css`
                  padding: 40px 20px 20px 32px;
                `}>
                <h2 className={tw`font-bold text-lightText text-sm`} style={{margin: '0 0 2px 0'}}>ステップ</h2>
                {steps.map((step, index) => (
                  <StepBalloon
                    key={step.id}
                    step={step}
                    workflow={workflow}
                    editable={isEditable}
                  />
                ))}
                {isEditable && <NewStepContainer workflowId={workflow.id} />}
                <br />
                <div>
                  <CustomNavLink to={`/workspaces/${workspaceId}/workflows/${workflow.id}/settings`} activeClassName="" className="">設定</CustomNavLink>
                </div>
              </div>
            </Route>
          </Switch>
        )}
      </div>

      <div className={cx(tw`flex mx-auto`, css`width: 700px;`)}>

        <Switch>
          <Route path="/workspaces/:workspaceId/workflows/:workflowId/steps/:stepId">
            {({match}: FIXME) => {
              const {stepId} = match.params;
              const step = findStep(stepId);

              return <StepPanelRoute key={stepId} workflow={workflow} step={step} />;
            }}
          </Route>
          <Route path="/workspaces/:workspaceId/workflows/:workflowId/description">
            {() => <WorkflowDescriptionPanel workflow={workflow} />}
          </Route>
          <Route path="/workspaces/:workspaceId/workflows/:workflowId/settings">{() => <WorkflowSettings workflow={workflow} />}</Route>
          <Route path="/workspaces/:workspaceId/workflows/:workflowId/preview">{() => <WorkflowPreview workflow={workflow} />}</Route>
          <Route exact path="/workspaces/:workspaceId/workflows/:workflowId">
            {() => {
              return <AllSteps workflow={workflow} />;
            }}
          </Route>
          <Route exact path="/workspaces/:workspaceId/workflows/:workflowId">
            {() => {
              return <AllSteps workflow={workflow} />;
            }}
          </Route>
        </Switch>

      </div>



    </>
  );
};

function AllSteps({workflow}: {workflow: WorkflowFragment}) {
  return (
    <>
      <div className={tw`flex flex-col` + ' ' + css` padding: 38px 0; width: 100%;`}>
        {workflow.steps.map((step, index) => {
          return (
            <Fragment key={step.id}>
              {index === 0 && (
                <div className={tw`flex flex-col items-center`}>
                  <div className={tw`border-border border text-lightText text-sm font-bold rounded-xl px-2`}>開始</div>
                  <hr style={{width: '1px', height: '20px', border: 'none', backgroundColor: colors.borderDark}} />
                  <NewStepContainer workflowId={workflow.id} before={workflow.steps[index].id} />
                  <hr style={{width: '1px', height: '20px', border: 'none', backgroundColor: colors.borderDark}} />
                </div>
              )}

              <div id={`step-${step.id}`} className={css`
                scroll-margin: 92px;
                padding: 28px 36px;
                border: 1px solid ${colors.borderDark};
                border-radius: 6px;
              `}>
                <LightStepPanel workflow={workflow} step={step} />
              </div>

              <div className={tw`flex flex-col items-center`}>
                <hr style={{width: '1px', height: '20px', border: 'none', backgroundColor: colors.borderDark}} />
                <NewStepContainer workflowId={workflow.id} before={workflow.steps[index + 1]?.id ?? undefined} />
                {index !== workflow.steps.length - 1 && <hr style={{width: '1px', height: '20px', border: 'none', backgroundColor: colors.borderDark}} />}
                {index === workflow.steps.length -1 && (
                  <>
                    <hr style={{width: '1px', height: '20px', border: 'none', backgroundColor: colors.borderDark}} />
                    <div className={tw`border-border border text-lightText text-sm font-bold rounded-xl px-2`}>終了</div>
                  </>
                )}
              </div>

            </Fragment>
          );
        })}
        <div style={{height: '80vh'}}></div>
      </div>
    </>
  );
}

function WorkflowSettings({workflow}: {workflow: WorkflowFragment}) {
  return (
    <div
      className={css`
        width: 700px;
        margin: 40px auto;
      `}>
      <h1 className={tw`text-blackText font-bold mb-8`}>設定</h1>

      <Field>
        <Label>ワークフロー名</Label>
        <div className={tw`font-bold text-blackText`}>{workflow.name}</div>
      </Field>

      <Field>
        <Label>カバー画像</Label>
        <EyeCatch workflow={workflow} />
      </Field>
    </div>
  );
}

function useResetCollapseEffect(workflowId: string) {
  useEffect(() => {
    // workflow.collapse === trueのままになると、最大表示のままになってしまうので状態をリセットする
    return () => {
      const data = cache.readQuery<FindWorkflow, FindWorkflowVariables>({
        query: findWorkflowQuery,
        variables: {id: workflowId},
      });

      if (data && data.workflow) {
        cache.writeQuery<FindWorkflow, FindWorkflowVariables>({
          query: findWorkflowQuery,
          variables: {id: workflowId},
          data: {
            workflow: {
              ...data.workflow,
              collapse: false,
            },
          },
        });
      }
    };
  }, [workflowId]);
}
