import {tw} from 'twind';

export const Header = ({className, ...rest}: JSX.IntrinsicElements['h2']) => (
  <h2 className={tw`font-semiBold text-md text-blackText` + ' ' + className} {...rest}>{rest.children}</h2>
);

export const HeaderBlock = ({className, ...rest}: JSX.IntrinsicElements['div']) => (
  <div className={tw`mb-2` + ' ' + className} {...rest} />
);

export const SubHeader = ({className, ...rest}: JSX.IntrinsicElements['h3']) => (
  <h3 className={tw`font-semiBold text-md text-blackText` + ' ' + className} {...rest}>{rest.children}</h3>
);

export const SubHeaderBlock = ({className, ...rest}: JSX.IntrinsicElements['div']) => (
  <div className={tw`mt-4 mb-2` + ' ' + className} {...rest} />
);

export const FieldLabel = ({className, ...rest}: JSX.IntrinsicElements['div']) => (
  <div className={tw`text-darkText mb-0 text-sm` + ' ' + className} {...rest} />
);
