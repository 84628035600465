import Editor, {theme} from '@relayhub/editor';
import {EditorContent} from './EditorContent';
import {forwardRef, Ref, useImperativeHandle, useRef, ReactNode} from 'react';
import {useToast} from '../Toast/Toast';
import {Tooltip} from '../Tooltip/Tooltip';
import {colors} from 'theme';
import {Prompt} from 'react-router-dom';

type BaseProps = Editor['props'];

interface Props {
  defaultValue: string;
  placeholder?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  footer?: ReactNode;
  readOnlyWriteCheckboxes?: boolean;
  uploadImage?: BaseProps['uploadImage'];
  onChange?: () => void;
  onSave?: BaseProps['onSave'];
  onCancel?: BaseProps['onCancel'];
  onImageUploadStart?: BaseProps['onImageUploadStart'];
  onImageUploadStop?: BaseProps['onImageUploadStop'];
  onCreateLink?: BaseProps['onCreateLink'];
  onSearchLink?: BaseProps['onSearchLink'];
  onClickLink?: BaseProps['onClickLink'];
  onHoverLink?: BaseProps['onHoverLink'];
  onClickHashtag?: BaseProps['onClickHashtag'];
  onShowToast?: BaseProps['onShowToast'];
  className?: string;
}

export interface DocumentEditorHandle {
  export: () => string | null;
}

export const DocumentEditor = forwardRef((props: Props, ref: Ref<DocumentEditorHandle>) => {
  const {onChange, footer = null, defaultValue, ...rest} = props;
  const valueRef = useRef<null | (() => string)>(null);

  useImperativeHandle(ref, () => {
    return {
      export() {
        return valueRef.current ? valueRef.current() : null;
      },
    };
  });

  const toast = useToast();

  return (
    <>
      <Prompt when={!props.readOnly} message={'編集中の内容が失われる恐れがあります。続行しますか?'} />
      <EditorContent readOnly={props.readOnly}>
        <Editor
          onShowToast={(message, code) => {
            toast.message(message);
          }}
          defaultValue={defaultValue}
          tooltip={EditorTooltip}
          onChange={(value) => {
            valueRef.current = value;
            if (onChange) {
              onChange();
            }
          }}
          dictionary={dict}
          theme={{
            ...theme,
            fontFamily: `"-apple-system", "BlinkMacSystemFont", Hiragino Sans, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Helvetica Neue, Helvetica, "メイリオ", Meiryo, sans-serif`,
            selected: colors.accent,
            tableSelected: colors.accent,
            tableSelectedBackground: colors.background,
            tableDivider: colors.border,
            ...{tableHeaderBackground: colors.lightBackground},
            link: colors.accent,
            placeholder: colors.placeholderText,
            text: colors.blackText,
            textLight: colors.lightText,
            textSecondary: colors.superLightText,
            blockToolbarTrigger: colors.background,
            blockToolbarHoverBackground: colors.background,

            noticeTipBackground: '#fff8e5',
            noticeTipText: '#92400E',

            noticeInfoBackground: colors.background,
            noticeInfoText: colors.blackText,

            noticeWarningBackground: '#fbeeee',
            noticeWarningText: '#991B1B',
            codeBackground: colors.background,
            primary: colors.accent,
            background: 'transparent',
          }}
          {...rest}
        />
        {footer}
      </EditorContent>
    </>
  );
});

export const dict = {
  addColumnAfter: '列を後ろに挿入する',
  addColumnBefore: '列を前に挿入する',
  addRowAfter: '行を後ろに挿入する',
  addRowBefore: '行を前に挿入する',
  alignCenter: '中央',
  alignLeft: '左寄せ',
  alignRight: '右寄せ',
  bulletList: 'リスト',
  checkboxList: 'TODOリスト',
  codeBlock: 'コード',
  codeCopied: 'クリップボードにコピーしました',
  codeInline: 'コード',
  createLink: 'リンクを作成',
  createLinkError: 'リンクの作成に失敗しました',
  createNewDoc: '新しいドキュメントを作成する',
  deleteColumn: '列を削除する',
  deleteRow: '行を削除する',
  deleteTable: '表を削除する',
  deleteImage: '画像を削除する',
  alignImageLeft: '左寄せ',
  alignImageRight: '右寄せ',
  alignImageDefault: '中央',
  em: 'イタリック',
  embedInvalidLink: 'そのリンクは埋め込むことができません',
  findOrCreateDoc: '新しいドキュメントを作成する、もしくは検索…',
  h1: '見出し(大)',
  h2: '見出し(中)',
  h3: '見出し(小)',
  heading: '見出し',
  hr: '水平線',
  image: '画像',
  imageUploadError: '画像のアップロードに失敗しました',
  info: 'インフォメーション',
  infoNotice: 'インフォメーション',
  link: 'リンク',
  linkCopied: 'リンクをクリップボードにコピーしました',
  mark: 'ハイライト',
  newLineEmpty: "'/'で挿入...",
  newLineWithSlash: 'キーワードで絞り込み…',
  noResults: '結果が見つかりません',
  openLink: 'リンクを開く',
  orderedList: '順序付きリスト',
  pasteLink: 'リンクを貼り付ける…',
  pasteLinkWithTitle: (title: string) => `${title}のリンクを貼り付ける…`,
  placeholder: 'プレースホルダ',
  quote: '引用',
  removeLink: 'リンクを削除する',
  searchOrPasteLink: '検索する、もしくはリンクを貼る…',
  strikethrough: '打ち消し線',
  strong: '太字',
  subheading: 'サブヘッダ',
  table: '表',
  tip: 'チップ',
  tipNotice: 'チップ',
  warning: '警告',
  warningNotice: '警告',
};

function EditorTooltip(props: {tooltip: string; children: ReactNode; placement: string}) {
  const {children, tooltip} = props;
  return (
    <Tooltip text={tooltip}>
      <span>{children}</span>
    </Tooltip>
  );
}
