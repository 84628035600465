// メモ: schemaはJSON schemaのサブセットにする
// builderの内部状態はまた別にするオプションあり

import * as z from 'zod';

export const textInputWidget = z.object({
  widgetType: z.literal('input'),
  placeholder: z.string().optional(),
});

export const textareaWidget = z.object({
  widgetType: z.literal('textarea'),
  placeholder: z.string().optional(),
});

export const schemaBase = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

type SchemaBase = z.infer<typeof schemaBase>;

export type TextInputWidget = z.infer<typeof textInputWidget>;

export const stringSchema = schemaBase.extend({
  type: z.literal('string'),
  default: z.string().optional(),
  'ui.widget': z.lazy(() => stringWidget).optional(),
});

export type StringSchema = z.infer<typeof stringSchema>;

export const wysiwygWidget = z.object({
  widgetType: z.literal('wysiwyg'),
  placeholder: z.string().optional(),
});

export type WysiwygWidget = z.infer<typeof wysiwygWidget>;

const dateWidget = z.object({
  widgetType: z.literal('date'),
});

export type DateWidget = z.infer<typeof dateWidget>;

const stringWidget = z.union([textInputWidget, textareaWidget]);

export type StringWidget = z.infer<typeof stringWidget>;

export type SchemaNode = StringSchema | ObjectSchema;

const objectSchema: z.ZodSchema<ObjectSchema> = schemaBase.extend({
  type: z.literal('object'),
  properties: z.record(z.union([stringSchema, z.lazy(() => objectSchema)])),
  required: z.array(z.string()).optional(),
  additionalProperties: z.literal(false),
});

// zodとTypeScriptの制限によりここは型を手書きする必要あり。
export interface ObjectSchema extends SchemaBase {
  type: 'object';
  properties: {
    [key: string]: StringSchema | ObjectSchema;
  };
  required?: string[];
  additionalProperties: false;
}

export const schemaRoot = objectSchema;

export type SchemaRoot = ObjectSchema;
