import {useHistory} from 'react-router-dom';
import {useOptionsMenu} from '../../base/components/OptionsMenu/hooks';
import {useAppQuery} from '../../base/hooks/useAppQuery';
import {FetchSelf} from '../../__generated__/FetchSelf';
import {fetchSelfQuery} from '../../gqls/selfQueries';
import {UserIconContainer} from './UserIconContainer';
import {MenuHeader, MenuItem, MenuSeparator} from '../../base/components/OptionsMenu/OptionsMenu';
import styled from '@emotion/styled';
import {LogOut, Settings, Check, Send, FileText, ChevronDown} from 'react-feather';
import {UserBlock} from './UserBlock';
import {colors} from 'theme';

const feedbackUrl = 'https://forms.gle/h1jREfcfR3weoBgx9';

export function UserIconMenu({workspaceId}: {workspaceId: string}) {
  const history = useHistory();
  const self = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-first',
    onError() {
      // Do nothing
    },
  });

  const user = self.data?.self;

  const {node} = useOptionsMenu({
    button: (
      <Wrapper>
        <UserIconContainer user={user} size={32} />
        <ChevronDown size={16} fill={colors.blackText} stroke={"transparent"} style={{marginLeft: "2px"}} />
      </Wrapper>
    ),
    menu(close) {
      return (
        <>
          <div style={{padding: '8px 4px'}}>{user && <UserBlock user={user} />}</div>
          <MenuSeparator />
          <MenuHeader>ワークスペース</MenuHeader>
          {user &&
            user.workspaces.map((workspace) => {
              const icon = workspaceId === workspace.id ? <Check size={16} /> : undefined;
              return (
                <MenuItem
                  key={workspace.id}
                  icon={icon}
                  onClick={() => {
                    close();
                    history.push(`/workspaces/${workspace.id}`);
                  }}>
                  {workspace.name}
                </MenuItem>
              );
            })}
          <MenuSeparator />
          <MenuItem
            icon={<Settings size={16} />}
            onClick={(event) => {
              close();
              history.push(`/workspaces/${workspaceId}/settings/personal`);
            }}>
            アカウントと設定…
          </MenuItem>
          <MenuItem
            icon={<Send size={16} strokeWidth={1.5} />}
            onClick={() => {
              window.open(feedbackUrl);
              close();
            }}>
            フィードバック
          </MenuItem>
          <MenuItem
            icon={<FileText size={16} />}
            onClick={() => {
              window.open('/terms_of_use');
              close();
            }}>
            利用規約
          </MenuItem>
          <MenuSeparator />
          <MenuItem
            icon={<LogOut size={16} />}
            onClick={() => {
              close();
              history.push('/logout');
            }}>
            ログアウト
          </MenuItem>
        </>
      );
    },
  });

  return node;
}

const Wrapper = styled.button`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  
  &:hover > span {
    filter: brightness(90%);
  }
  
  :focus:not(:focus-visible) {
    outline: none;
  }
`;
