import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';
import {useHistory} from 'react-router-dom';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {FetchSelf} from '../../../__generated__/FetchSelf';
import {fetchFavorites, fetchSelfQuery} from '../../../gqls/selfQueries';
import {FetchFavorites, FetchFavoritesVariables} from '../../../__generated__/FetchFavorites';
import {UserPanel, LoadingUserPanel} from './UserPanel';
import {WorkspacePanel, LoadingWorkspacePanel} from './WorkspacePanel';
import {Fill, Bottom} from 'react-spaces';
import {colors} from 'theme';
import {css} from '@emotion/css';
import {FetchWorkspace, FetchWorkspaceVariables} from '../../../__generated__/FetchWorkspace';
import {fetchWorkspaceQuery} from '../../../gqls/workspaceQueries';
import {CountWorkspaceWorkflows, CountWorkspaceWorkflowsVariables} from '../../../__generated__/CountWorkspaceWorkflows';
import {countWorkspaceWorkflows} from '../../../gqls/workflowQueries';

interface Props {
  workspaceId?: string;
}

export function DashboardNavi(props: Props) {
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const selfResponse = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-first',
    onError() {
      // do nothing
    },
  });

  // prefetch
  useAppQuery<FetchWorkspace, FetchWorkspaceVariables>(fetchWorkspaceQuery, {
    fetchPolicy: "cache-first",
    variables: {
      id: workspaceId,
    },
    onError() {
      // do nothing
    },
    onCompleted(data) {
      if (data && !data.workspace) {
        history.push('/');
      }
    }
  });

  const {data: countData} = useAppQuery<CountWorkspaceWorkflows, CountWorkspaceWorkflowsVariables>(
    countWorkspaceWorkflows,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        workspaceId,
      },
      onError(err) {
        // do nothing
      },
    }
  );

  const {data: favData} = useAppQuery<FetchFavorites, FetchFavoritesVariables>(fetchFavorites, {
    fetchPolicy: 'cache-and-network',
    variables: {
      workspaceId,
    },
    onError() {
      // do nothing
    },
  });

  const workspace = selfResponse.data?.self.workspaces.find(workspace => workspace.id === workspaceId);

  const loading = !favData || !workspace || !countData;

  return (
    <>
      <Fill scrollable>
        <div
          className={css`
            margin: 16px;
          `}>
          {loading && <LoadingWorkspacePanel />}
          {!loading && favData && workspace && countData && <WorkspacePanel workspace={workspace} favs={favData} countData={countData} />}
        </div>
      </Fill>
      <Bottom size={70}>
        <div
          className={css`
          margin: 0 16px;
          padding: 8px 0 0 0;
          border-top: 1px solid ${colors.border};
        `}>
          {selfResponse.data?.self && <UserPanel self={selfResponse.data?.self} />}
          {!selfResponse.data?.self && <LoadingUserPanel />}
        </div>
      </Bottom>
    </>
  );
}

