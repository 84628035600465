import {useMemo} from 'react';
import {StepFragment} from '../../../__generated__/StepFragment';
import {CustomForm} from '../../../base/components/CustomForm/CustomForm';
import {useDraft} from '../../../base/components/CustomForm/utils';
import {LargeButton, LargeDarkButton, SubtleQueitButton} from '../../../base/components/Button/Button';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {updateFormDataMutation, updateFormSchemaMutation} from '../../../gqls/workflowMutations';
import {UpdateFormData, UpdateFormDataVariables} from '../../../__generated__/UpdateFormData';
import {useToast} from '../../../base/components/Toast/Toast';
import {HorizontalBox, Space} from '../../../base/components/HorizontalBox/HorizontalBox';
import {Edit, Trash2, MoreHorizontal} from 'react-feather';
import {SchemaRoot, schemaRoot} from '../../../base/components/CustomForm/schema';
import styled from '@emotion/styled';
import {JSONMap} from '../../../base/components/CustomForm/types';
import {UpdateFormSchema, UpdateFormSchemaVariables} from '../../../__generated__/UpdateFormSchema';
import {useOptionsMenu} from '../../../base/components/OptionsMenu/hooks';
import {MenuItem} from '../../../base/components/OptionsMenu/OptionsMenu';
import {useModalDialog} from '../../../base/components/ModalDialog/ModalDialog';
import {colors} from 'theme';
import * as React from 'react';

type Form = {
  schema?: SchemaRoot;
  data?: JSONMap;
};

interface RunnerProps {
  step: StepFragment;
  editForm?: () => void;
  disabled?: boolean;
  preview?: boolean;
}

export function CustomFormRunner(props: RunnerProps) {
  const {step, editForm, preview = false, disabled = false} = props;
  const toast = useToast();

  const modal = useModalDialog();

  const [updateFormData, updateFormDataResult] = useAppMutation<UpdateFormData, UpdateFormDataVariables>(
    updateFormDataMutation,
    {
      onError() {
        toast.error('保存に失敗しました');
      },
    }
  );

  const [updateFormSchema, updateFormSchemaResult] = useAppMutation<UpdateFormSchema, UpdateFormSchemaVariables>(
    updateFormSchemaMutation,
    {
      onError() {
        toast.error('保存に失敗しました');
      },
    }
  );

  const loading = updateFormDataResult.loading || updateFormSchemaResult.loading;

  const form: Form = useMemo(() => {
    try {
      return JSON.parse(step.form || '');
    } catch {
      return {};
    }
  }, [step.form]);

  const removeForm = async () => {
    const yes = await modal.confirm({
      title: 'フォームを削除しますか?',
      message: '一度フォームを削除すると元のデータを復元できませんが、フォームを削除しますか?',
    });

    if (yes) {
      updateFormSchema({
        variables: {
          id: step.workflowId,
          stepId: step.id,
        },
      });
    }
  };

  const draft = useDraft<Form, Record<string, any>>({
    source: form,
    createDraft(source) {
      return {...source.data};
    },
    async save(state) {
      await updateFormData({
        variables: {
          id: step.workflowId,
          stepId: step.id,
          data: JSON.stringify(state),
        },
      });

      toast.message('保存しました');
    },
  });

  const menu = useOptionsMenu({
    button: (
      <SubtleQueitButton>
        <MoreHorizontal size={16} strokeWidth={"1.5px"} />
      </SubtleQueitButton>
    ),
    menu: (close) => {
      return (
        <>
          {editForm && (
            <MenuItem
              onClick={() => {
                close();
                editForm();
              }}
              disabled={disabled || loading}
              icon={<Edit size={16} strokeWidth="1.5px" />}>
              編集する
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              close();
              removeForm();
            }}
            disabled={disabled || loading}
            icon={<Trash2 size={16} />}>
            削除する
          </MenuItem>
        </>
      );
    },
  });

  if (!form.schema) {
    return null;
  }

  if (!schemaRoot.check(form.schema)) {
    console.error('スキーマが不正です', form.schema);
    throw Error('スキーマが不正です');
  }

  return (
    <>
      <FormWrapper>
        {!preview && <div style={{float: 'right'}}>{menu.node}</div>}

        <CustomForm
          schema={form.schema}
          disabled={loading || disabled}
          value={draft.state}
          preview={preview}
          setValue={draft.setState}
        />

        <HorizontalBox style={{margin: '36px 0 0 0'}}>
          <LargeDarkButton onClick={() => draft.save()} disabled={disabled || preview || !draft.isDirty || loading}>
            保存する
          </LargeDarkButton>
          <Space width="12px" />
          <LargeButton onClick={() => draft.discard()} disabled={disabled || preview || !draft.isDirty || loading}>
            キャンセル
          </LargeButton>
        </HorizontalBox>
      </FormWrapper>
    </>
  );
}

const FormWrapper = styled.div`
  /*
  margin: 24px 0 24px 0;
  padding: 48px;
  box-shadow:
    0 0 0 1px ${colors.border},
    0 1px 3px rgba(0, 0, 0, 0.03),
    0 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  background-color: white; /*hsl(232, 29%, 98%);*/
`;
