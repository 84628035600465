import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {colors} from 'theme';

export const editableBlock = css`
  cursor: pointer;
  margin: 8px 0;
  padding: 2px 2px;
  border-radius: 0;
  border: 1px dashed ${colors.border};
`;

export const selectedEditableBlock = css`
  border: 1px solid ${colors.accent};
`;

export const InspectorBlock = styled.div`
  padding: 6px 12px;
  background-color: white;
  box-sizing: border-box;
  border-left: 1px solid ${colors.border};
  width: 200px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow-y: auto;
`;

export const FormWrapper = styled.div`
  border: 1px solid ${colors.border};
  border-radius: 4px;
  padding: 20px 24px;
  margin: 20px 0;
  background-color: white;
`;

export const EditorPane = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 30px;
  overflow-y: auto;
  background-color: ${colors.background};
`;
