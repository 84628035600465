import {NodeEditorProps} from './types';
import {useSelect} from './useSelect';
import {editableBlock, selectedEditableBlock} from './styles';
import {Button} from '../Button/Button';
import {NodeStates} from './editorState';
import {cx} from '@emotion/css';
import {Plus} from 'react-feather';
import {StringNodeEditor} from './StringNodeEditor';
import shortId from 'shortid';

export function ObjectNodeEditor(props: NodeEditorProps) {
  const {store, id, disabled = false} = props;
  const nodeState = store.useState((state) => state.nodes[id], [id]);
  const isSelected = store.useState((state) => state.selectedId === id, [id]);
  const select = useSelect(props);

  if (nodeState.type !== 'object') {
    throw Error();
  }

  return (
    <div onClick={select} className={cx(editableBlock, isSelected && selectedEditableBlock)}>
      {typeof nodeState.title === 'string' && <h2>{nodeState.title}</h2>}
      {typeof nodeState.description === 'string' && <div>{nodeState.description}</div>}

      {nodeState.props.map((propId) => (
        <NodeEditor {...props} id={propId} key={propId} parent={nodeState} />
      ))}

      <br />

      <div>
        <Button
          onClick={(event) => {
            event.stopPropagation();

            const newNode: NodeStates['string'] = {
              id: shortId(),
              type: 'string',
              title: '(無名)',
              description: '',
              name: shortId(),
              widget: 'input',
              placeholder: '',
              required: false,
            };

            store.update((state) => {
              state.nodes[nodeState.id] = {
                ...nodeState,
                props: [...nodeState.props, newNode.id],
              };
              state.nodes[newNode.id] = newNode;
              state.selectedId = newNode.id;
            });
          }}
          disabled={disabled}>
          <Plus size={16} />
          &nbsp;フィールド
        </Button>
      </div>
    </div>
  );
}

function NodeEditor(props: NodeEditorProps) {
  const {store, id} = props;
  const nodeState = store.useState((state) => state.nodes[id]);

  switch (nodeState.type) {
    case 'string':
      return <StringNodeEditor {...props} id={nodeState.id} />;
    case 'object':
      return <ObjectNodeEditor {...props} id={nodeState.id} />;
  }
}
