import {ObjectSchema, SchemaNode, SchemaRoot, StringSchema} from './schema';
import {EditorState, NodeState} from './editorState';

export function exportEditorState(state: EditorState): SchemaRoot {
  const {nodes} = state;

  const aggregate = (nodeState: NodeState): SchemaNode => {
    switch (nodeState.type) {
      case 'object': {
        const object: ObjectSchema = {
          type: 'object',
          title: nodeState.title,
          description: nodeState.description,
          properties: {},
          required: [],
          additionalProperties: false,
        };

        const required = new Set<string>();

        for (const nodeId of nodeState.props) {
          const childState = nodes[nodeId];
          const schema = aggregate(childState);

          if (typeof childState.name !== 'string') {
            throw Error('nameが無いよ!');
          }

          if (object.properties[childState.name]) {
            console.warn(`Duplicated property: ${childState.name}`);
          }
          object.properties[childState.name] = schema;
          if (!!childState.required) {
            required.add(childState.name);
          }
        }

        object.required = [...required];

        return object;
      }

      case 'string': {
        const string: StringSchema = {
          type: 'string',
          title: nodeState.title,
          description: nodeState.description,
          default: nodeState.default,
          'ui.widget': {
            widgetType: nodeState.widget,
            placeholder: nodeState.placeholder,
          },
        };

        return string;
      }
    }
  };

  const root = aggregate(state.nodes[state.rootId]);
  if (root.type !== 'object') {
    throw Error();
  }

  return root;
}
