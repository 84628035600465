import {Fragment} from 'react';
import {AddStepForm} from '../../common/StepBalloon/StepBalloon';
import {addStepMutation} from '../../../gqls/workflowMutations';
import {Loading} from '../../common/Loading';
import {AddStep, AddStepVariables} from '../../../__generated__/AddStep';
import {useHistory} from 'react-router-dom';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useToast} from '../../../base/components/Toast/Toast';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';

interface Props {
  workflowId: string;
  before?: string;
}

export const NewStepContainer = ({workflowId, before}: Props) => {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const toast = useToast();
  const [mutate, {loading}] = useAppMutation<AddStep, AddStepVariables>(addStepMutation, {
    onError() {
      toast.error('ステップの作成に失敗しました');
    },
  });
  const submit = async (name: string) => {
    const result = await mutate({
      variables: {
        workflowId,
        stepName: name,
        before,
      },
    });

    if (result.data) {
      const {steps} = result.data.workflow.addStep;
      const stepId = steps[steps.length - 1].id;

      requestAnimationFrame(() => {
        history.push(`/workspaces/${workspaceId}/workflows/${workflowId}#step-${stepId}`);
      });
    }
  };

  return (
    <Fragment>
      {loading && <Loading />}

      <AddStepForm loading={loading} submit={submit} />
    </Fragment>
  );
};
