import gql from 'graphql-tag';
import {sourceWorkflowFragment, userFragment, workflowFragment, workflowSummaryFragment} from './fragments';

export const countWorkspaceWorkflows = gql`
  query CountWorkspaceWorkflows($workspaceId: ID!) {
    workflowsCount: workspace(id: $workspaceId) {
      running: workflowsCount(statuses: [running])
      draft: workflowsCount(statuses: [draft])
      finished: workflowsCount(statuses: [finished])
      published: workflowsCount(statuses: [published])
      archived: workflowsCount(statuses: [archived])
    }
  }
`;

export const findWorkflowsQuery = gql`
  query FindWorkflows(
    $limit: Int
    $after: String
    $ownerId: ID
    $sourceWorkflowId: ID
    $statuses: [WorkflowStatus!]
    $createdAt: DateRangeInput
    $sort: WorkflowSort
    $workspaceId: ID
  ) {
    workflows(
      limit: $limit
      after: $after
      ownerId: $ownerId
      sourceWorkflowId: $sourceWorkflowId
      statuses: $statuses
      createdAt: $createdAt
      sort: $sort
      workspaceId: $workspaceId
    ) {
      totalCount
      hasNext
      endCursor
      items {
        ...WorkflowSummaryFragment
        ...SourceWorkflowFragment
      }
    }
    self {
      id
      iconUrl
    }
  }
  ${workflowSummaryFragment}
  ${sourceWorkflowFragment}
`;

export const findWorkflowQuery = gql`
  query FindWorkflow($id: ID!) {
    workflow(id: $id) {
      ...WorkflowFragment
    }
  }

  ${workflowFragment}
`;

export const findAssigneeCandidatesQuery = gql`
  query AssigneeCandidates($id: ID!) {
    assigneeCandidates(workflowId: $id) {
      ...UserFragment
    }
  }

  ${userFragment}
`;
