import {useEffect, Fragment} from 'react';
import {ContentHeaderText, P} from '../Public';
import {Indicator} from '../../../base/components/Indicator/Indicator';
import {Setup} from './Setup';
import {findAvailableSignUpToken} from '../../../gqls/findAvailableSignUpToken';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {
  FindAvailableSignUpToken,
  FindAvailableSignUpTokenVariables,
} from '../../../__generated__/FindAvailableSignUpToken';
import {Props} from './StateContainer';

export function Initial(container: Props) {
  const token = new URLSearchParams(window.location.search).get('token') || '';
  const {data, loading, error} = useAppQuery<FindAvailableSignUpToken, FindAvailableSignUpTokenVariables>(
    findAvailableSignUpToken,
    {
      variables: {
        token,
      },
      onError() {
        // Do nothing
      },
    }
  );

  useEffect(() => {
    if (data && data.signupTokenIsAvailable) {
      container.setState({
        ...container.state,
        email: data.signupTokenIsAvailable.email,
        component: Setup,
        token,
      });
    }
  });

  if (loading) {
    return (
      <Fragment>
        <ContentHeaderText>確認しています...</ContentHeaderText>

        <div style={{textAlign: 'center'}}>
          <Indicator />
        </div>
      </Fragment>
    );
  }

  if (error) {
    return (
      <Fragment>
        <ContentHeaderText>何らかのエラーが起きました</ContentHeaderText>

        <P>{'' + error.message}</P>
      </Fragment>
    );
  }

  if (data && !data.signupTokenIsAvailable) {
    return (
      <Fragment>
        <ContentHeaderText>トークンを確認できませんでした</ContentHeaderText>

        <P>メールに記載された正しいURLにアクセスしてください。</P>
      </Fragment>
    );
  }

  if (data && data.signupTokenIsAvailable) {
    return <div />;
  }

  return (
    <Fragment>
      <ContentHeaderText>招待が無効になっています</ContentHeaderText>

      <P>この招待URLはすでに無効になっています。</P>
    </Fragment>
  );
}
