import {useCallback, useState} from 'react';

function get<T>(key: string, initialValue: T): T {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    console.log(error);
    return initialValue;
  }
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [, flush] = useState({});
  const storedValue = get(key, initialValue);

  const setValue = useCallback(
    (value: T) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
        flush({});
      } catch (error) {
        console.log(error);
      }
    },
    [key, flush]
  );

  return [storedValue, setValue] as const;
}
