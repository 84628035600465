const emailRegex = /^.+@.+?\..+$/;

export const isEmail = (email: string) => {
  return emailRegex.test('' + email);
};

export const isEmpty = (str: string) => {
  return ('' + str).length === 0;
};

export const run = (validate: () => string[] | Iterator<string>) => {
  if (!(validate instanceof Function)) {
    throw TypeError('validator function must be an instance of Function.');
  }

  const errors = validate() as any;

  return [...errors];
};
