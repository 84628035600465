import * as React from 'react';
import {UserFragment} from '../../../__generated__/UserFragment';
import styled from '@emotion/styled';
import {useHoverPopup} from '../../hooks/useHoverPopup';
import {WorkspaceSummaryFragment} from '../../../__generated__/WorkspaceSummaryFragment';
import {UserBlock} from '../../../app/common/UserBlock';

interface Props {
  user: UserFragment;
  target: React.ReactNode;
}

export function UserPopup(props: Props) {
  const {user, target} = props;

  return useHoverPopup({
    target,
    popup: (
      <Popup>
        <UserBlock user={user} />
      </Popup>
    ),
  });
}

interface WorkspaceProps {
  workspace: WorkspaceSummaryFragment;
  target: React.ReactNode;
}

export function WorkspacePopup(props: WorkspaceProps) {
  const {workspace, target} = props;

  return useHoverPopup({
    target,
    popup: (
      <Popup>
        {workspace.name} <br />
        {workspace.members.totalCount}名のメンバー
      </Popup>
    ),
  });
}

export const Popup = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: auto;
  background-color: white;
  border-radius: 5px;
  z-index: 101;
  box-sizing: border-box;
  padding: 12px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;
