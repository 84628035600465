import gql from 'graphql-tag';

export const createWorkspaceMutation = gql`
  mutation CreateEmptyWorkspace($name: String!) {
    createWorkspace(name: $name) {
      id
      name
    }
  }
`;

export const changeMemberRoleMutation = gql`
  mutation ChangeMemberRoleMutation($workspaceId: ID!, $userId: ID!, $role: MemberRole!) {
    workspace(id: $workspaceId) {
      updateMember(userId: $userId, role: $role) {
        __typename
      }
    }
  }
`;

export const closeWorkspaceMutation = gql`
  mutation CloseWorkspace($id: ID!, $password: String!) {
    workspace(id: $id) {
      close(password: $password)
    }
  }
`;

export const removeMemberMutation = gql`
  mutation RemoveMember($workspaceId: ID!, $userId: ID!) {
    workspace(id: $workspaceId) {
      removeMember(userId: $userId) {
        id
      }
    }
  }
`;

export const inviteMutation = gql`
  mutation Invite($workspaceId: ID!, $entries: [EntryInput!]!) {
    invite(workspaceId: $workspaceId, entries: $entries)
  }
`;

export const updateWorkspaceMutation = gql`
  mutation UpdateWorkspace($id: ID!, $name: String) {
    workspace(id: $id) {
      update(name: $name) {
        id
        name
        members {
          totalCount
          items {
            role
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;
