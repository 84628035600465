import {useState} from 'react';
import * as React from 'react';
import {FetchWorkspace, FetchWorkspace_workspace, FetchWorkspaceVariables} from '../../../__generated__/FetchWorkspace';
import {fetchWorkspaceQuery} from '../../../gqls/workspaceQueries';
import {updateWorkspaceMutation} from '../../../gqls/workspaceMutations';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {captionStyle} from '../../../base/components/settings/SettingLayout';
import {TextInput} from '../../../base/components/Form/Form';
import {
  DarkButton,
  MinimumButton,
  Button,
} from '../../../base/components/Button/Button';
import {useToast} from '../../../base/components/Toast/Toast';
import {MemberRole} from '../../../__generated__/globalTypes';
import {WarningAlert} from '../../../base/components/Alert/Alert';
import {useHistory} from 'react-router-dom';
import {HorizontalBox, Space, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {tw} from 'twind';
import {AlertTriangle, Edit, ChevronRight} from 'react-feather';
import {Tooltip} from '../../../base/components/Tooltip/Tooltip';
import {Header, HeaderBlock, SubHeaderBlock, SubHeader, FieldLabel} from './styles';
import {UserBlock} from '../../common/UserBlock';

interface Props {
  workspace: FetchWorkspace_workspace;
}

export function GeneralSettingsRoute(props: Props) {
  const {workspace} = props;
  const {id, role} = workspace;
  const isAdmin = role === MemberRole.admin;
  const history = useHistory();

  const toast = useToast();

  const {data, loading, error} = useAppQuery<FetchWorkspace, FetchWorkspaceVariables>(fetchWorkspaceQuery, {
    variables: {id},
    onCompleted: () => {
      if (data?.workspace?.name) {
        setState(prev => ({...prev, name: data?.workspace?.name ?? ''}));
      }
    },
    onError: () => {
      toast.error('読み込みに失敗しました');
    },
  });

  const [updateWorkspace, mutationResult] = useAppMutation(updateWorkspaceMutation, {
    onError() {
      toast.error('更新に失敗しました');
    },
    onCompleted() {
      toast.message('更新しました');
      setState(prev => ({...prev, mode: 'view'}));
    },
  });

  const [state, setState] = useState<{mode: 'view' | 'edit', name: string}>({
    mode: 'view',
    name: '',
  });

  if (loading || error) {
    return <div />;
  }

  const tryToChange = async () => {
    await updateWorkspace({
      variables: {id, name: state.name},
    });
  };

  return (
    <>
      <div>
        <h1 className={tw`font-semiBold text-xl mb-6`}>ワークスペース設定</h1>

        <HeaderBlock>
          <Header>基本</Header>
        </HeaderBlock>

        {!isAdmin && <WarningAlert>管理者権限を持っていない人は変更できません</WarningAlert>}

        <div className={tw`rounded border px-3 py-3`}>
          <FieldLabel>ワークスペース名</FieldLabel>
          {state.mode === 'view' && <>
            <HorizontalBox>
              <div className={tw`text-base font-bold text-blackText`}>{workspace.name}</div>
              <Space width={"6px"} />
              {isAdmin && (
                <Tooltip text={"名前を変更する"}>
                  <MinimumButton onClick={() => setState(prev => ({...prev, mode: 'edit'}))}>
                    <Edit size={16} strokeWidth={"1.5px"} />
                  </MinimumButton>
                </Tooltip>
              )}
            </HorizontalBox>
          </>}
          {state.mode === 'edit' && <>
            <HorizontalBox>
              <TextInput
                type="text"
                value={state.name}
                autoFocus
                style={{width: '250px'}}
                disabled={!isAdmin || loading || mutationResult.loading}
                onChange={(e) => setState(prev => ({...prev, name: e.target.value}))}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    tryToChange();
                  }
                }}
              />
              <Space width={'6px'} />
              <DarkButton onClick={tryToChange} disabled={!isAdmin || loading || mutationResult.loading} style={{height: '32px'}}>
                変更
              </DarkButton>
              <Space width={'6px'} />
              <Button onClick={() => setState(prev => ({...prev, mode: 'view'}))} disabled={!isAdmin || loading || mutationResult.loading} style={{height: '32px'}}>
                キャンセル
              </Button>
            </HorizontalBox>
            <div style={captionStyle} className={tw`mt-1`}>
              例: 〜〜株式会社、〇〇チーム、△△グループ、〜〜部署
            </div>
          </>}

          <hr className={tw`bg-border my-3 -mx-3`}  />

          <FieldLabel>
            所有者
          </FieldLabel>
          <div className={tw`mt-1`}>
            <UserBlock user={workspace.owner} variant="simple" />
          </div>
        </div>

        <SubHeaderBlock>
          <SubHeader>管理</SubHeader>
        </SubHeaderBlock>

        <div>
          <button
            className={tw`w-full flex items-center border rounded-md p-3 hover:bg-lightBackground active:bg-background focus:outline-none focus-visible:(ring-2 ring-darkAccent)`}
            onClick={() => history.push(`/workspaces/${workspace.id}/settings/close`)}>
            <AlertTriangle strokeWidth={1.5} size={16} className={tw`mr-2 text-darkText`} />
            <div className={tw`text-blackText font-normal`}>ワークスペースの閉鎖</div>
            <SpringSpace />
            <ChevronRight size={16} className={tw`text-superLightText`} />
          </button>
        </div>
      </div>
    </>
  );
}
