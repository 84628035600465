import gql from 'graphql-tag';

export const changeProfileSettingsMutation = gql`
  mutation ChangeProfileSettings($settings: ProfileSettingsInput!) {
    account {
      changeProfileSettings(settings: $settings) {
        id
        name
        receiveNews
      }
    }
  }
`;

export const changeProfileImageMutation = gql`
  mutation ChangepProfileImage($image: Upload!) {
    account {
      changeProfileImage(image: $image)
    }
  }
`;

export const closeAccountMutation = gql`
  mutation CloseAccount($password: String!) {
    account {
      close(password: $password)
    }
  }
`;

export const changePasswordMutation = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    account {
      changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
    }
  }
`;
