import {UserIcon as DumbUserIcon} from '../../base/components/UserIcon/UserIcon';
import {UserFragment} from '../../__generated__/UserFragment';

interface Props {
  user?: Pick<UserFragment, 'id' | 'iconUrl' | 'name'>;
  bgColor?: string;
  size?: 96 | 80 | 48 | 36 | 32 | 24 | 20 | 16;
}

export const UserIconContainer = (props: Props) => {
  const {user, bgColor, size = 24, ...rest} = props;

  if (user) {
    const {name, iconUrl} = user;

    return <DumbUserIcon size={size} url={iconUrl || undefined} name={name} id={user.id} {...rest} />;
  }

  return <DumbUserIcon size={size} loading={true} />;
};
