import gql from 'graphql-tag';
import {workflowSummaryFragment, workspaceSummaryFragment} from './fragments';

export const fetchSelfQuery = gql`
  query FetchSelf {
    self {
      id
      iconUrl
      name
      email
      receiveNews
      workspaces {
        ...WorkspaceSummaryFragment
      }
    }
  }

  ${workspaceSummaryFragment}
`;

export const fetchFavorites = gql`
  query FetchFavorites($workspaceId: ID!) {
    favorites(workspaceId: $workspaceId) {
      totalCount
      items {
        ...WorkflowSummaryFragment
      }
    }
  }

  ${workflowSummaryFragment}
`;
