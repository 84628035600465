import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

export const EditorContent = styled.div<{readOnly?: boolean}>`
  /* エディタ内でのみ共通スタイルから外す */
  font-size: ${fontSizes.md};
  box-sizing: border-box;

  .ProseMirror {
    background-color: transparent;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4,
  h5,
  h6 {
    font-size: 16px;
  }

  .ProseMirror {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 8px 0 8px 0;
      font-weight: ${fontWeights.bold};
      color: ${colors.blackText};
    }
  }

  .block-menu-trigger {
    line-height: 1;
    margin-top: -2px;
    margin-left: -28px;
  }

  pre {
    margin: 16px 0;
    background-color: ${colors.lightBackground};
    border: 1px solid ${colors.lightBorder};
  }

  .code-block button[type='button'] {
    appearance: none;
    border-radius: 2px;
    padding: 3px 4px;
    margin: 0;
    line-height: 1;
    font-size: ${fontSizes.sm};
    color: ${colors.darkText};
    background-color: transparent;
    border: none;

    &:hover {
      color: ${colors.blackText};
    }
  }

  .heading-anchor {
    /* 扱えないのでとりあえず見せないようにしておく */
    display: none !important;
  }

  ul {
    list-style: disc outside;
  }

  ol {
    list-style: decimal outside;
  }

  .checkbox_content {
    margin-left: 4px;
  }

  li {
    margin-left: 8px;
    width: 100%;
  }
  
  blockquote {
    border-left-width: 3px;
    padding-left: 8px !important;
  }
`;
