import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

export const RightFloat = styled((props: FIXME) => <div {...props} className={`right-float ${props.className}`} />)`
  float: right;
  margin: -3px 0 0 0;
  display: none;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0;

  &:hover .right-float {
    display: block;
  }
`;

export const CommentBlock = styled.div`
  border: 1px solid ${colors.border};
  border-radius: 6px;
  padding: 10px 12px;
  flex-grow: 1;
  margin: 0 0 0 8px;
`;

export const UserName = styled.span`
  font-size: ${fontSizes.md};
  font-weight: ${fontWeights.bold};
  color: ${colors.blackText};
`;

export const CommentDate = styled.span`
  font-size: ${fontSizes.base};
  color: ${colors.lightText};
`;

export const CommentContent = styled.div`
  font-size: ${fontSizes.base};
  color: ${colors.darkText};
  line-height: 1.6;
  margin: 6px 0 0 0;
`;

export const RemovedCommentContent = styled.div`
  font-size: ${fontSizes.base};
  font-weight: ${fontWeights.bold};
  color: ${colors.superLightText};
  line-height: 1.6;
  margin: 6px 0 0 0;
`;
