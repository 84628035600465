import styled from '@emotion/styled';
import {WorkflowCoverImageMenu} from '../../workspace/WorkflowCoverImageMenu';
import {NakedWorkflowIcon} from '../../../base/components/WorkflowIcon/WorkflowIcon';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {WorkflowSummaryFragment} from '../../../__generated__/WorkflowSummaryFragment';
import {colors} from 'theme';

interface Props {
  workflow: WorkflowFragment | WorkflowSummaryFragment;
  preview?: boolean;
}

export function EyeCatch({workflow, preview = false}: Props) {
  return (
    <>
      <EyeCatchBlock
        style={{
          backgroundImage: typeof workflow.coverImageUrl === 'string' ? `url(${workflow.coverImageUrl})` : '',
        }}>
        {!preview && <WorkflowCoverImageMenu workflow={workflow} />}
        {typeof workflow.coverImageUrl !== 'string' && (
          <NakedWorkflowIcon strokeWidth={1} workflow={workflow} size={42} color={colors.borderDark} />
        )}
      </EyeCatchBlock>
    </>
  );
}

const EyeCatchBlock = styled.div`
  width: 100%;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-color: ${colors.lightBackground};
  margin: 0;

  &:before {
    display: block;
    content: '';
    padding-top: 58%;
  }

  &:hover .show-on-hover {
    visibility: visible;
  }
`;
