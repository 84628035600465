import * as React from 'react';
import {Td, WorkflowNameTd, IconTd} from '../../../base/components/WorkflowTable';
import {ColumnName} from './types';
import {WorkflowMenu} from './WorkflowMenu';
import {NavLink} from 'react-router-dom';
import * as styles from '../../../base/components/WorkflowTable';
import {TimeAgo} from '../../../base/components/TimeAgo';
import {cx} from '@emotion/css';
import {StepFragment} from '../../../__generated__/StepFragment';
import {WorkflowSummaryFragment} from '../../../__generated__/WorkflowSummaryFragment';
import {NakedWorkflowIcon} from '../../../base/components/WorkflowIcon/WorkflowIcon';
import {HorizontalBox, Space} from '../../../base/components/HorizontalBox/HorizontalBox';
import {Star} from 'react-feather';
import {colors, fontSizes} from 'theme';
import {UserBlock} from '../../common/UserBlock';
import {SubtleStatusLabel} from '../../../base/components/StatusSelectBox/StatusLabel';

interface Props {
  workflow: WorkflowSummaryFragment;
  step?: StepFragment;
  columns: ColumnName[];
}

export function WorkflowRow(props: Props) {
  const {workflow, step, columns} = props;

  const rendererMap: {[Key in ColumnName]: () => React.ReactNode} = {
    icon: () => (
      <IconTd key="icon" style={{width: '30px'}}>
        <NakedWorkflowIcon workflow={workflow} />
      </IconTd>
    ),

    status: () => (
      <Td key="status">
        <SubtleStatusLabel status={workflow.status} />
      </Td>
    ),

    workspace: () => {
      return (
        <Td key="workspace">{workflow.workspace && <NavLink to={`/workflows`}>{workflow.workspace.name}</NavLink>}</Td>
      );
    },

    sourceWorkflow: () => {
      return (
        <Td key="source">
          {workflow.sourceWorkflow && (
            <NavLink to={`/workspaces/${workflow.workspace!.id}/workflows/${workflow.sourceWorkflow.id}`}>{workflow.sourceWorkflow.name}</NavLink>
          )}
        </Td>
      );
    },

    name: () => (
      <Td key="name">
        <HorizontalBox alignItems="center">
          {!step && (
            <NavLink to={`/workspaces/${workflow.workspace!.id}/workflows/${workflow.id}`} className={styles.workflowNameStyle}>
              {workflow.name}
              {typeof workflow.count === 'number' && (
                <span style={{color: colors.superLightText}}>&nbsp;#{workflow.count}</span>
              )}
            </NavLink>
          )}
          {step && (
            <NavLink to={`/workspaces/${workflow.workspace!.id}/workflows/${workflow.id}/steps/${step.id}`} className={styles.workflowNameStyle}>
              {workflow.name} <span className={cx(styles.processStepSeparatorStyle, 'separator')}>/</span> {step.name}
            </NavLink>
          )}

          <Space width="8px" />

          <Favorite workflow={workflow} />
        </HorizontalBox>
      </Td>
    ),

    progress: () => (
      <Td key="progress">
        <span style={{color: colors.superLightText, fontSize: fontSizes.sm}}>
          <span style={{}}>{workflow.progress.numerator}</span>
          <span style={{color: colors.superLightText}}>/</span>
          <span style={{}}>{workflow.progress.denominator}</span>
        </span>
      </Td>
    ),

    workflow: () => (
      <WorkflowNameTd key="workflow">
        {workflow.sourceWorkflow && (
          <NavLink to={`/workspaces/${workflow.workspace!.id}/workflows/${workflow.sourceWorkflow.id}`} className={styles.sourceWorkflowNameStyle}>
            {workflow.sourceWorkflow.name}
          </NavLink>
        )}
      </WorkflowNameTd>
    ),

    owner: () => (
      <Td key="owner">
        {workflow.owner && (
          <div className={styles.userBlock}>
            <UserBlock user={workflow.owner} iconSize={24} variant="icon" />
          </div>
        )}
      </Td>
    ),

    createdAt: () => (
      <Td key="createdAt">
        <span className={styles.dateStyle}>
          <TimeAgo>{workflow.createdAt}</TimeAgo>
        </span>
      </Td>
    ),

    updatedAt: () => (
      <Td key="updatedAt">
        <span className={styles.dateStyle}>
          <TimeAgo>{workflow.updatedAt}</TimeAgo>
        </span>
      </Td>
    ),

    menu: () => (
      <Td key="menu" style={{textAlign: 'right', width: '32px', padding: '0'}}>
        <WorkflowMenu />
      </Td>
    ),
  };

  return <tr>{columns.map((columnName) => rendererMap[columnName]())}</tr>;
}

function Favorite({workflow}: {workflow: WorkflowSummaryFragment}) {
  return (
    <>{workflow.isFavorite && <Star size={14} color={colors.shortcut} fill={colors.shortcut} strokeWidth="2px" />}</>
  );
}
