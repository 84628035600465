import {BaseDialog} from './BaseDialog';
import styled from '@emotion/styled';
import {css} from '@emotion/css';
import {colors, fontSizes, fontWeights} from 'theme';

const modalDialog = css`
  width: 420px;
  height: auto;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.14), 0 0 3px 0 rgba(0, 0, 0, 0.11), 0 2px 13px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 0;
  text-align: left;
  font-size: ${fontSizes.base};
  margin: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const modalMask = css`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 200;
  background-color: rgba(135, 135, 147, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalDialog = BaseDialog.bind({
  styles: {
    dialog: modalDialog,
    mask: modalMask,
  },
});

export const DialogContent = styled.div`
  margin: 6px 0;
  padding: 0 24px;
  flex-grow: 1;
`;

export const Footer = styled.div`
  display: flex;
  margin: 12px 0 24px 0;
  padding: 0 24px;
  align-items: center;
  justify-content: flex-end;
`;

export const DialogTitle = styled.h2`
  display: block;
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeights.semiBold};
  padding: 0 24px;
  margin: 24px 0 12px 0;
  color: ${colors.blackText};
`;
