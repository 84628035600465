import {useCallback} from 'react';
import {NodeEditorProps} from './types';

export function useMoveSelf(props: NodeEditorProps) {
  const {id, parent, store} = props;

  return useCallback(
    (delta: -1 | 1) => {
      if (!parent) {
        return;
      }

      const {props} = parent;
      const from = props.findIndex((propId) => propId === id);
      if (from === -1) {
        return;
      }

      const changed = {
        ...parent,
        props: moveInArray(props, from, from + delta),
      };

      store.update((state) => {
        state.nodes[changed.id] = changed;
      });
    },
    [id, parent, store]
  );
}

// 並び替えをする
const moveInArray = (source: string[], from: number, to: number): string[] => {
  const array = [...source];
  const item = array.splice(from, 1);
  if (!item.length) {
    throw new Error('There is no item in the array at index ' + from);
  }
  array.splice(to, 0, item[0]);
  return array;
};
