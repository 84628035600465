import {injectGlobal} from '@emotion/css';
import {colors, fontSizes, fontWeights} from 'theme';
import {setup} from 'twind';
import * as twindColors from 'twind/colors';

injectGlobal(`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: "palt";
  }
  
  body, input, textarea, button, a, span, div, h1, h2, h3, h4, h5, h6, h7 {
    font-family: ui-sans-serif, system-ui, -apple-system, Hiragino Sans, Hiragino Kaku Gothic ProN, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  body {
    margin: 0;
    padding: 0;
    background-color: white;
    letter-spacing: 0.06em;
    min-height: 90vh;
    font-size: ${fontSizes.base};
    line-height: 1;
  }

  label {
    user-select: none;
  }

  input, textarea {
    font-weight: ${fontWeights.normal};
    letter-spacing: 0.06em;
  }
  
  button {
    letter-spacing: 0.06em;
  }

  input[type=text],
  input[type=password],
  input[type=date],
  input[type=number] {
    appearance: none;
  }

  input::placeholder, textarea::placeholder {
    color: #ccc;
  }

  input:focus, textarea:focus {
    outline: none;
  }

  h1, h2, h3, h4, h5 {
    font-size: 16px;
    font-weight: ${fontWeights.bold};
    margin: 0.4em 0;
    padding: 0;
    color: ${colors.blackText};
  }
  
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 15px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 14px;
  }

  a, a:link, a:visited {
    color: ${colors.lightText};
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
    color: ${colors.darkAccent};
  }

  a:active {
  }
  
  strong, b {
    font-weight: ${fontWeights.bold};
  }

  ::-webkit-search-cancel-button,
  ::-webkit-search-decoration {
    appearance: none;
  }
`);

setup({
  preflight: true,
  theme: {
    fontSize: fontSizes,
    fontWeight: Object.entries(fontWeights).reduce<Record<string, string>>((result, [key, value]) => {
      return Object.assign(result, {[key]: `${value}`});
    }, {}),
    colors: {
      ...twindColors,
      ...colors,
    },
  },
});

