import {useRef, useMemo} from 'react';
import {StepFragment} from '../../../__generated__/StepFragment';
import {Button, DarkButton} from '../../../base/components/Button/Button';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useToast} from '../../../base/components/Toast/Toast';
import {HorizontalBox} from '../../../base/components/HorizontalBox/HorizontalBox';
import {updateFormSchemaMutation} from '../../../gqls/workflowMutations';
import {schemaRoot} from '../../../base/components/CustomForm/schema';
import {UpdateFormSchema, UpdateFormSchemaVariables} from '../../../__generated__/UpdateFormSchema';
import {CustomFormEditor} from '../../../base/components/CustomForm/CustomFormEditor';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from '../../../base/components/Dialog/Dialog';
import {Store} from 'pullstate';
import {createEditorState} from '../../../base/components/CustomForm/createEditorState';
import {exportEditorState} from '../../../base/components/CustomForm/exportEditorState';

interface EditorProps {
  step: StepFragment;
  terminate: () => void; // フォーム編集を終わる
}

export function CustomFormEditorContainer(props: EditorProps) {
  const {step, terminate} = props;
  const initialPropsRef = useRef(props);

  const toast = useToast();
  const [updateFormSchema, {loading}] = useAppMutation<UpdateFormSchema, UpdateFormSchemaVariables>(
    updateFormSchemaMutation,
    {
      onError() {
        toast.error('更新に失敗しました');
      },
    }
  );

  const initialState = useMemo(() => {
    const form = initialPropsRef.current.step.form;

    if (form) {
      const formSchema = JSON.parse(form).schema;
      if (schemaRoot.check(formSchema)) {
        return createEditorState(formSchema);
      }
      console.log('schemaが不正です');
    }

    return createEditorState({
      type: 'object',
      title: '',
      properties: {
        name: {
          type: 'string',
          title: '姓名',
        },
        intro: {
          type: 'string',
          title: '自己紹介',
          'ui.widget': {
            widgetType: 'textarea',
          },
        },
        resume: {
          type: 'string',
          title: '経歴',
          'ui.widget': {
            widgetType: 'textarea',
          },
        },
      },
      additionalProperties: false,
    });
  }, []);

  const store = useMemo(() => new Store(initialState), [initialState]);

  const save = async () => {
    const schema = exportEditorState(store.getRawState());

    await updateFormSchema({
      variables: {
        id: step.workflowId,
        stepId: step.id,
        schema: JSON.stringify(schema),
      },
    });
    terminate();
  };

  return (
    <Dialog style={{width: '900px'}}>
      <DialogTitle>フォームビルダー</DialogTitle>

      <DialogCloseButton onClick={terminate} />

      <DialogContent>
        <CustomFormEditor store={store} disabled={loading} />
      </DialogContent>

      <DialogFooter>
        <HorizontalBox>
          <DarkButton onClick={() => save()} disabled={loading}>
            保存する
          </DarkButton>
          &nbsp;
          <Button
            onClick={() => {
              terminate();
            }}>
            キャンセル
          </Button>
        </HorizontalBox>
      </DialogFooter>
    </Dialog>
  );
}
