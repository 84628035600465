import {Component} from 'react';
import {Indicator} from '../../base/components/Indicator/Indicator';
import {Page, ContentBox, ContentHeaderText} from './Public';
import {Mutation} from '@apollo/client/react/components';
import {Helmet} from 'react-helmet-async';
import {logoutMutation} from '../../gqls/logoutMutations';

export class Logout extends Component<{logout: Function}, {error: any}> {
  state = {
    error: undefined,
  };

  async componentDidMount() {
    try {
      await this.props.logout();
      window.location.href = '/login';
    } catch (error) {
      this.setState({error: error as Error});
    }
  }

  render() {
    const {error} = this.state;

    if (error) {
      return (
        <Page>
          <ContentBox>
            <ContentHeaderText>エラーが発生しました</ContentHeaderText>

            <p>{(error as any).message}</p>
          </ContentBox>
        </Page>
      );
    }

    return (
      <Page>
        <Helmet>
          <title>ログアウトしています...</title>
        </Helmet>
        <ContentBox>
          <ContentHeaderText>ログアウトしています...</ContentHeaderText>

          <p style={{textAlign: 'center'}}>
            <Indicator />
          </p>
        </ContentBox>
      </Page>
    );
  }
}
export const LogoutRoute = () => (
  <Mutation mutation={logoutMutation}>{(mutate: FIXME, mutateResult: FIXME) => <Logout logout={mutate} />}</Mutation>
);
