import {css} from '@emotion/css';
import {colors} from 'theme';

const emptyBoxStyle = css`
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const labelStyle = css`
  color: ${colors.lightText};
  font-size: 16px;
  margin: 10px 0 10px 0;
`;

export const EmptyWorkflows = () => {
  return (
    <div className={emptyBoxStyle}>
      <div>
        <img src="/images/workflows-empty.svg" width="72" height="72" alt="" />
        <p className={labelStyle}>ワークフローはありません</p>
      </div>
    </div>
  );
};
