import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {useToast} from '../../../base/components/Toast/Toast';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {ToggleFavoriteWorkflow, ToggleFavoriteWorkflowVariables} from '../../../__generated__/ToggleFavoriteWorkflow';
import {toggleFavoriteWorkflowMutation} from '../../../gqls/workflowMutations';
import {findWorkflowQuery} from '../../../gqls/workflowQueries';

export function useFavoriteToggle(workflow: WorkflowFragment): [() => void, boolean] {
  const toast = useToast();

  const [toggleFavorite, {loading}] = useAppMutation<ToggleFavoriteWorkflow, ToggleFavoriteWorkflowVariables>(
    toggleFavoriteWorkflowMutation,
    {
      onError() {
        toast.error('ショートカットの設定に失敗しました');
      },
      onCompleted() {
        if (workflow.isFavorite) {
          toast.message('ショートカットに追加しました');
        } else {
          toast.message('ショートカットから外しました');
        }
      },
      refetchQueries: [{query: findWorkflowQuery, variables: {id: workflow.id}}],
      awaitRefetchQueries: true,
    }
  );

  const toggle = () => {
    toggleFavorite({
      variables: {
        workflowId: workflow.id,
      },
    });
  };

  return [toggle, loading];
}
