import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';
import {CSSProperties} from 'react';

export const HeaderText = styled.h3`
  font-size: ${fontSizes.lg};
  color: ${colors.blackText};
  font-weight: ${fontWeights.bold};
  margin: 4px 0 4px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const HR = styled.hr`
  height: 1px;
  background-color: ${colors.border};
  border: none;
  padding: 0;
`;

export const LeftSide = styled.div`
  width: 220px;
  box-sizing: border-box;
  padding: 0 0 0 0;
  margin: 0 18px 0 0;
  flex-shrink: 0;
`;

export const RightSide = styled.div`
  padding: 0 0 24px 0;
  flex-grow: 1;
`;

export const SettingLayout = styled.div`
  display: flex;
  align-items: stretch;
`;

export const SettingSection = styled.div`
  margin: 0 0 40px 0;
`;

export const captionStyle: CSSProperties = {
  fontSize: fontSizes.sm,
  color: colors.lightText,
  fontWeight: fontWeights.semiBold,
};
