import {useQuery, useLazyQuery, QueryHookOptions, LazyQueryHookOptions, QueryResult} from '@apollo/client';
import {DocumentNode} from 'graphql';

export function useAppQuery<TData = any, TVariables = {}>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  return useQuery<TData, TVariables>(query, options);
}
export function useLazyAppQuery<TData = any, TVariables = {}>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>
) {
  return useLazyQuery(query, options);
}
