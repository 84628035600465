import {DragEvent, useRef, useState} from 'react';
import {
  draggingStyle,
  dropAfterStyle,
  dropBeforeStyle,
  StepBalloonBlock,
  stepBalloonNameStyle,
  stepBalloonStyle,
} from '../../common/StepBalloon/StepBalloon';
import {Loading} from '../../common/Loading';
import {insertStepBeforeMutation} from '../../../gqls/workflowMutations';
import {cx} from '@emotion/css';
import {StepFragment} from '../../../__generated__/StepFragment';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {MoreVertical} from 'react-feather';
import {colors} from 'theme';
import {StepMenu} from './StepMenu';
import {CustomNavLink} from '../../common/CustomNavLink';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';

type Props = {
  step: StepFragment;
  workflow: WorkflowFragment;
  editable: boolean;
};

export const StepBalloon = (props: Props) => {
  const {step, workflow, editable} = props;
  const nameStyle = cx(stepBalloonNameStyle);
  const [extraClassName, setExtraClassName] = useState<string>('');
  const [dragging, setDragging] = useState<boolean>(false);
  const workspaceId = useWorkspaceId();

  const [insertStepBefore, {loading}] = useAppMutation(insertStepBeforeMutation);

  const ref = useRef<HTMLDivElement>(null);

  const onDragStart = (event: DragEvent) => {
    event.dataTransfer.setData('text', step.id);
    if (ref.current) {
      event.dataTransfer.setDragImage(ref.current, 0, 0);
    }
    setDragging(true);
  };

  const calculateDragPosition = (event: DragEvent) => {
    const pageY = event.pageY;
    const elementCenterY = (() => {
      const dom = ref.current;
      if (!dom) {
        return NaN;
      }

      const {top, height} = dom.getBoundingClientRect();
      return window.pageYOffset + top + height / 2;
    })();

    if (isNaN(elementCenterY)) {
      return null;
    }

    if (pageY > elementCenterY) {
      return 'after';
    } else {
      return 'before';
    }
  };

  const onDragOver = (event: DragEvent) => {
    if (dragging) {
      return;
    }

    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';

    const status = calculateDragPosition(event);

    if (status === 'after') {
      setExtraClassName(dropAfterStyle);
    } else if (status === 'before') {
      setExtraClassName(dropBeforeStyle);
    } else {
      setExtraClassName('');
    }
  };

  const onDragLeave = (event: DragEvent) => {
    setExtraClassName('');
  };

  const onDragEnd = (event: DragEvent) => {
    setDragging(false);
  };

  const insertBefore = async (stepId: string, referenceStepId?: string) => {
    try {
      const step = workflow.steps.find((step) => step.id === stepId);
      if (!step) {
        return;
      }
      const steps = workflow.steps.filter((step) => step.id !== stepId);
      if (typeof referenceStepId === 'string') {
        const index = steps.findIndex((step) => step.id === referenceStepId);
        steps.splice(index, 0, step);
      } else {
        steps.push(step);
      }

      await insertStepBefore({
        variables: {
          workflowId: workflow.id,
          stepId,
          referenceStepId,
        },
        optimisticResponse: {
          workflow: {
            ...workflow,
            insertStepBefore: {
              ...workflow,
              steps: steps.map((step) => ({
                ...step,
              })),
            },
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onDrop = (event: DragEvent) => {
    setExtraClassName('');
    setDragging(false);

    event.preventDefault();
    const stepId = event.dataTransfer.getData('text');

    const status = calculateDragPosition(event);

    if (status === 'after') {
      const index = workflow.steps.findIndex((_step) => _step.id === step.id) + 1;
      insertBefore(stepId, workflow.steps[index] ? workflow.steps[index].id : undefined);
    } else if (status === 'before') {
      insertBefore(stepId, step.id);
    } else {
      // TODO: エラーをレポートする
      console.log('?????', status);
    }
  };

  return (
    <CustomNavLink
      innerRef={ref}
      draggable={editable}
      onDragStart={editable ? onDragStart : undefined}
      onDragOver={editable ? onDragOver : undefined}
      onDragEnd={editable ? onDragEnd : undefined}
      onDrop={editable ? onDrop : undefined}
      onDragLeave={editable ? onDragLeave : undefined}
      className={`${stepBalloonStyle} ${extraClassName} ${dragging && draggingStyle}`}
      activeClassName={``}
      to={`/workspaces/${workspaceId}/workflows/${workflow.id}#step-${step.id}`}
      onClick={() => {
        const stepElement = window.document.querySelector(`#step-${step.id}`);
        stepElement?.scrollIntoView();
      }}
      style={{cursor: 'pointer'}}>
      {loading && <Loading />}
      <StepBalloonBlock>
        <div className={nameStyle}>{step.name}</div>
        {editable && (
          <span style={{display: 'inline-flex', cursor: 'move', margin: '0'}} className="step-menu">
            <MoreVertical size={16} color={colors.darkText} strokeWidth={1.5} />
            <MoreVertical size={16} color={colors.darkText} strokeWidth={1.5} style={{margin: '0 0 0 -11px'}} />
          </span>
        )}
        {false && (
          <>
            <span className="step-menu">
              <StepMenu step={step} workflow={workflow} />
            </span>
          </>
        )}
      </StepBalloonBlock>
    </CustomNavLink>
  );
};