import {useSearchParams} from '../../../base/hooks/useSearchParams';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {acceptWorkspaceInvitation, findWorkspaceInvitation} from '../../../gqls/invitation';
import {ContentBox, ContentHeaderText, FieldLabel, Page} from '../Public';
import {Helmet} from 'react-helmet-async';
import {Field} from '../../../base/components/Form/Form';
import {ExLargeButton} from '../../../base/components/Button/Button';
import {Indicator} from '../../../base/components/Indicator/Indicator';
import {tw} from 'twind';
import {UserBlock} from '../../common/UserBlock';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useToast} from '../../../base/components/Toast/Toast';
import {
  FindWorkspaceInvitation,
  FindWorkspaceInvitationVariables,
} from '../../../__generated__/FindWorkspaceInvitation';
import {
  AcceptWorkspaceInvitation,
  AcceptWorkspaceInvitationVariables,
} from '../../../__generated__/AcceptWorkspaceInvitation';
import {useState, createContext, ReactElement, useMemo, useContext} from 'react';
import {Link} from 'react-router-dom';

type ContextType = {
  set(element: ReactElement): void;
};

const NavigationContext = createContext<ContextType>({
  set() {
    console.warn('A value of NavigationContext is not provided.');
  },
});

interface InnerNavigationProps {
  default: ReactElement;
}

function InnerNavigation(props: InnerNavigationProps) {
  const [state, setState] = useState<ReactElement>(props.default);
  const context = useMemo(() => {
    return {
      set(element: ReactElement) {
        setState(element);
      },
    };
  }, []);

  return <NavigationContext.Provider value={context}>{state}</NavigationContext.Provider>;
}

function useInnerNavigator() {
  return useContext(NavigationContext).set;
}

export function InvitationRoute() {
  return <InnerNavigation default={<InvitationForm />} />;
}

function useInvitationToken(): string | null {
  const {params} = useSearchParams();
  return params.get('token');
}

function InvitationSucceed() {
  const token = useInvitationToken();
  const {data} = useAppQuery<FindWorkspaceInvitation, FindWorkspaceInvitationVariables>(findWorkspaceInvitation, {
    fetchPolicy: 'cache-only',
    variables: {
      token: token ?? '',
    },
    onError() {
      // do nothing
    },
  });

  const invitation = data?.workspaceInvitation;

  return (
    <Page>
      <Helmet>
        <title>招待を受ける</title>
      </Helmet>

      <ContentBox>
        <p className={tw`text-center font-normal text-black`}>
          <span className={tw`font-bold`}>{invitation?.targetUser.name}</span>
          さんが
          <span className={tw`font-bold`}>{invitation?.workspace.name}</span>ワークスペースに加入しました
        </p>

        <div className={tw`text-center mt-8`}>
          <Link to="/login" component={LoginButton}>
            ログインする
          </Link>
        </div>
      </ContentBox>
    </Page>
  );
}

const LoginButton = ExLargeButton.withComponent('a');

function InvitationForm() {
  const navigate = useInnerNavigator();
  const token = useInvitationToken();
  const toast = useToast();

  const {data, loading} = useAppQuery<FindWorkspaceInvitation, FindWorkspaceInvitationVariables>(
    findWorkspaceInvitation,
    {
      skip: typeof token !== 'string',
      fetchPolicy: 'network-only',
      variables: {
        token: token ?? '',
      },
      onError() {
        toast.error('招待データの取得に失敗しました');
      },
    }
  );

  const invitation = data?.workspaceInvitation;

  const [accept, {loading: sending}] = useAppMutation<AcceptWorkspaceInvitation, AcceptWorkspaceInvitationVariables>(
    acceptWorkspaceInvitation,
    {
      onError() {
        toast.error('ワークスペースの加入に失敗しました');
      },
      onCompleted() {
        toast.message('ワークスペースに加入しました');
        navigate(<InvitationSucceed />);
      },
    }
  );

  const onClick = async () => {
    await accept({
      variables: {
        token: token ?? '',
      },
    });
  };

  const memberRole = {
    guest: 'ゲスト',
    member: 'メンバー',
    admin: '管理者',
  } as const;

  return (
    <Page>
      <Helmet>
        <title>招待を受ける</title>
      </Helmet>

      <ContentBox>
        {loading && (
          <>
            <div style={{textAlign: 'center'}}>
              <Indicator />
            </div>
          </>
        )}

        {invitation && invitation.isConsumable && (
          <>
            <ContentHeaderText>招待を受ける</ContentHeaderText>

            <FieldLabel>招待された人</FieldLabel>
            <div className={tw`my-1`}>
              <UserBlock user={invitation.targetUser} />
            </div>

            <FieldLabel>招待した人</FieldLabel>
            <div className={tw`my-1`}>
              <UserBlock user={invitation.user} />
            </div>

            <FieldLabel>ワークスペース</FieldLabel>
            <div className={tw`font-bold text-base text-black`}>{invitation.workspace.name}</div>

            <FieldLabel>ロール</FieldLabel>
            <div className={tw`font-bold text-base text-black`}>{memberRole[invitation.role]}</div>

            <Field>
              <ExLargeButton onClick={onClick} disabled={sending}>
                {sending ? <Indicator color="white" /> : 'ワークスペースに加入する'}
              </ExLargeButton>
            </Field>
          </>
        )}

        {invitation && !invitation.isConsumable && (
          <>
            <div className={tw`text-black text-base text-center`}>この招待は有効ではありません</div>
          </>
        )}

        {data && !invitation && (
          <>
            <div className={tw`text-black text-base text-center`}>招待が見つかりませんでした</div>
          </>
        )}

        {!token && (
          <>
            <div className={tw`text-black text-base text-center`}>招待が見つかりませんでした</div>
          </>
        )}
      </ContentBox>
    </Page>
  );
}
