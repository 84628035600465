import * as React from 'react';
import {css} from '@emotion/css';
import {ReactComponent as ArrowIcon} from './select-box-arrow.svg';
import {useOptionsMenu} from '../OptionsMenu/hooks';
import {colors, fontSizes, fontWeights} from 'theme';
import {CSSProperties} from 'react';
import styled from '@emotion/styled';

const selectBoxStyle = css`
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${colors.inputBorder};
  line-height: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 30px 0 8px;
  color: ${colors.darkText};
  font-size: ${fontSizes.base};
  font-weight: ${fontWeights.normal};
  min-width: 100px;
  box-sizing: border-box;
  cursor: pointer;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    outline: none;
    border: 1px solid ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;

const arrowIconStyle = css`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-40%);
  pointer-events: none;
  color: ${colors.superLightText};
`;

const SelectBoxWrapper = styled.div`
  height: 30px;
  width: auto;
  position: relative;
  display: inline-flex;
  justify-content: stretch;
  align-items: stretch;
  box-sizing: border-box;
  
  &:focus-within .arrow-icon {
    color: ${colors.accent};
  }
`;

export const SelectBox = (props: JSX.IntrinsicElements['select']) => {
  return (
    <SelectBoxWrapper style={{...props.style}}>
      <ArrowIcon className={arrowIconStyle + ' arrow-icon'} />
      <select {...props} className={`${selectBoxStyle} ${props.className || ''}`} />
    </SelectBoxWrapper>
  );
};

interface CustomSelectBoxProps {
  options: {
    value: string;
    menu: React.ReactElement | ((selected: boolean) => React.ReactElement);
    label: React.ReactNode;
  }[];
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

export function CustomSelectBox(props: CustomSelectBoxProps) {
  const {value = '', onChange = () => {}, options, placeholder = '選択する…', disabled = false, style = {}} = props;
  const ref = React.useRef<HTMLButtonElement>(null);
  const currentOption = options.find((option) => option.value === value);
  const currentLabel = currentOption ? (
    currentOption.label
  ) : (
    <span style={{color: colors.placeholderText, fontWeight: fontWeights.normal}}>{placeholder}</span>
  );

  const {node} = useOptionsMenu({
    button: (
      <SelectBoxWrapper style={style}>
        <ArrowIcon className={arrowIconStyle + ' arrow-icon'} />
        <button ref={ref} className={selectBoxStyle} disabled={disabled} style={style}>
          {currentLabel}
        </button>
      </SelectBoxWrapper>
    ),
    menu(close) {
      return (
        <>
          {options.map((option) => {
            return (
              <React.Fragment key={option.value}>
                {React.cloneElement(
                  typeof option.menu === 'function' ? option.menu(option.value === value) : option.menu,
                  {
                    onMouseDown: (e: any) => {
                      e.preventDefault();
                      close();
                      onChange(option.value);
                      ref.current?.focus();
                    },
                  }
                )}
              </React.Fragment>
            );
          })}
        </>
      );
    },
  });

  return node;
}
