import {useState, useRef} from 'react';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {updateStepMutation, uploadImageMutation} from '../../../gqls/workflowMutations';
import {UploadImage, UploadImageVariables} from '../../../__generated__/UploadImage';
import {useToast} from '../../../base/components/Toast/Toast';
import {SubtleQueitButton, DarkButton} from '../../../base/components/Button/Button';
import {Edit, Save} from 'react-feather';
import {UpdateStep, UpdateStepVariables} from '../../../__generated__/UpdateStep';
import {StepFragment} from '../../../__generated__/StepFragment';
import {DocumentEditor} from '../../../base/components/DocumentEditor/DocumentEditor';
import {HorizontalBox, Space, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {findActivitiesQuery} from '../../../gqls/findActivitiesQuery';
import {colors} from 'theme';
import {css} from '@emotion/css';
import {tw} from 'twind';

interface Props {
  content: string;
  step: StepFragment;
  preview?: boolean;
}

export function StepNoteEditor(props: Props) {
  const {content, step, preview = false} = props;
  const {workspaceId} = step;
  const [key, setKey] = useState(0);

  const ref = useRef<any>(null);
  const [state, setState] = useState<{mode: 'edit' | 'view'}>({
    mode: 'view',
  });

  const toast = useToast();

  const [updateStep, updateStepResult] = useAppMutation<UpdateStep, UpdateStepVariables>(updateStepMutation, {
    onError() {
      toast.error('更新に失敗しました');
    },
    refetchQueries: [
      {
        query: findActivitiesQuery,
        variables: {
          stepId: step.id,
          workflowId: step.workflowId,
        },
      },
    ],
  });

  const save = async (params: {description: string}) => {
    try {
      await updateStep({
        variables: {
          ...params,
          stepId: step.id,
          workflowId: step.workflowId,
        },
      });
      toast.message('保存しました');
    } catch (error) {
      toast.error('保存に失敗しました');
    }
  };

  const [upload] = useAppMutation<UploadImage, UploadImageVariables>(uploadImageMutation);

  const onSave = async () => {
    const content = ref.current!.export();
    if (content) {
      await save({description: content});
    }
  };

  const onSaveAndExit = async () => {
    await onSave();
    setState({mode: 'view'});
  };

  const onCancel = () => {
    setState({mode: 'view'});
    setKey(key + 1);
  };

  if (state.mode === 'view' && (content === '' || content === '\\\n' || !content)) {
    return (
      <div>
        {preview && <div className={tw`text-lightText text-base font-normal leading-none`}>
          ステップの説明がありません
        </div>}
        {!preview && (
          <a href="" style={{display: 'inline-flex', alignItems: 'center'}} onClick={event => {
            event.preventDefault();
            setState({mode: 'edit'});
          }}>
            <Edit size={16} strokeWidth={1.5} />&nbsp;説明を追加
          </a>
        )}
      </div>
    );
  }

  return (
    <div className={tw`relative`}>
      <DocumentEditor
        key={key}
        ref={ref}
        autoFocus={state.mode === 'edit'}
        defaultValue={content}
        readOnly={state.mode === 'view'}
        readOnlyWriteCheckboxes={!preview && state.mode === 'view' && !updateStepResult.loading}
        onSave={({done}) => {
          if (done) {
            onSaveAndExit();
          } else {
            onSave();
          }
        }}
        onCancel={onCancel}
        placeholder="ステップの説明を記述する…"
        onChange={() => {
          if (state.mode === 'view') {
            onSave();
          }
        }}
        footer={
          state.mode === 'edit' ? (
            <Footer save={onSaveAndExit} cancel={onCancel} loading={updateStepResult.loading} />
          ) : null
        }
        uploadImage={async (file) => {
          if (file.size > 1024 * 1024 * 5) {
            throw Error('アップロードできる画像は5MiBまでです。');
          }

          const result = await upload({
            variables: {
              image: file,
              workspaceId,
            },
          });

          const url = result?.data?.uploadImage;

          if (typeof url !== 'string') {
            throw Error();
          }

          return url;
        }}
      />

      {state.mode !== 'edit' && !preview && 
        <div className={tw`mt-4`}>
          <a href="" style={{display: 'inline-flex', alignItems: 'center'}} onClick={event => {
            event.preventDefault();
            setState({mode: 'edit'});
          }}>
            <Edit size={16} strokeWidth={1.5} />&nbsp;説明を編集
          </a>
        </div>
      }
    </div>
  );
}

interface FooterProps {
  save: () => void;
  cancel: () => void;
  loading: boolean;
}

function Footer(props: FooterProps) {
  const {save, cancel, loading} = props;
  const isMac = navigator.platform.toLowerCase().indexOf('mac') >= 0;

  return (
    <HorizontalBox
      className={css`
        flex-grow: 1;
        margin: 16px 0 0 0;
        border-top: 1px solid ${colors.border};
        background-color: white;
        position: static;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding: 8px 0 8px 0;
      `}>
      <span className={tw`text-sm text-superLightText` + ' ' + css({paddingLeft: '8px'})}>
        {loading && '保存中…'}
        {!loading && (isMac ? '⌘+Sで保存' : 'Ctrl+Sで保存')}
      </span>
      <SpringSpace />
      <SubtleQueitButton onClick={cancel} disabled={loading}>
        キャンセル
      </SubtleQueitButton>
      <Space width={"4px"} />
      <DarkButton onClick={save} disabled={loading}>
        <Save strokeWidth={1.5} size={16} className={tw`mr-1`} />
        保存して完了
      </DarkButton>
      <Space width={"8px"} />
    </HorizontalBox>
  );
}

