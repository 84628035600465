import {useState} from 'react';
import {Indicator} from '../../../base/components/Indicator/Indicator';
import {Link} from 'react-router-dom';
import {run, isEmpty, isEmail} from '../../../base/validators';
import {Page, ContentBox, ContentHeaderText, FieldLabel, Errors, ErrorItem, A} from '../Public';
import {Helmet} from 'react-helmet-async';
import {loginMutation} from '../../../gqls/loginMutations';
import {Field, LargeTextInput} from '../../../base/components/Form/Form';
import {ExLargeButton} from '../../../base/components/Button/Button';
import {Login, LoginVariables} from '../../../__generated__/Login';
import {HorizontalBox, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {fetchSelfQuery} from '../../../gqls/selfQueries';
import {FetchSelf} from '../../../__generated__/FetchSelf';
import {useHistory} from 'react-router-dom';
import {useToast} from '../../../base/components/Toast/Toast';
import {tw} from 'twind';

interface State {
  email: string;
  password: string;
  errors: string[];
}

export const LoginRoute = () => {
  useAutoLogin();
  const history = useHistory();
  const toast = useToast();

  const [state, setState] = useState<State>({
    password: '',
    email: '',
    errors: [],
  });
  const {password, email, errors} = state;

  const [login, loginResult] = useAppMutation<Login, LoginVariables>(loginMutation);

  const onSubmit = async (event: FIXME) => {
    event.preventDefault();

    const errors = validateForm({email, password});
    setState({...state, errors});

    if (errors.length > 0) {
      return;
    }

    try {
      await login({variables: {email, password}});

      history.push(getRedirectPath());
      toast.message('ログインしました');
    } catch (error) {
      if ((error as FIXME).graphQLErrors) {
        setState({
          ...state,
          errors: (error as FIXME).graphQLErrors.map((error: {message: string}) => error.message),
        });
      } else {
        setState({
          ...state,
          errors: ['なんらかのエラーが発生しました'],
        });
        // TODO どこかにどうかしたい
        console.log(error);
      }
    }
  };

  return (
    <Page>
      <Helmet>
        <title>ログイン</title>
      </Helmet>

      <ContentBox>
        <form onSubmit={onSubmit}>
          <ContentHeaderText>ログイン</ContentHeaderText>

          <FieldLabel>メールアドレス</FieldLabel>
          <LargeTextInput
            type="text"
            tabIndex={1}
            disabled={loginResult.loading}
            placeholder="user@example.com"
            value={email}
            onChange={(event) =>
              setState({
                ...state,
                email: event.target.value,
              })
            }
            autoFocus
          />

          <HorizontalBox alignItems="flex-end">
            <FieldLabel>パスワード</FieldLabel>
            <SpringSpace />
            <Link to="/forgot_password" tabIndex={5} className={tw`mb-1`}>
              パスワードを忘れた
            </Link>
          </HorizontalBox>
          <LargeTextInput
            type="password"
            tabIndex={2}
            disabled={loginResult.loading}
            placeholder="••••••"
            value={password}
            autoComplete={"current-password"}
            onChange={(event) =>
              setState({
                ...state,
                password: event.target.value,
              })
            }
          />

          <Errors>{errors && errors.map((error) => <ErrorItem key={error}>{error}</ErrorItem>)}</Errors>

          <Field>
            <ExLargeButton tabIndex={3} type="submit" disabled={loginResult.loading}>
              {loginResult.loading ? <Indicator modifier="white" /> : 'ログイン'}
            </ExLargeButton>
          </Field>

          <div className={tw`text-center`}>
            もしくは&nbsp;
            <Link to="/signup" component={A} tabIndex={4}>
              アカウントを作成
            </Link>
          </div>
        </form>
      </ContentBox>
    </Page>
  );
};

function useAutoLogin() {
  const history = useHistory();
  const toast = useToast();

  // 自動的にログインする
  useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'network-only',
    onCompleted() {
      history.push(getRedirectPath());
      toast.message('ログインしました');
    },
    onError() {
      // do nothing
    },
  });
}

function getRedirectPath() {
  const pathname = window.location.pathname.replace(/^\/login/, '');
  const search = window.location.search;
  const path = pathname + (search === '?' ? '' : search);
  const href = path === '' ? '/' : path;

  return href;
}

const validateEmail = function* (email: string): Generator<string> {
  if (isEmpty(email)) {
    return yield 'メールアドレスを入力してください。';
  }

  if (!isEmail(email)) {
    return yield 'メールアドレスを正しく入力してください。';
  }
};

const validatePassword = function* (password: string): Generator<string> {
  if (isEmpty(password)) {
    return yield 'パスワードを入力してください。';
  }
};

const validateForm = ({email, password}: FIXME) => {
  return run(function* () {
    yield* validatePassword(password);
    yield* validateEmail(email);
  });
};
