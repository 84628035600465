import {css} from '@emotion/css';
import {useCallback, useState} from 'react';
import {ArrowDown, Check, ChevronDown, Link2, Menu, MoreHorizontal} from 'react-feather';
import {Link} from 'react-router-dom';
import {colors} from 'theme';
import {tw} from 'twind';
import {Button, QueitButton, SubtleQueitButton} from '../../../base/components/Button/Button';
import {HorizontalBox, Space, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {useOptionsMenu} from '../../../base/components/OptionsMenu/hooks';
import {MenuItem} from '../../../base/components/OptionsMenu/OptionsMenu';
import {NakedWorkflowIcon} from '../../../base/components/WorkflowIcon/WorkflowIcon';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';
import {UserIconMenu} from '../../common/UserIconMenu';

export const WorkflowRunRoute = () => {

  return (
    <>
      <WorkflowRunHeader />
      <div className={css`width: 700px; margin: 0 auto;`}>
        <div className={tw`my-4`}>

          <Space height="24px" />

          <div className={tw`flex flex-col items-center`}>
            <div className={tw`border-borderDark border text-lightText text-sm font-bold rounded-xl px-2`}>開始</div>

            <Line height="24px" />

            <StepRun name="給与計算" done />

            <Line height="16px" />

            <StepRun name="給与の支払いと特別徴収" expand />

            <Line height="24px" />

            <Button><ArrowDown size={16} strokeWidth={1.5} />&nbsp;<span className={tw`font-semiBold`}>次のステップを開始</span></Button>
          </div>
        </div>
      </div>
    </>
  );
};

interface StepRunProps {
  name: string;
  done?: boolean;
  expand?: boolean;
}

const StepRun = ({name, done, expand}: StepRunProps) => {
  const [state, setState] = useState<{expand: boolean}>({
    expand: !!expand,
  });

  const toggleExpand = useCallback(() => {
    setState(prev => ({...prev, expand: !prev.expand}));
  }, []);

  return (
    <div className={tw`border border-borderDark rounded-md px-8 py-4 w-full`}>
      <HorizontalBox>
        <ChevronDown size={16} fill={colors.blackText} stroke={"transparent"} style={{marginLeft: "-18px", transform: state.expand ? "" : "rotate(-90deg)", cursor: 'pointer'}} onClick={toggleExpand} />
        <Space width="2px" />
        <span className={tw`font-bold text-lg cursor-pointer select-none`} onClick={toggleExpand}>{name}</span>
        {done && <>
          <Space width="8px" />
          <span className={tw`bg-green-700 rounded-full text-white font-bold text-sm w-4 h-4 flex items-center justify-center`}><Check size={12} strokeWidth={3} /></span>
          <Space width="6px" />
          <span className={tw`text-lightText text-base`}>5月23日</span>
        </>}
        <SpringSpace />
        <SubtleQueitButton style={{padding: '0 6px'}}><Menu strokeWidth={1.5} size={16} /></SubtleQueitButton>
      </HorizontalBox>

      {state.expand && <>
        <Space height="8px" />
        <div>
          hogehoge
        </div>
      </>}
    </div>
  );
};

const Line = ({height}: {height: string}) => {
  return <hr className={tw`border-none bg-borderDark` + ' ' + css`width: 1px; height: ${height};`} />;
};

const WorkflowRunHeader = () => {
  const workspaceId = useWorkspaceId();

  const optionsMenu = useOptionsMenu({
    button: (
      <QueitButton style={{padding: '0 6px'}}>
        <MoreHorizontal size={16} strokeWidth={"1.5px"} />
      </QueitButton>
    ),
    menu: (close) => {
      return (
        <>
          <MenuItem icon={<Link2 strokeWidth={1.5} size={16} />}>
            URLをコピー
          </MenuItem>
        </>
      )
    }
  });

  return (
    <div>
      <HorizontalBox className={css`height: 52px; padding: 0 8px; border-bottom: 1px solid ${colors.border};`}>
        <Space width="10px" />
        <NakedWorkflowIcon workflow={{icon: null}} size={20} strokeWidth={1.4} />
        <Space width="14px" />
        <div>
          <HorizontalBox>
            <span className={tw`font-semiBold text-base text-black`}>
              給与支払い5月分
              <span
                className={tw`font-semiBold text-superLightText`}>
                  &nbsp;#{56}
              </span>
            </span>

            <Space width="12px" />
          </HorizontalBox>
        </div>
          <div className={tw`text-sm text-lightText`}>
            <Link
              className={tw`text-sm font-semiBold`}
              to={`/workspaces/${workspaceId}/workflows/hogehoge`}>
              給与支払い
            </Link>ワークフローから実行
          </div>
        <Space width="12px" />
        <SpringSpace />
        {optionsMenu.node}
        <Space width="30px" />
        <UserIconMenu workspaceId={workspaceId} />
      </HorizontalBox>
    </div>
  );
}