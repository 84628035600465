import {useState} from 'react';
import {closeWorkspaceMutation} from '../../../gqls/workspaceMutations';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useToast} from '../../../base/components/Toast/Toast';
import {WarningAlert} from '../../../base/components/Alert/Alert';
import {Field, TextInput} from '../../../base/components/Form/Form';
import {DarkButton} from '../../../base/components/Button/Button';
import {FieldLabel} from '../../public/Public';
import {CloseWorkspace, CloseWorkspaceVariables} from '../../../__generated__/CloseWorkspace';
import {ErrorBlock} from '../../../base/components/ErrorBlock/ErrorBlock';
import {useModalDialog} from '../../../base/components/ModalDialog/ModalDialog';
import {useHistory, useParams} from 'react-router-dom';
import {Header, HeaderBlock} from './styles';
import {tw} from 'twind';
import * as React from 'react';


export function CloseWorkspaceRoute() {
  const {workspaceId} = useParams<{workspaceId: string}>();

  const [state, setState] = useState<{password: string; error: string | null}>({
    password: '',
    error: null,
  });
  const toast = useToast();
  const modal = useModalDialog();
  const history = useHistory();

  const [mutate, {loading}] = useAppMutation<CloseWorkspace, CloseWorkspaceVariables>(closeWorkspaceMutation, {
    onError() {
      toast.error('ワークスペースの閉鎖に失敗しました');
    },
    async onCompleted(data) {
      if (data.workspace.close) {
        await modal.alert({
          title: '閉鎖されました',
          message: 'ワークスペースが閉鎖されました。',
        });

        history.push('/');
      } else {
        setState({
          ...state,
          error: '正しいパスワードを入力して下さい',
        });
      }
    },
  });

  const deleteWorkspace = async () => {
    mutate({
      variables: {
        id: workspaceId,
        password: state.password,
      },
    });
  };

  const isValid = state.password.length > 3;

  return (
    <div>
      <h1 className={tw`font-semiBold text-xl mb-6`}>ワークスペースの閉鎖</h1>

      <HeaderBlock>
        <Header>ワークスペースの閉鎖</Header>
      </HeaderBlock>

      <WarningAlert>
        ワークスペースを閉鎖すると、そのワークスペースのすべてのデータにアクセスできなくなります。<br />
        また、いちど閉鎖すると元に戻すことができません。
      </WarningAlert>

      <Field>
        <FieldLabel>パスワード</FieldLabel>
        <TextInput
          disabled={loading}
          type="password"
          value={state.password}
          onChange={(event) => setState({...state, password: event.currentTarget.value})}
          style={{width: '200px'}}
          placeholder="******"
        />
      </Field>

      <ErrorBlock>{state.error}</ErrorBlock>
      <div>
        <DarkButton disabled={!isValid || loading} onClick={deleteWorkspace}>
          閉鎖する
        </DarkButton>
      </div>
    </div>
  );
}
