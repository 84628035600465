import {useParams} from 'react-router-dom';
import {useAppQuery} from '../../base/hooks/useAppQuery';
import {fetchWorkspaceUsageQuery} from '../../gqls/workspaceQueries';
import {FetchWorkspaceUsage, FetchWorkspaceUsageVariables} from '../../__generated__/FetchWorkspaceUsage';
import {useToast} from '../../base/components/Toast/Toast';

export function UsageRoute() {
  const {workspaceId} = useParams<{workspaceId: string}>();
  const toast = useToast();

  const {data} = useAppQuery<FetchWorkspaceUsage, FetchWorkspaceUsageVariables>(fetchWorkspaceUsageQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: workspaceId,
    },
    onError() {
      toast.error('データの取得に失敗しました');
    },
  });

  const workspace = data?.workspace;

  if (!workspace) {
    return null;
  }

  const usage = workspace?.usage;

  if (!usage) {
    return null;
  }

  return (
    <>
      <h1>Usage</h1>

      <div>名前: {workspace.name}</div>
      <div>アップロードされたファイル容量: {usage.totalUploadedFileSize}</div>
      <div>メンバー数: {usage.memberCount}</div>
    </>
  );
}
