import {useAppMutation} from '../../base/hooks/useAppMutation';
import {CreateEmptyWorkspace, CreateEmptyWorkspaceVariables} from '../../__generated__/CreateEmptyWorkspace';
import {createWorkspaceMutation} from '../../gqls/workspaceMutations';
import {useModalDialog} from '../../base/components/ModalDialog/ModalDialog';
import {useToast} from '../../base/components/Toast/Toast';
import {fetchSelfQuery} from '../../gqls/selfQueries';
import {useHistory} from 'react-router-dom';

export function useCreateWorkspace() {
  const [mutate, result] = useAppMutation<CreateEmptyWorkspace, CreateEmptyWorkspaceVariables>(
    createWorkspaceMutation,
    {
      onError() {
        toast.error('ワークスペースの作成に失敗しました');
      },
    }
  );

  const history = useHistory();
  const modal = useModalDialog();
  const toast = useToast();

  const run = async () => {
    const name = await modal.prompt({
      title: '新しいワークスペースを作成する',
      message: '作成するワークスペースの名前を入力してください',
      placeholder: '会社名またはチーム名',
      buttonTexts: {
        submit: '作成する',
      },
    });

    if (typeof name !== 'string') {
      return;
    }

    const result = await mutate({
      variables: {
        name: name === '' ? '無名のワークスペース' : name,
      },
      refetchQueries: [{query: fetchSelfQuery}],
      awaitRefetchQueries: true,
    });

    if (result.data) {
      toast.message('ワークスペースが作成されました');
      history.push(`/workspaces/${result.data.createWorkspace.id}`);
    } else {
      toast.error('ワークスペースの作成に失敗しました');
    }
  };

  return {
    ...result,
    mutate,
    run,
  };
}
