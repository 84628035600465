import {MenuItem} from '../../../base/components/OptionsMenu/OptionsMenu';
import {Check} from 'react-feather';

export const roleOptions = [
  {
    value: 'owner',
    label: <>所有</>,
    menu: (selected: boolean) => (
      <MenuItem
        selected={selected}
        icon={selected ? <Check size={16} /> : null}
        description={<>ワークフローの全ての事柄を変更できます。</>}>
        所有
      </MenuItem>
    ),
    description: 'ワークフローの全ての事柄を変更できます。',
  },
  {
    value: 'editor',
    label: <>編集</>,
    menu: (selected: boolean) => (
      <MenuItem
        selected={selected}
        icon={selected ? <Check size={16} /> : null}
        description={<>ワークフローやワークフローに属するステップを編集することができます。</>}>
        編集
      </MenuItem>
    ),
    description: 'ワークフローやワークフローに属するステップを編集することができます。',
  },
  {
    value: 'viewer',
    label: <>閲覧</>,
    menu: (selected: boolean) => (
      <MenuItem
        selected={selected}
        icon={selected ? <Check size={16} /> : null}
        description={
          <>
            ワークフローを閲覧したり、コメントしたりできます。
            <br />
            それ以外のことはできません。
          </>
        }>
        閲覧
      </MenuItem>
    ),
    description: 'ワークフローを閲覧したり、コメントできます。それ以外のことはできません。',
  },
];

export const extraRoleOptions = [
  ...roleOptions,
  {
    value: 'none',
    label: <>共有しない</>,
    menu: (selected: boolean) => (
      <MenuItem selected={selected} icon={selected ? <Check size={16} /> : null}>
        共有しない
      </MenuItem>
    ),
  },
];
