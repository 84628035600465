import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

export const Table = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
`;

export const MenuButton = styled.button`
  appearance: none;
  background-color: white;
  border-radius: 50%;
  padding: 0;
  height: 24px;
  width: 24px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  vertical-align: 3px;
  box-sizing: border-box;
  cursor: pointer;
  color: ${colors.darkText};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${colors.background};
  }

  &:active {
    background-color: ${colors.background};
  }
`;

export const Td = styled.td`
  border-bottom: 1px solid ${colors.lightBorder};
  margin: 0;
  padding: 12px 0;

  &:first-of-type {
    width: 66px;
  }
`;

export const IconTd = styled.td`
  margin: 0;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  min-width: min-content;
  text-align: right;
`;

export const WorkflowNameTd = styled(Td)`
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.lightText};
  vertical-align: top;
`;

export const sourceWorkflowNameStyle = css`
  &,
  &:link,
  &:visited,
  &:hover {
    font-size: ${fontSizes.base};
    color: ${colors.darkText};
    text-decoration: none;
  }
`;

export const Th = styled.th`
  text-align: left;
  font-weight: ${fontWeights.normal};
  font-size: 11px;
  color: ${colors.superLightText};
  border-bottom: 1px solid ${colors.lightBorder};
  margin: 0;
  padding: 0;
  height: 28px;
`;

export const UserName = styled.div`
  font-size: 12px;
  font-weight: ${fontWeights.semiBold};
`;

export const processStepSeparatorStyle = css`
  color: ${colors.border};
`;

export const workflowNameStyle = css`
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.base};

  &:link,
  &:visited {
    color: ${colors.blackText};
    text-decoration: none;
  }

  &:hover {
    color: ${colors.darkAccent};
    text-decoration: none;
  }

  &:hover > .separator {
    color: ${colors.darkAccent};
  }
`;

export const dateStyle = css`
  font-size: 12px;
  color: ${colors.lightText};
  white-space: nowrap;
  line-height: 1;
`;

export const userBlock = css`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const loadMoreButtonStyle = css`
  border-radius: 3px;
  border: 1px solid #efeef1;
  display: block;
  width: 100%;
  appearance: none;
  background-color: transparent;
  height: 42px;
  line-height: 42px;
  color: ${colors.lightText};
  font-weight: ${fontWeights.semiBold};
  text-align: center;
  font-size: ${fontSizes.base};
  cursor: pointer;
  margin: 12px 0;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${colors.background};
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    border-color: transparent;
    font-weight: ${fontWeights.normal};
  }
`;
