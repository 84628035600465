import {css, keyframes} from '@emotion/css';
import {colors} from 'theme';
import {useEffect, useRef} from 'react';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 10%, 241.32%;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 201%, 50.322%;
    stroke-dashoffset: -100%;
  }

  100% {
    stroke-dasharray: 10%, 241.32%;
    stroke-dashoffset: -251.32%;
  }
`;

const indicatorStyle = css`
  height: 24px;
  position: relative;
  width: 24px;
  animation: ${rotate} 2s linear infinite;
`;

const primaryStyle = css`
  fill: none;
  stroke-width: 8%;
  stroke-miterlimit: 10;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: ${colors.darkText};
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  animation: ${dash} 1.5s ease-in-out infinite;
`;

const whitePrimaryStyle = css`
  stroke: white;
`;

interface Props {
  modifier?: string;
  color?: string;
  size?: number;
  delay?: boolean;
}

export const Indicator = ({modifier, color, size = 24, delay = false}: Props) => {
  modifier = typeof modifier === 'string' ? modifier : '';
  const style: any = {};
  const ref = useRef<SVGSVGElement>(null);

  if (color) {
    style.stroke = color;
  }

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.visibility = 'visible';
        }
      }, 400);
    } else {
      if (ref.current) {
        ref.current.style.visibility = 'visible';
      }
    }
  }, [delay]);

  return (
    <svg ref={ref} className={indicatorStyle} style={{visibility: 'hidden', height: `${size}px`, width: `${size}px`, display: 'inline-block'}}>
      <circle
        cx="50%"
        cy="50%"
        r="40%"
        className={`${primaryStyle} ${modifier === 'white' ? whitePrimaryStyle : ''}`}
        style={style}
      />
    </svg>
  );
};
