import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {NavLink} from 'react-router-dom';
import {colors, fontSizes, fontWeights} from 'theme';

export const stepBalloonNameStyle = css`
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.base};
  line-height: 1.4;
  color: ${colors.blackText};

  &:hover {
    color: ${colors.darkAccent};
  }
`;

export const stepBalloonContentBlockStyle = css`
  display: flex;
  justify-content: stretch;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const stepBalloonStyle = css`
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: block;
  padding: 0;
  margin: 6px 0;
  color: ${colors.darkText};

  &:link,
  &:visited {
    text-decoration: none;
    color: ${colors.darkText};
  }

  & .step-menu {
    visibility: hidden;
  }

  &:hover .step-menu {
    visibility: visible;
  }

  &:hover {
    color: ${colors.blackText};
  }
`;

export const selectedStepBalloonStyle = css`
`;

export const doneStepBalloonNameStyle = css`
`;

export const TerminalStepBalloon = styled.div`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  cursor: auto;
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.base};
  color: ${colors.blackText};

  &:link,
  &:hover,
  &:visited {
    color: ${colors.blackText};
  }
`;

export const TerminalStepBalloonLink = TerminalStepBalloon.withComponent(NavLink);

export const startStepStyle = css`
`;

export const addStepForm = css`
  margin: 4px 10px 4px 0;
`;

export const addStepInput = css`
  appearance: none;
  display: block;
  width: 100%;
  height: 38px;
  font-weight: ${fontWeights.semiBold};
  line-height: 38px;
  color: ${colors.blackText};
  border-radius: 4px;
  padding: 0 8px;
  box-sizing: border-box;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:focus {
    border: 1px solid ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const draggingStyle = css`
  opacity: 0.4;
`;

export const dropAfterStyle = css`
  &::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 0;
    border: 1px solid ${colors.accent};
    border-radius: 2px;
    content: '';
    pointer-events: none;
  }
`;

export const dropBeforeStyle = css`
  &::after {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 0;
    border: 1px solid ${colors.accent};
    border-radius: 2px;
    content: '';
    pointer-events: none;
  }
`;
