import {NodeEditorProps} from './types';
import {useSelect} from './useSelect';
import {useRemoveSelf} from './useRemoveSelf';
import {useMoveSelf} from './useMoveSelf';
import {editableBlock, selectedEditableBlock} from './styles';
import {Tooltip} from '../Tooltip/Tooltip';
import {MinimumButton} from '../Button/Button';
import {StringBlock} from './CustomForm';
import {doNothing} from './utils';
import {cx} from '@emotion/css';
import {Trash2} from 'react-feather';

export function StringNodeEditor(props: NodeEditorProps) {
  const {parent, id, store, disabled = false} = props;
  const nodeState = store.useState((state) => state.nodes[id], [id]);
  const isSelected = store.useState((state) => state.selectedId === id, [id]);
  const isProperty = !!props.parent;
  const select = useSelect(props);

  if (nodeState.type !== 'string') {
    throw Error();
  }

  const removeSelf = useRemoveSelf(props);
  const moveSelf = useMoveSelf(props);

  const isLast = parent ? parent.props[parent.props.length - 1] === id : false;
  const isFirst = parent ? parent.props[0] === id : false;

  return (
    <div className={cx(editableBlock, isSelected ? selectedEditableBlock : '')} onClick={select}>
      <div style={{float: 'right'}} onClick={(event) => event.stopPropagation()}>
        {isSelected && isProperty && !isFirst && (
          <Tooltip text="フィールドを上に移動する">
            <MinimumButton disabled={disabled} onClick={() => moveSelf(-1)}>
              ↑
            </MinimumButton>
          </Tooltip>
        )}
        &nbsp;
        {isSelected && isProperty && !isLast && (
          <Tooltip text="フィールドを下に移動する">
            <MinimumButton disabled={disabled} onClick={() => moveSelf(1)}>
              ↓
            </MinimumButton>
          </Tooltip>
        )}
        &nbsp;
        {isSelected && isProperty && (
          <Tooltip text="フィールドを破棄する">
            <MinimumButton disabled={disabled} onClick={() => removeSelf()}>
              <Trash2 size={12} />
            </MinimumButton>
          </Tooltip>
        )}
      </div>

      <StringBlock
        schema={{
          type: 'string',
          title: nodeState.title,
          description: nodeState.description,
          'ui.widget': {
            widgetType: nodeState.widget,
            placeholder: nodeState.placeholder,
          },
        }}
        value={undefined}
        setValue={doNothing}
        required={nodeState.required}
        disabled={disabled}
        preview
      />
    </div>
  );
}
