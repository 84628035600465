
function assumeToBeString(key: string): string {
  const target = process.env[key] as any;
  if (typeof target !== 'string') {
    console.warn(`Set ${JSON.stringify(key, null, '  ')} environment variable.`);
  }

  return '' + target;
}

export const PUSHER_KEY = assumeToBeString('REACT_APP_PUSHER_KEY');
export const PUSHER_CLUSTER = assumeToBeString('REACT_APP_PUSHER_CLUSTER');
