import {EditorState} from './editorState';
import {InspectorBlock} from './styles';
import {CheckboxLabel, Field, SmallLabel, SmallTextarea, SmallTextInput} from '../Form/Form';
import {SmallCheckbox} from '../Checkbox/Checkbox';
import {Store} from 'pullstate';
import {SelectBox} from '../SelectBox/SelectBox';
import {fontWeights} from 'theme';

export const Inspector = (props: {store: Store<EditorState>}) => {
  const {store} = props;
  const node = store.useState((state) => state.nodes[state.selectedId]);

  if (!node) {
    return <InspectorBlock />;
  }

  const {id} = node;

  return (
    <InspectorBlock>
      <Field>
        <SmallLabel>タイプ</SmallLabel>
        <div style={{fontWeight: fontWeights.bold}}>
          {node.type === 'string' && 'フィールド'}
          {node.type === 'object' && 'フォーム'}
        </div>
      </Field>

      {node.type === 'string' && (
        <Field>
          <SmallLabel>ウィジェット</SmallLabel>
          <SelectBox
            value={node.widget}
            onChange={(event) => {
              const value = event.currentTarget.value;
              store.update((state) => {
                if (value === 'input' || value === 'textarea') {
                  state.nodes[node.id] = {
                    ...node,
                    widget: value,
                  };
                }
              });
            }}>
            ;<option value="input">一行のテキスト</option>
            <option value="textarea">複数行のテキスト</option>
          </SelectBox>
        </Field>
      )}

      <Field>
        <SmallLabel>タイトル</SmallLabel>
        <div>
          <SmallTextInput
            type="text"
            onChange={(event) => {
              const value = event.currentTarget.value;
              store.update((state) => {
                state.nodes[id].title = value === '' ? undefined : value;
              });
            }}
            value={node.title || ''}
            style={{width: '100%'}}
          />
        </div>
      </Field>

      <Field>
        <SmallLabel>概要</SmallLabel>
        <div>
          <SmallTextarea
            onChange={(event) => {
              const value = event.currentTarget.value;
              store.update((state) => {
                state.nodes[id]!.description = value === '' ? undefined : value;
              });
            }}
            value={node.description || ''}
            style={{width: '100%'}}
          />
        </div>
      </Field>

      {node.type === 'string' && (
        <Field>
          <SmallLabel>プレースホルダ</SmallLabel>
          <div>
            <SmallTextarea
              onChange={(event) => {
                const value = event.currentTarget.value;
                store.update((state) => {
                  state.nodes[id] = {...node, placeholder: value};
                });
              }}
              value={node.placeholder || ''}
              style={{width: '100%'}}
            />
          </div>
        </Field>
      )}

      {typeof node.name === 'string' && (
        <Field>
          <SmallLabel>Key</SmallLabel>
          <div>
            <SmallTextInput
              type="text"
              onChange={(event) => {
                const value = event.currentTarget.value;
                store.update((state) => {
                  state.nodes[id].name = value;
                });
              }}
              value={node.name}
              style={{width: '100%'}}
            />
          </div>
        </Field>
      )}

      {typeof node.required === 'boolean' && (
        <Field>
          <CheckboxLabel>
            <SmallCheckbox
              checked={!!node.required}
              onChange={(event) => {
                store.update((state) => {
                  state.nodes[id].required = event.currentTarget.checked;
                });
              }}>
              必須
            </SmallCheckbox>
          </CheckboxLabel>
        </Field>
      )}
    </InspectorBlock>
  );
};
