import {useState} from 'react';
import {SyntheticEvent} from 'react';
import {ContentHeaderText, P, FieldLabel} from '../Public';
import {SmallCheckbox} from '../../../base/components/Checkbox/Checkbox';
import {Done} from './Done';
import {State} from './StateContainer';
import {run} from '../../../base/validators';
import {ErrorBlock} from '../../../base/components/ErrorBlock/ErrorBlock';
import {Indicator} from '../../../base/components/Indicator/Indicator';
import {validateName, validatePassword} from '../../../base/validators/User';
import {createAccountMutation} from '../../../gqls/createAccountMutation';
import {Field, LargeTextInput} from '../../../base/components/Form/Form';
import {ExLargeButton} from '../../../base/components/Button/Button';
import {useAppMutation} from '../../../base/hooks/useAppMutation';

interface Props {
  state: State;
  setState(state: State): void;
}

export function Setup(props: Props) {
  const [createAccount, createAccountResult] = useAppMutation(createAccountMutation);

  const [state, setState] = useState<{
    errors: string[];
    agree: boolean;
  }>({
    errors: [],
    agree: false,
  });

  const onSubmit = async (e: SyntheticEvent<any>) => {
    e.preventDefault();

    const errors = validate();

    if (errors.length > 0) {
      setState({...state, errors});
    } else {
      await createUserAccount();
    }
  };

  const handleAgreeChange = () => {
    setState({...state, agree: !state.agree});
  };

  const handleInputChange = (name: string) => (event: SyntheticEvent<HTMLInputElement>) => {
    props.setState({...props.state, [name]: event.currentTarget.value});
  };

  const handleNameChange = handleInputChange('userName');

  const handlePasswordChange = handleInputChange('userPassword');

  const createUserAccount = async () => {
    try {
      setState({...state, errors: []});

      const {token, userName, userPassword} = props.state;

      await createAccount({
        variables: {
          token,
          name: userName,
          password: userPassword,
        },
      });

      props.setState({...props.state, component: Done});
    } catch (error) {
      const errors = (error as FIXME).graphQLErrors.map((error: {message: string}) => error.message);
      setState({...state, errors});
    }
  };

  const validate = () => {
    const {userName, userPassword} = props.state;
    const {agree} = state;

    return run(() => [
      ...validateName(userName),
      ...validatePassword(userPassword),
      ...(() => {
        if (!agree) {
          return ['アカウントを作成するには利用規約に同意してください'];
        }

        return [];
      })(),
    ]);
  };

  const {userName, userPassword, email} = props.state;
  const {errors, agree} = state;
  const {loading} = createAccountResult;

  return (
    <form onSubmit={onSubmit}>
      <ContentHeaderText>アカウントの設定</ContentHeaderText>

      <FieldLabel>メールアドレス</FieldLabel>

      <LargeTextInput autoFocus type="text" value={email} readOnly />

      <FieldLabel>名前</FieldLabel>

      <LargeTextInput
        autoFocus
        type="text"
        value={userName}
        onChange={handleNameChange}
        placeholder="山田 太郎"
        disabled={false}
      />

      <FieldLabel>パスワード</FieldLabel>

      <LargeTextInput
        type="password"
        value={userPassword}
        placeholder="********"
        autoComplete="new-password"
        onChange={handlePasswordChange}
        disabled={loading}
      />

      <P>
        <SmallCheckbox checked={agree} onChange={handleAgreeChange} disabled={loading}>
          &nbsp;RelayHubの
          <a href="/terms_of_use" rel="noopener noreferrer" target="_blank">
            利用規約
          </a>
          に同意する
        </SmallCheckbox>
      </P>

      <ErrorBlock>
        {errors.map((error: any) => (
          <div key={error}>{error}</div>
        ))}
      </ErrorBlock>

      <Field>
        <ExLargeButton type="submit" disabled={loading}>
          {loading ? <Indicator modifier="white" /> : 'アカウントを作成'}
        </ExLargeButton>
      </Field>
    </form>
  );
}
