import usePortal from 'react-useportal';
import * as React from 'react';

interface UsePopover {
  openPopover: (event?: any) => void;
  closePopover: (event?: any) => void;
  togglePopover: (event?: any) => void;
  Popover: React.ComponentType;
  isOpen: boolean;
}

interface UsePopoverParams {
  width?: number;
  closeOnOutsideClick?: boolean;
  onClose?: () => void;
}

export function usePopover(params: UsePopoverParams = {}): UsePopover {
  const {width = 300, closeOnOutsideClick, onClose} = params;
  const {Portal, closePortal, openPortal, togglePortal, ...rest} = usePortal({
    closeOnOutsideClick,
    onOpen: React.useCallback(
      (args) => {
        const {portal, currentTarget} = args;

        const rect = currentTarget.getBoundingClientRect();
        const left = Math.round(rect.left + rect.width / 2);
        const top = rect.top + rect.height;

        portal.current.style.cssText = `
        width: ${typeof width === 'number' ? width + 'px' : width};
        position: absolute;
        top: ${top}px;
        left: ${left}px;
        transform: translateX(-50%);
			`;
      },
      [width]
    ),
    onClose,
  });

  return React.useMemo(
    () => ({
      Popover: Portal,
      closePopover: closePortal,
      openPopover: openPortal,
      togglePopover: togglePortal,
      ...rest,
    }),
    [Portal, closePortal, openPortal, togglePortal, rest]
  );
}
