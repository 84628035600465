import * as React from 'react';
import {Fragment} from 'react';
import {TextPlaceholder} from '../../../base/components/LoadingPlaceholder';
import {Loading} from '../../common/Loading';
import {Helmet} from 'react-helmet-async';
import {StepBalloonBlock, stepBalloonStyle} from '../../common/StepBalloon/StepBalloon';
import {BackButton, WorkflowHeaderBlock, WorkflowHeaderPlacholder} from './styles';
import {HorizontalBox, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {css} from '@emotion/css';

export const LoadingWorkflow = () => {
  return (
    <Fragment>
      <Loading />

      <Helmet>
        <title>読み込み中…</title>
      </Helmet>

      <HeaderPlaceholder />
      {false && <>
        <div
          className={css`
            padding: 32px 28px;
          `}>
          <BalloonPreview>.....</BalloonPreview>
          <BalloonPreview>........</BalloonPreview>
          <BalloonPreview>......</BalloonPreview>
          <BalloonPreview>.........</BalloonPreview>
        </div>
      </>}
    </Fragment>
  );
};

function HeaderPlaceholder() {
  return (
    <>
      <WorkflowHeaderBlock>
        <HorizontalBox style={{height: '100%'}}>
          {false && <BackButton to="/" />}
          <TextPlaceholder>..</TextPlaceholder>
          &nbsp;
          <TextPlaceholder>...</TextPlaceholder>
          &nbsp;
          <TextPlaceholder>............</TextPlaceholder>
          <SpringSpace />
          <TextPlaceholder>.....</TextPlaceholder>
        </HorizontalBox>
      </WorkflowHeaderBlock>
      <WorkflowHeaderPlacholder />
    </>
  );
}

const BalloonPreview = ({children}: {children: React.ReactNode}) => {
  return (
    <div className={stepBalloonStyle} style={{padding: '4px 0'}}>
      <StepBalloonBlock>
        <TextPlaceholder>{children}</TextPlaceholder>
      </StepBalloonBlock>
    </div>
  );
};
