import {isEmail} from './index';

export const validateName = (name: string) => {
  name = name.trim();

  if (name.length === 0) {
    return ['氏名を入力してください'];
  }

  if (name.length > 64) {
    return ['氏名は64文字以内にしてください'];
  }

  return [];
};

export const validatePassword = (password: string) => {
  password = password.trim();

  if (password.length === 0) {
    return ['パスワードを入力してください'];
  }

  if (password.length <= 6) {
    return ['パスワードは6文字よりも長く入力してください'];
  }

  if (password.length > 64) {
    return ['パスワードは64文字以内にしてください'];
  }

  if (!password.match(/^[-!"#$%&'()=~|[\]{}@*+`:;\\/?.>,<_a-zA-Z0-9]*$/)) {
    return ['パスワードは半角英数字もしくは半角記号のみで入力して下さい'];
  }

  return [];
};

export const validateEmail = (email: string) => {
  email = email.trim();

  if (email.length === 0) {
    return ['メールアドレスを入力してください'];
  }

  if (!isEmail(email)) {
    return ['メールアドレスが有効な形式ではありません'];
  }

  return [];
};
