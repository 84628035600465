import * as React from 'react';
import {Component} from 'react';
import {Page, ContentBox} from '../Public';
import {ForgotPasswordRoute} from '../ForgotPasswordRoute';
import {Confirming} from './Confirming';
import {Helmet} from 'react-helmet-async';

class ResetPasswordContainer extends Component<{}, {component: unknown}> {
  state = {
    component: Confirming,
  };

  render() {
    const Content = this.state.component;
    const setComponent = (component: FIXME) => this.setState({component});

    return (
      <ResetPasswordLayout>
        <Content setComponent={setComponent} />
      </ResetPasswordLayout>
    );
  }
}

function ResetPasswordLayout({children}: {children: React.ReactNode}) {
  return (
    <Page>
      <Helmet>
        <title>パスワードの再設定</title>
      </Helmet>
      <ContentBox>{children}</ContentBox>
    </Page>
  );
}

export const ResetPasswordRoute = () => {
  if (window.location.search === '') {
    return <ForgotPasswordRoute title={'パスワードを再設定する'} />;
  }

  return <ResetPasswordContainer />;
};
