import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

export const Field = styled.div`
  margin: 24px 0 16px 0;
  display: block;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Label = styled.div`
  color: ${colors.lightText};
  font-size: ${fontSizes.base};
  font-weight: ${fontWeights.normal};
  margin: 12px 0 4px 0;
`;

export const CheckboxLabel = styled.label`
  color: ${colors.darkText};
  font-size: ${fontSizes.base};
  font-weight: ${fontWeights.normal};
  margin: 0 0 2px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SmallLabel = styled.span`
  display: block;
  color: ${colors.darkText};
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeights.normal};
  margin: 0.4em 0 0.4em 0;
`;

export const MiniLabel = styled.span`
  color: ${colors.darkText};
  font-size: 11px;
  font-weight: ${fontWeights.normal};
  margin: 2px 0 0 0;
`;

export const TextInput = styled.input`
  appearance: none;
  border: 1px solid ${colors.inputBorder};
  height: 32px;
  background-color: white;
  box-sizing: border-box;
  font-size: ${fontSizes.base};
  padding: 0 8px 0 8px;
  border-radius: 4px;
  line-height: 1;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:disabled {
    background-color: ${colors.disabledInputBackground};
    opacity: 0.6;
  }

  &:focus {
    border-color: ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
  }
`;

export const SmallTextInput = styled.input`
  appearance: none;
  border: 1px solid ${colors.inputBorder};
  height: 28px;
  background-color: white;
  box-sizing: border-box;
  font-size: ${fontSizes.sm};
  padding: 0 4px 0 4px;
  border-radius: 3px;
  line-height: 28px;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:disabled {
    background-color: ${colors.disabledInputBackground};
    opacity: 0.6;
  }

  &:focus {
    border-color: ${colors.accent};
  }
`;

export const Textarea = styled.textarea`
  border: 1px solid ${colors.inputBorder};
  border-radius: 3px;
  appearance: none;
  background-color: white;
  padding: 6px 4px;
  box-sizing: border-box;
  font-size: ${fontSizes.base};
  line-height: 1.6;
  width: auto;
  resize: none;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:disabled {
    background-color: ${colors.disabledInputBackground};
    opacity: 0.6;
  }

  &:focus {
    border: 1px solid ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
  }
`;

export const SmallTextarea = styled.textarea`
  border: 1px solid ${colors.inputBorder};
  border-radius: 2px;
  appearance: none;
  background-color: white;
  padding: 6px 4px;
  box-sizing: border-box;
  font-size: ${fontSizes.sm};
  line-height: 1.4;
  width: auto;
  resize: none;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:disabled {
    background-color: ${colors.disabledInputBackground};
    opacity: 0.6;
  }

  &:focus {
    border: 1px solid ${colors.accent};
  }
`;

export const LargeTextarea = styled.textarea`
  border: 1px solid ${colors.inputBorder};
  border-radius: 4px;
  appearance: none;
  background-color: white;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: ${fontSizes.md};
  line-height: 1.8;
  width: auto;
  resize: none;
  letter-spacing: 0.04em;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:disabled {
    background-color: ${colors.disabledInputBackground};
    opacity: 0.6;
  }

  &:focus {
    border: 1px solid ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
  }
`;

export const LargeTextInput = styled.input`
  appearance: none;
  display: block;
  background-color: white;
  height: 42px;
  width: 100%;
  font-size: ${fontSizes.md};
  padding: 0 12px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 4px;
  border: 1px solid ${colors.inputBorder};
  letter-spacing: 0.04em;

  &::placeholder {
    color: ${colors.superLightText};
  }

  &:focus:not(:read-only) {
    outline: none;
    background-color: white;
    border-color: ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
  }

  &:disabled {
    background-color: ${colors.disabledInputBackground};
    opacity: 0.6;
  }

  &:read-only {
    background-color: ${colors.disabledInputBackground};
  }
`;
