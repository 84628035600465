import {colors} from 'theme';
import styled from '@emotion/styled';
import {ReactNode, useRef} from 'react';
import {DocumentEditor, DocumentEditorHandle} from '../../../base/components/DocumentEditor/DocumentEditor';

interface CommentInputProps {
  children: ReactNode;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
}

export const CommentInput = (props: CommentInputProps) => {
  const ref = useRef<DocumentEditorHandle>(null);

  return (
    <Wrapper>
      <StyledDocumentEditor
        ref={ref}
        defaultValue={props.defaultValue ?? ''}
        placeholder={props.placeholder ?? ''}
        onChange={() => {
          const text = ref.current?.export() ?? '';
          props.onChange?.(text);
        }}
        autoFocus={props.autoFocus}
      />
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid ${colors.inputBorder};
  border-radius: 3px;
  padding: 8px 8px 2px 8px;
  overflow: hidden;

  &:focus-within {
    background-color: white;
    border: 1px solid ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
  }

  &:disabled {
    background-color: ${colors.disabledInputBackground};
    opacity: 0.6;
  }
`;

const StyledDocumentEditor = styled(DocumentEditor)`
  border-bottom: 1px solid ${colors.lightBorder};
`;
