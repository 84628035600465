var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __markAsModule = (target) => __defProp(target, "__esModule", { value: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __reExport = (target, module2, copyDefault, desc) => {
  if (module2 && typeof module2 === "object" || typeof module2 === "function") {
    for (let key of __getOwnPropNames(module2))
      if (!__hasOwnProp.call(target, key) && (copyDefault || key !== "default"))
        __defProp(target, key, { get: () => module2[key], enumerable: !(desc = __getOwnPropDesc(module2, key)) || desc.enumerable });
  }
  return target;
};
var __toCommonJS = /* @__PURE__ */ ((cache) => {
  return (module2, temp) => {
    return cache && cache.get(module2) || (temp = __reExport(__markAsModule({}), module2, 1), cache && cache.set(module2, temp), temp);
  };
})(typeof WeakMap !== "undefined" ? /* @__PURE__ */ new WeakMap() : 0);

// index.ts
var theme_exports = {};
__export(theme_exports, {
  colors: () => colors,
  fontSizes: () => fontSizes,
  fontWeights: () => fontWeights
});
var colors = {
  blackText: "hsl(217, 20%, 10%)",
  darkText: "hsl(217, 18%, 25%)",
  lightText: "hsl(217, 14%, 40%)",
  superLightText: "hsl(217, 10%, 55%)",
  placeholderText: "hsl(217, 10%, 75%)",
  dangerBackground: "hsl(5, 61%, 52%)",
  darkDangerBackground: "hsl(5, 61%, 35%)",
  placeholderBackground: "hsl(217, 6%, 91%)",
  border: "hsl(217, 15%, 90%)",
  borderDark: "hsl(217, 15%, 80%)",
  lightBorder: "hsl(217, 15%, 95%)",
  inputBorder: "hsl(217, 15%, 88%)",
  checkboxBorder: "hsl(217, 15%, 80%)",
  lightAccent: "hsl(218, 76%, 55%)",
  accent: "hsl(218, 76%, 48%)",
  darkAccent: "hsl(218, 76%, 41%)",
  superLightAccent: "hsl(218, 90%, 88%)",
  accentButton: "hsl(220, 70%, 50%)",
  darkAccentButton: "hsl(220, 70%, 40%)",
  focusShadow: "hsl(218, 61%, 90%)",
  background: "hsl(240, 6%, 95%)",
  darkBackground: "hsl(240, 6%, 92%)",
  lightBackground: "hsl(240, 6%, 97%)",
  disabledInputBackground: "hsl(240, 6%, 97%)",
  buttonBackground: "hsl(215, 59%, 20%)",
  shortcut: "#fca503",
  sky50: "#F0F9FF",
  sky100: "#E0F2FE",
  sky200: "#BAE6FD",
  sky300: "#7DD3FC",
  sky400: "#38BDF8",
  sky500: "#0EA5E9",
  sky600: "#0284C7",
  sky700: "#0369A1",
  sky800: "#075985",
  sky900: "#0C4A6E",
  blue50: "#EFF6FF",
  blue100: "#DBEAFE",
  blue200: "#BFDBFE",
  blue300: "#93C5FD",
  blue400: "#60A5FA",
  blue500: "#3B82F6",
  blue600: "#2563EB",
  blue700: "#1D4ED8",
  blue800: "#1E40AF",
  blue900: "#1E3A8A"
};
var fontSizes = {
  xs: "11px",
  sm: "12px",
  base: "14px",
  md: "15px",
  lg: "16px",
  xl: "18px",
  xl2: "20px",
  xl3: "22px",
  xl4: "24px",
  xl5: "26px"
};
var fontWeights = {
  light: 300,
  normal: 400,
  semiBold: 500,
  bold: 600,
  extraBold: 700
};
module.exports = __toCommonJS(theme_exports);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  colors,
  fontSizes,
  fontWeights
});
