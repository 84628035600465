import {SyntheticEvent, useState} from 'react';
import {Label, TextInput, Field, SmallLabel} from '../../../../base/components/Form/Form';
import {DarkButton} from '../../../../base/components/Button/Button';
import {ErrorBlock} from '../../../../base/components/ErrorBlock/ErrorBlock';
import {validatePassword} from '../../../../base/validators/User';
import {useToast} from '../../../../base/components/Toast/Toast';
import {changePasswordMutation} from '../../../../gqls/accountMutations';
import {ChangePassword, ChangePasswordVariables} from '../../../../__generated__/ChangePassword';
import {useAppMutation} from '../../../../base/hooks/useAppMutation';
import {Header, HeaderBlock} from '../styles';
import {tw} from 'twind';
import * as React from 'react';

interface State {
  currentPassword: string;
  newPassword: string;
  errors: string[];
}

export function PasswordSettingRoute() {
  const [changePassword, changePasswordResult] = useAppMutation<ChangePassword, ChangePasswordVariables>(
    changePasswordMutation,
    {}
  );

  const [state, setState] = useState<State>({
    currentPassword: '',
    newPassword: '',
    errors: [],
  });

  const toast = useToast();

  const submit = async (event: SyntheticEvent<any>) => {
    event.preventDefault();

    setState({...state, errors: []});

    const currentPassword = state.currentPassword.trim();
    const newPassword = state.newPassword.trim();

    if (currentPassword.length === 0) {
      return setState({
        ...state,
        errors: ['現在のパスワードを入力してください'],
      });
    }

    const errors = validatePassword(newPassword);
    if (errors.length > 0) {
      return setState({...state, errors});
    }

    try {
      await requestPasswordChange();
    } catch (error) {
      const errors = (error as FIXME).graphQLErrors.map((error: FIXME) => error.message);
      toast.error('パスワードが間違っています');
      return setState({...state, errors});
    }

    setState({
      errors: [],
      newPassword: '',
      currentPassword: '',
    });

    toast.message('パスワードが変更されました。ログアウトします…');
    setTimeout(() => {
      window.location.href = '/login';
    }, 5000);
  };

  const requestPasswordChange = async () => {
    const currentPassword = state.currentPassword.trim();
    const newPassword = state.newPassword.trim();

    await changePassword({variables: {currentPassword, newPassword}});
  };

  const {currentPassword, newPassword, errors} = state;
  const sending = changePasswordResult.loading;

  return (
    <>
      <h1 className={tw`font-semiBold text-xl mb-6`}>パスワードの再設定</h1>

      <HeaderBlock>
        <Header>パスワードの再設定</Header>
      </HeaderBlock>

      <div>
        <form onSubmit={submit}>
          <Field>
            <Label>現在のパスワード</Label>
            <TextInput
              type="password"
              value={currentPassword}
              size={50}
              placeholder="*******"
              disabled={sending}
              autoComplete="current-password"
              onChange={(e) => setState({...state, currentPassword: e.target.value})}
            />
          </Field>

          <Field>
            <Label>新しいパスワード</Label>
            <TextInput
              type="password"
              value={newPassword}
              size={50}
              placeholder="*******"
              disabled={sending}
              autoComplete="new-password"
              onChange={(e) => setState({...state, newPassword: e.target.value})}
            />
            <SmallLabel>
              パスワードには、6文字以上の英数字や記号を入力してください。
              <br />
              記号には!"#$%&'()-=^~¥|\`@[{'{]}'}:*;+_/?.,{'><'}が利用できます。
            </SmallLabel>
          </Field>

          <ErrorBlock>
            {errors.map((error, i) => (
              <div key={'' + i + error}>{error}</div>
            ))}
          </ErrorBlock>

          <div>
            <DarkButton type="submit" disabled={sending}>
              パスワードを更新
            </DarkButton>
          </div>
        </form>
      </div>
    </>
  );
}

