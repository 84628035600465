import gql from 'graphql-tag';
import {commentFragment} from './fragments';

export const addCommentMutation = gql`
  mutation AddComment($workflowId: ID!, $stepId: ID!, $commentText: String!, $files: [Upload!]) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        comment(text: $commentText, files: $files) {
          ...CommentFragment
        }
      }
    }
  }
  ${commentFragment}
`;

export const updateCommentMutation = gql`
  mutation UpdateComment($workflowId: ID!, $stepId: ID!, $commentId: ID!, $commentText: String!) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        updateComment(id: $commentId, text: $commentText) {
          ...CommentFragment
        }
      }
    }
  }
  ${commentFragment}
`;

export const removeCommentMutation = gql`
  mutation RemoveComment($workflowId: ID!, $stepId: ID!, $commentId: ID!) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        removeComment(id: $commentId) {
          ...CommentFragment
        }
      }
    }
  }
  ${commentFragment}
`;
