import styled from '@emotion/styled';
import {HorizontalBox} from '../HorizontalBox/HorizontalBox';
import * as React from 'react';
import {AlertTriangle} from 'react-feather';
import {fontSizes, fontWeights} from 'theme';
import {tw} from 'twind';

export const Alert = styled.div`
  font-size: ${fontSizes.base};
  border-radius: 4px;
  line-height: 1.4;
  font-weight: ${fontWeights.normal};
  margin: 12px 0;
  box-sizing: border-box;
  letter-spacing: 0.08em;
  background-color: white;

  &:empty {
    display: none;
  }
`;

const WarningBlock = styled(Alert)`
  color: #c35200;
`;

const ErrorBlock = styled(Alert)`
  color: hsl(0deg 60% 53%);
  border: 1px solid hsl(0deg 60% 53%);
`;

interface Props {
  children: React.ReactNode;
}

export function WarningAlert(props: Props) {
  const {children} = props;

  return (
    <WarningBlock>
      <HorizontalBox alignItems="center">
        <AlertTriangle size={16} strokeWidth={"1.5px"} className={tw`ml-0.5 mr-1.5 flex-shrink-0`} />
        <div>{children}</div>
      </HorizontalBox>
    </WarningBlock>
  );
}
export function ErrorAlert(props: Props) {
  const {children} = props;

  return (
    <ErrorBlock>
      <HorizontalBox alignItems="center">
        <AlertTriangle size={16} className={tw`ml-0.5 mr-1.5 flex-shrink-0`} />
        <div>{children}</div>
      </HorizontalBox>
    </ErrorBlock>
  );
}
