import {SchemaNode, SchemaRoot} from './schema';
import {EditorState, NodeState, NodeStates} from './editorState';
import shortId from 'shortid';

export function createEditorState(schema: SchemaRoot): EditorState {
  const nodes: Record<string, NodeState> = {};

  const aggregate = (node: SchemaNode): NodeState => {
    switch (node.type) {
      case 'object': {
        const state: NodeStates['object'] = {
          id: shortId(),
          type: 'object',
          description: node.description,
          title: node.title,
          props: [],
        };

        for (const [name, value] of Object.entries(node.properties)) {
          const nodeState = aggregate(value);
          nodeState.name = name;
          nodeState.required = (node.required || []).includes(name);
          state.props.push(nodeState.id);
        }

        nodes[state.id] = state;

        return state;
      }

      case 'string': {
        const state: NodeStates['string'] = {
          id: shortId(),
          type: 'string',
          description: node.description,
          title: node.title,
          widget: node['ui.widget']?.widgetType || 'input',
          placeholder: node['ui.widget']?.placeholder || '',
        };

        nodes[state.id] = state;

        return state;
      }
    }
  };

  const root = aggregate(schema);

  if (root.type !== 'object') {
    throw Error('Invalid state');
  }

  return {
    rootId: root.id,
    nodes,
    selectedId: root.id,
  };
}
