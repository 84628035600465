import {useRef} from 'react';
import {Upload, Trash2, Settings} from 'react-feather';
import {useToast} from '../../base/components/Toast/Toast';
import {useAppMutation} from '../../base/hooks/useAppMutation';
import {UploadImage, UploadImageVariables} from '../../__generated__/UploadImage';
import {updateWorkflowMutation, uploadImageMutation} from '../../gqls/workflowMutations';
import {UpdateWorkflow, UpdateWorkflowVariables} from '../../__generated__/UpdateWorkflow';
import {useOptionsMenu} from '../../base/components/OptionsMenu/hooks';
import {MenuItem} from '../../base/components/OptionsMenu/OptionsMenu';
import {Indicator} from '../../base/components/Indicator/Indicator';
import {Button} from '../../base/components/Button/Button';
import styled from '@emotion/styled';
import {WorkflowSummaryFragment} from '../../__generated__/WorkflowSummaryFragment';
import {colors} from 'theme';

interface Props {
  workflow: WorkflowSummaryFragment;
}

export function WorkflowCoverImageMenu(props: Props) {
  const {workflow} = props;

  const hasCoverImage = typeof workflow.coverImageUrl === 'string';

  const toast = useToast();
  const [uploadImage, uploadImageResult] = useAppMutation<UploadImage, UploadImageVariables>(uploadImageMutation, {
    onError(e) {
      toast.error('カバー画像のアップロードに失敗しました');
    },
  });

  const [updateWorkflow, updateWorkflowResult] = useAppMutation<UpdateWorkflow, UpdateWorkflowVariables>(
    updateWorkflowMutation,
    {
      onError() {
        toast.error('カバー画像の設定に失敗しました');
      },
    }
  );

  const fileInput = useRef<HTMLInputElement | null>(null);

  const {node} = useOptionsMenu({
    button: (
      <WorkflowMenuButton className="show-on-hover">
        <Settings size={16} strokeWidth="1.5px" />
      </WorkflowMenuButton>
    ),
    menu(close) {
      return (
        <>
          <MenuItem
            onClick={async () => {
              close();
              fileInput.current!.click();
            }}
            description={'アップロードできる画像は5MiBまで'}
            icon={<Upload size={16} />}>
            カバー画像をアップロード…
          </MenuItem>

          {hasCoverImage && (
            <MenuItem
              icon={<Trash2 size={16} />}
              onClick={() => {
                close();

                updateWorkflow({
                  variables: {
                    id: workflow.id,
                    coverImageUrl: '',
                  },
                });
              }}>
              カバー画像を削除する
            </MenuItem>
          )}
        </>
      );
    },
  });

  return (
    <span onClick={(event) => event.stopPropagation()} style={{position: 'absolute', top: '10px', right: '10px'}}>
      <input
        type="file"
        style={{display: 'none'}}
        ref={fileInput}
        onChange={async (event) => {
          const input = event.currentTarget;
          if (input.files && input.files.length > 0) {
            // ファイルサイズチェックする
            if (input.files[0].size > 1024 * 1024 * 5) {
              toast.error('ファイルが大きすぎます');
              return;
            }

            const result = await uploadImage({
              variables: {
                workspaceId: workflow.workspace!.id,
                image: input.files[0],
              },
            });

            input.value = '';

            if (typeof result?.data?.uploadImage === 'string') {
              updateWorkflow({
                variables: {
                  id: workflow.id,
                  coverImageUrl: result.data.uploadImage,
                },
              });
            }
          }
        }}
      />
      {uploadImageResult.loading || updateWorkflowResult.loading ? <Indicator color={colors.blackText} /> : node}
    </span>
  );
}

const WorkflowMenuButton = styled(Button)`
  visibility: hidden;
  width: auto;
  border: 1px solid rgba(0, 0, 0, 20%);
  background-clip: padding-box;

  &,
  &:hover,
  &:active {
    background-color: white;
    padding-left: 6px;
    padding-right: 6px;
  }

  &:active {
    background-color: #eee;
  }
`;
