import * as React from 'react';
import {useAnimationState} from '../../hooks/useAnimationState';

interface Deps {
  styles: {
    mask: string;
    dialog: string;
  };
}

type Props = {
  children?: React.ReactNode;
  onClickOutside?: () => void;
  style?: React.CSSProperties;
  zIndex?: React.CSSProperties['zIndex'];
};

export function BaseDialog(this: Deps, props: Props) {
  const {styles} = this;
  const {children = null, onClickOutside = () => {}, style = {}} = props;
  const {started, ended} = useAnimationState(0.2);

  const maskAnimationStyle = {
    willChange: ended ? '' : 'opacity',
    opacity: started ? 1 : 0,
    transition: 'opacity 0.2s ease',
  };

  const dialogAnimationStyle = {
    willChange: ended ? '' : 'transform',
    transform: started ? 'scale(1)' : 'scale(0.9)',
    transition: 'transform 0.2s ease',
  };

  const handleClickOutside = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        onClickOutside();
      }
    },
    [onClickOutside]
  );

  return (
    <div className={styles.mask} onClick={handleClickOutside} style={maskAnimationStyle}>
      <div className={styles.dialog} onClick={(e) => e.stopPropagation()} style={{...style, ...dialogAnimationStyle}}>
        {children}
      </div>
    </div>
  );
}
