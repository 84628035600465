import styled from '@emotion/styled';
import {Page} from './Public';
import {Helmet} from 'react-helmet-async';
import {colors, fontSizes} from 'theme';
import {tw} from 'twind';

const Content = styled.div`
  width: 960px;
  max-width: 98%;
  border-radius: 8px;
  background-color: white;
  padding: 36px 60px;
  line-height: 1.6;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.06);
  color: ${colors.darkText};
  margin: 40px 0;
  font-size: ${fontSizes.md};
`;

export function TermsOfUseRoute() {
  // TODO: 利用規約を追加する
  return (
    <Page>
      <Helmet>
        <title>利用規約</title>
      </Helmet>

      <Content>
        <h1 className={tw`text-black text-xl2 font-bold mb-2`}>RelayHub利用規約</h1>

        <pre className={tw``} style={{whiteSpace: 'pre-wrap'}}>{利用規約}</pre>
      </Content>
    </Page>
  );
}

const 利用規約 = `
この利用規約(以下、「本規約」といいます。)は、RelayHub合同会社(以下、「当社」といいます。)がこのウェブサイト上で提供するサービス(以下、「本サービス」といいます。)の利用条件を定めるものです。登録ユーザーの皆さま(以下、「ユーザー」といいます。)には、本規約に従って、本サービスをご利用いただきます。

第1条(適用)
本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。

第2条(利用登録)
1. 登録希望者が当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
2. 当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
(1)利用登録の申請に際して虚偽の事項を届け出た場合
(2)本規約に違反したことがある者からの申請である場合
(3)その他、当社が利用登録を相当でないと判断した場合

第3条(ユーザーIDおよびパスワードの管理)
1. ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。
2. ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。

第4条(利用料金および支払方法)
1. ユーザーは、本サービス利用の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします。

第5条(禁止事項)
ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
(1)法令または公序良俗に違反する行為
(2)犯罪行為に関連する行為
(3)当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
(4)当社のサービスの運営を妨害するおそれのある行為
(5)他のユーザーに関する個人情報等を収集または蓄積する行為
(6)他のユーザーに成りすます行為
(7)当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
(8)その他、当社が不適切と判断する行為

第6条(本サービスの提供の停止等)
1. 当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
(1)本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
(2)地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
(3)コンピュータまたは通信回線等が事故により停止した場合
(4)その他、当社が本サービスの提供が困難と判断した場合
2. 当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。

第7条(利用制限および登録抹消)
1. 当社は、以下の場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
(1)本規約のいずれかの条項に違反した場合
(2)登録事項に虚偽の事実があることが判明した場合
(3)その他、当社が本サービスの利用を適当でないと判断した場合
2. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。

第8条(免責事項)
1. 当社の債務不履行責任は、当社の故意または重過失によらない場合には免責されるものとします。
2. 当社は、何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内かつ有料サービスにおいては代金額(継続的サービスの場合には1か月分相当額)の範囲内においてのみ賠償の責任を負うものとします。
3. 当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。

第9条(サービス内容の変更等)
当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。

第10条(利用規約の変更)
当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。

第11条(通知または連絡)
ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。

第12条(権利義務の譲渡の禁止)
ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。

第13条(一般条項)
1. 本規約の解釈にあたっては、日本法を準拠法とします。
2. 本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
以上
`;
