import {WorkflowStatus as Status} from '../../../__generated__/globalTypes';
import {css} from '@emotion/css';
import {fontWeights} from 'theme';

const baseStyle = `
  color: white;
  font-size: 12px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  line-height: 1;
  padding: 3px 4px;
  font-weight: ${fontWeights.bold};
  text-align: center;
  word-wrap: normal;
  word-break: keep-all;
  appearance: none;
  border: 1px solid black;
  color: black;
  
  &:focus {
    outline: none;
  }
`;

const runningStyle = css`
  ${baseStyle};
  border-color: rgb(222, 181, 112);
  color: rgb(217, 119, 6);
`;

const draftStyle = css`
  ${baseStyle};
  border-color: #0284C7;
  color: #0284C7;
`;

const publishedStyle = css`
  ${baseStyle};
  border-color: #5B21B6;
  color: #5B21B6;
`;

const canceledStyle = css`
  ${baseStyle};
  border-color: #E36911;
  color: #E36911;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3) inset;
`;

const archivedStyle = css`
  ${baseStyle};
  border-color: dimgray;
  color: dimgray;
`;

const finishedStyle = css`
  ${baseStyle};
  border-color: rgb(114, 197, 144);
  color: rgb(22, 163, 74);
`;

export const statusDictionary: Record<string, {style: string; label: string; color: string;}> = {
  [Status.draft]: {
    style: draftStyle,
    label: '下書き',
    color: '#0284C7'
  },

  [Status.running]: {
    style: runningStyle,
    label: '処理中',
    color: 'rgb(217, 119, 6)',
  },

  [Status.canceled]: {
    style: canceledStyle,
    label: '中止',
    color: '#E36911',
  },

  [Status.finished]: {
    style: finishedStyle,
    label: '完了',
    color: 'rgb(22, 163, 74)',
  },

  [Status.published]: {
    style: publishedStyle,
    label: '公開',
    color: '#5B21B6',
  },

  [Status.archived]: {
    style: archivedStyle,
    label: 'アーカイブ',
    color: 'dimgray',
  },
};
