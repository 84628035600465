import {Helmet} from 'react-helmet-async';
import {WorkflowsRoute, instanceStatuses, prototypeStatuses} from '../../workflow/WorkflowsRoute';
import {Redirect, Route, Switch, useParams} from 'react-router-dom';
import {colors} from 'theme';
import {Fill, LeftResizable, ViewPort} from 'react-spaces';
import {css} from '@emotion/css';
import {DashboardNavi} from './DashboardNavi';
import {StartWorkflowRoute} from '../../workflow/StartWorkflowRoute';
import {UsageRoute} from '../../sandbox/UsageRoute';
import {useLocalStorage} from '../../../base/hooks/useLocalStorage';
import {SettingsRoute} from '../SettingsRoute/SettingsRoute';
import {WorkflowRoute} from '../../workflow/WorkflowRoute/WorkflowRoute';
import {WorkflowRunRoute} from '../../workflowRun/WorkflowRunRoute/WorkflowRunRoute';
import {WorkflowRunsRoute} from '../../workflowRun/WorkflowRunsRoute/WorkflowRunsRoute';

export function WorkspaceRoute() {
  const {workspaceId} = useParams<{workspaceId: string}>();
  const [leftWidth, setLeftWidth] = useLocalStorage(`workspace-${workspaceId}.dashboard.leftWidth`, 280);

  return (
    <ViewPort>
      <Helmet>
        <title>ダッシュボード - RelayHub</title>
      </Helmet>

      <LeftResizable
        size={leftWidth}
        minimumSize={240}
        maximumSize={600}
        scrollable
        // NOTE: このコンポーネントのonResizeEndにわたす関数は、レンダリングの初回に渡したもののみが呼び出される。
        // workspaceIdが変わるタイミングでLeftResizableを初期化しないと古い関数が呼び出されるのでこのkeyは必要
        key={workspaceId}
        onResizeEnd={(width) => {
          if (typeof width === 'number') {
            setLeftWidth(width);
          }
        }}
        className={css`
          height: 100%;
          background-color: ${colors.lightBackground};
          padding: 16px;
          border-right: 1px solid ${colors.border};
        `}>
        <DashboardNavi />
      </LeftResizable>
      <Fill scrollable>
          <Switch>
            <Route path={'/workspaces/:workspaceId/workflows/start'}>
              <div className={css` margin: 28px 36px;`}>
                <StartWorkflowRoute />
              </div>
            </Route>
            <Route path={'/workspaces/:workspaceId/workflows/:workflowId'}>
              <WorkflowRoute />
            </Route>
            <Route exact path={'/workspaces/:workspaceId/workflow_runs'}>
              <div className={css` margin: 28px 36px;`}>
                <WorkflowRunsRoute />
              </div>
            </Route>
            <Route path={'/workspaces/:workspaceId/workflow_runs/:workflowRunId'}>
              <WorkflowRunRoute />
            </Route>
            <Route path={'/workspaces/:workspaceId/settings'}>
              <div className={css` margin: 28px 36px;`}>
                <SettingsRoute />
              </div>
            </Route>
            <Route path={'/workspaces/:workspaceId/usage'}>
              <div className={css` margin: 28px 36px;`}>
                <UsageRoute />
              </div>
            </Route>
            <Route path={'/workspaces/:workspaceId/workflows'}>
              <div className={css` margin: 28px 36px;`}>
                <WorkflowsRoute statuses={prototypeStatuses} title="ワークフロー" />
              </div>
            </Route>
            <Route path={'/workspaces/:workspaceId/instances'}>
              <div className={css` margin: 28px 36px;`}>
                <WorkflowsRoute statuses={instanceStatuses} title="起動したワークフロー" />
              </div>
            </Route>
            <Route>
              <Redirect to={`/workspaces/${workspaceId}/workflows`} />
            </Route>
          </Switch>
      </Fill>
    </ViewPort>
  );
}
