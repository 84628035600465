import gql from 'graphql-tag';
import {commentFragment, userFragment} from './fragments';
import {FindActivities_activities_items} from '../__generated__/FindActivities';

export type Activity = FindActivities_activities_items;

export const findActivitiesQuery = gql`
  query FindActivities($stepId: ID!, $workflowId: ID!, $after: String, $limit: Int) {
    activities(stepId: $stepId, workflowId: $workflowId, after: $after, limit: $limit) {
      totalCount
      hasNext
      endCursor
      items {
        id
        createdAt

        ... on CreateStepActivity {
          user {
            ...UserFragment
          }
        }

        ... on UpdateStepActivity {
          name {
            from
            to
          }
          user {
            ...UserFragment
          }
        }

        ... on RemoveCommentActivity {
          user {
            ...UserFragment
          }
        }

        ... on AddCommentActivity {
          comment {
            ...CommentFragment
          }
        }

        ... on ChangeStepStatusActivity {
          from
          to
          user {
            ...UserFragment
          }
        }

        ... on AssignStepActivity {
          user {
            ...UserFragment
          }
          assignee {
            ...UserFragment
          }
        }

        ... on UnassignStepActivity {
          user {
            ...UserFragment
          }
          assignee {
            ...UserFragment
          }
        }
      }
    }
  }
  ${userFragment}
  ${commentFragment}
`;
