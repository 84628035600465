import {useContext} from 'react';
import {fetchSelfQuery} from '../../../gqls/selfQueries';
import {FetchSelf} from '../../../__generated__/FetchSelf';
import {duplicateWorkflowMutation, removeWorkflowMutation} from '../../../gqls/workflowMutations';
import {DuplicateWorkflow, DuplicateWorkflowVariables} from '../../../__generated__/DuplicateWorkflow';
import {useHistory} from 'react-router';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {MenuItem, MenuSeparator, MenuHeader} from '../../../base/components/OptionsMenu/OptionsMenu';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useOptionsMenu} from '../../../base/components/OptionsMenu/hooks';
import {QueitButton} from '../../../base/components/Button/Button';
import {
  Archive,
  Edit,
  Edit3,
  Eye,
  Loader,
  Trash2,
  CheckSquare,
  Square,
  Link2,
  Star,
  MoreHorizontal,
} from 'react-feather';
import {ToastContext, useToast} from '../../../base/components/Toast/Toast';
import {RemoveWorkflow, RemoveWorkflowVariables} from '../../../__generated__/RemoveWorkflow';
import {useModalDialog} from '../../../base/components/ModalDialog/ModalDialog';
import {WorkflowStatus} from '../../../__generated__/globalTypes';
import {useChangeWorkflowStatus} from './workflowHooks';
import {isPrototype} from './StepPanel';
import {UserBlock} from '../../../app/common/UserBlock';
import {tw} from 'twind';
import {Link} from 'react-router-dom';
import {Tooltip} from '../../../base/components/Tooltip/Tooltip';
import {SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {useFavoriteToggle} from './useFavoriteToggle';
import {clipboard} from '../../../base/modules/clipboard';
import * as React from 'react';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';
const dateFormat = require('dateformat');

interface Props {
  workflow: WorkflowFragment;
}

function useRemoveWorkflow() {
  const toast = useContext(ToastContext);
  const history = useHistory();
  const [mutate, result] = useAppMutation<RemoveWorkflow, RemoveWorkflowVariables>(removeWorkflowMutation, {
    onError(error) {
      // do nothing
    },
    onCompleted() {
      history.push('/');
      toast.message('ワークフローを削除しました');
    },
  });

  return {
    mutate,
    ...result,
  };
}

function useRemoveWorkflowDialog({workflow}: {workflow: WorkflowFragment}) {
  const {mutate} = useRemoveWorkflow();
  const modal = useModalDialog();

  const removeWorkflow = async () => {
    const yes = await modal.confirm({
      title: '確認',
      message: 'ワークフローを削除しますか?',
    });

    if (yes) {
      mutate({
        variables: {
          id: workflow.id,
        },
      });
    }
  };

  return removeWorkflow;
}

export function WorkflowMenu({workflow}: Props) {
  const {data} = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-only',
  });
  const showRemoveMenu = data?.self.id === workflow.owner?.id;

  const removeWorkflow = useRemoveWorkflowDialog({workflow});
  const archive = useChangeWorkflowStatus(WorkflowStatus.archived, workflow);
  const changeToDraft = useChangeWorkflowStatus(WorkflowStatus.draft, workflow);
  const publish = useChangeWorkflowStatus(WorkflowStatus.published, workflow);
  const finish = useChangeWorkflowStatus(WorkflowStatus.finished, workflow);
  //const cancel = useChangeWorkflowStatus(WorkflowStatus.canceled, workflow);
  const running = useChangeWorkflowStatus(WorkflowStatus.running, workflow);
  const history = useHistory();

  const isPreviewRoute = !!history.location.pathname.match(/\/preview$/);
  const toast = useToast();

  const [toggleFavorite] = useFavoriteToggle(workflow);
  const workspaceId = useWorkspaceId();

  const optionsMenu = useOptionsMenu({
    button: (
      <QueitButton style={{padding: '0 6px'}}>
        <MoreHorizontal size={16} strokeWidth={"1.5px"} />
      </QueitButton>
    ),
    menu: (close) => {
      return (
        <>
          {clipboard.isAvailable() && (
            <>
              <MenuItem
                icon={<Link2 size={16} />}
                onClick={() => {
                  clipboard.writeText(`${window.location.protocol}//${window.location.host}/workspaces/${workspaceId}/workflows/${workflow.id}`);
                  close();
                  toast.message('URLをコピーしました');
                }}
                description="ワークフローのURLを取得する">
                URLをコピー
              </MenuItem>
            </>
          )}
          {(workflow.status === WorkflowStatus.published || workflow.status === WorkflowStatus.draft) && (
            <>
              <MenuItem
                icon={<Eye size={16} />}
                onClick={() => {
                  close();
                  if (isPreviewRoute) {
                    history.replace(`/workspaces/${workspaceId}/workflows/${workflow.id}`);
                  } else {
                    history.replace(`/workspaces/${workspaceId}/workflows/${workflow.id}/preview`);
                  }
                }}
                checked={isPreviewRoute}
                description={'ワークフロー全体を1ページでプレビューする'}>
                プレビュー
              </MenuItem>
            </>
          )}
          <MenuSeparator />
          <MenuHeader>状態</MenuHeader>
          {workflow.status === WorkflowStatus.published && (
            <MenuItem
              icon={<Archive size={16} />}
              description={'ワークフローをアーカイブする'}
              disabled={!workflow.canEdit}
              onClick={(event) => {
                archive.run();
                close();
              }}>
              アーカイブ
            </MenuItem>
          )}
          {workflow.status === WorkflowStatus.published && (
            <MenuItem
              icon={<Edit3 size={16} />}
              description={'ワークフローを下書き状態に戻す'}
              disabled={!workflow.canEdit}
              onClick={(event) => {
                changeToDraft.run();
                close();
              }}>
              下書きに戻す
            </MenuItem>
          )}
          {workflow.status === WorkflowStatus.draft && (
            <MenuItem
              icon={<Loader size={16} />}
              description={'公開範囲内でこのワークフローを開始できるようにする'}
              disabled={!workflow.canEdit}
              onClick={(event) => {
                publish.run();
                close();
              }}>
              公開する
            </MenuItem>
          )}
          {workflow.status === WorkflowStatus.archived && (
            <MenuItem
              description={'ワークフローをアーカイブから復元して、開始できるようにする'}
              disabled={!workflow.canEdit}
              onClick={(event) => {
                publish.run();
                close();
              }}>
              アーカイブから復元する
            </MenuItem>
          )}

          {!isPrototype(workflow.status) && workflow.status !== WorkflowStatus.finished && (
            <MenuItem
              description={'ワークフローを完了状態にする'}
              icon={<CheckSquare size={16} />}
              disabled={!workflow.canEdit}
              onClick={(event) => {
                finish.run();
                close();
              }}>
              完了する
            </MenuItem>
          )}
          {!isPrototype(workflow.status) && workflow.status !== WorkflowStatus.running && (
            <MenuItem
              icon={<Square size={16} />}
              description={'ワークフローを処理中の状態に戻す'}
              disabled={!workflow.canEdit}
              onClick={(event) => {
                running.run();
                close();
              }}>
              処理中にする
            </MenuItem>
          )}
          {/*
          {!isPrototype(workflow.status) && workflow.status !== WorkflowStatus.canceled && <MenuItem
            description={'ワークフローをキャンセル状態にする'} onClick={(event) => {
            cancel.run();
            close();
          }}>
            キャンセルする
          </MenuItem>}
          */}
          <MenuSeparator />
          {!workflow.isFavorite ? (
            <>
              <MenuItem
                icon={<Star size={16} />}
                onClick={() => {
                  close();
                  toggleFavorite();
                }}>
                ショートカットに追加
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem
                icon={<Star size={16} />}
                onClick={() => {
                  close();
                  toggleFavorite();
                }}>
                ショートカットから外す
              </MenuItem>
            </>
          )}
          <RenameWorkflowMenuItem close={close} workflow={workflow} />
          {showRemoveMenu && (
            <MenuItem
              icon={<Trash2 size={16} />}
              description={''}
              disabled={!workflow.canEdit}
              onClick={() => {
                close();
                removeWorkflow();
              }}>
              削除する…
            </MenuItem>
          )}
          <MenuSeparator />
          <div className={tw`m-2`}>
            {workflow.owner && (
              <div className={tw`my-3`}>
                <span className={tw`text-lightText text-sm font-semiBold`}>所有者</span>
                <div className={tw`my-1`}>
                  <UserBlock user={workflow.owner} variant={'simple'} iconSize={24} />
                </div>
              </div>
            )}

            <div className={tw`flex items-center my-2`}>
              <span className={tw`text-lightText text-sm font-semiBold`}>
                {isPrototype(workflow.status) ? '作成日時' : '開始日時'}
              </span>
              <SpringSpace />
              <span className={tw`font-bold text-sm text-lightText leading-none`}>
                <Tooltip text={dateFormat(workflow.createdAt, 'yyyy年m月d日 HH:MM')}>
                  <span>{dateFormat(workflow.createdAt, 'm月d日 HH:MM')}</span>
                </Tooltip>
              </span>
            </div>

            <div className={tw`flex items-center my-2`}>
              <span className={tw`text-lightText text-sm font-semiBold`}>更新日時</span>
              <SpringSpace />
              <span className={tw`font-bold text-sm text-lightText leading-none`}>
                <Tooltip text={dateFormat(workflow.updatedAt, 'yyyy年m月d日 HH:MM')}>
                  <span>{dateFormat(workflow.updatedAt, 'm月d日 HH:MM')}</span>
                </Tooltip>
              </span>
            </div>

            {workflow.sourceWorkflow && (
              <div className={tw`my-3`}>
                <span className={tw`text-lightText text-sm font-semiBold`}>元のワークフロー</span>
                <div className={tw``}>
                  <Link
                    className={tw`font-semiBold text-blackText visited:text-blackText link:text-blackText hover:text-darkAccent hover:underline`}
                    to={`/workspaces/${workspaceId}/workflows/${workflow.sourceWorkflow.id}`}>
                    {workflow.sourceWorkflow.name}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </>
      );
    },
  });

  return <>{optionsMenu.node}</>;
}

export function DuplicateMenuItem({close, workflow}: {close: () => void; workflow: WorkflowFragment}) {
  const workspaceId = useWorkspaceId();
  const [mutate] = useAppMutation<DuplicateWorkflow, DuplicateWorkflowVariables>(duplicateWorkflowMutation, {
    onError() {
      // do nothing
    },
    onCompleted(data) {
      if (data?.workflow.duplicate) {
        const duplicatedWorkflow = data.workflow.duplicate;
        history.push(`/workspaces/${workspaceId}/workflows/${duplicatedWorkflow.id}`);
      }
    },
  });
  const history = useHistory();

  // NOTE: APIがまだ実装されてない

  return (
    <MenuItem
      onClick={async () => {
        close();
        await mutate({variables: {id: workflow.id}});
      }}>
      複製する
    </MenuItem>
  );
}

function RenameWorkflowMenuItem({close, workflow}: {close: () => void; workflow: WorkflowFragment}) {
  return (
    <MenuItem
      icon={<Edit size={16} strokeWidth="1.5px" />}
      disabled={!workflow.canEdit}
      onClick={() => {
        close();

        // TODO: この辺どうにかしたい
        const input = document.querySelector<HTMLInputElement>('input[name=workflow-name-input]');
        if (input) {
          input.focus();
          input.select();
        }
      }}>
      名前を変更する
    </MenuItem>
  );
}
