import * as React from 'react';
import {ObjectSchema, SchemaRoot, StringSchema} from './schema';
import {Field, Label, LargeTextarea, LargeTextInput} from '../Form/Form';
import {JSON, JSONMap} from './types';

interface Props {
  schema: SchemaRoot;
  value: JSONMap;
  setValue(value: JSONMap): void;
  disabled?: boolean;
  preview?: boolean;
}

// カスタムブロックを走らせるコンポーネント
export function CustomForm(props: Props) {
  return <ObjectBlock {...props} />;
}

interface BlockProps<Schema, Value> {
  schema: Schema;
  value: Value | undefined;
  setValue(value: Value): void;
  preview?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export function ObjectBlock(props: BlockProps<ObjectSchema, JSONMap>) {
  const {schema, setValue, preview = false, disabled = false} = props;
  const value = props.value || {};

  const blocks = Object.entries(schema.properties).map(([name, propSchema]) => {
    switch (propSchema.type) {
      case 'string':
        return (
          <StringBlock
            key={name}
            schema={propSchema}
            value={getString(value, name)}
            preview={preview}
            required={!!schema.required?.includes(name)}
            disabled={disabled}
            setValue={(blockValue) => {
              setValue({
                ...value,
                [name]: blockValue,
              });
            }}
          />
        );

      case 'object':
        return (
          <ObjectBlock
            key={name}
            schema={propSchema}
            preview={preview}
            disabled={disabled}
            value={getJSONMap(value, name)}
            setValue={(blockValue) => {
              setValue({
                ...value,
                [name]: blockValue,
              });
            }}
          />
        );
    }

    throw Error();
  });

  return (
    <Field style={{marginTop: 0}}>
      {typeof schema.title === 'string' && <h2>{schema.title}</h2>}
      {typeof schema.description === 'string' && <div>{schema.description}</div>}
      <>{blocks}</>
    </Field>
  );
}

export function StringBlock(props: BlockProps<StringSchema, string>) {
  const {schema, setValue, preview, required, disabled = false} = props;
  const value = asString(props.value, asString(schema.default));

  const widget = schema['ui.widget'];
  const widgetType = widget?.widgetType || 'input';
  const placeholder = widget?.placeholder || '';

  const style: React.CSSProperties = {
    pointerEvents: preview ? 'none' : 'auto',
    width: widgetType === 'input' ? '75%' : '100%',
  };

  return (
    <Field>
      {typeof schema.title === 'string' && (
        <Label>
          {schema.title}
          {required && '(必須)'}
        </Label>
      )}
      {typeof schema.title !== 'string' && required && <Label>(必須)</Label>}
      {typeof schema.description === 'string' && <div>{schema.description}</div>}

      {widgetType === 'input' && (
        <LargeTextInput
          value={value}
          readOnly={preview}
          disabled={disabled}
          placeholder={placeholder}
          style={style}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      )}
      {widgetType === 'textarea' && (
        <LargeTextarea
          value={value}
          readOnly={preview}
          disabled={disabled}
          placeholder={placeholder}
          cols={60}
          rows={4}
          style={style}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      )}
    </Field>
  );
}

export function getString(target: JSON, name: string): string | undefined {
  const value = asJSONMap(target)[name];

  if (typeof value === 'number' || typeof value === 'string') {
    return '' + value;
  }

  return undefined;
}

export function getJSONMap(target: JSON, name: string): JSONMap | undefined {
  const value = asJSONMap(target)[name];

  if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
    return value;
  }

  return undefined;
}

export function asJSONMap(value: JSON): JSONMap {
  if (value instanceof Object) {
    return value as JSONMap; // まずいかも
  }

  return {};
}

export function asString(value: any, fallback: string = ''): string {
  return typeof value === 'string' ? value : fallback;
}
