// idから色相を求めるのに利用する
export function cheapHash(str: string): number {
  let hash = 0;

  if (str.length === 0) {
    return hash;
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
}

export function rgb2cssColor(rgb: [number, number, number]): string {
  const pad = (str: string) => ('0' + str).substr(-2, 2);
  return '#' + rgb.map((n) => pad(Math.round(n).toString(16))).join('');
}

export function lab2rgb(lab: [number, number, number]): [number, number, number] {
  const [l, a, b] = lab;
  const y = l <= 8 ? (l * 100) / 903.3 : 100 * Math.pow((l + 16) / 116, 3);
  const y2 = l <= 8 ? 7.787 * (y / 100) + 16 / 116 : Math.cbrt(y / 100);
  const x =
    a / 500.0 + y2 <= 0.2069 ? (95.047 * (a / 500 + y2 - 16 / 116)) / 7.787 : 95.047 * Math.pow(a / 500 + y2, 3);
  const z =
    y2 - b / 200.0 <= 0.2059 ? (108.883 * (y2 - b / 200 - 16 / 116)) / 7.787 : 108.883 * Math.pow(y2 - b / 200, 3);
  const [x1, y1, z1] = [x, y, z].map((d) => d / 100);

  return [
    x1 * 3.2406 + y1 * -1.5372 + z1 * -0.4986,
    x1 * -0.9689 + y1 * 1.8758 + z1 * 0.0415,
    x1 * 0.0557 + y1 * -0.204 + z1 * 1.057,
  ].map(
    (d) => Math.min(1, Math.max(0, d > 0.0031308 ? 1.055 * Math.pow(d, 1.0 / 2.4) - 0.055 : d * 12.92)) * 255
  ) as FIXME;
}

export function lch2lab(lch: [number, number, number]): [number, number, number] {
  const rad = (lch[2] * Math.PI) / 180;
  return [lch[0], lch[1] * Math.cos(rad), lch[1] * Math.sin(rad)];
}

export function lch2color(lch: [number, number, number]): string {
  return rgb2cssColor(lab2rgb(lch2lab(lch)));
}

export function generateColorFromID(id: string, lightness: number = 62, chroma: number = 51): string {
  const hash = cheapHash(id);
  const hue = hash ** 10 % 360;
  return lch2color([lightness, chroma, hue]);
}
