import {useMemo} from 'react';
import styled from '@emotion/styled';
import {UserFragment} from '../../../__generated__/UserFragment';
import {generateColorFromID} from '../../utils/utils';
import {colors, fontSizes, fontWeights} from 'theme';

interface Props {
  size?: 96 | 80 | 48 | 32 | 36 | 24 | 20 | 16;
  url?: string;
  name?: string;
  loading?: boolean;
  id?: string;
}

export const UserIcon = (props: Props) => {
  const {size = 32, url, loading = false, id = ''} = props;
  const generatedColor = useMemo(() => generateColorFromID(id), [id]);
  const backgroundColor = typeof url === 'string' ? 'transparent' : generatedColor;

  const scale = size < 24 ? 0.55 : 0.42;

  const hasImage = typeof url === 'string';

  const style = {
    width: size + 'px',
    height: size + 'px',
    minWidth: size + 'px',
    minHeight: size + 'px',
    padding: '0',
    backgroundImage: hasImage ? `url(${url})` : 'none',
    content: '',
    backgroundColor: loading || hasImage ? 'transparent' : backgroundColor,
    fontSize: Math.floor(scale * size) + 'px',
    textAlign: 'center',
  } as const;

  const alternativeText = !hasImage ? getAlternativeText(props.name) : '';

  return (
    <InnerUserIcon style={style}>
      {typeof url !== 'string' && !loading && <AvatarText>{alternativeText}</AvatarText>}
    </InnerUserIcon>
  );
};

function getAlternativeText(name: string | undefined) {
  if (typeof name !== 'string') {
    return '';
  }

  const result = name.match(/^[a-zA-Z]+\s+[a-zA-Z0-9]+\s*$/);
  if (result) {
    const names = name.split(/\s+/);
    if (names.length > 1) {
      return names[0].substr(0, 1).toUpperCase() + names[1].substr(0, 1).toUpperCase();
    }
  }

  const firstCharacter = name.substr(0, 1);

  return firstCharacter.toUpperCase();
}

const AvatarText = styled.span`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  line-height: 1;
  padding-left: 0.08em;
  letter-spacing: 0.03em;
  color: white;
`;

const InnerUserIcon = styled.span`
  display: table;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-clip: border-box;
  box-sizing: border-box;
  content: '';
  text-align: center;
  overflow: hidden;
  user-select: none;
  vertical-align: top;
  flex-shrink: 0;
`;

interface InlineUserBlockProps {
  user: Pick<UserFragment, 'name' | 'iconUrl'>;
}

export function InlineUserBlock16(props: InlineUserBlockProps) {
  const {name, iconUrl} = props.user;

  return (
    <UserBlock>
      <span style={{transform: 'translateY(1px)'}}>
        <UserIcon size={16} name={name} url={iconUrl || undefined} />
      </span>
      &nbsp;
      <span
        style={{
          lineHeight: 1,
          fontSize: fontSizes.base,
          fontWeight: fontWeights.bold,
          color: colors.blackText,
        }}>
        {name}
      </span>
    </UserBlock>
  );
}

export const UserBlock = styled.span`
  display: inline-flex;
  align-items: center;
`;
