import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

export const StepPanelLayout = styled.div`
  margin: 20px auto 48px auto;
`;

export const StepPanelWrapper = styled(StepPanelLayout)`
  background-color: white;
  margin: 44px 0 48px 0;
`;

export const StepNameInput = styled.input`
  margin: 0;
  padding: 0;
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeights.bold};
  appearance: none;
  display: block;
  width: 100%;
  flex-grow: 1;
  color: ${colors.blackText};
  border: 1px solid transparent;
  border-radius: 2px;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover,
  &:focus {
    border: 1px solid ${colors.inputBorder};
  }

  &:hover {
    background-color: ${colors.background};
  }

  &:focus {
    outline: none;
    border: 1px solid ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
    background-color: white;
  }

  &:read-only {
    opacity: 1;
    border: 1px solid transparent;
    background-color: white;
    box-shadow: none;
  }
`;
