import {useCallback} from 'react';
import {NodeEditorProps} from './types';

export function useRemoveSelf(props: NodeEditorProps) {
  const {id, parent, store} = props;

  return useCallback(() => {
    if (!parent) {
      return;
    }

    const changed = {
      ...parent,
      props: parent.props.filter((propId) => propId !== id),
    };

    store.update((state) => {
      state.nodes[changed.id] = changed;
      delete state.nodes[id];
    });
  }, [id, parent, store]);
}
