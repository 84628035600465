import {tw} from 'twind';
import * as React from 'react';
import {useParams, Switch, Redirect} from 'react-router-dom';
import {Route} from 'react-router';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {FetchWorkspace, FetchWorkspaceVariables} from '../../../__generated__/FetchWorkspace';
import {fetchWorkspaceQuery} from '../../../gqls/workspaceQueries';
import {LoadingErrorBlock} from '../../../base/LoadingErrorBlock';
import {GeneralSettingsRoute} from './GeneralSettingsRoute';
import {CloseWorkspaceRoute} from './CloseWorkspaceRoute';
import {InviteRoute} from './InviteRoute';
import {MembersRoute} from './MembersRoute';
import {UserSettingsRoute} from './personal/UserSettingsRoute';
import {CloseAccountRoute} from './personal/CloseAccountRoute';
import {PasswordSettingRoute} from './personal/PasswordSettingRoute';
import {Indicator} from '../../../base/components/Indicator/Indicator';

export function SettingsRoute() {
  const {workspaceId} = useParams<{workspaceId: string}>();

  const {data, error} = useAppQuery<FetchWorkspace, FetchWorkspaceVariables>(fetchWorkspaceQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {id: workspaceId},
  });

  const workspace = data?.workspace;

  if (error) {
    return <LoadingErrorBlock>読み込みに失敗しました</LoadingErrorBlock>;
  }

  if (!workspace) {
    return <div className={tw`text-center`}><Indicator /></div>;
  }

  return (
    <Switch>
      <Route path={'/workspaces/:workspaceId/settings/close'}>
        <CloseWorkspaceRoute />
      </Route>
      <Route path={'/workspaces/:workspaceId/settings/general'}>
        <GeneralSettingsRoute workspace={workspace} />
      </Route>
      <Route path={'/workspaces/:workspaceId/settings/members'}>
        <MembersRoute workspace={workspace} />
      </Route>
      <Route path={'/workspaces/:workspaceId/settings/invite'}>
        <InviteRoute />
      </Route>
      <Route path={'/workspaces/:workspaceId/settings/personal/close'}>
        <CloseAccountRoute />
      </Route>
      <Route path={'/workspaces/:workspaceId/settings/personal/password'}>
        <PasswordSettingRoute />
      </Route>
      <Route path={'/workspaces/:workspaceId/settings/personal'}>
        <UserSettingsRoute />
      </Route>
      <Route>
        <Redirect to={`/workspaces/${workspaceId}/settings/general`} />
      </Route>
    </Switch>
  );
}

