import {Activity} from '../../../gqls/findActivitiesQuery';
import {StepActivity} from '../../../base/components/StepActivity/StepActivity';
import styled from '@emotion/styled';
import {UserFragment} from '../../../__generated__/UserFragment';
import {Comment} from '../Comment/Comment';
import {colors, fontWeights} from 'theme';
import {Edit, UserPlus, UserMinus, PlusCircle} from 'react-feather';
import * as React from 'react';
import {CommentStatus} from '../../../__generated__/globalTypes';

export const Strong = styled.strong`
  color: ${colors.blackText};
  font-weight: ${fontWeights.bold};
`;

const Strike = styled.s`
  color: ${colors.blackText};
  font-weight: ${fontWeights.bold};
`;

const UserInline = ({user}: {user: UserFragment}) => {
  return <span style={{color: colors.blackText, fontWeight: fontWeights.bold}}>{user.name}</span>;
};

type Props = {
  activity: Activity;
  stepId: string;
  workflowId: string;
};

export const ActivityRenderer = ({activity, stepId, workflowId}: Props) => {
  switch (activity.__typename) {
    case 'CreateStepActivity':
      return (
        <StepActivity
          icon={
            <IconWrapper>
              <PlusCircle size={16} strokeWidth={"1.5px"} />
            </IconWrapper>
          }
          createdAt={new Date(activity.createdAt)}
          user={activity.user}>
          ステップを作成しました
        </StepActivity>
      );

    case 'UpdateStepActivity':
      if (activity.name) {
        return (
          <StepActivity
            createdAt={new Date(activity.createdAt)}
            user={activity.user}
            icon={
              <IconWrapper>
                <Edit size={16} strokeWidth="1.5px" />
              </IconWrapper>
            }>
            {<UserInline user={activity.user} />}がステップ名を<Strike>{activity.name.from}</Strike>から<Strong>{activity.name.to}</Strong>に変更しました
          </StepActivity>
        );
      } else {
        return (
          <StepActivity
            createdAt={new Date(activity.createdAt)}
            user={activity.user}
            icon={
              <IconWrapper>
                <Edit size={16} strokeWidth="1.5px" />
              </IconWrapper>
            }>
            {<UserInline user={activity.user} />}がステップを更新しました
          </StepActivity>
        );
      }

    case 'RemoveCommentActivity':
      return (
        <StepActivity createdAt={new Date(activity.createdAt)} user={activity.user}>
          {<UserInline user={activity.user} />}がコメントを削除しました
        </StepActivity>
      );

    case 'AddCommentActivity':
      if (activity.comment.status === CommentStatus.removed) {
        return null;
      }
      return <Comment comment={activity.comment} workflowId={workflowId} stepId={stepId} />;

    case 'ChangeStepStatusActivity':
      if (activity.to === 'open' && activity.from === 'close') {
        return (
          <StepActivity createdAt={new Date(activity.createdAt)} user={activity.user}>
            ステップを<Strong>未完了</Strong>に設定しました
          </StepActivity>
        );
      }

      if (activity.to === 'close' && activity.from === 'open') {
        return (
          <StepActivity createdAt={new Date(activity.createdAt)} user={activity.user}>
            ステップを<Strong>完了</Strong>に設定しました
          </StepActivity>
        );
      }

      return (
        <StepActivity createdAt={new Date(activity.createdAt)} user={activity.user}>
          ステップの状態を{activity.from}から{activity.to}に変更しました
        </StepActivity>
      );

    case 'AssignStepActivity':
      return (
        <StepActivity
          createdAt={new Date(activity.createdAt)}
          user={activity.user}
          icon={
            <IconWrapper>
              <UserPlus size={16} />
            </IconWrapper>
          }>
          ステップの担当者に{<UserInline user={activity.assignee} />}を設定しました
        </StepActivity>
      );

    case 'UnassignStepActivity':
      return (
        <StepActivity
          createdAt={new Date(activity.createdAt)}
          user={activity.user}
          icon={
            <IconWrapper>
              <UserMinus size={16} />
            </IconWrapper>
          }>
          ステップの担当者から{<UserInline user={activity.assignee} />}を外しました
        </StepActivity>
      );

    default:
      throw Error('Invalid state.');
  }
};

const IconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  color: ${colors.lightText};
  border-radius: 50%;
`;
