/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ACLRole {
  editor = "editor",
  none = "none",
  owner = "owner",
  viewer = "viewer",
}

export enum CommentStatus {
  created = "created",
  edited = "edited",
  removed = "removed",
}

export enum InvitationStatus {
  approved = "approved",
  canceled = "canceled",
  pending = "pending",
}

export enum MemberRole {
  admin = "admin",
  guest = "guest",
  member = "member",
}

export enum StepStatus {
  close = "close",
  open = "open",
}

export enum WorkflowSort {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export enum WorkflowStatus {
  archived = "archived",
  canceled = "canceled",
  draft = "draft",
  finished = "finished",
  published = "published",
  running = "running",
}

export interface DateRangeInput {
  end?: any | null;
  start?: any | null;
}

export interface EntryInput {
  email: string;
  role: MemberRole;
}

export interface ProfileSettingsInput {
  name?: string | null;
  receiveNews?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
