import * as React from 'react';
import {css, cx} from '@emotion/css';
import {colors} from 'theme';

const smallCheckbox = css`
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid ${colors.checkboxBorder};
  position: relative;
`;

const checkbox = css`
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: 2px solid ${colors.checkboxBorder};
`;

const smallCheckboxInput = css`
  appearance: none;
  opacity: 0.0001;
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: 0;

  &:checked + .checkbox {
    background-color: ${colors.accent};
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:focus + .checkbox,
  &:active + .checkbox {
    box-shadow: 0 0 0 2px ${colors.focusShadow};
    border: 1px solid ${colors.accent};
  }

  &:checked + .checkbox::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    width: 6px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    border-radius: 0 0 0 1px;
    content: '';
    transform: scale(1.6) translate(2px, 2px) rotate(-45deg);
  }

  &:disabled + .checkbox {
    opacity: 0.6;
    background-color: #f3f3f3;
    pointer-events: none;
  }

  &:checked:disabled + .checkbox {
    opacity: 0.6;
    background-color: #43a2ff;
  }

  &:disabled:focus + .checkbox {
    box-shadow: none;
  }
  
  &:disabled {
    pointer-events: none;
  }
`;

const checkboxInput = css`
  &:checked + .checkbox::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    width: 10px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 0 0 0 1px;
    content: '';
    transform: scale(1) translate(1.5px, 2.5px) rotate(-45deg);
  }

  &:focus + .checkbox,
  &:active + .checkbox {
    box-shadow: 0 0 0 2px ${colors.focusShadow};
    border: 2px solid ${colors.accent};
  }

  &:checked + .checkbox {
    background-color: ${colors.darkText};
    border: 2px solid transparent;
    border-radius: 4px;
  }
`;

const labelStyle = css`
  display: inline-flex;
  align-items: center;

  &:active > .checkbox {
    box-shadow: inset 0 0 0 14px rgba(0, 0, 0, 0.06), inset 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 2px #efeff2;
  }

  &[disabled]:active > .checkbox {
    box-shadow: none;
  }
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onClick?: (event: any) => void;
  checkboxClassName?: string;
}

export const SmallCheckbox = ({children, checked, onChange, onClick, checkboxClassName, disabled = false, ...rest}: Props) => {
  return (
    <label className={labelStyle} onClick={onClick || (() => {})} style={{pointerEvents: disabled ? 'none' : 'auto'}}>
      <input
        type="checkbox"
        className={smallCheckboxInput}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      <span className={cx('checkbox', smallCheckbox, checkboxClassName)} />
      &nbsp;
      <span style={{lineHeight: '1'}}>{children}</span>
    </label>
  );
};

export const Checkbox = (props: Props) => {
  const {children, checked, onChange, onClick = () => {}, disabled = false, ...rest} = props;

  return (
    <label className={labelStyle} onClick={onClick}>
      <input
        type="checkbox"
        className={cx(smallCheckboxInput, checkboxInput)}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      <span className={cx('checkbox', smallCheckbox, checkbox)} />
      &nbsp;
      <span style={{lineHeight: '1'}}>{children}</span>
    </label>
  );
};
