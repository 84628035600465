import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: ${fontSizes.lg};
  font-weight: ${fontWeights.normal};
  color: ${colors.superLightText};
  text-align: center;
  width: 100%;
  min-height: 500px;
`;

export const NoSelectedStep = () => {
  return (
    <Wrapper>
      <div>
        <div style={{margin: '8px 0 0 0'}}>ステップが未選択です</div>
      </div>
    </Wrapper>
  );
};
