import * as React from 'react';
import {css} from '@emotion/css';
import {colors, fontWeights} from 'theme';
import {statusDictionary} from './_common';

const baseStyle = `
  color: white;
  font-size: 12px;
  background-color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  line-height: 1;
  padding: 2px 4px;
  font-weight: ${fontWeights.bold};
  text-align: center;
  word-wrap: normal;
  word-break: keep-all;
  appearance: none;
  border: 1px solid black;
  color: black;
  
  &:focus {
    outline: none;
  }
`;

const subtleStatus = css`
  ${baseStyle};

  border-color: ${colors.darkBackground};
  font-weight: ${fontWeights.bold};
  color: ${colors.lightText};
`;

export function SubtleStatusLabel({status}: {status: string}) {
  return <span className={subtleStatus}>{(statusDictionary as any)[status].label}</span>;
}

export function StatusLabel({status, style}: {status: string; style?: JSX.IntrinsicElements['span']['style']}) {
  if (statusDictionary[status]) {
    const {style: className, label} = statusDictionary[status];

    return <span className={className} style={style}>{label}</span>;
  }

  return null;
}
