import {Page} from '../common/basis/basis';
import {Helmet} from 'react-helmet-async';
import {colors} from 'theme';
import {HorizontalBox, Space, SpringSpace} from '../../base/components/HorizontalBox/HorizontalBox';
import {ArrowRightCircle} from 'react-feather';
import {NewWorkflowButton, WorkflowBox, WorkflowGalleryContainer} from '../workspace/WorkflowGallary';
import {WorkflowSort, WorkflowStatus} from '../../__generated__/globalTypes';
import {tw} from 'twind';
import {RunWorkflowButton} from '../../app/workflow/RunWorkflowButton';
import {Indicator} from '../../base/components/Indicator/Indicator';

export function StartWorkflowRoute() {
  return (
    <Page>
      <Helmet>
        <title>ワークフローを開始</title>
      </Helmet>

      <div>
        <HorizontalBox>
          <ArrowRightCircle size={24} color={colors.blackText} className={tw`flex-shrink-0`} strokeWidth={1.5} />
          <Space width={'8px'} />
          <h1 className={tw`text-black font-semiBold text-xl whitespace-nowrap`}>ワークフローを開始</h1>
          <SpringSpace />
          <RunWorkflowButton />
          <Space width="6px" />
          <NewWorkflowButton />
        </HorizontalBox>

        <p className={tw`text-lightText mt-3 mb-4`}>開始するワークフローを選択してください。</p>

        <WorkflowGalleryContainer
          status={WorkflowStatus.published}
          renderWorkflow={(workflow) => <WorkflowBox key={workflow.id} workflow={workflow} preview />}
          sort={WorkflowSort.updatedAt}
          renderLoading={() => <div className={tw`my-16 flex justify-center`}><Indicator delay /></div>}
        />
      </div>
    </Page>
  );
}
