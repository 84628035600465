import {HorizontalBox, Space} from '../../base/components/HorizontalBox/HorizontalBox';
import {UserIconContainer} from './UserIconContainer';
import {UserFragment} from '../../__generated__/UserFragment';
import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';
import {UserPopup} from '../../base/components/UserPopup/UserPopup';

interface Props {
  user: UserFragment;
  variant?: 'default' | 'simple' | 'icon';
  iconSize?: 96 | 48 | 36 | 32 | 24 | 20 | 16;
}

export function UserBlock({user, iconSize, variant = 'default'}: Props) {
  switch (variant) {
    case 'default':
      return <DefaultUserBlock user={user} iconSize={iconSize} />;
    case 'simple':
      return <SimpleUserBlock user={user} />;
    case 'icon':
      return <IconUserBlock user={user} iconSize={iconSize} />;
  }
  throw Error();
}

function DefaultUserBlock({user, iconSize}: Props) {
  return (
    <Box>
      <UserIconContainer user={user} size={iconSize ?? 32} />
      <Space />
      <div style={{}}>
        <Name>{user.name}</Name>
        <Email>{user.email}</Email>
      </div>
    </Box>
  );
}

export function SimpleUserBlock({user}: Props) {
  return (
    <Box>
      <UserIconContainer user={user} size={24} />
      <Space />
      <div style={{flexShrink: 1, flexGrow: 1}}>
        <Name>{user.name}</Name>
      </div>
    </Box>
  );
}

export function IconUserBlock({user, iconSize = 24}: Props) {
  return <UserPopup user={user} target={<UserIconContainer user={user} size={iconSize} />} />;
}

const Name = styled.div`
  color: ${colors.blackText};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.base};
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Email = styled.div`
  margin: 2px 0 0 0;
  color: ${colors.superLightText};
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.sm};
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0.02em;
`;

const Box = styled(HorizontalBox)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: stretch;
`;
