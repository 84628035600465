import gql from 'graphql-tag';
import {workflowFragment, stepFragment, userFragment} from './fragments';

export const createWorkflowMutation = gql`
  mutation CreateWorkflow($name: String!, $workspaceId: ID!) {
    createWorkflow(name: $name, workspaceId: $workspaceId) {
      id
    }
  }
`;

export const toggleFavoriteWorkflowMutation = gql`
  mutation ToggleFavoriteWorkflow($workflowId: ID!, $favorite: Boolean) {
    toggleFavoriteWorkflow(workflowId: $workflowId, favorite: $favorite)
  }
`;

export const updateWorkflowMutation = gql`
  mutation UpdateWorkflow(
    $id: ID!
    $name: String
    $status: WorkflowStatus
    $color: String
    $icon: String
    $coverImageUrl: String
  ) {
    workflow(id: $id) {
      update(name: $name, status: $status, color: $color, icon: $icon, coverImageUrl: $coverImageUrl) {
        id
        name
        status
        color
        icon
        coverImageUrl
      }
    }
  }
`;

export const updateFormDataMutation = gql`
  mutation UpdateFormData($id: ID!, $stepId: ID!, $data: String!) {
    workflow(id: $id) {
      step(id: $stepId) {
        updateFormData(data: $data) {
          ...StepFragment
        }
      }
    }
  }
  ${stepFragment}
`;

export const updateFormSchemaMutation = gql`
  mutation UpdateFormSchema($id: ID!, $stepId: ID!, $schema: String) {
    workflow(id: $id) {
      step(id: $stepId) {
        updateFormSchema(schema: $schema) {
          ...StepFragment
        }
      }
    }
  }
  ${stepFragment}
`;

export const runWorkflowMutation = gql`
  mutation RunWorkflow($name: String!, $workspaceId: ID!, $sourceWorkflowId: ID) {
    runWorkflow(name: $name, workspaceId: $workspaceId, sourceWorkflowId: $sourceWorkflowId) {
      id
    }
  }
`;

export const addUserRoleMutation = gql`
  mutation AddUserRoleMutation($id: ID!, $email: String!, $role: ACLRole!) {
    workflow(id: $id) {
      addUserRole(email: $email, role: $role) {
        id
        acl {
          user {
            ...UserFragment
          }
          workspace {
            id
            name
          }
          role
        }
      }
    }
  }
  ${userFragment}
`;

export const updateUserRoleMutation = gql`
  mutation UpdateUserRoleMutation($id: ID!, $userId: ID!, $role: ACLRole!) {
    workflow(id: $id) {
      updateUserRole(userId: $userId, role: $role) {
        id
      }
    }
  }
`;

export const addWorkspaceRoleMutation = gql`
  mutation AddWorkspaceRoleMutation($id: ID!, $workspaceId: ID!, $role: ACLRole!) {
    workflow(id: $id) {
      addWorkspaceRole(workspaceId: $workspaceId, role: $role) {
        id
      }
    }
  }
`;

export const updateWorkspaceRoleMutation = gql`
  mutation UpdateWorkspaceRoleMutation($id: ID!, $workspaceId: ID!, $role: ACLRole!) {
    workflow(id: $id) {
      updateWorkspaceRole(workspaceId: $workspaceId, role: $role) {
        id
      }
    }
  }
`;

export const removeWorkspaceRoleMutation = gql`
  mutation RemoveWorkspaceRoleMutation($id: ID!, $workspaceId: ID!) {
    workflow(id: $id) {
      removeWorkspaceRole(workspaceId: $workspaceId) {
        id
      }
    }
  }
`;

export const removeUserRoleMutation = gql`
  mutation RemoveUserRoleMutation($id: ID!, $userId: ID!) {
    workflow(id: $id) {
      removeUserRole(userId: $userId) {
        id
      }
    }
  }
`;

export const removeWorkflowMutation = gql`
  mutation RemoveWorkflow($id: ID!) {
    workflow(id: $id) {
      remove {
        id
        status
      }
    }
  }
`;

export const changeStepStatusMutation = gql`
  mutation ChangeStepStatus($workflowId: ID!, $stepId: ID!, $status: StepStatus!) {
    workflow(id: $workflowId) {
      changeStepStatus(id: $stepId, status: $status) {
        id
        status
        steps {
          id
          workflowId
          status
        }
        progress {
          numerator
          denominator
        }
      }
    }
  }
`;

export const assignToStepMutation = gql`
  mutation AssignToStep($workflowId: ID!, $stepId: ID!, $userId: ID!) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        assign(userId: $userId) {
          id
          workflowId
          assignees {
            ...UserFragment
          }
        }
      }
    }
  }
  ${userFragment}
`;

export const unassignFromStepMutation = gql`
  mutation UnassignFromStep($workflowId: ID!, $stepId: ID!, $userId: ID!) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        unassign(userId: $userId) {
          id
          workflowId
          assignees {
            ...UserFragment
          }
        }
      }
    }
  }
  ${userFragment}
`;

export const updateStepMutation = gql`
  mutation UpdateStep($workflowId: ID!, $stepId: ID!, $name: String, $description: String) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        update(name: $name, description: $description) {
          id
          workflowId
          name
          description
        }
      }
    }
  }
`;

export const addStepMutation = gql`
  mutation AddStep($workflowId: ID!, $stepName: String!, $before: String) {
    workflow(id: $workflowId) {
      addStep(name: $stepName, before: $before) {
        id
        name
        status
        createdAt
        owner {
          ...UserFragment
        }
        steps {
          ...StepFragment
        }
      }
    }
  }

  ${stepFragment}
  ${userFragment}
`;

export const removeStepMutation = gql`
  mutation RemoveStep($workflowId: ID!, $stepId: ID!) {
    workflow(id: $workflowId) {
      removeStep(id: $stepId) {
        id
        name
        status
        createdAt
        owner {
          ...UserFragment
        }
        steps {
          ...StepFragment
        }
      }
    }
  }

  ${stepFragment}
  ${userFragment}
`;

export const insertStepBeforeMutation = gql`
  mutation InsertStepBefore($workflowId: ID!, $stepId: ID!, $referenceStepId: ID) {
    workflow(id: $workflowId) {
      insertStepBefore(stepId: $stepId, referenceStepId: $referenceStepId) {
        id
        name
        status
        createdAt
        owner {
          ...UserFragment
        }
        steps {
          ...StepFragment
        }
      }
    }
  }

  ${stepFragment}
  ${userFragment}
`;

export const attachFileToStepMutation = gql`
  mutation AttachFileToStep($workflowId: ID!, $stepId: ID!, $file: Upload!) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        attachFile(file: $file) {
          ...StepFragment
        }
      }
    }
  }

  ${stepFragment}
`;

export const detachFileFromStepMutation = gql`
  mutation DetachFileFromStep($workflowId: ID!, $stepId: ID!, $fileId: ID!) {
    workflow(id: $workflowId) {
      step(id: $stepId) {
        detachFile(uploadedFileId: $fileId) {
          ...StepFragment
        }
      }
    }
  }

  ${stepFragment}
`;

export const duplicateWorkflowMutation = gql`
  mutation DuplicateWorkflow($id: ID!) {
    workflow(id: $id) {
      duplicate {
        ...WorkflowFragment
      }
    }
  }

  ${workflowFragment}
`;

export const uploadImageMutation = gql`
  mutation UploadImage($image: Upload!, $workspaceId: ID!) {
    uploadImage(image: $image, workspaceId: $workspaceId)
  }
`;
