import {useState, useMemo} from 'react';
import {inviteMutation} from '../../../gqls/workspaceMutations';
import {fetchWorkspaceQuery} from '../../../gqls/workspaceQueries';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {Field, Textarea} from '../../../base/components/Form/Form';
import {DarkButton} from '../../../base/components/Button/Button';
import {useToast} from '../../../base/components/Toast/Toast';
import {Invite, InviteVariables} from '../../../__generated__/Invite';
import {MemberRole} from '../../../__generated__/globalTypes';
import {tw} from 'twind';
import {useParams} from 'react-router-dom';
import {FieldLabel} from './styles';
import * as React from 'react';

type State = {
  text: string;
  errorMessage?: string;
};

export function InviteRoute() {
  const {workspaceId} = useParams<{workspaceId: string}>();
  const toast = useToast();

  const [mutate, {loading}] = useAppMutation<Invite, InviteVariables>(inviteMutation, {
    refetchQueries: [{query: fetchWorkspaceQuery, variables: {id: workspaceId}}],
    awaitRefetchQueries: true,
    onError() {
      toast.error('招待に失敗しました');
    },
    onCompleted() {
      toast.message('招待しました');
    },
  });

  const [state, setState] = useState<State>({
    text: '',
  });

  const emails = useMemo(() => {
    return state.text
      .split(/\r?\n/)
      .map((line) => line.trim())
      .filter((line) => line.length > 0);
  }, [state.text]);

  const invite = async () => {
    await mutate({
      variables: {
        workspaceId,
        entries: emails.map((email) => ({
          email,
          role: MemberRole.member,
        })),
      },
    });

    setState({
      text: '',
    });
  };

  return (
    <div>
      <h1 className={tw`font-semiBold text-xl mb-6`}>招待する</h1>

      <Field>
        <FieldLabel className={tw`mb-1`}>メールアドレス</FieldLabel>
        <Textarea
          style={{width: '100%', height: '120px'}}
          value={state.text}
          disabled={loading}
          onChange={(event) => setState({...state, text: event.currentTarget.value})}
          placeholder={`taro@example.com\nhanako@example.com`}
        />
        <p className={tw`text-darkText text-sm mb-4`}>
          このワークスペースに招待する人のメールアドレスを入力してください。
          <br />
          メールアドレスは改行で複数入力することができます。
        </p>
      </Field>

      <DarkButton onClick={invite} disabled={loading || emails.length === 0}>
        招待する
      </DarkButton>
    </div>
  );
}
