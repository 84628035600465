import {StepPanelLayout} from '../../common/StepPanelLayout';
import {WorkflowIcon} from './WorkflowIcon';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {HorizontalBox, Space} from '../../../base/components/HorizontalBox/HorizontalBox';
import {UserBlock} from '../../common/UserBlock';
import {EyeCatch} from './EyeCatch';

interface Props {
  workflow: WorkflowFragment;
}

export function WorkflowDescriptionPanel(props: Props) {
  const {workflow} = props;

  return (
    <StepPanelLayout style={{width: '540px'}}>
      <EyeCatch workflow={workflow} />

      <HorizontalBox style={{margin: '0 0 0 -48px'}}>
        <WorkflowIcon workflow={workflow} size={36} />
        <Space width="12px" />
        <h1>{workflow.name}</h1>
      </HorizontalBox>

      <div style={{fontSize: '15px', lineHeight: '1.8'}}>
        {workflow.owner && (
          <section style={{margin: '2em 0'}}>
            <h3>オーナー</h3>
            <UserBlock user={workflow.owner} />
          </section>
        )}

        <section style={{margin: '2em 0'}}>
          <h3>このワークフローについて</h3>

          {workflow.description}
        </section>
      </div>
    </StepPanelLayout>
  );
}
