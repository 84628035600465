import {EditorState} from './editorState';
import {EditorPane, FormWrapper} from './styles';
import {HorizontalBox} from '../HorizontalBox/HorizontalBox';
import {Store} from 'pullstate';
import {Inspector} from './Inspector';
import {ObjectNodeEditor} from './ObjectNodeEditor';
import {colors} from 'theme';

interface Props {
  store: Store<EditorState>;
  disabled?: boolean;
}

export function CustomFormEditor(props: Props) {
  const {store} = props;
  const rootId = store.useState((state) => state.rootId);

  return (
    <HorizontalBox alignItems="stretch" style={{border: '1px solid ' + colors.border, height: '500px'}}>
      <EditorPane>
        <FormWrapper>
          <ObjectNodeEditor {...props} id={rootId} />
        </FormWrapper>
      </EditorPane>
      <Inspector store={store} />
    </HorizontalBox>
  );
}
