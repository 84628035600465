import gql from 'graphql-tag';
import {userFragment, workspaceSummaryFragment} from './fragments';

export const findWorkspaceInvitation = gql`
  query FindWorkspaceInvitation($token: String!) {
    workspaceInvitation(token: $token) {
      id
      status
      user {
        ...UserFragment
      }
      workspace {
        ...WorkspaceSummaryFragment
      }
      targetUser {
        ...UserFragment
      }
      role
      isConsumable
      createdAt
    }
  }

  ${userFragment}
  ${workspaceSummaryFragment}
`;

export const acceptWorkspaceInvitation = gql`
  mutation AcceptWorkspaceInvitation($token: String!) {
    acceptWorkspaceInvitation(token: $token)
  }
`;
