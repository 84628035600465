import {addStepForm, addStepInput} from './styles';
import * as React from 'react';
import {Plus} from 'react-feather';
import {SyntheticEvent} from 'react';
import {SmallButton} from '../../../base/components/Button/Button';
import {TextInput} from '../../../base/components/Form/Form';
import {Tooltip} from '../../../base/components/Tooltip/Tooltip';

type AddStepFormProps = {
  loading: boolean;
  submit: (key: string) => Promise<void>;
};

export function AddStepForm(props: AddStepFormProps) {
  const [state, setState] = React.useState({
    showInput: false,
    stepName: '',
  });
  const ref = React.useRef<HTMLInputElement>(null);

  const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setState({...state, stepName: e.currentTarget.value});
  };

  const handleInputBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    setState({...state, showInput: false});
  };

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {stepName} = state;
    const {submit} = props;

    if (stepName.length === 0) {
      return;
    }

    try {
      await submit(stepName);
      setState({...state, stepName: '', showInput: false});
    } catch (error) {}
  };

  const {showInput, stepName} = state;
  const {loading} = props;

  if (showInput) {
    return (
      <form onSubmit={submit} className={addStepForm}>
        <TextInput
          autoFocus
          type="text"
          ref={ref}
          className={addStepInput}
          value={stepName}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          placeholder="ステップ名を入力(Enterで確定)…"
          maxLength={64}
          disabled={loading}
          style={{width: '280px'}}
        />
      </form>
    );
  } else {
    const onClick = async () => {
      setState({...state, showInput: true});
    };

    return <AddStepButton onClick={onClick} disabled={loading} />;
  }
}

const AddStepButton = (props: JSX.IntrinsicElements['button']) => {
  const {className, ...rest} = props;
  return (
    <Tooltip text="ステップを追加" placement="top">
      <SmallButton style={{padding: '0', width: '24px', height: '24px', borderRadius: '100%', flexShrink: 0}} {...rest}>
        <Plus size={16} strokeWidth="1.5px" />
      </SmallButton>
    </Tooltip>
  );
};