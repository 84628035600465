import {Component, Fragment} from 'react';
import {A, ContentHeaderText, ErrorItem} from '../Public';
import {Indicator} from '../../../base/components/Indicator/Indicator';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {Mutation} from '@apollo/client/react/components';
import {ResetForm} from './ResetForm';
import {confirmPasswordResetTokenMutation} from '../../../gqls/passwordResetMutations';

const ConfirmPasswordResetMutation = ({children}: any) => (
  <Mutation mutation={confirmPasswordResetTokenMutation}>{children}</Mutation>
);

export const Confirming = (props: any) => (
  <ConfirmPasswordResetMutation>
    {(mutate: FIXME, result: FIXME) => <InnerConfirming {...props} mutate={mutate} confirmResult={result} />}
  </ConfirmPasswordResetMutation>
);

class InnerConfirming extends Component<any, unknown> {
  state = {
    error: null,
  };

  async componentDidMount() {
    const token = queryString.parse(window.location.search).token;

    if (typeof token !== 'string' || token === '') {
      return this.setState({
        error: '確認メールに記載されている正しいURLにアクセスして下さい。',
      });
    }

    try {
      await this.props.mutate({variables: {token}});

      this.props.setComponent(ResetForm);
    } catch (error) {
      this.setState({error: `${(error as FIXME).graphQLErrors[0].message}`});
    }
  }

  render() {
    const {error} = this.state;

    if (error) {
      return (
        <Fragment>
          <ErrorItem>{error}</ErrorItem>

          <div style={{textAlign: 'center'}}>
            <Link to="/reset_password" component={A}>
              戻る
            </Link>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <ContentHeaderText>確認しています…</ContentHeaderText>

        <div style={{textAlign: 'center'}}>
          <Indicator />
        </div>
      </Fragment>
    );
  }
}
