import {usePopover} from './usePopover';
import {useRef, useState} from 'react';
import * as React from 'react';

interface Params {
  target: React.ReactNode;
  popup: React.ReactNode;
}

export function useHoverPopup(params: Params) {
  const {target, popup} = params;
  const {openPopover, Popover, isOpen, closePopover} = usePopover({closeOnOutsideClick: true});
  const timeoutId = useRef<number | null>(null);
  const [show, setShow] = useState(false);

  return (
    <span
      onMouseEnter={(e: any) => {
        openPopover(e);
        const id = setTimeout(() => {
          setShow(true);
          Object.assign(timeoutId, {current: null});
        }, 500);
        Object.assign(timeoutId, {current: id});
      }}
      onMouseLeave={(e: any) => {
        closePopover(e);
        setShow(false);
        if (timeoutId.current !== null) {
          clearTimeout(timeoutId.current);
          Object.assign(timeoutId, {current: null});
        }
      }}>
      {target}
      {show && isOpen && <Popover>{popup}</Popover>}
    </span>
  );
}
