import {RemoveMember, RemoveMemberVariables} from '../../../__generated__/RemoveMember';
import {changeMemberRoleMutation, removeMemberMutation} from '../../../gqls/workspaceMutations';
import {fetchWorkspaceQuery} from '../../../gqls/workspaceQueries';
import {
  ChangeMemberRoleMutation,
  ChangeMemberRoleMutationVariables,
} from '../../../__generated__/ChangeMemberRoleMutation';
import {FetchWorkspace_workspace} from '../../../__generated__/FetchWorkspace';
import {CustomSelectBox} from '../../../base/components/SelectBox/SelectBox';
import {HorizontalBox, SpringSpace, Space} from '../../../base/components/HorizontalBox/HorizontalBox';
import {MenuItem} from '../../../base/components/OptionsMenu/OptionsMenu';
import {UserBlock} from '../../common/UserBlock';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useToast} from '../../../base/components/Toast/Toast';
import {MemberRole} from '../../../__generated__/globalTypes';
import {useOptionsMenu} from '../../../base/components/OptionsMenu/hooks';
import {CtaButton, QueitButton} from '../../../base/components/Button/Button';
import {MoreHorizontal, UserPlus, Check} from 'react-feather';
import {tw} from 'twind';
import {TabBar, Tab} from '../../../base/components/TabBar';
import {useHistory} from 'react-router-dom';
import {HelpMark} from '../../../base/components/HelpMark/HelpMark';

interface Props {
  workspace: FetchWorkspace_workspace;
}

export function MembersRoute(props: Props) {
  const {workspace} = props;
  const history = useHistory();

  return (
    <>
      <div style={{float: 'right'}}>
        <CtaButton variant="green" onClick={() => history.push(`/workspaces/${workspace.id}/settings/invite`)}>
          <UserPlus size={16} className={tw`mr-1`} strokeWidth={1.5} />
          招待する
        </CtaButton>
      </div>

      <h1 className={tw`font-semiBold text-xl mb-6`}>メンバー</h1>

      <TabBar>
        <Tab>全て</Tab>
      </TabBar>
      {workspace.members.items.flatMap((member, i) => {
        if (!member.user) {
          return [];
        }

        return [<MemberBlock workspace={workspace} index={i} key={member.user.id} />];
      })}
    </>
  );
}

interface MemberBlockProps {
  workspace: FetchWorkspace_workspace;
  index: number;
}

export function MemberBlock(props: MemberBlockProps) {
  const {workspace, index} = props;
  const {id: workspaceId} = workspace;
  const member = workspace.members.items[index];
  const {user, role} = member;
  const toast = useToast();
  const isAdmin = workspace.role === MemberRole.admin;

  const detailsMenu = useOptionsMenu({
    button: (
      <QueitButton>
        <MoreHorizontal size={16} strokeWidth={"1.5px"} />
      </QueitButton>
    ),
    menu: (close) => {
      const handleClick = () => {
        close();
        removeMember({
          variables: {workspaceId, userId: user!.id},
        });
      };

      return (
        <>
          <MenuItem onClick={handleClick} disabled={!isAdmin}>
            ワークスペースから外す
          </MenuItem>
        </>
      );
    },
  });

  const [removeMember] = useAppMutation<RemoveMember, RemoveMemberVariables>(removeMemberMutation, {
    refetchQueries: [{query: fetchWorkspaceQuery, variables: {id: workspace!.id}}],
    awaitRefetchQueries: true,
    onError() {
      toast.error('更新に失敗しました');
    },
  });

  const [changeRole, changeRoleResult] = useAppMutation<ChangeMemberRoleMutation, ChangeMemberRoleMutationVariables>(
    changeMemberRoleMutation,
    {
      refetchQueries: [{query: fetchWorkspaceQuery, variables: {id: workspace!.id}}],
      awaitRefetchQueries: true,
      onError() {
        toast.error('更新に失敗しました');
      },
    }
  );

  if (!user || !workspace) {
    return null;
  }

  return (
    <HorizontalBox style={{margin: '20px 0 20px 0'}}>
      <UserBlock user={user} iconSize={36} />
      <SpringSpace />

      {isAdmin && <>
        <CustomSelectBox
          value={role}
          options={options}
          onChange={async (value) => {
            await changeRole({
              variables: {
                workspaceId: workspace.id,
                userId: user.id,
                role: value as any,
              },
            });
            toast.message('メンバーの権限を変更しました');
          }}
          disabled={changeRoleResult.loading}
        />
        &nbsp;
        {detailsMenu.node}
      </>}

      {!isAdmin && <>
        <div className={tw`font-normal text-lightText`} style={{width: '70px'}}>{roleMap[role].label}</div>
        <Space width={"4px"} />
        <HelpMark placement={"left"}>{roleMap[role].description}</HelpMark>
      </>}
    </HorizontalBox>
  );
}

const roleMap: Record<string, {label: string; description: string;}> = {
  admin: {
    label: '管理者',
    description: 'ワークスペースの管理者です。全ての設定を変更できます。',
  },
  member: {
    label: 'メンバー',
    description: 'ワークスペースに属する一般的なユーザーです。',
  },
};

const options = [
  {
    value: 'admin',
    label: <>{roleMap['admin'].label}</>,
    menu: (selected: boolean) => (
      <MenuItem
        selected={selected}
        icon={selected ? <Check size={16} /> : null}
        description={roleMap['admin'].description}>
        管理者
      </MenuItem>
    ),
  },
  {
    value: 'member',
    label: <>{roleMap['member'].label}</>,
    menu: (selected: boolean) => (
      <MenuItem
        selected={selected}
        icon={selected ? <Check size={16} /> : null}
        description={roleMap['member'].description}>
        {roleMap['member'].label}
      </MenuItem>
    ),
  },
];
