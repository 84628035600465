import gql from 'graphql-tag';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    name
    iconUrl
    email
  }
`;

export const workspaceSummaryFragment = gql`
  fragment WorkspaceSummaryFragment on Workspace {
    id
    name
    members {
      totalCount
    }
  }
`;

export const commentFragment = gql`
  fragment CommentFragment on Comment {
    id
    createdAt
    updatedAt
    user {
      ...UserFragment
    }
    text
    status
    attachedFiles {
      id
      createdAt
      fileName
      mimeType
      fileSize
      url
    }
  }
  ${userFragment}
`;

export const stepFragment = gql`
  fragment StepFragment on Step {
    id
    workflowId
    name
    workspaceId
    description
    status
    form
    createdAt
    updatedAt
    files {
      id
      createdAt
      fileName
      url
      fileSize
      mimeType
      user {
        ...UserFragment
      }
    }
    assignees {
      ...UserFragment
    }
    comments {
      totalCount
      hasNext
      items {
        ...CommentFragment
      }
      endCursor
    }
  }
  ${commentFragment}
`;

export const sourceWorkflowFragment = gql`
  fragment SourceWorkflowFragment on Workflow {
    sourceWorkflow {
      id
      name
      description
    }
  }
`;

export const workflowSummaryFragment = gql`
  fragment WorkflowSummaryFragment on Workflow {
    id
    name
    isFavorite
    color
    icon
    status
    coverImageUrl
    description
    count
    canEdit
    canEditACL
    owner {
      ...UserFragment
    }
    createdAt
    updatedAt
    steps {
      id
      name
      status
    }
    progress {
      numerator
      denominator
    }
    workspace {
      id
      name
    }
    ...SourceWorkflowFragment
  }

  ${userFragment}
  ${sourceWorkflowFragment}
`;

export const workflowFragment = gql`
  fragment WorkflowFragment on Workflow {
    id
    name
    status
    updatedAt
    createdAt
    description
    collapse @client
    count
    startCount
    color
    coverImageUrl
    icon
    isFavorite
    workspace {
      id
      name
    }
    owner {
      ...UserFragment
    }
    steps {
      ...StepFragment
    }
    progress {
      numerator
      denominator
    }
    sourceWorkflow {
      ...WorkflowSummaryFragment
    }
    acl {
      user {
        ...UserFragment
      }
      workspace {
        ...WorkspaceSummaryFragment
      }
      role
    }
    canEdit
    canEditACL
  }

  ${stepFragment}
  ${workflowSummaryFragment}
  ${userFragment}
  ${workspaceSummaryFragment}
`;
