import * as React from 'react';
import {CSSProperties} from 'react';
import styled from '@emotion/styled';
import {WorkflowSummaryFragment} from '../../../__generated__/WorkflowSummaryFragment';
import {cheapHash} from '../../utils/utils';
import {Icon as IconComponent} from 'react-feather';
import * as icons from 'react-feather';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {colors, fontWeights} from 'theme';

export const iconTable: Record<string, IconComponent | null> = {
  'fe:database': icons.Database,
  'fe:box': icons.Box,
  'fe:dollarSign': icons.DollarSign,
  'fe:download': icons.Download,
  'fe:clock': icons.Clock,
  'fe:activity': icons.Activity,
  'fe:alert': icons.AlertCircle,
  'fe:award': icons.Award,
  'fe:book': icons.BookOpen,
  'fe:calendar': icons.Calendar,
  'fe:briefcase': icons.Briefcase,
  'fe:clipboard': icons.Clipboard,
  'fe:code': icons.Code,
  'fe:cloud': icons.Cloud,
  'fe:coffee': icons.Coffee,
  'fe:compass': icons.Compass,
  'fe:eye': icons.Eye,
  'fe:fileText': icons.FileText,
  'fe:film': icons.Film,
  'fe:folder': icons.Folder,
  'fe:folderMinus': icons.FolderMinus,
  'fe:folderPlus': icons.FolderPlus,
  'fe:gift': icons.Gift,
  'fe:heart': icons.Heart,
  'fe:helpCircle': icons.HelpCircle,
  'fe:image': icons.Image,
  'fe:lock': icons.Lock,
  'fe:mail': icons.Mail,
  'fe:mapPin': icons.MapPin,
  'fe:map': icons.Map,
  'fe:message': icons.MessageSquare,
  'fe:mic': icons.Mic,
  'fe:moon': icons.Moon,
  'fe:package': icons.Package,
  'fe:phone': icons.Phone,
  'fe:phoneOff': icons.PhoneOff,
  'fe:pieChart': icons.PieChart,
  'fe:printer': icons.Printer,
  'fe:send': icons.Send,
  'fe:search': icons.Search,
  'fe:server': icons.Server,
  'fe:cart': icons.ShoppingCart,
  'fe:smile': icons.Smile,
  'fe:sun': icons.Sun,
  'fe:target': icons.Target,
  'fe:tag': icons.Tag,
  'fe:tool': icons.Tool,
  'fe:triangle': icons.Triangle,
  'fe:user': icons.User,
  'fe:userPlus': icons.UserPlus,
  'fe:userMinus': icons.UserMinus,
  'fe:users': icons.Users,
  'fe:userCheck': icons.UserCheck,
  'fe:zap': icons.Zap,
  'fe:watch': icons.Watch,
  'fe:default': null,
};

export const DefaultIcon = icons.Box;

interface Props {
  className?: string;
  workflow: WorkflowSummaryFragment | WorkflowFragment;
  size?: 20 | 24 | 32 | 36;
  style?: CSSProperties;
  ref?: React.Ref<HTMLDivElement>;
  onClick?: React.HTMLAttributes<HTMLDivElement>['onClick'];
}

export function generateWorkflowColorFromID(id: string): string {
  const hash = cheapHash(id);
  const base = ['#f9f9f9', 'white'];
  const index = Math.abs(hash) % base.length;

  return base[index];
}

interface NakedWorkflowIconProps {
  workflow: {
    icon: string | null;
  };
  size?: number;
  color?: string;
  style?: JSX.IntrinsicElements['svg']['style'];
  strokeWidth?: number;
}

export const NakedWorkflowIcon = (props: NakedWorkflowIconProps, ref: React.Ref<HTMLDivElement>) => {
  const {workflow, size = 16, color = undefined, style, strokeWidth = 1.5} = props;
  const Icon = typeof workflow.icon === 'string' ? iconTable[workflow.icon] : DefaultIcon;

  if (!Icon) {
    return null;
  }

  return <Icon size={size} strokeWidth={strokeWidth} color={color ?? colors.darkText} style={style} />;
};

export const WorkflowIcon = styled(
  React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
    const {workflow, className, style, size = 20, onClick} = props;
    const id = workflow.sourceWorkflow ? workflow.sourceWorkflow.id : workflow.id;
    const color = typeof workflow.color === 'string' ? 'white' : colors.darkText;
    const backgroundColor = typeof workflow.color === 'string' ? workflow.color : generateWorkflowColorFromID(id);
    const boxShadow = typeof workflow.color === 'string' ? '' : `0 0 0 1px ${colors.lightBorder} inset`;

    const applyStyle: CSSProperties = {
      backgroundColor,
      width: size + 'px',
      height: size + 'px',
      borderRadius: Math.round(size * 0.15),
      fontSize: Math.round(size * 0.5),
      color,
      boxShadow,
    };

    const Icon = typeof workflow.icon === 'string' ? iconTable[workflow.icon] : DefaultIcon;
    const iconSize = size === 36 ? 20 : size === 32 ? 18 : size === 24 ? 16 : 14;

    return (
      <div className={className} style={{...applyStyle, ...style}} ref={ref} onClick={onClick}>
        {Icon && <Icon size={iconSize} strokeWidth={1.5} />}
      </div>
    );
  })
)`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: lightgray;
  display: inline-flex;
  text-align: center;
  line-height: 1;
  font-size: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: ${fontWeights.semiBold};
`;
