import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {
  addUserRoleMutation,
  addWorkspaceRoleMutation,
  removeUserRoleMutation,
  removeWorkspaceRoleMutation,
  runWorkflowMutation,
  updateUserRoleMutation,
  updateWorkflowMutation,
  updateWorkspaceRoleMutation,
} from '../../../gqls/workflowMutations';
import {AddUserRoleMutation, AddUserRoleMutationVariables} from '../../../__generated__/AddUserRoleMutation';
import {UpdateUserRoleMutation, UpdateUserRoleMutationVariables} from '../../../__generated__/UpdateUserRoleMutation';
import {RemoveUserRoleMutation, RemoveUserRoleMutationVariables} from '../../../__generated__/RemoveUserRoleMutation';
import {
  AddWorkspaceRoleMutation,
  AddWorkspaceRoleMutationVariables,
} from '../../../__generated__/AddWorkspaceRoleMutation';
import {
  UpdateWorkspaceRoleMutation,
  UpdateWorkspaceRoleMutationVariables,
} from '../../../__generated__/UpdateWorkspaceRoleMutation';
import {
  RemoveWorkspaceRoleMutation,
  RemoveWorkspaceRoleMutationVariables,
} from '../../../__generated__/RemoveWorkspaceRoleMutation';
import {findWorkflowQuery} from '../../../gqls/workflowQueries';
import {UpdateWorkflow, UpdateWorkflowVariables} from '../../../__generated__/UpdateWorkflow';
import {useHistory} from 'react-router-dom';
import {ToastContext, useToast} from '../../../base/components/Toast/Toast';
import {RunWorkflow, RunWorkflowVariables} from '../../../__generated__/RunWorkflow';
import {useContext} from 'react';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {WorkflowStatus} from '../../../__generated__/globalTypes';
import {useModalDialog} from '../../../base/components/ModalDialog/ModalDialog';
import {ArrowRightCircle} from 'react-feather';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';

export function useAddUserRole({workflow}: {workflow: WorkflowFragment}) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<AddUserRoleMutation, AddUserRoleMutationVariables>(addUserRoleMutation, {
    refetchQueries: [{query: findWorkflowQuery, variables: {id: workflow.id}}],
    awaitRefetchQueries: true,
    onError(error) {
      toast.error('失敗しました');
    },
    onCompleted() {
      toast.message('共有しました');
    },
  });

  return {
    ...result,
    mutate,
  };
}

export function useChangeWorkflowStatus(status: WorkflowStatus, workflow: WorkflowFragment) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<UpdateWorkflow, UpdateWorkflowVariables>(updateWorkflowMutation, {
    onError() {
      toast.error('処理に失敗しました');
    },
    onCompleted() {
      if (status === WorkflowStatus.published) {
        toast.message('公開しました');
      }

      if (status === WorkflowStatus.draft) {
        toast.message('下書きにしました');
      }

      if (status === WorkflowStatus.archived) {
        toast.message('アーカイブしました');
      }

      if (status === WorkflowStatus.canceled) {
        toast.message('キャンセル状態にしました');
      }

      if (status === WorkflowStatus.running) {
        toast.message('処理中の状態にしました');
      }

      if (status === WorkflowStatus.finished) {
        toast.message('完了にしました');
      }
    },
  });

  const run = () => {
    mutate({
      variables: {
        id: workflow.id,
        status,
      },
    });
  };

  return {
    ...result,
    run,
  };
}

export function usePublishWorkflow(workflow: WorkflowFragment) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<UpdateWorkflow, UpdateWorkflowVariables>(updateWorkflowMutation, {
    onError() {
      toast.error('公開に失敗しました');
    },
    onCompleted() {
      toast.message('公開しました');
    },
  });

  const publishWorkflow = () => {
    mutate({
      variables: {
        id: workflow.id,
        status: WorkflowStatus.published,
      },
    });
  };

  return {
    ...result,
    publishWorkflow,
  };
}

export function useUpdateWorkflow({workflow}: {workflow: WorkflowFragment}) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<UpdateWorkflow, UpdateWorkflowVariables>(updateWorkflowMutation, {
    onError() {
      toast.error('変更に失敗しました');
    },
    onCompleted() {
      toast.message('更新しました');
    },
  });

  return {
    ...result,
    mutate,
  };
}

export function useUpdateUserRole({workflowId}: {workflowId: string}) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(
    updateUserRoleMutation,
    {
      refetchQueries: [{query: findWorkflowQuery, variables: {id: workflowId}}],
      awaitRefetchQueries: true,
      onError(error) {
        toast.error('変更に失敗しました');
      },
      onCompleted() {
        toast.message('更新しました');
      },
    }
  );

  return {
    mutate,
    ...result,
  };
}

export function useRemoveUserRole({workflowId}: {workflowId: string}) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>(
    removeUserRoleMutation,
    {
      refetchQueries: [{query: findWorkflowQuery, variables: {id: workflowId}}],
      awaitRefetchQueries: true,
      onError(error) {
        toast.error('失敗しました');
      },
      onCompleted() {
        toast.message('削除しました');
      },
    }
  );

  return {
    mutate,
    ...result,
  };
}

export function useRemoveWorkspaceRole({workflow}: {workflow: WorkflowFragment}) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<RemoveWorkspaceRoleMutation, RemoveWorkspaceRoleMutationVariables>(
    removeWorkspaceRoleMutation,
    {
      refetchQueries: [{query: findWorkflowQuery, variables: {id: workflow.id}}],
      awaitRefetchQueries: true,
      onError(error) {
        toast.error('失敗しました');
      },
      onCompleted() {
        toast.message('更新しました');
      },
    }
  );

  return {
    mutate,
    ...result,
  };
}

export function useUpdateWorkspaceRole({workflow}: {workflow: WorkflowFragment}) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<UpdateWorkspaceRoleMutation, UpdateWorkspaceRoleMutationVariables>(
    updateWorkspaceRoleMutation,
    {
      refetchQueries: [{query: findWorkflowQuery, variables: {id: workflow.id}}],
      awaitRefetchQueries: true,
      onError(error) {
        toast.error('失敗しました');
      },
      onCompleted() {
        toast.message('更新しました');
      },
    }
  );

  return {
    mutate,
    ...result,
  };
}

export function useAddWorkspaceRole({workflow}: {workflow: WorkflowFragment}) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<AddWorkspaceRoleMutation, AddWorkspaceRoleMutationVariables>(
    addWorkspaceRoleMutation,
    {
      refetchQueries: [{query: findWorkflowQuery, variables: {id: workflow.id}}],
      awaitRefetchQueries: true,
      onError(error) {
        toast.error('失敗しました');
      },
      onCompleted() {
        toast.message('更新しました');
      },
    }
  );

  return {
    mutate,
    ...result,
  };
}

export function useRunWorkflow(workflow: Pick<WorkflowFragment, 'id' | 'name' | 'workspace'>) {
  const history = useHistory();
  const toast = useContext(ToastContext);
  const workspaceId = useWorkspaceId();
  const [runWorkflow, {loading}] = useAppMutation<RunWorkflow, RunWorkflowVariables>(runWorkflowMutation, {
    onCompleted(data) {
      const {id} = data.runWorkflow;
      toast.message('ワークフローを作成しました');
      history.push(`/workspaces/${workspaceId}/workflows/${id}`);
    },
    onError(error) {
      toast.error('ワークフローの作成に失敗しました');
    },
  });

  const modal = useModalDialog();

  return {
    loading,
    runWorkflow: async () => {
      const result = await modal.prompt({
        message: `${workflow.name}からワークフローを開始します。`,
        title: 'ワークフローを開始',
        icon: <ArrowRightCircle />,
        defaultValue: workflow.name,
        placeholder: '',
        buttonTexts: {
          submit: '開始',
        },
      });

      if (typeof result === 'string') {
        await runWorkflow({
          variables: {
            workspaceId: workflow.workspace!.id,
            sourceWorkflowId: workflow.id,
            name: result,
          },
        });
      }
    },
  };
}

export function useUpdateWorkflowName(workflow: WorkflowFragment) {
  const toast = useToast();

  const [mutate, result] = useAppMutation<UpdateWorkflow, UpdateWorkflowVariables>(updateWorkflowMutation, {
    onError() {
      toast.error('名前の変更に失敗しました');
    },
    optimisticResponse(variables) {
      return {
        workflow: {
          __typename: 'WorkflowOps',
          update: {
            ...workflow,
            id: variables.id,
            name: variables.name ?? workflow.name,
            status: workflow.status,
          },
        },
      };
    },
  });

  return {
    ...result,
    mutate,
  };
}
