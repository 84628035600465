import {useAppQuery} from '../../base/hooks/useAppQuery';
import {findWorkflowsQuery} from '../../gqls/workflowQueries';
import {WorkflowStatus} from '../../__generated__/globalTypes';
import {WorkflowSummaryFragment} from '../../__generated__/WorkflowSummaryFragment';
import styled from '@emotion/styled';
import {TimeAgo} from '../../base/components/TimeAgo';
import {Link, useHistory} from 'react-router-dom';
import {CtaButton} from '../../base/components/Button/Button';
import {PlusCircle, Star, CheckCircle, Plus} from 'react-feather';
import {NakedWorkflowIcon} from '../../base/components/WorkflowIcon/WorkflowIcon';
import {FlexItem, HorizontalBox, Space} from '../../base/components/HorizontalBox/HorizontalBox';
import {useAppMutation} from '../../base/hooks/useAppMutation';
import {createWorkflowMutation} from '../../gqls/workflowMutations';
import {useToast} from '../../base/components/Toast/Toast';
import {CreateWorkflow, CreateWorkflowVariables} from '../../__generated__/CreateWorkflow';
import {SubtleStatusLabel} from '../../base/components/StatusSelectBox/StatusLabel';
import {WorkflowCoverImageMenu} from './WorkflowCoverImageMenu';
import {colors, fontSizes, fontWeights} from 'theme';
import {useWorkspaceId} from '../../base/hooks/useWorkspaceId';
import {useModalDialog} from '../../base/components/ModalDialog/ModalDialog';
import {FindWorkflowsVariables, FindWorkflows} from '../../__generated__/FindWorkflows';
import {tw} from 'twind';
import * as React from 'react';
import {css} from '@emotion/css';
import {ReactNode} from 'react';

interface Props {
  status: WorkflowStatus;
  sort: FindWorkflowsVariables['sort'];
  renderHeader?: (totalCount: number) => ReactNode;
  renderWorkflow?: (workflow: WorkflowSummaryFragment) => ReactNode;
  renderLoading?: () => ReactNode;
}

export function WorkflowGalleryContainer(props: Props) {
  const workspaceId = useWorkspaceId();
  const {error, loading, data} = useAppQuery<FindWorkflows, FindWorkflowsVariables>(findWorkflowsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      workspaceId,
      statuses: [props.status],
      sort: props.sort,
    },
    onError() {
      // Do nothing
    },
  });

  const {renderLoading = () => null} = props;

  if (loading && !data) {
    return <>{renderLoading()}</>;
  }

  if (error) {
    return null;
  }

  if (data) {
    const count = data.workflows.totalCount;
    const workflows = data.workflows.items;

    return (
      <>
        {props.renderHeader?.(count)}
        <WorkflowGallery
          totalCount={count}
          workflows={workflows}
          shouldShowNewWorkflowButton={props.status === WorkflowStatus.published}
          renderWorkflow={props.renderWorkflow}
        />
      </>
    );
  }

  return null;
}

function useCreateWorkflow(): [() => void, {loading: boolean}] {
  const workspaceId = useWorkspaceId();
  const toast = useToast();
  const history = useHistory();
  const modal = useModalDialog();
  const [mutate, result] = useAppMutation<CreateWorkflow, CreateWorkflowVariables>(createWorkflowMutation, {
    onError() {
      toast.error('ワークフローの作成に失敗しました');
    },
    onCompleted(data) {
      history.push(`/workspaces/${workspaceId}/workflows/${data.createWorkflow.id}`);
    },
  });

  const tryToCreate = async () => {
    const result = await modal.prompt({
      message: `作成するワークフローの名前を入力して下さい。`,
      title: 'ワークフローを作成',
      defaultValue: '無名',
      placeholder: '',
      buttonTexts: {
        submit: '作成',
      },
    });

    if (typeof result === 'string') {
      mutate({
        variables: {
          name: result,
          workspaceId,
        },
      });
    }
  };

  return [tryToCreate, result];
}

export function NewWorkflowButton() {
  const [onClick, result] = useCreateWorkflow();

  return (
    <CtaButton variant="green" onClick={() => onClick()} disabled={result.loading}>
      <PlusCircle size={16} className={tw`flex-shrink-0 mr-1.5`} strokeWidth={"1.5px"} />
      <span className={tw`whitespace-nowrap`}>ワークフローを作成</span>
    </CtaButton>
  );
}

interface GalleryProps {
  workflows: WorkflowSummaryFragment[];
  totalCount: number;
  renderWorkflow?: (workflow: WorkflowSummaryFragment) => React.ReactNode;
  shouldShowNewWorkflowButton?: boolean;
}

function WorkflowGallery(props: GalleryProps) {
  const {
    workflows,
    shouldShowNewWorkflowButton = false,
    renderWorkflow = (workflow) => <WorkflowBox key={workflow.id} workflow={workflow} />,
  } = props;

  return (
    <div className={tw`my-4`}>
      {workflows.length > 0 && (
        <>
          <WorkflowGrid>
            {workflows.map((workflow) => renderWorkflow(workflow))}
            {shouldShowNewWorkflowButton && <NewWorkflowBox />}
          </WorkflowGrid>
        </>
      )}
    </div>
  );
}

interface WorkflowBoxProps {
  workflow: WorkflowSummaryFragment;
  preview?: boolean; // プレビュー時に使用
  className?: string;
}

export const NewWorkflowBox = () => {
  const [onClick] = useCreateWorkflow();

  return (
    <div className={css`
      &:hover .highlight-on-hover {
        color: ${colors.darkAccent};
      }
    `}>
      <button className={tw`w-full flex items-stretch justify-stretch rounded-lg hover:(bg-background)` + ' ' + css`
        &:before {
          content: '';
          display: block;
          padding-top: 56%;
        }
      `} onClick={onClick}>
        <div className={tw`border-dashed border-2 rounded-lg  w-full items-center justify-center flex`}>
          <Plus size={36} className={tw`text-superLightText`} strokeWidth="1.5px" />
        </div>
      </button>
      <HorizontalBox style={{margin: '12px 0 6px 0'}} alignItems="center">
        <button onClick={onClick} className={tw`font-bold text-blackText text-base leading-none` + ' highlight-on-hover'}>新しいワークフローを作成</button>
      </HorizontalBox>
    </div>
  );
};

export const WorkflowBox = styled((props: WorkflowBoxProps) => {
  const {workflow, className, preview = false} = props;

  const to = `/workspaces/${workflow.workspace!.id}/workflows/${workflow.id}${preview ? '/preview' : ''}`;

  return (
    <div className={className}>
      <EyeCatch
        to={to}
        coverImage={typeof workflow.coverImageUrl === 'string' ? `url(${workflow.coverImageUrl})` : undefined}
      >
        {!preview && <WorkflowCoverImageMenu workflow={workflow} />}
        {typeof workflow.coverImageUrl !== 'string' && (
          <NakedWorkflowIcon strokeWidth={1} workflow={workflow} size={36} color={colors.lightText} />
        )}
      </EyeCatch>

      <HorizontalBox style={{margin: '12px 0 6px 0'}} alignItems="center">
        <FlexItem flex="1 0" className={tw`flex items-center`}>
          <WorkflowName className={"highlight-on-hover"} to={to}>{workflow.name}</WorkflowName>
          <Space width="4px" />
          <Favorite workflow={workflow} />
        </FlexItem>
        {workflow.status === WorkflowStatus.draft && (
          <>
            &nbsp;
            <FlexItem>
              <SubtleStatusLabel status={workflow.status} />
            </FlexItem>
          </>
        )}
      </HorizontalBox>

      <div className={tw`flex items-center text-superLightText text-sm font-bold`}>
        <CheckCircle size={14} />
        <Space width={"4px"} />
        {workflow.steps.length}ステップ
        <Space width={"6px"} />
        <TimeAgo>{workflow.updatedAt}</TimeAgo>に更新
      </div>
    </div>
  );
})`
  display: block;
  width: 100%;
  overflow: hidden;
  background-color: white;

  :hover .show-on-hover {
    visibility: visible;
  }
  
  :hover .highlight-on-hover {
    color: ${colors.darkAccent};
  }
`;

const EyeCatch = styled(({className, to, children}: {className?: string; to: string; children: ReactNode}) => {
  return <Link to={to} className={className} children={children} />;
})<{coverImage?: string}>`
  position: relative;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-color: ${colors.lightBackground};
  background-image: ${props => typeof props.coverImage === 'string' ? props.coverImage : 'none'};
  background-clip: border-box;
  ${props => typeof props.coverImage === 'string'
    ? `box-shadow: 0 0 0 1px rgba(0, 0, 0, .06) inset;`
    : `box-shadow: 0 0 0 1px ${colors.border} inset;`
  }

  &:before {
    content: '';
    display: block;
    padding-top: 56%; // アスペクト比固定
  }

  &:hover {
    background-color: ${colors.background};
    box-shadow: 0 0 0 2px ${colors.darkAccent} inset;
  }
`;

const WorkflowName = styled(Link)`
  line-height: 1;

  &:link,
  &:visited {
    color: ${colors.blackText};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.md};
  }

  &:hover {
    color: ${colors.darkAccent};
  }
`;

const WorkflowGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 32px;
`;

function Favorite({workflow}: {workflow: WorkflowSummaryFragment}) {
  return (
    <>
      {workflow.isFavorite && (
        <Star
          size={14}
          color={colors.shortcut}
          fill={colors.shortcut}
          strokeWidth="2px"
          className={tw`flex-shrink-0`}
        />
      )}
    </>
  );
}
