import * as React from 'react';
import {Component, Fragment, ReactNode} from 'react';
import {PromptDialog} from './PromptDialog';
import {ConfirmDialog} from './ConfirmDialog';
import {AlertDialog} from './AlertDialog';

export const ModalDialogContext = React.createContext<ModalDialogContextValue>({
  alert: async () => {},
  confirm: async () => false,
  prompt: async () => {},
});

export function useModalDialog() {
  return React.useContext(ModalDialogContext);
}

interface BaseParams {
  message: ReactNode;
  title?: string;
  icon?: ReactNode;
}

interface PromptParams extends BaseParams {
  placeholder?: string;
  defaultValue?: string;
  buttonTexts?: {
    submit?: string;
    cancel?: string;
  };
}

export interface ModalDialogContextValue {
  alert(params: BaseParams): Promise<void>;
  confirm(params: BaseParams): Promise<boolean>;
  prompt(params: PromptParams): Promise<any>;
}

interface State {
  type: 'alert' | 'confirm' | 'prompt';
  params: BaseParams | PromptParams;
  onClose: (value: any) => void;
  show: boolean;
}

interface Props {
  children: React.ReactNode;
}

export class ModalDialogProvider extends Component<Props, State> {
  state: State = {
    type: 'alert' as const, // alertかconfirmかpromptのどれか
    show: false,
    params: {
      message: '',
      title: '',
      placeholder: '',
      buttonTexts: {
        submit: '送信する',
        cancel: 'キャンセル',
      },
    },
    onClose: (value: any) => {}, // ダイアログを閉じる時に呼び出す関数
  };

  contextValue: ModalDialogContextValue = {
    alert: (params) => {
      return new Promise((resolve) => {
        this.setState({
          type: 'alert',
          onClose: resolve,
          show: true,
          params,
        });
      });
    },

    confirm: (params) => {
      return new Promise((resolve) => {
        this.setState({
          type: 'confirm',
          onClose: (ok) => resolve(ok),
          show: true,
          params,
        });
      });
    },

    prompt: (params) => {
      return new Promise((resolve) => {
        this.setState({
          type: 'prompt',
          show: true,
          onClose: (value) => resolve(value),
          params,
        });
      });
    },
  };

  content = (
    <ModalDialogContext.Provider value={this.contextValue}>{this.props.children}</ModalDialogContext.Provider>
  );

  close: any = (value: any) => {
    const {onClose} = this.state;
    this.setState({show: false, onClose: (value: any) => {}});
    onClose(value);
  };

  render() {
    const {show, type, params} = this.state;

    return (
      <Fragment>
        {show && type === 'alert' && <AlertDialog {...params} close={this.close} />}

        {show && type === 'confirm' && <ConfirmDialog {...params} close={this.close} />}

        {show && type === 'prompt' && <PromptDialog {...(params as PromptParams)} close={this.close} />}

        {this.content}
      </Fragment>
    );
  }
}
