import * as React from 'react';
import {NodeEditorProps} from './types';

export function useSelect(props: NodeEditorProps) {
  const {id, store} = props;
  return React.useCallback(
    (event: React.MouseEvent) => {
      store.update((state) => {
        state.selectedId = id;
      });
      event.stopPropagation();
    },
    [id, store]
  );
}
