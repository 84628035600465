import {useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {ArrowRightCircle, Loader, Star, Eye} from 'react-feather';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {useChangeWorkflowStatus, useRunWorkflow, useUpdateWorkflow, useUpdateWorkflowName} from './workflowHooks';
import {StatusSelectBox} from '../../../base/components/StatusSelectBox/StatusSelectBox';
import {WorkflowStatus} from '../../../__generated__/globalTypes';
import {BackButton, WorkflowHeaderBlock} from './styles';
import {Loading} from '../../common/Loading';
import {HorizontalBox, Space, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {isPrototype} from './StepPanel';
import {TimeAgo} from '../../../base/components/TimeAgo';
import {ACLButton} from '../WorkflowSettingsDialog/ACLButton';
import {
  CtaButton,
  QueitButton,
  SubtleQuietButton,
} from '../../../base/components/Button/Button';
import {WorkflowMenu} from './WorkflowMenu';
import {UserIconMenu} from '../../common/UserIconMenu';
import {WorkflowIcon} from './WorkflowIcon';
import {Tooltip} from '../../../base/components/Tooltip/Tooltip';
import {colors, fontSizes, fontWeights} from 'theme';
import {useHistory} from 'react-router';
import {tw} from 'twind';
import {useFavoriteToggle} from './useFavoriteToggle';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';

// TODO リファクタリング
function NameInput({workflow}: {workflow: WorkflowFragment}) {
  const placeholderText = 'ワークフロー名を入力...';

  const [text, setText] = useState(workflow.name);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.dataset.value = text.length === 0 ? placeholderText : text;
    }
  }, [text]);

  useEffect(() => {
    setText(workflow.name);
    if (ref.current) {
      ref.current.dataset.value = workflow.name.length === 0 ? placeholderText : workflow.name;
    }
  }, [workflow.name]);

  const updateWorkflowName = useUpdateWorkflowName(workflow);

  const tryToSave = () => {
    if (workflow.name !== text) {
      updateWorkflowName.mutate({
        variables: {
          id: workflow.id,
          name: text,
        },
      });
    }
  };

  return (
    <WorkflowNameInputWrapper ref={ref}>
      <WorkflowNameInput
        readOnly={!workflow.canEdit}
        type="text"
        name="workflow-name-input"
        value={text}
        placeholder={placeholderText}
        onInput={(event) => {
          if (ref.current) {
            const {value} = event.currentTarget;
            ref.current.dataset.value = value.length === 0 ? placeholderText : value;
          }
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.currentTarget.blur();
          }
        }}
        onBlur={tryToSave}
        onChange={(event) => setText(event.currentTarget.value)}
        size={1}
      />
    </WorkflowNameInputWrapper>
  );
}

const WorkflowNameInputWrapper = styled.span`
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: ${fontSizes.md};
  font-weight: ${fontWeights.semiBold};
  line-height: 1;

  &::after {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    padding: 0;
    margin: 0;
    line-height: 1;
    resize: none;
    background: none;
    appearance: none;
    box-sizing: border-box;
    letter-spacing: 0;
    content: attr(data-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
    border: 1px solid transparent;
    height: 1px;
  }
`;
const WorkflowNameInput = styled.input`
  color: ${colors.blackText};
  letter-spacing: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
  width: auto;
  min-width: 1em;
  grid-area: 1 / 2;
  font: inherit;
  font-size: ${fontSizes.md};
  font-weight: ${fontWeights.semiBold};
  padding: 0;
  margin: 0;
  resize: none;
  background: none;
  appearance: none;
  border-radius: 2px;
  line-height: 1;

  &::placeholder {
    color: ${colors.placeholderText};
  }

  &:hover,
  &:focus {
    border: 1px solid ${colors.inputBorder};
  }

  &:hover {
    background-color: ${colors.background};
  }

  &:focus {
    border: 1px solid ${colors.accent};
    box-shadow: 0 0 0 2px ${colors.focusShadow};
    background-color: white;
  }

  &:read-only {
    border: 1px solid transparent;
    background-color: white;
    box-shadow: none;
  }
`;

function FavoriteButtonContainer({workflow}: {workflow: WorkflowFragment}) {
  const [toggle, loading] = useFavoriteToggle(workflow);

  return (
    <Tooltip text={workflow.isFavorite ? 'ショートカットから外す' : 'ショートカットに追加する'} placement="bottom">
      <QueitButton onClick={() => toggle()} disabled={loading} style={{padding: '0 6px'}}>
        {workflow.isFavorite && <Star size={16} color={colors.shortcut} fill={colors.shortcut} strokeWidth="1.5px" />}
        {!workflow.isFavorite && <Star size={16} strokeWidth={"1.5px"} style={{color: colors.lightText}} />}
      </QueitButton>
    </Tooltip>
  );
}

export function WorkflowHeader({workflow}: {workflow: WorkflowFragment}) {
  const {runWorkflow, loading} = useRunWorkflow(workflow);
  const publishWorkflow = useChangeWorkflowStatus(WorkflowStatus.published, workflow);

  const history = useHistory();
  const isPreviewRoute = !!history.location.pathname.match(/\/preview$/);
  const workspaceId = useWorkspaceId();

  return (
    <>
      <WorkflowHeaderBlock>
        {loading && <Loading />}

        <HorizontalBox style={{height: '100%'}}>
          {false && <BackButton to={workflow.workspace ? `/workspaces/${workflow.workspace!.id}` : '/'} />}
          <WorkflowIcon workflow={workflow} size={20} />
          <Space />
          <div>
            <HorizontalBox alignItems="center">
              <NameInput workflow={workflow} />
              {typeof workflow.count === 'number' && (
                <span
                  style={{
                    color: colors.superLightText,
                    fontWeight: fontWeights.semiBold,
                    fontSize: fontSizes.md,
                    lineHeight: '1',
                  }}>
                  &nbsp;#{workflow.count}
                </span>
              )}
              {isPrototype(workflow.status) && (
                <span
                  style={{
                    color: colors.superLightText,
                    fontWeight: fontWeights.semiBold,
                    fontSize: fontSizes.md,
                    lineHeight: '1',
                  }}>
                  ワークフロー
                </span>
              )}
              &nbsp;
              <StatusSelector workflow={workflow} />
            </HorizontalBox>

            <HorizontalBox alignItems="center" style={{fontSize: '12px', display: 'none'}}>
              {workflow.sourceWorkflow && (
                <>
                  <Link to={`/workspaces/${workspaceId}/workflows/${workflow.sourceWorkflow.id}`}>{workflow.sourceWorkflow.name}</Link>から&nbsp;
                </>
              )}
              <span style={{color: colors.superLightText, fontSize: '12px'}}>
                <TimeAgo>{workflow.createdAt}</TimeAgo>に作成 &nbsp;
                <TimeAgo>{workflow.updatedAt}</TimeAgo>に更新
              </span>
              &nbsp;
              {workflow.workspace && <Link to={`/workspaces/${workflow.workspace.id}`}>{workflow.workspace.name}</Link>}
            </HorizontalBox>
          </div>

          <SpringSpace />

          {isPreviewRoute && (
            <>
              <SubtleQuietButton
                className={tw`mr-2`}
                onClick={() => {
                  history.replace(`/workspaces/${workspaceId}/workflows/${workflow.id}/`);
                }}>
                <Eye size={16} strokeWidth={1.5} />
                <span className={tw`font-normal text-base ml-1`}>プレビューを解除</span>
              </SubtleQuietButton>
            </>
          )}

          <FavoriteButtonContainer workflow={workflow} />

          {false && !isPreviewRoute && (
            <>
              <Space width="8px" />
              <ACLButton workflow={workflow} />
            </>
          )}
          <Space width="4px" />

{false && <>
          {workflow.status === WorkflowStatus.published && (
            <CtaButton variant="green" className={tw`mr-2`} onClick={runWorkflow} disabled={loading}>
              <ArrowRightCircle size={16} className={tw`mr-1`} strokeWidth={"1.5px"} />
              ワークフローを開始
            </CtaButton>
          )}
          {workflow.status === WorkflowStatus.draft && workflow.canEdit && (
            <CtaButton
              variant="green"
              className={tw`mr-2`}
              onClick={() => publishWorkflow.run()}
              disabled={publishWorkflow.loading}>
              <Loader size={16} className={tw`mr-1`} />
              公開する
            </CtaButton>
          )}
</>}
          <WorkflowMenu workflow={workflow} />
          <Space width="30px" />
          <UserIconMenu workspaceId={workflow.workspace!.id} />
        </HorizontalBox>
      </WorkflowHeaderBlock>
    </>
  );
}

export function StatusSelector({workflow}: {workflow: WorkflowFragment}) {
  const updateWorkflow = useUpdateWorkflow({workflow});

  return (
    <StatusSelectBox
      value={workflow.status}
      disabled={!workflow.canEdit}
      onChange={(value) => {
        updateWorkflow.mutate({
          variables: {
            id: workflow.id,
            status: value as WorkflowStatus,
          },
          optimisticResponse: {
            workflow: {
              __typename: 'WorkflowOps',
              update: {
                ...workflow,
                status: value as WorkflowStatus,
              },
            },
          },
        });
      }} />
  );
}
