import {ApolloClient, split, from, ApolloLink} from '@apollo/client';
import {BatchHttpLink} from '@apollo/client/link/batch-http';
import {onError} from '@apollo/client/link/error';
import {InMemoryCache} from '@apollo/client/cache';
import {createUploadLink} from 'apollo-upload-client';
import {redirectToLoginRoute} from './redirect';

export const cache = new InMemoryCache({
  typePolicies: {
    WorkflowOps: {
      merge: true,
    },
    Workflow: {
      fields: {
        collapse: {
          read(value) {
            return !!value;
          },
        },
        steps: {
          merge: false,
        },
      },
    },

    Query: {
      fields: {
        activities: {
          keyArgs: ['stepId', 'workflowId'],
          merge(existing, incoming, options) {
            const after = options.args?.after;

            if (!existing || typeof after !== 'string') {
              return incoming;
            }

            return {
              ...incoming,
              items: [...existing.items, ...incoming.items],
            };
          }
        },
        workflows: {
          keyArgs: ['ownerId', 'sourceWorkflowId', 'statuses', 'createdAt', 'sort', 'workspaceId'],
          merge(existing, incoming, options) {
            const after = options.args?.after;

            if (!existing || typeof after !== 'string') {
              return incoming;
            }

            return {
              ...incoming,
              items: [...existing.items, ...incoming.items],
            };
          }
        }
      }
    },
    WorkflowFeed: {
    },
    Step: {},
    MemberFeed: {
      merge: false,
    },
    Workspace: {
      merge: true,
      fields: {
        workflowsCount: {
          keyArgs: ['ownerId', 'sourceWorkflowId', 'statuses'],
          merge: false,
        }
      }
    },
  },
});

const linkOptions = {
  uri: '/graphql',
  credentials: 'include',
};

const uploadLink = createUploadLink(linkOptions);

export const client = new ApolloClient<unknown>({
  link: from([
    onError((error): void => {
      if (error.graphQLErrors) {
        if (isLoginRequired(error)) {
          if (!window.location.pathname.startsWith('/login')) {
            redirectToLoginRoute();
          }

          return;
        }

        error.graphQLErrors.forEach(({message, locations, path}) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
      }

      if (error.networkError) {
        console.log(`[Network error]: ${error.networkError}`);
      }
    }),

    split(
      (operation) => {
        const {variables} = operation;
        return hasFile(variables);
      },
      (uploadLink as unknown) as ApolloLink, // FIXME
      new BatchHttpLink(linkOptions)
    ),
  ]),

  cache,
});

const hasFile = (object: any) => {
  for (const name in object) {
    if (object.hasOwnProperty(name)) {
      const value: unknown = object[name];

      if (value instanceof File) {
        return true;
      }

      if (value instanceof Object && hasFile(value)) {
        return true;
      }
    }
  }
  return false;
};

function isLoginRequired(error: any) {
  if (error && error.graphQLErrors) {
    return error.graphQLErrors.some((error: any) => error.loginRequired);
  }
  return false;
}
