import * as React from 'react';
import {useOutsideClick} from '../../hooks/useOutsideClick';
import {MenuButtonWrapper, Popup} from './OptionsMenu';
import usePortal from 'react-useportal';
import {MinimumButton} from '../Button/Button';
import {MoreHorizontal} from 'react-feather';

interface UseOptionsMenuParams {
  menu: (close: () => void) => React.ReactNode;
  button: React.ReactElement;
  hide?: boolean;
  direction?: 'auto' | 'left' | 'right';
  popoverStyle?: React.HTMLAttributes<HTMLDivElement>['style'];
}

interface UsePopupParams {
  menu: (close: () => void) => React.ReactNode;
  button: React.ReactElement;
  popoverStyle?: React.HTMLAttributes<HTMLDivElement>['style'];
}

export function usePopup(props: UsePopupParams) {
  const {menu, button, popoverStyle = {}} = props;
  const [isOpen, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const node = (
    <>
      <MenuButtonWrapper ref={ref as React.RefObject<HTMLDivElement>}>
        {React.cloneElement(button, {onClick: () => setOpen(!isOpen)})}
        {isOpen && (
          <Popup
            style={{
              position: 'absolute',
              top: '100%',
              left: '100%',
              right: 'auto',
              transform: 'translateX(-50%)',
              zIndex: 3,
              ...popoverStyle,
            }}>
            {menu(() => setOpen(false))}
          </Popup>
        )}
      </MenuButtonWrapper>
    </>
  );

  return {
    node,
    isOpen,
    setOpen,
  };
}

export function useOptionsMenu(props: UseOptionsMenuParams) {
  const {menu, button, hide = false, popoverStyle = {}, direction = 'auto'} = props;

  const {isOpen, Portal, openPortal, closePortal, ref, togglePortal} = usePortal({
    closeOnOutsideClick: true,
    closeOnEsc: false,
    onOpen(args) {
      const {portal, targetEl} = args;
      const rect = targetEl.current.getBoundingClientRect();

      const computedDirection =
        direction !== 'auto' ? direction : rect.left + rect.width / 2 > window.innerWidth / 2 ? 'left' : 'right';

      const computedVDirection = rect.top + rect.height / 2 > window.innerHeight / 2 ? 'top' : 'bottom';

      const left = computedDirection === 'left' ? Math.round(rect.left + rect.width) : Math.round(rect.left);
      const top = computedVDirection === 'bottom' ? Math.round(rect.top + rect.height) : Math.round(rect.top);
      let transform = computedDirection === 'left' ? 'translateX(-100%)' : '';
      transform += computedVDirection === 'top' ? ' translateY(-100%)' : '';

      portal.current.style.cssText = `
        z-index: 100;
        position: fixed;
        top: ${top}px;
        left: ${left}px;
        transform: ${transform};
        width: auto;
			`;
    },
  });

  const setOpen = (isOpen: boolean) => {
    isOpen ? openPortal() : closePortal();
  };

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    togglePortal(event);
    event.preventDefault();
    event.stopPropagation();
  };

  const node = (
    <>
      {!hide && (
        <MenuButtonWrapper>
          {React.cloneElement(button, {ref, onClick})}
          {isOpen && (
            <Portal>
              <Popup style={{top: 'auto', right: 'auto', position: 'static', ...popoverStyle}}>
                {menu(closePortal)}
              </Popup>
            </Portal>
          )}
        </MenuButtonWrapper>
      )}
    </>
  );

  return {
    node,
    isOpen,
    setOpen,
  };
}

export function useDetailsMenu({menu, hide = false}: Omit<UseOptionsMenuParams, 'button'>) {
  return useOptionsMenu({
    menu,
    hide,
    button: (
      <MinimumButton>
        <MoreHorizontal size={16} strokeWidth={"1.5px"} />
      </MinimumButton>
    ),
  });
}
