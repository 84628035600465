import * as React from 'react';

export function useHover<E extends HTMLElement = HTMLElement>(): [React.Ref<E>, boolean] {
  const [value, setValue] = React.useState(false);
  const ref = React.useRef<E>(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  React.useLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, []);

  return [ref, value];
}
