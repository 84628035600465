import styled from '@emotion/styled';
import {fontWeights} from 'theme';

export const ErrorBlock = styled((props: any) => {
  if (props.errors) {
    const messages = props.errors.map((error: any) => {
      if (typeof error === 'string') {
        return error;
      }

      if (typeof error.message === 'string') {
        return error.message;
      }

      throw Error('Invalid state');
    });

    return (
      <div {...props}>
        {messages.map((message: any) => (
          <div key={message}>{message}</div>
        ))}
      </div>
    );
  }

  return <div {...props}>{props.children || null}</div>;
})`
  font-size: 12px;
  color: #e70d0d;
  padding: 0;
  border-radius: 4px;
  font-weight: ${fontWeights.bold};
  margin: 4px 0;
  box-sizing: border-box;

  &:empty {
    display: none;
  }
`;
