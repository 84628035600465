import {FC, forwardRef} from 'react';

type Props = JSX.IntrinsicElements['div'] & {
  justify?: 'flex-end' | 'flex-start' | 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'end';
  alignItems?: 'stretch' | 'center' | 'start' | 'end' | 'flex-end' | 'flex-start';
  ref?: React.Ref<any>;
};

export const HorizontalBox: FC<Props> = forwardRef((props, ref) => {
  const {children, justify, alignItems, style = {}, ...rest} = props;

  const flexStyle = {
    display: 'flex',
    justifyContent: justify || 'start',
    alignItems: alignItems || 'center',
    flexDirection: 'row' as 'row',
  };

  return (
    <div style={{...flexStyle, ...style}} {...rest} ref={ref}>
      {children}
    </div>
  );
});

type FlexItemProps = JSX.IntrinsicElements['div'] & {
  flex?: string;
};

export const FlexItem = (props: FlexItemProps) => {
  const {children, flex = '0 0 auto', style = {}, ...rest} = props;

  return (
    <div style={{flex, ...style}} {...rest}>
      {children}
    </div>
  );
};

export const SpringSpace = ({minWidth = undefined}: {minWidth?: string}) => {
  return <div style={{flexGrow: 1, flexShrink: 1, minWidth}} />;
};

export const Space: FC<{width?: string; height?: string}> = ({width, height}) => {
  // 何もpropsが指定されていない場合には、横方向のスペースを優先して表示する。
  if (width || !height) {
    return <div style={{width: width || '0.5em', flexShrink: 0}} />;
  }

  return <div style={{height: height, flexShrink: 0}} />;
};
