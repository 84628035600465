import {keyframes} from '@emotion/css';
import styled from '@emotion/styled';
import {colors} from 'theme';

const bgAnimation = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`;

export const TextPlaceholder = styled.span<{color?: string}>`
  position: relative;
  color: transparent;
  letter-spacing: 1.4em;
  overflow: hidden;
  display: inline;
  border-radius: 3px;
  line-height: 1.3;
  animation: ${bgAnimation} 1.2s ease-in-out infinite;
  
  ${props => `
    background-color: ${props.color ?? colors.placeholderBackground};
  `}
`;

export const CirclePlaceholder = styled.div<{size?: number}>`
  display: block;
  border-radius: 50%;
  animation: ${bgAnimation} 1.2s ease-in-out infinite;
  background-color: ${colors.placeholderBackground};

  ${props => `
    width: ${props.size ?? 32}px;
    height: ${props.size ?? 32}px;
  `}
`;
