import {signUpMutation} from '../../gqls/signUpMutation';
import {useState, ComponentType} from 'react';
import {Field, LargeTextInput} from '../../base/components/Form/Form';
import {ExLargeButton} from '../../base/components/Button/Button';
import {Page, ContentBox, ContentHeaderText, P, FieldLabel, Errors, ErrorItem, A} from './Public';
import {Helmet} from 'react-helmet-async';
import {Fragment} from 'react';
import {Indicator} from '../../base/components/Indicator/Indicator';
import {run, isEmail, isEmpty} from '../../base/validators';
import {css} from '@emotion/css';
import {Link} from 'react-router-dom';
import {useAppMutation} from '../../base/hooks/useAppMutation';
import {SignUp, SignUpVariables} from '../../__generated__/SignUp';
import {colors, fontWeights} from 'theme';
import {Send} from 'react-feather';
import {HorizontalBox} from '../../base/components/HorizontalBox/HorizontalBox';
import {tw} from 'twind';

export const Signup = ({state, setState}: {state: State; setState: (state: State) => void}) => {
  const {contentComponent: ContentComponent} = state;

  return (
    <Page>
      <Helmet>
        <title>サインアップ</title>
      </Helmet>
      <ContentBox>
        <ContentComponent state={state} setState={setState} />
      </ContentBox>
    </Page>
  );
};

export const EmailForm = ({state, setState}: {state: State; setState: (state: State) => void}) => {
  const [authEmail, mutateResult] = useAppMutation<SignUp, SignUpVariables>(signUpMutation, {
    onError(error) {
      setState({
        ...state,
        errors: [error.graphQLErrors ? error.graphQLErrors[0].message : error.message],
      });
    },
    onCompleted() {
      setState({
        ...state,
        errors: [],
        contentComponent: EmailSent,
      });
    },
  });
  const {email, errors} = state;
  const sending = mutateResult.loading;

  const onSubmit = async (event: FIXME) => {
    event.preventDefault();

    const errors = run(function* () {
      if (isEmpty(email)) {
        return yield 'メールアドレスを入力してください。';
      }

      if (!isEmail(email)) {
        yield 'メールアドレスを正しく入力して下さい。';
      }
    });

    if (errors.length > 0) {
      return setState({...state, errors});
    }

    setState({...state, errors: []});
    authEmail({variables: {email}});
  };

  const onChangeEmail = (event: FIXME) => setState({...state, email: event.target.value});

  return (
    <form onSubmit={onSubmit}>
      <ContentHeaderText>サインアップ</ContentHeaderText>

      <P>アカウントを作成するには、メールアドレスを入力してください。</P>

      {process.env.NODE_ENV === 'production' && <P>現在サインアップできません</P>}

      <FieldLabel>メールアドレス</FieldLabel>

      <LargeTextInput
        type="text"
        placeholder="email@example.com"
        value={email}
        onChange={onChangeEmail}
        autoFocus
        disabled={sending}
      />

      <Errors>
        {errors.map((error: string) => (
          <ErrorItem key={error}>{error}</ErrorItem>
        ))}
      </Errors>

      <Field>
        <ExLargeButton type="submit" disabled={sending}>
          {sending ? <Indicator modifier="white" /> : '次へ'}
        </ExLargeButton>
      </Field>

      <div className={tw`text-center my-4`}>
        もしくは&nbsp;
        <Link to="/login" component={A}>
          ログインする
        </Link>
      </div>
    </form>
  );
};

const sentMailStyle = css`
  font-weight: ${fontWeights.bold};
  color: ${colors.blackText};
`;

export const EmailSent = ({state: {email}}: any) => {
  return (
    <Fragment>
      <ContentHeaderText>
        <HorizontalBox>
          <Send />
          &nbsp;確認のメールを送信しました
        </HorizontalBox>
      </ContentHeaderText>

      <P>
        <strong className={sentMailStyle}>{email}</strong>
        宛に確認のメールを送信しました。
      </P>

      <P>サインアップを続けるには、メールに記載されているURLにアクセスして下さい。</P>
    </Fragment>
  );
};

type State = {
  contentComponent: ComponentType<any>;
  email: string;
  receiveNews: boolean;
  sending: boolean;
  errors: string[];
};

export const SignupRoute = () => {
  const [state, setState] = useState<State>({
    contentComponent: EmailForm,
    email: '',
    receiveNews: true,
    sending: false,
    errors: [],
  });

  return <Signup state={state} setState={setState} />;
};
