import {TextInput} from '../Form/Form';
import styled from '@emotion/styled';
import {MouseEvent, useState, useCallback, ReactElement, cloneElement, useRef} from 'react';
import {colors} from 'theme';
import {UserFragment} from '../../../__generated__/UserFragment';
import {UserBlock} from '../../../app/common/UserBlock';
import {tw} from 'twind';

export interface Item {
  key: string;
  label: ReactElement;
  value: string;
}

interface Props extends Omit<JSX.IntrinsicElements['div'], 'onChange'> {
  target?: ReactElement;
  users: UserFragment[];
  onChange?: (value: string) => void;
}

interface State {
  popup: boolean;
}

export function UserSelectInput(props: Props) {
  const {target = <TextInput />, users, onChange = () => {}, ...rest} = props;

  const ref = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<State>({
    popup: false,
  });

  const handleBlur = useCallback(() => {
    setState((state) => ({...state, popup: false}));
  }, [setState]);

  const handleFocus = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      setState((state) => ({...state, popup: true}));
      event.currentTarget.select();
    },
    [setState]
  );

  const handleClick = useCallback(() => {
    setState((state) => ({...state, popup: true}));
  }, [setState]);

  const filteredUsers = users.filter((user) => {
    const value = ref.current?.value ?? '';
    return user.email.indexOf(value) !== -1 || user.name.indexOf(value) !== -1;
  });

  return (
    <Wrapper {...rest}>
      {cloneElement(target, {ref, onClick: handleClick, onBlur: handleBlur, onFocus: handleFocus})}

      {state.popup && users.length > 0 && (
        <Popup>
          {filteredUsers.map((user) => (
            <PopupItem
              key={user.id}
              onMouseDown={event => {
                event.preventDefault();
              }}
              onClick={event => {
                onChange(user.email);
                ref.current!.value = user.email;
              }}>
              <UserBlock user={user} />
            </PopupItem>
          ))}

          {filteredUsers.length === 0 && <PopupItem className={tw`text-superLightText text-sm text-center!`}>見つかりません</PopupItem>}
        </Popup>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: stretch;
  align-items: stretch;
`;

const Popup = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  border-radius: 4px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.08), 0 0 3px 0 rgba(0, 0, 0, 0.05), 0 2px 13px 0 rgba(0, 0, 0, 0.08);
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
`;

const PopupItem = styled.button<{selected?: boolean}>`
  display: block;
  width: 100%;
  padding: 8px;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: transparent;
  text-align: left;

  &:hover {
    background-color: ${colors.background};
  }
`;
