import styled from '@emotion/styled';
import {colors} from 'theme';

export const Page = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
`;

export const ClickableText = styled.span<{disabled?: boolean}>`
  color: ${colors.superLightText};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${colors.darkAccent};
  }

  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
`;

export const WorkflowLayout = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 52px);
`;

export const WorkflowLayoutLeft = styled.div`
  flex-basis: 400px;
  border-right: 1px solid ${colors.border};
  background-color: ${colors.lightBackground};
  padding: 4px 0 10px 8px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const WorkflowLayoutRight = styled.div`
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  min-width: 640px;
  position: relative;
`;
