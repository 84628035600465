import {useState, useLayoutEffect} from 'react';

export function useAnimationState(second: number) {
  const [started, setStart] = useState(false);
  const [ended, setEnd] = useState(false);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setStart(true);
        setTimeout(() => setEnd(true), second);
      });
    });
  }, [second]);

  return {
    started,
    ended,
  };
}
