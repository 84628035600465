import {ContentHeaderText, P} from '../Public';
import {ExLargeButton} from '../../../base/components/Button/Button';
import {useHistory} from 'react-router-dom';

export function Done() {
  const history = useHistory();

  return (
    <>
      <ContentHeaderText>アカウントが作成されました</ContentHeaderText>

      <P>あなたのアカウントが作成されました。早速ログインしてみましょう。</P>

      <ExLargeButton onClick={() => history.push('/login')}>ログインする</ExLargeButton>
    </>
  );
}
