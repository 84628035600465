import {Fragment} from 'react';
import {P, ContentHeaderText} from '../Public';
import {ExLargeButton} from '../../../base/components/Button/Button';
import {useHistory} from 'react-router';

export function ResetDone() {
  const history = useHistory();
  const handleClick = () => {
    history.replace('/login');
  };

  return (
    <Fragment>
      <ContentHeaderText>パスワードが再設定されました</ContentHeaderText>

      <P>先程入力したパスワードが新しく再設定されました。新しいパスワードでログインすることができます。</P>

      <ExLargeButton onClick={handleClick}>ログインへ移動する</ExLargeButton>
    </Fragment>
  );
}

export const ResetDoneWithRouter = ResetDone;
