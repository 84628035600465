import {FetchSelf} from '../../../__generated__/FetchSelf';
import {UserIconContainer} from '../../common/UserIconContainer';
import {TextPlaceholder, CirclePlaceholder} from '../../../base/components/LoadingPlaceholder';
import styled from '@emotion/styled';
import {Space} from '../../../base/components/HorizontalBox/HorizontalBox';
import {UserDetailIconMenu} from '../../common/UserDetailIconMenu';
import {colors, fontWeights} from 'theme';
import {tw} from 'twind';

export function UserPanel({self}: {self: FetchSelf['self']}) {
  const {name, email} = self;

  return (
    <>
      <UserBlock>
        <UserIconContainer size={36} user={self} />
        <div>&nbsp;</div>
        <div className={tw`flex-grow-1 flex-shrink-1 overflow-hidden`}>
          <Name>{name}</Name>
          <Email>{email}</Email>
        </div>
        <Space width={"8px"} />
        <UserDetailIconMenu includeWorkspaceMenu={false} />
      </UserBlock>
    </>
  );
}

export function LoadingUserPanel() {
  return (
    <UserBlock>
      <CirclePlaceholder size={36} />
      <div>&nbsp;</div>
      <div className={tw`flex-grow-1 flex-shrink-1 overflow-hidden`}>
        <Name><TextPlaceholder>..</TextPlaceholder></Name>
        <Email><TextPlaceholder>......</TextPlaceholder></Email>
      </div>
    </UserBlock>
  );
}

const UserBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 0 8px 0;
  margin: 0 0 12px 0;
`;

const Name = styled.span`
  color: ${colors.blackText};
  font-size: 14px;
  font-weight: ${fontWeights.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 170px;
  line-height: 1;
`;

const Email = styled.div`
  color: ${colors.superLightText};
  font-size: 12px;
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.06em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 170px;
  line-height: 1;
  margin: 2px 0 0 0;
`;
