import * as React from 'react';
import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {colors, fontSizes, fontWeights} from 'theme';

export const TabBar = styled.div`
  display: flex;
  padding: 0;
  margin: 4px 0 0 0;
  width: 100%;
  clear: both;
  box-sizing: border-box;
  background-position: left 0 bottom 0;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-image: linear-gradient(0deg, #e9eaed, #e9eaed);
`;

export const tabStyle = css`
  display: inline-flex;
  align-items: center;

  &,
  &:link,
  &:visited {
    height: 36px;
    margin: 0 6px;
    display: block;
    line-height: 38px;
    font-size: ${fontSizes.sm};
    border-bottom: 2px solid transparent;
    padding: 0 4px;
    color: ${colors.lightText};
    text-decoration: none;
    font-weight: ${fontWeights.bold};
    transition: border 0.15s ease;
    cursor: pointer;

    &:hover {
      color: ${colors.darkText};
      border-bottom: 2px solid #e9eaed;
      .count-in-tab {
        color: ${colors.blackText};
      }
    }
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

export const currentTabStyle = css`
  &,
  &:link,
  &:visited {
    border-bottom: 2px solid #24303d;
    color: ${colors.blackText};
    font-weight: ${fontWeights.bold};
    transition: border 0.15s ease;

    &:hover {
      border-bottom: 2px solid #24303d;
      color: ${colors.blackText};
      font-weight: ${fontWeights.bold};
    }

    .count-in-tab {
      color: ${colors.blackText};
    }
  }
`;

export const Tab = (props: JSX.IntrinsicElements['span']) => {
  return <span {...props} className={tabStyle + ' ' + currentTabStyle + ' ' + props.className}>
    {props.children}
  </span>;
}

export const TabLink = (props: any & {to: string; children: React.ReactNode}) => (
  <NavLink {...props} to={props.to} className={tabStyle + ' ' + props.className} activeClassName={currentTabStyle}>
    {props.children}
  </NavLink>
);

export const LargeTabBar = styled(TabBar)`
  background-color: white;
`;

interface LargeTabProps extends NavLinkProps {
  activeColor?: string;
  activeTextColor?: string;
}

export const LargeTab = (props: LargeTabProps) => {
  const {activeColor, activeTextColor, ...rest} = props;
  return (
    <NavLink
      {...rest}
      className={css`
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 0;
        width: 88px;

        &,
        &:link,
        &:visited {
          height: 40px;
          margin: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          font-size: ${fontSizes.base};
          border-bottom: 2px solid transparent;
          padding: 0 12px;
          color: ${colors.superLightText};
          text-decoration: none;
          font-weight: ${fontWeights.normal};
          transition: border 0.15s ease;
          cursor: pointer;

          &:hover {
            color: ${activeTextColor ?? colors.blackText};
            border-bottom: 2px solid ${activeColor ?? colors.lightText};
            font-weight: ${fontWeights.bold};
          }
        }

        &:first-of-type {
          margin-left: 0;
        }
      `}
      activeClassName={css`
        &,
        &:link,
        &:visited {
          border-bottom: 2px solid ${activeColor ?? colors.blackText};
          color: ${activeTextColor ?? colors.blackText};
          font-weight: ${fontWeights.bold};
          transition: border 0.15s ease;

          &:hover {
            border-bottom: 2px solid ${activeColor ?? colors.blackText};
            color: ${activeTextColor ?? colors.blackText};
            font-weight: ${fontWeights.bold};
          }
        }
      `}
    />
  );
};
