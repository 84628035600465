import {useState, ReactNode} from 'react';
import {DialogContent, DialogTitle, Footer, ModalDialog} from '../Dialog/ModalDialog';
import {TextInput} from '../Form/Form';
import {LargeButton, LargeDarkButton} from '../Button/Button';
import {HorizontalBox} from '../HorizontalBox/HorizontalBox';
import {HelpCircle} from 'react-feather';

interface PromptDialogProps {
  message: ReactNode;
  title?: string;
  close: (result: string | null) => void;
  placeholder?: string;
  buttonTexts?: {
    submit?: string;
    cancel?: string;
  };
  defaultValue?: string;
  icon?: ReactNode;
}

export function PromptDialog(props: PromptDialogProps) {
  const {title, message, close, placeholder, buttonTexts, icon = <HelpCircle strokeWidth={1.5} />, defaultValue = ''} = props;
  const [state, setState] = useState<string>(defaultValue);

  return (
    <ModalDialog>
      <DialogTitle>
        <HorizontalBox>
          {icon}
          &nbsp;
          {title || ''}
        </HorizontalBox>
      </DialogTitle>

      <DialogContent>
        <div>{message}</div>
        <form style={{margin: '6px 0'}} onSubmit={() => close(state)}>
          <TextInput
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            autoFocus
            onFocus={(e) => e.currentTarget.select()}
            placeholder={placeholder}
            style={{width: '100%'}}
          />
        </form>
      </DialogContent>

      <Footer>
        <LargeButton onClick={() => close(null)}>{buttonTexts?.cancel ?? 'キャンセル'}</LargeButton>&nbsp;
        <LargeDarkButton onClick={() => close(state)}>{buttonTexts?.submit ?? '送信'}</LargeDarkButton>
      </Footer>
    </ModalDialog>
  );
}
