import {useWorkspaceId} from '../../base/hooks/useWorkspaceId';
import {useContext} from 'react';
import {useHistory} from 'react-router';
import {ToastContext} from '../../base/components/Toast/Toast';
import {useAppMutation} from '../../base/hooks/useAppMutation';
import {RunWorkflow, RunWorkflowVariables} from '../../__generated__/RunWorkflow';
import {runWorkflowMutation} from '../../gqls/workflowMutations';
import {ApolloError} from '@apollo/client';
import {useModalDialog} from '../../base/components/ModalDialog/ModalDialog';
import {ArrowRightCircle} from 'react-feather';
import {Button} from '../../base/components/Button/Button';

export function RunWorkflowButton() {
  const workspaceId = useWorkspaceId();

  const history = useHistory();
  const toast = useContext(ToastContext);
  const [runWorkflow, {loading: creating}] = useAppMutation<RunWorkflow, RunWorkflowVariables>(runWorkflowMutation, {
    variables: {
      workspaceId,
      name: '',
    },
    onCompleted(data) {
      const {id} = data.runWorkflow;
      history.push(`/workspaces/${workspaceId}/workflows/${id}`);
      toast.message('ワークフローを開始しました');
    },
    onError(error: ApolloError) {
      toast.error('ワークフローの開始に失敗しました');
    },
  });

  const {prompt} = useModalDialog();
  const onClickRunWorkflow = async () => {
    const result = await prompt({
      message: `空のワークフローを開始します。`,
      title: 'ワークフローを開始',
      icon: <ArrowRightCircle strokeWidth={1.5} />,
      defaultValue: '無名',
      placeholder: '',
      buttonTexts: {
        submit: '開始',
      },
    });

    if (typeof result === 'string') {
      runWorkflow({
        variables: {
          workspaceId,
          name: result,
        },
      });
    }
  };

  return (
    <>
      <Button onClick={() => onClickRunWorkflow()} disabled={creating}>
        空のワークフローを開始
      </Button>
    </>
  );
}
