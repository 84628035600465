import {StatusLabel} from './StatusLabel';
import {useOptionsMenu} from '../OptionsMenu/hooks';
import {ReactElement} from 'react';
import {MenuItem} from '../OptionsMenu/OptionsMenu';
import {Check} from 'react-feather';
import styled from '@emotion/styled';
import {statusDictionary} from './_common';

interface Option {
  name: string;
  value: string;
  description?: ReactElement;
}

const runningWorkflowOptions: Option[] = [
  {
    name: '処理中',
    value: 'running',
  },
  {
    name: '完了',
    value: 'finished',
  },
];

const prototypeWorkflowOptions: Option[] = [
  {
    name: '公開中',
    value: 'published',
    description: <div>ワークスペースのメンバーがワークフローを開始できます。</div>,
  },
  {
    name: '下書き',
    value: 'draft',
    description: <div>ワークフローの下書き状態です。メンバーはワークフローを開始することはできません。</div>,
  },
  {
    name: 'アーカイブ',
    value: 'archived'
  },
];

interface Props {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const SelectBoxWrapper = styled.button<{disabled?: boolean}>`
  position: relative;
  display: flex;
  line-height: 1;
  color: black;
  width: max-content;
  align-items: center;
  justify-content: center;
  
  &::after {
    content: '';
    position: absolute;
    border-right: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
    width: 5px;
    height: 5px;
    top: 5px;
    right: 4px;
    transform-origin: 0 0;
    transform: rotate(45deg);
    background-color: transparent;
    pointer-events: none;
    display: ${props => props.disabled ? 'none' : 'block'};
  }
`;

export const StatusSelectBox = (props: Props) => {
  const {color} = statusDictionary[props.value];

  const options = runningWorkflowOptions.map(option => option.value).includes(props.value) ? runningWorkflowOptions : prototypeWorkflowOptions;

  const {node} = useOptionsMenu({
    button: <SelectBoxWrapper style={{color}} disabled={props.disabled}><StatusLabel status={props.value} style={{paddingRight: '16px'}} /></SelectBoxWrapper>,
    menu: close => <>
      {options.map(option => (
        <MenuItem key={option.value} description={option.description} onClick={() => {
          close();
          if (props.value !== option.value) {
            props.onChange?.(option.value);
          }
        }} icon={props.value === option.value ? <Check /> : undefined}>
          {option.name}
        </MenuItem>
      ))}
    </>
  });

  return node;
}
