import {Component} from 'react';
import {ContentHeaderText, FieldLabel, Errors} from '../Public';
import {Indicator} from '../../../base/components/Indicator/Indicator';
// @ts-ignore
import queryString from 'query-string';
import {Mutation} from '@apollo/client/react/components';
import {ResetDoneWithRouter} from './ResetDone';
import {resetPasswordMutation} from '../../../gqls/passwordResetMutations';
import {LargeTextInput} from '../../../base/components/Form/Form';
import {ExLargeButton} from '../../../base/components/Button/Button';

const ResetPasswordMutation = ({children}: FIXME) => <Mutation mutation={resetPasswordMutation}>{children}</Mutation>;

export const ResetForm = (props: any) => (
  <ResetPasswordMutation>
    {(mutate: FIXME, result: FIXME) => (
      <InnerResetForm {...props} resetPassword={mutate} resetPasswordResult={result} />
    )}
  </ResetPasswordMutation>
);

type Props = {
  resetPassword: any;
  setComponent: any;
  resetPasswordResult: {
    loading: boolean;
  };
};

export class InnerResetForm extends Component<Props, any> {
  state = {
    password: '',
    error: null,
  };

  handleChangePassword = (event: any) => {
    this.setState({password: event.target.value});
  };

  handleSubmit = async (event: any) => {
    if (event) {
      event.preventDefault();
    }

    const password = this.state.password.trim();

    if (password.length < 6) {
      this.setState({error: 'パスワードは6文字よりも長く入力してください'});
      return;
    }

    if (password.length > 255) {
      this.setState({error: 'パスワードは255文字よりも短く入力してください'});
      return;
    }

    this.setState({error: null});

    try {
      const token = queryString.parse(window.location.search).token;
      await this.props.resetPassword({variables: {token, password}});
      this.props.setComponent(ResetDoneWithRouter);
    } catch (e) {
      // TODO: ログ
      this.setState({
        loading: false,
        error: 'パスワードの設定に失敗しました',
      });
    }
  };

  render() {
    const {password, error} = this.state;
    const {loading} = this.props.resetPasswordResult;

    return (
      <form onSubmit={this.handleSubmit}>
        <ContentHeaderText>パスワードを再設定する</ContentHeaderText>

        <FieldLabel>新しいパスワード</FieldLabel>
        <LargeTextInput
          autoFocus
          type="password"
          placeholder="*******"
          value={password}
          autoComplete="new-password"
          onChange={this.handleChangePassword}
          disabled={loading}
        />

        {typeof error === 'string' && <Errors>{error}</Errors>}

        <ExLargeButton type="submit" onClick={this.handleSubmit} disabled={loading}>
          {loading ? <Indicator modifier="white" /> : 'パスワードを再設定'}
        </ExLargeButton>
      </form>
    );
  }
}
