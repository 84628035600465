import {addCommentMutation} from '../../../gqls/commentMutations';
import {UserIconContainer} from '../../common/UserIconContainer';
import {fetchSelfQuery} from '../../../gqls/selfQueries';
import {Loading} from '../../common/Loading';
import {findWorkflowQuery} from '../../../gqls/workflowQueries';
import {AddComment, AddCommentVariables} from '../../../__generated__/AddComment';
import {FindWorkflow, FindWorkflowVariables} from '../../../__generated__/FindWorkflow';
import {produce} from 'immer';
import {FetchSelf} from '../../../__generated__/FetchSelf';
import {useAppQuery} from '../../../base/hooks/useAppQuery';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {useToast} from '../../../base/components/Toast/Toast';
import {useRef, useState} from 'react';
import {HorizontalBox, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {MinimumButton, SubtleQueitButton} from '../../../base/components/Button/Button';
import {css} from '@emotion/css';
import {Paperclip, Send, X} from 'react-feather';
import {Tooltip} from '../../../base/components/Tooltip/Tooltip';
import fileSize from 'file-size';
import {colors, fontSizes, fontWeights} from 'theme';
import {CommentInput} from '../WorkflowRoute/CommentInput';
import {Prompt} from 'react-router-dom';
import {tw} from 'twind';
import {findActivitiesQuery} from '../../../gqls/findActivitiesQuery';
import {FindActivities, FindActivitiesVariables} from '../../../__generated__/FindActivities';

interface Props {
  workflowId: string;
  stepId: string;
}

interface State {
  mode: 'placeholder' | 'form';
  text: string;
  files: File[];
}

const initialState: State = {
  mode: 'placeholder',
  text: '',
  files: [],
};

export const CommentFormContainer = (props: Props) => {
  const {workflowId, stepId} = props;
  const {addComment, loading} = useAddComment();
  const self = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-only',
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<State>(initialState);
  const toast = useToast();

  const handleFileChange = () => {
    const input = inputRef.current!;
    let files = Array.from(input.files || []);
    if (files.length > 0) {
      files = files.filter((file) => {
        const ok = file.size < 1024 * 1024 * 5;

        if (!ok) {
          toast.error('ファイルが大きすぎます');
        }

        return ok;
      });

      setState({...state, files: [...state.files, ...files]});
    }
    input.value = '';
  };

  if (state.mode === 'placeholder') {
    return (
      <HorizontalBox>
        <UserIconContainer size={32} user={self.data?.self} />
        <div className={rightPanel}>
          <div className={placeholder} onClick={() => setState({...state, mode: 'form'})}>
            コメントを残す...
          </div>
        </div>
      </HorizontalBox>
    );
  }

  return (
    <>
      {loading && <Loading />}

      <input
        type="file"
        ref={inputRef}
        multiple
        style={{display: 'none'}}
        onChange={(event) => {
          handleFileChange();
          event.preventDefault();
        }}
      />

      <form className={layout}>
        <Prompt message="コメントの内容が保存されていません。続行しますか?" />
        <UserIconContainer size={32} user={self.data?.self} />
        <div className={rightPanel}>
          <CommentInput autoFocus onChange={(value) => setState({...state, text: value})}>
            {state.files.length > 0 && (
              <>
                <div style={{margin: '4px 0'}}>
                  {state.files.map((file) => {
                    return (
                      <HorizontalBox key={file.toString()}>
                        <span style={{fontSize: fontSizes.base}}>
                          <span style={{fontWeight: fontWeights.bold}}>{file.name}</span>
                          <span style={{fontWeight: fontWeights.bold, color: colors.lightText}}>
                            ({fileSize(file.size, {spacer: ''}).human('si')})
                          </span>
                          &nbsp;
                          <Tooltip text="添付ファイルを取り消す">
                            <MinimumButton>
                              <X
                                size={16}
                                onClick={() =>
                                  setState({
                                    ...state,
                                    files: state.files.filter((_file) => _file !== file),
                                  })
                                }
                              />
                            </MinimumButton>
                          </Tooltip>
                        </span>
                      </HorizontalBox>
                    );
                  })}
                </div>
              </>
            )}

            <HorizontalBox>
              <Tooltip text="ファイルを添付(5MiBまで)">
                <SubtleQueitButton
                  disabled={loading}
                  onClick={(event) => {
                    event.preventDefault();
                    inputRef.current!.click();
                  }}>
                  <Paperclip size={16} strokeWidth={1.5} />
                </SubtleQueitButton>
              </Tooltip>
              <SpringSpace />
              <SubtleQueitButton
                onClick={() => {
                  setState({...state, mode: 'placeholder'});
                }}
                disabled={loading}>
                キャンセル
              </SubtleQueitButton>
              <SubtleQueitButton
                onClick={async () => {
                  const result = await addComment({
                    workflowId,
                    stepId,
                    commentText: state.text,
                    files: state.files,
                  });

                  if (!result?.errors) {
                    setState(initialState);
                  }
                }}
                disabled={loading || state.text.length === 0}>
                <Send size={16} strokeWidth={1.5} className={tw`mr-1`} />
                送信
              </SubtleQueitButton>
            </HorizontalBox>
          </CommentInput>
        </div>
      </form>
    </>
  );
};

function useAddComment() {
  const toast = useToast();
  const [mutate, {loading, data, error}] = useAppMutation<AddComment, AddCommentVariables>(addCommentMutation, {
    onCompleted() {
      toast.message('コメントを追加しました');
    },
    onError() {
      toast.error('コメントの追加に失敗しました');
    },
  });

  return {
    async addComment(variables: AddCommentVariables) {
      const {workflowId, stepId} = variables;
      return await mutate({
        variables,
        update: (store, {data}) => {
          const comment = data?.workflow?.step?.comment;

          if (!comment) {
            return
          }

          {
            const data = store.readQuery<FindWorkflow, FindWorkflowVariables>({
              query: findWorkflowQuery,
              variables: {id: workflowId},
            });

            if (data?.workflow) {
              store.writeQuery<FindWorkflow, FindWorkflowVariables>({
                query: findWorkflowQuery,
                variables: {id: workflowId},
                data: produce(data, (draft) => {
                  const step = draft.workflow?.steps.find((step) => {
                    return step.id === stepId;
                  });

                  if (step) {
                    step.comments.totalCount++;
                    step.comments.items.unshift(comment);
                  }
                }),
              });
            }
          }

          {
            const data = store.readQuery<FindActivities, FindActivitiesVariables>({
              query: findActivitiesQuery,
              variables: {
                stepId,
                workflowId
              }
            });

            if (data?.activities) {
              store.writeQuery<FindActivities, FindActivitiesVariables>({
                query: findActivitiesQuery,
                variables: {stepId, workflowId},
                data: produce(data, (draft) => {
                  draft.activities.totalCount++;
                  draft.activities.items.unshift({
                    __typename: "AddCommentActivity",
                    id: comment.id,
                    createdAt: comment.createdAt,
                    comment: {
                      ...comment
                    }
                  });
                })
              })
            }
          }
        },
      });
    },
    loading,
    data,
    error,
  };
}

const layout = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 12px 0 12px 0;
`;

const rightPanel = css`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 0 0 8px;
`;

const placeholder = css`
  background-color: ${colors.lightBackground};
  border-radius: 4px;
  font-size: 14px;
  color: ${colors.lightText};
  height: 32px;
  line-height: 32px;
  border: none;
  appearance: none;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: ${fontWeights.light};

  &:active {
    background-color: ${colors.background};
  }
`;
