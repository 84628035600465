import {BaseDialog} from './BaseDialog';
import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {X} from 'react-feather';
import {colors, fontSizes, fontWeights} from 'theme';
import {createActionPlug} from 'react-action-plug';

const DialogActions = createActionPlug<{
  close(): void;
}>();

export const useDialogActions = DialogActions.useActions;
export const useDialogActionHandlers = DialogActions.useActionHandlers;

export const dialog = css`
  width: 780px;
  height: auto;
  border-radius: 5px;
  z-index: 101;
  background-color: white;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.14), 0 0 3px 0 rgba(0, 0, 0, 0.11), 0 2px 13px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 0;
  text-align: left;
  font-size: ${fontSizes.base};
  margin: 10vh auto;
  display: flex;
  flex-direction: column;
`;

export const mask = css`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  background-color: rgba(135, 135, 147, 0.45);
  overflow-y: auto;
`;

export const DialogContent = styled.div`
  margin: 24px;
  flex-grow: 1;
`;

export const Dialog = BaseDialog.bind({
  styles: {
    dialog: dialog,
    mask: mask,
  },
});

export const DialogTitle = styled.h2`
  display: block;
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeights.semiBold};
  padding: 0 26px;
  margin: 30px 0 12px 0;
  color: ${colors.blackText};
`;

const _DialogHeader = styled.div`
  border-bottom: 1px solid ${colors.lightBorder};
  margin: 0 8px;
  justify-content: center;
  align-items: center;
  height: 52px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr auto 1fr;
  gap: 0;
  text-align: center;
`;

const DialogHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DialogHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DialogHeader = Object.assign(_DialogHeader, {
  Left: DialogHeaderLeft,
  Right: DialogHeaderRight,
  CloseButton: styled((props: JSX.IntrinsicElements['button']) => {
      const {className, ...rest} = props;
      return (
        <button className={className} {...rest}>
          <X size={24} strokeWidth={"1.5px"} />
        </button>
      );
    })`
    appearance: none;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.lightText};
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 4px;
    border-radius: 5px;

    :focus:not(:focus-visible) {
      outline: none;
    }

    &:hover {
      color: ${colors.blackText};
      background-color: ${colors.background};
    }
  `,
  IconButton: styled.button`
    appearance: none;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.lightText};
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 4px;
    border-radius: 5px;

    :focus:not(:focus-visible) {
      outline: none;
    }

    &:hover {
      color: ${colors.blackText};
      background-color: ${colors.background};
    }
  `
});

export const DialogHeaderTitle = styled.span`
  font-size: ${fontSizes.md};
  font-weight: ${fontWeights.semiBold};
  line-height: 1;
  color: ${colors.blackText};
`;

export const DialogCloseButton = styled((props: JSX.IntrinsicElements['button']) => {
  const {className, ...rest} = props;
  return (
    <button className={className} {...rest}>
      <X size={20} />
    </button>
  );
})`
  position: absolute;
  right: 10px;
  top: 10px;
  appearance: none;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.lightText};
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 4px;
  border-radius: 5px;

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    color: ${colors.blackText};
    background-color: ${colors.background};
  }
`;

export const DialogHeaderCloseButton = styled((props: JSX.IntrinsicElements['button']) => {
  const {className, ...rest} = props;
  return (
    <button className={className} {...rest}>
      <X size={24} strokeWidth={"1.5"} />
    </button>
  );
})`
  appearance: none;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.lightText};
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 4px;
  border-radius: 5px;

  :focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    color: ${colors.blackText};
    background-color: ${colors.background};
  }
`;

export const DialogFooter = styled.div`
  display: flex;
  margin: 16px;
  align-items: center;
  justify-content: flex-end;
`;
