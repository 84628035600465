import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import {StepNameInput} from '../../common/StepPanelLayout';
import {NoSelectedStep} from '../../common/NoSelectedStep/NoSelectedStep';
import {Loading} from '../../common/Loading';
import {Helmet} from 'react-helmet-async';
import {SubtleQueitButton, QueitButton, DarkButton, SmallQuietButton} from '../../../base/components/Button/Button';
import fileSize from 'file-size';
import {
  attachFileToStepMutation,
  changeStepStatusMutation,
  detachFileFromStepMutation,
  removeStepMutation,
  updateStepMutation,
} from '../../../gqls/workflowMutations';
import {ToastContext, useToast} from '../../../base/components/Toast/Toast';
import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {StepFragment, StepFragment_files} from '../../../__generated__/StepFragment';
import {UpdateStep, UpdateStepVariables} from '../../../__generated__/UpdateStep';
import {css, cx} from '@emotion/css';
import styled from '@emotion/styled';
import {findWorkflowQuery} from '../../../gqls/workflowQueries';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {StepStatus, WorkflowStatus} from '../../../__generated__/globalTypes';
import {ActivitiesPanel} from './ActivitiesPanel';
import {HorizontalBox, SpringSpace, Space} from '../../../base/components/HorizontalBox/HorizontalBox';
import {AttachFileToStep, AttachFileToStepVariables} from '../../../__generated__/AttachFileToStep';
import {DetachFileFromStep, DetachFileFromStepVariables} from '../../../__generated__/DetachFileFromStep';
import {StepNoteEditor} from './StepNoteEditor';
import {useHover} from '../../../base/hooks/useHover';
import {CustomForm} from './CustomForm';
import {ChangeStepStatus, ChangeStepStatusVariables} from '../../../__generated__/ChangeStepStatus';
import {CustomFormEditorContainer} from './CustomFormEditorContainer';
import {useOptionsMenu} from '../../../base/components/OptionsMenu/hooks';
import {MenuItem, MenuSeparator} from '../../../base/components/OptionsMenu/OptionsMenu';
import {RemoveStep, RemoveStepVariables} from '../../../__generated__/RemoveStep';
import {ModalDialogContext, useModalDialog} from '../../../base/components/ModalDialog/ModalDialog';
import {
  CheckSquare,
  Maximize,
  Minimize,
  Paperclip,
  Square,
  Trash2,
  Type,
  AlertTriangle,
  Link2,
  Image,
  Edit,
  Menu,
} from 'react-feather';
import {Tooltip} from '../../../base/components/Tooltip/Tooltip';
import {cache} from '../../../base/modules/apolloClient';
import {FindWorkflow, FindWorkflowVariables} from '../../../__generated__/FindWorkflow';
import {findActivitiesQuery} from '../../../gqls/findActivitiesQuery';
import {colors, fontSizes, fontWeights} from 'theme';
import {tw} from 'twind';
import {PanelProps} from './StepInspectorPanel';
import {clipboard} from '../../../base/modules/clipboard';
import {UserBlock} from '../../common/UserBlock';
import {Indicator} from '../../../base/components/Indicator/Indicator';
import {useWorkspaceId} from '../../../base/hooks/useWorkspaceId';

const dateFormat = require('dateformat');

interface Props {
  workflow: WorkflowFragment;
  step?: StepFragment;
}

export const StepPanelRoute = (props: Props) => {
  const {workflow, step} = props;

  if (!workflow) {
    throw Error('Invalid state');
  }

  if (!step) {
    return <NoSelectedStep />;
  }

  const toggleCollapse = (collapse: boolean | null = null) => {
    cache.writeQuery<FindWorkflow, FindWorkflowVariables>({
      query: findWorkflowQuery,
      variables: {id: workflow.id},
      data: {
        workflow: {
          ...workflow,
          collapse: collapse === null ? !workflow.collapse : collapse,
        },
      },
    });
  };

  return (
    <>
      {step.name && (
        <Helmet>
          <title>
            {workflow.name} / {step.name}
          </title>
        </Helmet>
      )}

      <HeaderMenu>
        {workflow.collapse && (
          <Tooltip text="最小化する" placement="left">
            <SubtleQueitButton onClick={() => toggleCollapse()}>
              <Minimize size={16} strokeWidth={1.5} />
            </SubtleQueitButton>
          </Tooltip>
        )}
        {!workflow.collapse && (
          <Tooltip text="最大化する" placement="left">
            <SubtleQueitButton onClick={() => toggleCollapse()}>
              <Maximize size={16} strokeWidth={1.5} />
            </SubtleQueitButton>
          </Tooltip>
        )}
      </HeaderMenu>

      <div className={tw`flex justify-center`}>
        <div
          className={cx(
            tw`mt-9 mb-12`,
            css`
              max-width: calc(100% - 80px);
              width: 700px;
            `
          )}>
          <StepPanel workflow={workflow} step={step} />
        </div>
      </div>
    </>
  );
};

export const LightStepPanel = (props: PanelProps) => {
  const {workflow, step, preview = false} = props;

  if (!workflow) {
    throw Error('Invalid state');
  }

  if (!step) {
    return <NoSelectedStep />;
  }

  return (
    <div>
      <StepHeaderPanel preview={preview} step={step} workflow={workflow} />
      <Space height="20px" />
      <StepNoteEditor step={step} content={step.description || ''} preview={preview} />
      <AttachedFiles step={step} workflow={workflow} />
    </div>
  );
};

export const StepPanel = LightStepPanel;

export const OldStepPanel = (props: PanelProps) => {
  const {workflow, step, preview = false} = props;

  if (!workflow) {
    throw Error('Invalid state');
  }

  if (!step) {
    return <NoSelectedStep />;
  }

  return (
    <div>
      {!preview && !isPrototype(workflow.status) && <div className={tw`mb-0.5`}>
        <StepStatusLabel status={step.status} />
        <div><Space height="8px" /></div>
      </div>}
      <StepHeaderPanel preview={preview} step={step} workflow={workflow} />

      <Space height="20px" />
      <StepNoteEditor step={step} content={step.description || ''} preview={preview || !workflow.canEdit} />

      <CustomForm step={step} preview={preview} />

      <AttachedFiles step={step} workflow={workflow} />

      {!preview && <BottomPanel workflow={workflow} step={step} />}
    </div>
  );
};


function BottomPanel(props: {workflow: WorkflowFragment; step: StepFragment}) {
  const {step, workflow} = props;

  return (
    <>
      <Hr />
      <ActivitiesPanel stepId={step.id} workflowId={workflow.id} isPrototype={isPrototype(workflow.status)} />
    </>
  );
}

export function isPrototype(status: WorkflowStatus): boolean {
  return [WorkflowStatus.archived, WorkflowStatus.published, WorkflowStatus.draft].includes(status);
}

function StepHeaderPanel({preview = false, step, workflow}: PanelProps) {
  const [state, setState] = useState({
    isEdit: false,
    name: step.name,
  });
  const {name, isEdit} = state;
  const toast = useToast();

  const [update, updateResult] = useAppMutation<UpdateStep, UpdateStepVariables>(updateStepMutation, {
    onError() {
      toast.error('ステップ名の更新に失敗しました');
    },
    onCompleted() {
      toast.message('ステップ名を更新しました');
      setState(prev => ({...prev, isEdit: false}));
    },
    refetchQueries: [
      {
        query: findActivitiesQuery,
        variables: {
          stepId: step.id,
          workflowId: workflow.id,
        },
      },
    ],
  });

  const tryToSave = () => {
    if (!isEdit) {
      return;
    }

    if (name !== step.name) {
      update({
        variables: {
          workflowId: step.workflowId,
          stepId: step.id,
          name,
        },
      });
    } else {
      setState(prev => ({...prev, isEdit: false}));
    }
  };

  const startEdit = React.useCallback(() => {
    setState(prev => ({...prev, isEdit: true}));
  }, []);

  return (
    <HorizontalBox style={{alignItems: 'center'}}>
      {!isEdit && <>
        <h2 className={tw`font-bold text-blackText text-xl overflow-hidden whitespace-wrap flex-shrink-1`}>
          {step.name}
        </h2>
        <SpringSpace />
      </>}
      {isEdit && <>
        <StepNameInput
          value={name}
          onChange={(event) => setState({...state, name: event.currentTarget.value})}
          maxLength={128}
          readOnly={!workflow.canEdit || preview}
          autoFocus
          placeholder="ステップ名"
          onFocus={event => {
            event.currentTarget.select();
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.currentTarget.blur();
              tryToSave();
            }
          }}
        />
        <Space width={"6px"} />
        <DarkButton
          onClick={() => tryToSave()} className={tw`flex-shrink-0 whitespace-nowrap`}
          disabled={updateResult.loading}>
          保存する
        </DarkButton>
        <Space width={"6px"} />
        <QueitButton
          onClick={() => {
            setState(prev => ({...prev, isEdit: false}));
          }} className={tw`flex-shrink-0 whitespace-nowrap`} disabled={updateResult.loading}>
          キャンセル
        </QueitButton>
      </>}
      {!preview && !isEdit && (
        <>
          <Space width="8px" />
          <StepMenu2 step={step} workflow={workflow} startStepNameEdit={startEdit} />
        </>
      )}
    </HorizontalBox>
  );
}

export const AttachedFile = styled(
  (props: {className?: string; step: StepFragment; workflow: WorkflowFragment; file: StepFragment_files}) => {
    const {step, workflow, file, className} = props;
    const toast = useToast();
    const [detachFile, {loading}] = useAppMutation<DetachFileFromStep, DetachFileFromStepVariables>(
      detachFileFromStepMutation,
      {
        onCompleted() {
          toast.message('添付ファイルを削除しました');
        },
        onError() {
          toast.error('添付ファイルの削除に失敗しました');
        },
      }
    );

    const modal = useModalDialog();
    const confirmToRemoveFile = async () => {
      const yes = await modal.confirm({
        message: '添付ファイルを削除しますか?',
        title: '確認',
        icon: <AlertTriangle />,
      });

      if (yes) {
        await detachFile({
          variables: {
            workflowId: workflow.id,
            stepId: step.id,
            fileId: file.id,
          },
        });
      }
    };

    const [ref, isHover] = useHover<HTMLDivElement>();

    const menu = useOptionsMenu({
      button: (
        <SmallQuietButton style={{paddingLeft: '6px', paddingRight: '6px'}}>
          <Menu size={16} strokeWidth={"1.5px"} />
        </SmallQuietButton>
      ),
      menu: close => <div style={{minWidth: '220px'}}>
        <MenuItem
          icon={<Trash2 size={16} strokeWidth={1.5} />}
          onClick={() => {
            confirmToRemoveFile();
            close();
          }}
        >
          削除する...
        </MenuItem>
        <MenuSeparator />
        <div className={tw`m-2`}>
          <span className={tw`text-lightText text-sm font-semiBold`}>添付した人</span>
          <div className={tw`my-1`}>
            <UserBlock user={file.user} variant={'simple'} iconSize={24} />
          </div>

          <div className={tw`flex items-center my-2`}>
            <span className={tw`text-lightText text-sm font-semiBold`}>添付日時</span>
            <SpringSpace minWidth="8px" />
            <span className={tw`font-bold text-sm text-lightText leading-none`}>
                <Tooltip text={dateFormat(file.createdAt, 'yyyy年m月d日 HH:MM')}>
                  <span>{dateFormat(file.createdAt, 'm月d日 HH:MM')}</span>
                </Tooltip>
              </span>
          </div>
        </div>
      </div>
    });

    return (
      <div className={className} ref={ref}>
        {['image/bmp', 'image/gif', 'image/jpeg', 'image/svg+xml', 'image/png', 'image/webp'].includes(file.mimeType)
          ? <Image size={16} color={colors.lightText} strokeWidth={1.5} className={tw`flex-shrink-0 mr-2`} />
          : <Paperclip strokeWidth={1.5} size={16} color={colors.lightText} className={tw`flex-shrink-0 mr-2`} />}
        <a
          href={file.url}
          target="_blank"
          rel="noopener noreferrer"
          className={tw`
            font-semiBold
            text-blackText
            text-base
            link:(text-blackText)
            visited:(text-blackText)
            hover:(text-darkAccent underline)
            flex-shrink-1 overflow-hidden overflow-ellipsis whitespace-nowrap
          `}>
          {file.fileName}
        </a>
        <Space width="8px" />
        <span className={tw`text-base text-lightText font-normal whitespace-nowrap`}>
          {fileSize(file.fileSize, {spacer: ''}).human('si')}
        </span>
        <SpringSpace minWidth="8px" />
        {loading
          ? <Indicator size={24} />
          : <div style={{visibility: isHover || menu.isOpen ? 'visible' : 'hidden'}}>{menu.node}</div>}
      </div>
    );
  }
)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  border: 1px solid ${colors.border};
  border-top-width: 0;
  margin: 0;
  flex-shrink: 1;
  
  &:first-of-type {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-width: 1px;
  }
  
  &:last-of-type {
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  &:last-of-type:first-of-type {
    border-radius: 5px;
  }
`;

const useAttachFiles = (step: StepFragment) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachFile, attachFilesResult] = useAppMutation<AttachFileToStep, AttachFileToStepVariables>(
    attachFileToStepMutation
  );
  const toast = useContext(ToastContext);

  const attachFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChange = () => {
    const input = fileInputRef.current;
    if (input && input.files && input.files.length > 0) {
      const [file] = input.files;
      input.value = '';

      if (file.size > 1024 * 1024 * 5) {
        toast.message('アップロードできるファイルは5MiBまでです');
        return;
      }

      upload(file);
    }
  };

  const upload = async (file: File) => {
    const variables = {
      workflowId: step.workflowId,
      stepId: step.id,
      file,
    };

    try {
      await attachFile({
        variables,
      });
    } catch (e) {
      toast.error('ファイルの添付に失敗しました');
    } finally {
      toast.message('ファイルが添付されました');
    }
  };

  const node = (
    <>
      <input type="file" onChange={onChange} ref={fileInputRef} style={{display: 'none'}} />
    </>
  );

  return {attachFiles, node, sending: attachFilesResult.loading};
};

export const AttachedFiles = (props: {step: StepFragment; workflow: WorkflowFragment}) => {
  const {step, workflow} = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachFile, {loading: sending}] = useAppMutation<AttachFileToStep, AttachFileToStepVariables>(attachFileToStepMutation);
  const toast = useContext(ToastContext);

  const onChange = () => {
    const input = fileInputRef.current;
    if (input && input.files && input.files.length > 0) {
      upload(input.files[0]);
      input.value = '';
    }
  };

  const upload = async (file: File) => {
    const variables = {
      workflowId: workflow.id,
      stepId: step.id,
      file,
    };

    try {
      await attachFile({
        variables,
      });
    } catch (e) {
      toast.error('ファイルの添付に失敗しました');
    } finally {
      toast.message('ファイルが添付されました');
    }
  };

  if (step.files.length === 0) {
    return null;
  }

  return (
    <div className={tw`mt-8`}>
      {sending && <Loading />}

      <div className={tw`font-bold text-sm text-lightText mb-2 leading-none`}>
        添付ファイル({step.files.length})
      </div>

      <div>
        {step.files.map((file) => (
          <AttachedFile key={file.id} step={step} workflow={workflow} file={file} />
        ))}
      </div>

      <input type="file" onChange={onChange} ref={fileInputRef} style={{display: 'none'}} />
    </div>
  );
};

interface StepStatusLabelProps {
  className?: string;
  status: StepStatus;
}

const StepStatusLabel = styled((props: StepStatusLabelProps) => {
  const {className, status} = props;
  const dict = {
    [StepStatus.open]: {
      text: '未処理',
      borderColor: 'hsla(0 0% 80%)',
      color: 'hsla(0 0% 40%)',
      backgroundColor: '',
    },
    [StepStatus.close]: {
      text: '完了',
      borderColor: 'rgb(114 197 144)',
      color: '#16A34A',
      backgroundColor: '',
    },
  };
  const {text, color, borderColor, backgroundColor} = dict[status];

  return (
    <span className={className} style={{color, borderColor, backgroundColor}}>
      {text}
    </span>
  );
})`
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeights.bold};
  line-height: 1;
  padding: 4px 4px 3px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Hr = styled.hr`
  border: none;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.border};
  margin: 20px 0;
`;

function useRemoveStep(step: StepFragment) {
  const toast = useToast();
  const [mutate, result] = useAppMutation<RemoveStep, RemoveStepVariables>(removeStepMutation, {
    onError() {
      toast.error('ステップの削除に失敗しました');
    },
    onCompleted() {
      toast.message('ステップを削除しました');
    },
  });
  const modalDialog = React.useContext(ModalDialogContext);

  const confirmToRemove = async () => {
    const yes = await modalDialog.confirm({
      title: '確認',
      message: 'ステップを削除しますか?',
    });

    if (yes) {
      mutate({
        variables: {
          stepId: step.id,
          workflowId: step.workflowId,
        },
      });
    }
  };

  return {
    loading: result.loading,
    confirmToRemove,
  };
}

function useFormEditor(step: StepFragment) {
  const [editing, setEditing] = React.useState(false);

  const node = editing ? <CustomFormEditorContainer step={step} terminate={() => setEditing(false)} /> : null;

  return {node, setEditing};
}

interface StepMenuProps {
  workflow: WorkflowFragment;
  step: StepFragment;
  className?: string;
  startStepNameEdit: () => void;
}

const HeaderMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  display: flex;
  justify-content: flex-end;
`;

const StepMenu2 = styled((props: StepMenuProps) => {
  const {step, workflow, startStepNameEdit} = props;
  const toast = useToast();

  const formEditor = useFormEditor(step);

  const [changeStepStatus, changeStepStatusResult] = useAppMutation<ChangeStepStatus, ChangeStepStatusVariables>(
    changeStepStatusMutation,
    {
      onCompleted: () => {
        if (step.status === StepStatus.close) {
          toast.message('ステップを完了にしました');
        } else {
          toast.message('ステップを未処理にしました');
        }
      },
    }
  );

  const {attachFiles, sending, node} = useAttachFiles(step);

  const toggleStepStatus = () => {
    changeStepStatus({
      variables: {
        stepId: step.id,
        workflowId: step.workflowId,
        status: step.status === StepStatus.open ? StepStatus.close : StepStatus.open,
      },
    });
  };
  const workspaceId = useWorkspaceId();

  const removeStep = useRemoveStep(step);

  const menu = useOptionsMenu({
    button: (
      <QueitButton>
        <Menu size={16} strokeWidth={"1.5px"} />
      </QueitButton>
    ),
    menu: (close) => {
      return (
        <>
          <MenuItem
            icon={<Edit size={16} />}
            onClick={() => {
              startStepNameEdit();
            }}>
            ステップ名を編集
          </MenuItem>
          <MenuSeparator />
          {clipboard.isAvailable() && (
            <>
              <MenuItem
                icon={<Link2 size={16} />}
                onClick={() => {
                  clipboard.writeText(
                    `${window.location.protocol}//${window.location.host}/workspaces/${workspaceId}/workflows/${workflow.id}/steps/${step.id}`
                  );
                  close();
                  toast.message('URLをコピーしました');
                  close();
                }}
                description={'ステップのURLを取得する'}>
                URLをコピー
              </MenuItem>
              <MenuSeparator />
            </>
          )}

          {!isPrototype(workflow.status) &&
            (step.status === StepStatus.open ? (
              <MenuItem
                icon={<CheckSquare size={16} />}
                onClick={() => {
                  close();
                  toggleStepStatus();
                }}
                description={'ステップを完了状態にする'}
                disabled={changeStepStatusResult.loading || !workflow.canEdit}>
                完了する
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  close();
                  toggleStepStatus();
                }}
                description={'ステップを未処理の状態にする'}
                icon={<Square size={16} />}
                disabled={changeStepStatusResult.loading || !workflow.canEdit}>
                未処理に戻す
              </MenuItem>
            ))}

          <MenuItem
            icon={<Paperclip size={16} />}
            onClick={() => {
              close();
              attachFiles();
            }}
            description={'アップロードできるのは5MiBまで'}
            disabled={sending || !workflow.canEdit}>
            ファイルを添付
          </MenuItem>

          {typeof step.form !== 'string' && (
            <MenuItem
              icon={<Type size={16} />}
              disabled={!workflow.canEdit}
              onClick={() => {
                close();
                formEditor.setEditing(true);
              }}>
              フォームを追加
            </MenuItem>
          )}

          <MenuSeparator />

          <MenuItem
            icon={<Trash2 size={16} />}
            onClick={() => {
              close();
              removeStep.confirmToRemove();
            }}
            disabled={removeStep.loading || !workflow.canEdit}>
            ステップを削除…
          </MenuItem>

          <MenuSeparator />

          <div className={tw`my-2 mx-2`}>
            <div className={tw`flex items-center my-2`}>
              <span className={tw`text-lightText text-sm font-semiBold`}>作成日時</span>
              <SpringSpace />
              <span className={tw`font-bold text-sm text-lightText leading-none`}>
                <Tooltip text={dateFormat(step.createdAt, 'yyyy年m月d日 HH:MM')}>
                  <span>{dateFormat(step.createdAt, 'm月d日 HH:MM')}</span>
                </Tooltip>
              </span>
            </div>

            <div className={tw`flex items-center my-2`}>
              <span className={tw`text-lightText text-sm font-semiBold`}>更新日時</span>
              <SpringSpace />
              <span className={tw`font-bold text-sm text-lightText leading-none`}>
                <Tooltip text={dateFormat(step.updatedAt, 'yyyy年m月d日 HH:MM')}>
                  <span>{dateFormat(step.updatedAt, 'm月d日 HH:MM')}</span>
                </Tooltip>
              </span>
            </div>
          </div>
        </>
      );
    },
  });

  return (
    <>
      {formEditor.node}
      {menu.node}
      {node}
    </>
  );
})``;
