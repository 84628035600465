import 'raf/polyfill';
import {ReactNode, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ToastProvider} from './base/components/Toast/Toast';
import {ModalDialogProvider} from './base/components/ModalDialog/ModalDialog';
import {TooltipProvider} from './base/components/Tooltip/Tooltip';
import {SignupRoute} from './app/public/SignUpRoute';
import {LoginRoute} from './app/public/LoginRoute/LoginRoute';
import {ForgotPasswordRoute} from './app/public/ForgotPasswordRoute';
import {LogoutRoute} from './app/public/LogoutRoute';
import {TermsOfUseRoute} from './app/public/TermsOfUseRoute';
import {NotFoundRoute} from './app/NotFoundRoute';
import {ResetPasswordRoute} from './app/public/ResetPasswordRoute/ResetPasswordRoute';
import {NewUserRoute} from './app/public/NewUserRoute/NewUserRoute';
import {ApolloProvider} from '@apollo/client';
import {client} from './base/modules/apolloClient';
import './globalStyle';
import {WorkspaceRoute} from './app/workspace/WorkspaceRoute/WorkspaceRoute';
import {HelmetProvider} from 'react-helmet-async';
import {RootRoute} from './app/RootRoute';
import {InvitationRoute} from './app/public/InvitationRoute/InvitationRoute';

export const App = () => {
  return (
    <Wrapper>
      <Route path="/login" component={LoginRoute} />
      <Route path="/signup" exact component={SignupRoute} />
      <Route path="/terms_of_use" exact component={TermsOfUseRoute} />
      <Route path="/new" component={NewUserRoute} />
      <Route path="/invitation" component={InvitationRoute} />
      <Route path="/forgot_password" exact component={ForgotPasswordRoute} />
      <Route path="/reset_password" exact component={ResetPasswordRoute} />
      <Route path="/logout" exact component={LogoutRoute} />
      <Route path="/workspaces/:workspaceId" component={WorkspaceRoute} />
      <Route path="/" exact component={RootRoute} />
      <Route component={NotFoundRoute} />
    </Wrapper>
  );
};

const smallChatScript = 'https://embed.small.chat/T01K8E7T5R8C02GDP7ULKT.js';

function Wrapper({children}: {children: ReactNode}) {

  useEffect(() => {
    const element = window.document.createElement('script');
    element.setAttribute('async', '');
    element.setAttribute('src', smallChatScript);
    window.document.body.appendChild(element);

    return () => {
      element.remove();
    };
  }, []);

  return (
    <HelmetProvider>
      <ModalDialogProvider>
        <ToastProvider>
          <TooltipProvider>
            <ApolloProvider client={client}>
              <Router>
                <Switch>{children}</Switch>
              </Router>
            </ApolloProvider>
          </TooltipProvider>
        </ToastProvider>
      </ModalDialogProvider>
    </HelmetProvider>
  );
}
