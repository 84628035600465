import {ReactNode} from 'react';
import {DialogContent, DialogTitle, Footer, ModalDialog} from '../Dialog/ModalDialog';
import {LargeDarkButton} from '../Button/Button';
import {Info} from 'react-feather';
import {HorizontalBox} from '../HorizontalBox/HorizontalBox';

interface AlertDialogProps {
  title?: string;
  message: ReactNode;
  close: () => void;
  icon?: ReactNode;
}

export function AlertDialog({title, message, close, icon = <Info strokeWidth={1.5} />}: AlertDialogProps) {
  return (
    <ModalDialog>
      <DialogTitle>
        <HorizontalBox>
          {icon}
          &nbsp;
          {title || ''}
        </HorizontalBox>
      </DialogTitle>

      <DialogContent>{message}</DialogContent>

      <Footer>
        <LargeDarkButton onClick={close}>OK</LargeDarkButton>
      </Footer>
    </ModalDialog>
  );
}
