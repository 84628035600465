import * as React from 'react';
import {Component} from 'react';
import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {ErrorBlock} from '../../base/components/ErrorBlock/ErrorBlock';
import {colors, fontSizes, fontWeights} from 'theme';

const bodyStyle = css`
  background-color: #f5f5f7;
  background-image: url(/images/bg.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
`;

export const pageStyle = css`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
`;

export const pageContentStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export class Page extends Component<{children: React.ReactNode}> {
  componentDidMount() {
    window.document.body.classList.add(bodyStyle);
  }

  componentWillUnmount() {
    window.document.body.classList.remove(bodyStyle);
  }

  render() {
    return (
      <div className={pageStyle}>
        <div className={pageContentStyle}>{this.props.children}</div>
      </div>
    );
  }
}

export const ContentBox = styled.div`
  width: 400px;
  border-radius: 12px;
  background-color: white;
  box-sizing: border-box;
  padding: 48px 42px 48px 42px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04), 0 2px 3px 0 rgba(0, 0, 0, 0.06);
  margin: 40px 0;
`;

export const ContentHeaderText = styled.h3`
  color: ${colors.blackText};
  font-size: 20px;
  font-weight: ${fontWeights.bold};
  margin: 0 0 24px 0;
`;

export const P = styled.p`
  line-height: 1.8;
`;

export const A = styled.a`
  &,
  &:link,
  &:visited {
    color: ${colors.darkAccent};
    font-weight: ${fontWeights.semiBold};
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const FieldLabel = styled.div`
  font-weight: ${fontWeights.normal};
  color: ${colors.blackText};
  margin: 18px 0 8px 0;
  font-size: ${fontSizes.base};
  line-height: 1;
`;

export const SmallFieldLabel = styled.div`
  font-weight: ${fontWeights.normal};
  color: ${colors.darkText};
  font-size: ${fontSizes.sm};
  margin: 12px 0 8px 0;
  line-height: 1;
`;

export const Errors = ErrorBlock;

export const ErrorItem = styled.div``;
