import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

export const Badge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${colors.superLightText};
  font-size: ${fontSizes.xs};
  font-weight: ${fontWeights.normal};
  padding: 0 6px;
  height: 16px;
  line-height: 16px;
`;

export const OldBadge = styled.div`
  background-color: ${colors.darkBackground};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${colors.lightText};
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeights.bold};
  padding: 0 6px;
  height: 16px;
  line-height: 16px;
  border-radius: 17px;
`;
