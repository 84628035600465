import styled from '@emotion/styled';
import {colors, fontSizes} from 'theme';

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 16px auto 16px auto;
  box-sizing: border-box;
`;

export const Content = styled.div`
  flex-grow: 1;
  font-size: ${fontSizes.base};
  color: ${colors.lightText};
  line-height: 1.6;
`;

export const RelativeDate = styled.div`
  font-size: ${fontSizes.sm};
  color: ${colors.lightText};
  display: inline;
  white-space: nowrap;
`;
