import {useParams} from 'react-router-dom';

export function useWorkspaceId(): string {
  const {workspaceId} = useParams<{workspaceId?: string}>();

  if (typeof workspaceId !== 'string') {
    throw Error();
  }

  return workspaceId;
}
