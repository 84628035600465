import {ReactNode, useState} from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import {colors, fontWeights, fontSizes} from 'theme';
import {usePopper} from 'react-popper';

const TIME_TO_SHOW = 100;
const bg = colors.blackText;

const TooltipBase = styled.div`
  display: block;
  background-color: ${bg};
  position: fixed;
  border-radius: 5px;
  padding: 6px;
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeights.semiBold};
  color: white;
  z-index: 900;
  line-height: 1.1;
  letter-spacing: 0.09em;
  pointer-events: none;
  width: fit-content;
`;

// derived from react-popper typing
export type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

type Context = {
  fireMouseEnter(params: {target: HTMLElement; text: ReactNode; placement: Placement}): void;
  fireMouseLeave(): void;
};

export const TooltipContext = React.createContext<Context>({
  fireMouseEnter() {},
  fireMouseLeave() {},
});
export const {Provider, Consumer} = TooltipContext;

type State = {
  show: boolean;
  tooltipContent: ReactNode;
  placement: Placement;
  referenceElement?: HTMLElement;
};

interface Props {
  children: ReactNode;
}

export function TooltipProvider(props: Props) {
  const [state, setState] = useState<State>({
    show: false,
    tooltipContent: '',
    placement: 'auto',
  });

  const [popper, setPopper] = useState<HTMLElement | null>(null);

  const timeoutId = React.useRef<any>(null);

  const contextValue = React.useMemo<Context>(
    () => ({
      fireMouseEnter: ({target, text, placement}) => {
        timeoutId.current = setTimeout(() => {
          setState({
            show: true,
            tooltipContent: text,
            placement,
            referenceElement: target
          });
        }, TIME_TO_SHOW);
      },
      fireMouseLeave: () => {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }

        setState({
          show: false,
          tooltipContent: '',
          placement: 'auto',
          referenceElement: undefined
        });
      },
    }),
    []
  );

  const {placement, show, tooltipContent} = state;

  const body = React.useMemo(() => <Provider value={contextValue}>{props.children}</Provider>, [
    contextValue,
    props.children,
  ]);

  const { styles, attributes } = usePopper(state.referenceElement, popper, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });

  return (
    <>
      {show && <TooltipBase ref={setPopper} style={styles.popper}  {...attributes.popper}>
        {tooltipContent}
      </TooltipBase>}

      {body}
    </>
  );
}

type TooltipProps = {
  text: React.ReactNode;
  children: React.ReactElement;
  placement?: Placement;
};

export function Tooltip({text, children, placement = 'auto'}: TooltipProps) {
  const tooltip = React.useContext(TooltipContext);

  const onMouseEnter = React.useCallback(
    (event) => {
      tooltip.fireMouseEnter({
        target: event.currentTarget,
        text,
        placement,
      });
    },
    [tooltip, text, placement]
  );

  const onMouseLeave = React.useCallback(
    () => {
      tooltip.fireMouseLeave();
    },
    [tooltip]
  );

  React.useEffect(() => {
    return () => tooltip.fireMouseLeave();
  }, [tooltip]);

  return React.cloneElement(children, {onMouseEnter, onMouseLeave});
}
