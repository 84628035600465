import gql from 'graphql-tag';

export const confirmPasswordResetTokenMutation = gql`
  mutation ConfirmPasswordResetToken($token: String!) {
    confirmPasswordResetToken(token: $token)
  }
`;

export const resetPasswordMutation = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

export const requestPasswordResetMutation = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordResetMail(email: $email)
  }
`;
