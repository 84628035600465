import * as React from 'react';
import {selectedStepBalloonStyle, stepBalloonContentBlockStyle, stepBalloonNameStyle, stepBalloonStyle} from './styles';
import {ReactComponent as BalloonHandleIcon} from '../../../base/components/images/balloon-handle.svg';
import {css, cx} from '@emotion/css';
import {TextPlaceholder} from '../../../base/components/LoadingPlaceholder';
export {AddStepForm} from './AddStepForm';
export * from './styles';

type Props = {
  selected?: boolean;
  children: React.ReactNode;
};

export const StepBalloon = (props: Props) => {
  const {children, selected = false} = props;

  const balloonStyle = cx({
    [stepBalloonStyle]: true,
    [stepBalloonNameStyle]: true,
    [selectedStepBalloonStyle]: selected,
  });

  return <div className={balloonStyle}>{children}</div>;
};

export const StepBalloonBlock = ({children}: {children: React.ReactNode}) => {
  return <div className={stepBalloonContentBlockStyle}>{children}</div>;
};

const handleStyle = css`
  padding: 4px 6px 0 2px;
  margin: 0;
  cursor: move;
`;

export const DragHandle = () => {
  return (
    <div className={handleStyle}>
      <BalloonHandleIcon />
    </div>
  );
};

export const AssigneesBlock = ({children}: {children: React.ReactNode}) => {
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: -2px 0 6px 0;
      `}>
      {children}
    </div>
  );
};

export const StepBalloonPreview = ({children}: {children: React.ReactNode}) => {
  return (
    <div className={cx(stepBalloonStyle)}>
      <TextPlaceholder>{children}</TextPlaceholder>
    </div>
  );
};
