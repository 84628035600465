import * as React from 'react';
import styled from '@emotion/styled/macro';
import {ReactComponent as Checkmark} from './check.svg';
import {colors, fontSizes, fontWeights} from 'theme';
import {Check} from 'react-feather';
import {tw} from 'twind';

export const MenuButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Popup = styled.div`
  display: block;
  background-color: white;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05), 0 0 3px 0 rgba(0, 0, 0, 0.05), 0 2px 7px 0 rgba(0, 0, 0, 0.08),
    0 4px 13px 0 rgba(0, 0, 0, 0.08);
  text-align: left;
  border: none;
  background-clip: padding-box;
  transition: opacity 0.1s ease;
  width: max-content;
  max-width: 340px;
`;

export const MenuSeparator = styled.hr`
  height: 1px;
  border: none;
  background-color: ${colors.lightBorder};
  margin: 6px 0;
  padding: 0;
`;

type MenuItemProps = JSX.IntrinsicElements['button'] & {
  disabled?: boolean;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  selected?: boolean;
  checked?: boolean;
};

export function MenuItem(props: MenuItemProps) {
  const {children, icon, description, checked = null, ...rest} = props;

  return (
    <Button {...rest}>
      <MenuItemTop>
        <MenuItemIcon>{icon}</MenuItemIcon>
        <div className={tw`flex items-center`}>
          <div className={tw`flex-grow-1 flex-shrink-1`}>{children}</div>
          {typeof checked === 'boolean' && checked && <Check size={16} className={tw`flex-shrink-0`} />}
        </div>
      </MenuItemTop>
      {description && <MenuItemDescription>{description}</MenuItemDescription>}
    </Button>
  );
}

export const CheckmarkIcon = Checkmark;

export const MenuHeader = styled.div`
  text-align: left;
  display: block;
  font-size: ${fontSizes.xs};
  color: ${colors.superLightText};
  padding: 0 16px 0 2px;
  text-decoration: none;
  line-height: 20px;
  height: 20px;
  font-weight: ${fontWeights.bold};
  box-sizing: border-box;
  user-select: none;
  white-space: nowrap;
`;

const MenuItemTop = styled.div`
  text-align: left;
  display: block;
  font-size: ${fontSizes.base};
  padding: 0 16px 0 30px;
  text-decoration: none;
  line-height: 34px;
  height: 34px;
  font-weight: ${fontWeights.normal};
  box-sizing: border-box;
  user-select: none;
  white-space: nowrap;
  color: ${colors.blackText};
  
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: initial;
`;

export const MenuItemIcon = styled.div`
  position: absolute;
  left: 10px;
  width: 20px;
  height: 32px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.darkText};
  
  svg {
    stroke-width: 1.5px;
  }
`;

const MenuItemDescription = styled.div`
  padding: 0 8px 8px 30px;
  line-height: 1.4;
  text-decoration: none;
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.sm};
  user-select: none;
  margin: -4px 0 0 0;
  color: ${colors.lightText};
`;

const Button = styled.button<{selected?: boolean}>`
  cursor: pointer;
  text-align: left;
  display: block;
  padding: 0;
  border-radius: 5px;
  width: 100%;
  color: ${colors.darkText};

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover {
    background-color: ${colors.background};
    color: ${colors.blackText};
  }
  
  &:hover ${MenuItemTop},
  &:hover ${MenuItemIcon} {
    color: ${colors.blackText};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
  
  ${({selected}) => selected && `
    background-color: ${colors.background};
    color: ${colors.blackText};
    
    ${MenuItemTop}, ${MenuItemIcon} {
      color: ${colors.blackText};
    }
  `}
`;

