import {Page} from './public/Public';
import {Helmet} from 'react-helmet-async';
import {NotFound as NotFoundComponent} from '../base/components/NotFound/NotFound';

export function NotFoundRoute() {
  return (
    <Page>
      <Helmet>
        <title>ページが見つかりませんでした</title>
      </Helmet>

      <NotFoundComponent title={'ページが見つかりませんでした'} />
    </Page>
  );
}
