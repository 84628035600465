import {useState, SyntheticEvent, useContext, useRef, useCallback} from 'react';
import {css} from '@emotion/css';
import {TextInput} from '../../../../base/components/Form/Form';
import {ErrorBlock} from '../../../../base/components/ErrorBlock/ErrorBlock';
import {SmallCheckbox} from '../../../../base/components/Checkbox/Checkbox';
import {validateName} from '../../../../base/validators/User';
import {ToastContext, useToast} from '../../../../base/components/Toast/Toast';
import {changeProfileImageMutation, changeProfileSettingsMutation} from '../../../../gqls/accountMutations';
import {fetchSelfQuery} from '../../../../gqls/selfQueries';
import styled from '@emotion/styled';
import {Tooltip} from '../../../../base/components/Tooltip/Tooltip';
import {ChangeProfileSettings, ChangeProfileSettingsVariables} from '../../../../__generated__/ChangeProfileSettings';
import {ChangepProfileImage, ChangepProfileImageVariables} from '../../../../__generated__/ChangepProfileImage';
import {FetchSelf} from '../../../../__generated__/FetchSelf';
import {useAppQuery} from '../../../../base/hooks/useAppQuery';
import {useAppMutation} from '../../../../base/hooks/useAppMutation';
import {HorizontalBox, Space, SpringSpace} from '../../../../base/components/HorizontalBox/HorizontalBox';
import {tw} from 'twind';
import {Key, AlertTriangle, ChevronRight, Edit} from 'react-feather';
import {MinimumButton, DarkButton, Button} from '../../../../base/components/Button/Button';
import * as React from 'react';
import {UserIconContainer} from '../../../common/UserIconContainer';
import {useHistory, useParams} from 'react-router-dom';
import {Header, HeaderBlock, SubHeader, SubHeaderBlock, FieldLabel} from '../styles';

export const UserSettingsRoute = () => {
  const {workspaceId} = useParams<{workspaceId: string}>();
  const [changeProfileImage, {loading: uploading}] = useAppMutation<ChangepProfileImage, ChangepProfileImageVariables>(
    changeProfileImageMutation,
    {
      onError() {
        // do nothing
      },
    }
  );
  const {data: fetchSelf, loading} = useAppQuery<FetchSelf>(fetchSelfQuery, {
    onError() {
      // do nothing
    },
  });

  const toast = useContext(ToastContext);
  const history = useHistory();
  const [state, setState] = useState<{imageErrors: string[]}>({
    imageErrors: [],
  });

  const upload = async (e: SyntheticEvent<HTMLInputElement>) => {
    const input = e.currentTarget;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];
    input.value = ''; // onChangeを毎回ファイル選ぶたびに起動するために、毎回ファイルの登録を消してやる

    if (!file) {
      return;
    }

    if (file.size > 1024 * 1024 * 5) {
      return setState({
        ...state,
        imageErrors: ['画像のファイルの大きさは5メガバイト以下にしてください。'],
      });
    }

    setState({...state, imageErrors: []});

    try {
      await changeProfileImage({
        variables: {
          image: file,
        },
        refetchQueries: [{query: fetchSelfQuery}],
        awaitRefetchQueries: true,
      });

      toast.message('プロフィール画像が更新されました');

      setState({
        ...state,
        imageErrors: [],
      });
    } catch (error) {
      setState({
        ...state,
        imageErrors: ['プロフィール画像の更新に失敗しました'],
      });
    }
  };

  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <div>
        <h1 className={tw`font-semiBold text-xl mb-6`}>アカウントと設定</h1>

        <HeaderBlock>
          <Header>プロフィール</Header>
        </HeaderBlock>

        <div className={tw`rounded border px-3 py-3`}>
          <NameSetting />

          <hr className={tw`bg-border my-3 -mx-3`}  />

          <FieldLabel>メールアドレス</FieldLabel>
          <div className={tw`font-bold text-black text-base`}>{fetchSelf?.self.email || ''}</div>

          <hr className={tw`bg-border my-3 -mx-3`}  />

          <FieldLabel>プロフィール画像</FieldLabel>
          <HorizontalBox className={tw`mt-1`}>
            <div className={tw`mr-2`}>
              <input
                ref={ref}
                accept=".png,.gif,.jpg,.jpeg, image/png, image/gif, image/jpg"
                type="file"
                style={{display: 'none'}}
                disabled={uploading || loading}
                onChange={upload}
              />

              <UploadButton onClick={() => ref.current!.click()}>
                <Tooltip text="プロフィール画像を変更する">
              <span className={profileImageWrapperStyle} style={{opacity: uploading || loading ? 0.4 : 1}}>
                <UserIconContainer user={fetchSelf?.self} size={80} />
              </span>
                </Tooltip>
              </UploadButton>
            </div>

            <div className={tw`flex justify-center`}>
              <Tooltip text="プロフィール画像を変更">
                <button
                  className={tw`text-xs font-semiBold text-darkText py-0.5 px-1 hover:bg-background rounded`}
                  onClick={() => ref.current!.click()}>
                  変更
                </button>
              </Tooltip>
            </div>

          </HorizontalBox>
        </div>

        <ErrorBlock>
          {state.imageErrors.map((error, i) => (
            <div key={i + ':' + error}>{error}</div>
          ))}
        </ErrorBlock>

        <SubHeaderBlock>
          <SubHeader>お知らせ</SubHeader>
        </SubHeaderBlock>

        <ReceiveNewsSection />

        <SubHeaderBlock>
          <SubHeader>アカウントの管理</SubHeader>
        </SubHeaderBlock>

        <div>
          <button
            className={tw`w-full flex items-center border rounded-t-md p-3 hover:bg-lightBackground active:bg-background focus:outline-none focus-visible:(ring-2 ring-darkAccent)`}
            onClick={() => history.push(`/workspaces/${workspaceId}/settings/personal/password`)}>
            <Key size={16} strokeWidth={1.5} className={tw`mr-2 text-darkText`} />
            <div className={tw`text-blackText font-normal`}>パスワードを変更する</div>
            <SpringSpace />
            <ChevronRight size={16} className={tw`text-superLightText`} />
          </button>

          <button
            className={tw`w-full flex items-center border border-t-0 rounded-b-md p-3 hover:bg-lightBackground active:bg-background focus:outline-none focus-visible:(ring-2 ring-darkAccent)`}
            onClick={() => history.push(`/workspaces/${workspaceId}/settings/personal/close`)}>
            <AlertTriangle size={16} strokeWidth={1.5} className={tw`mr-2 text-darkText`} />
            <div className={tw`text-blackText font-normal`}>アカウントを閉鎖する</div>
            <SpringSpace />
            <ChevronRight size={16} className={tw`text-superLightText`} />
          </button>
        </div>
      </div>
    </>
  );
};

const profileImageWrapperStyle = css`
  position: relative;
  display: block;
  height: 80px;
  width: 80px;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const UploadButton = styled.label`
  cursor: pointer;
  display: block;
`;

function NameSetting() {
  const [state, setState] = useState<{mode: 'view' | 'edit'; name: string; errors: string[]}>({
    mode: 'view',
    name: '',
    errors: [],
  });

  const {data: fetchSelf} = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-first',
    onError() {
      // do nothing
    },
    onCompleted(data) {
      const {name} = data.self;
      setState({...state, name});
    },
  });

  const [updateProfileSettings, updateProfileSettingsResult] = useAppMutation<
    ChangeProfileSettings,
    ChangeProfileSettingsVariables
  >(changeProfileSettingsMutation, {});

  const toast = useToast();

  const updateName = useCallback(
    async (name: string) => {
      await updateProfileSettings({
        variables: {
          settings: {
            name,
          },
        },
      });

      setState(prev => ({...prev, mode: 'view'}));

      toast.message('変更が保存されました');
    },
    [updateProfileSettings, toast, setState]
  );

  const tryToSaveName = async () => {
    const {name} = state;

    const errors = validateName(name);
    if (errors.length > 0) {
      return setState({...state, errors});
    } else {
      setState({...state, errors: []});
    }

    await updateName(name);
  };

  return (
    <div className={tw`flex-grow-1`}>
      <FieldLabel>名前</FieldLabel>
      {state.mode === 'view' && <>
        <div className={tw`flex items-center`}>
          <span className={tw`text-base font-bold text-blackText`}>{fetchSelf?.self.name}</span>
          <Space width="6px" />
          <Tooltip text={"名前を変更する"}>
            <MinimumButton onClick={() => setState(prev => ({...prev, mode: 'edit'}))}>
              <Edit size={16} strokeWidth="1.5px" />
            </MinimumButton>
          </Tooltip>
        </div>
      </>}
      {state.mode === 'edit' && <>
        <HorizontalBox alignItems={"stretch"}>
          <TextInput
            type="text"
            value={state.name}
            autoFocus
            style={{width: '200px'}}
            disabled={updateProfileSettingsResult.loading}
            onChange={(e) => setState({...state, name: e.target.value})}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
                tryToSaveName();
              }
            }}
          />
          <Space width={"6px"} />
          <DarkButton onClick={() => tryToSaveName()} disabled={updateProfileSettingsResult.loading} style={{height: "32px"}}>保存</DarkButton>
          <Space width={'6px'} />
          <Button onClick={() => setState(prev => ({...prev, mode: 'view'}))} disabled={updateProfileSettingsResult.loading} style={{height: '32px'}}>
            キャンセル
          </Button>
        </HorizontalBox>
      </>}

      <ErrorBlock>
        {state.errors.map((error, i) => (
          <div key={i + ':' + error}>{error}</div>
        ))}
      </ErrorBlock>
    </div>
  );
}

function ReceiveNewsSection() {
  const {data: fetchSelf, loading} = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'cache-first',
    onError() {
      // do nothing
    },
  });

  const [updateProfileSettings, {loading: sending}] = useAppMutation<
    ChangeProfileSettings,
    ChangeProfileSettingsVariables
  >(changeProfileSettingsMutation, {});

  const toast = useToast();

  const toggleReceiveNews = async () => {
    await updateProfileSettings({
      variables: {
        settings: {
          receiveNews: !fetchSelf?.self.receiveNews,
        },
      },
    });

    toast.message('変更が保存されました');
  };

  return (
    <div className={tw`flex items-center border rounded p-3 mb-3`}>
      <SmallCheckbox
        checked={fetchSelf?.self.receiveNews || false}
        onChange={(e) => {
          toggleReceiveNews();
        }}
        disabled={loading || sending}>
        &nbsp;RelayHubに関するお知らせを受け取る
      </SmallCheckbox>
      <SpringSpace />
    </div>
  );
}
