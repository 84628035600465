import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {useAppMutation} from '../../../base/hooks/useAppMutation';
import {UpdateWorkflow, UpdateWorkflowVariables} from '../../../__generated__/UpdateWorkflow';
import {updateWorkflowMutation} from '../../../gqls/workflowMutations';
import {useOptionsMenu} from '../../../base/components/OptionsMenu/hooks';
import {iconTable, NakedWorkflowIcon} from '../../../base/components/WorkflowIcon/WorkflowIcon';
import styled from '@emotion/styled';
import {MinimumButton} from '../../../base/components/Button/Button';
import {Label} from '../../../base/components/Form/Form';
import {X} from 'react-feather';
import {HorizontalBox} from '../../../base/components/HorizontalBox/HorizontalBox';
import {colors} from 'theme';
import {css} from '@emotion/css';
import {useToast} from '../../../base/components/Toast/Toast';

interface Props {
  size?: number;
  workflow: WorkflowFragment;
  preview?: boolean;
}

export function WorkflowIcon(props: Props) {
  const {workflow, size = 36, preview = false} = props;
  const toast = useToast();
  const [mutate] = useAppMutation<UpdateWorkflow, UpdateWorkflowVariables>(updateWorkflowMutation, {
    onError() {
      toast.error('変更に失敗しました');
    },
    optimisticResponse(variables) {
      return {
        __typename: 'Mutation',
        workflow: {
          __typename: 'WorkflowOps',
          update: {
            ...workflow,
            color: variables.color === '' ? null : variables.color ?? workflow.color,
            icon: variables.icon === '' ? null : variables.icon ?? workflow.icon,
          },
        },
      };
    },
  });

  const setColor = (color: string) => {
    mutate({
      variables: {
        id: workflow.id,
        color,
      },
    });
  };

  const setIcon = (icon: string) => {
    mutate({
      variables: {
        id: workflow.id,
        icon,
      },
    });
  };

  const button = (
    <button
      className={css`
        height: 32px;
        width: 32px;
        appearance: none;
        padding: 0;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        
        &:focus:not(:focus-visible) {
          outline: none;
        }
        
        &:hover {
          background-color: ${colors.background};
        }
        
        &:disabled {
          pointer-events: none;
          cursor: default;
        }
      `}
      disabled={preview || !workflow.canEdit}>
      <NakedWorkflowIcon
        color={workflow.color || undefined}
        size={size}
        workflow={workflow}
      />
    </button>
  );

  const {node} = useOptionsMenu({
    direction: 'right',
    button,
    menu(close) {
      return (
        <div style={{width: '272px', padding: '10px'}}>
          <Label>アイコン</Label>

          <IconGrid>
            {Object.entries(iconTable).map(([name, Icon]) => {
              if (Icon === null) {
                return null;
              }

              if (name === workflow.icon) {
                return (
                  <SelectedIconButton key={name}>
                    <Icon size={20} strokeWidth={1.5} />
                  </SelectedIconButton>
                );
              }

              return (
                <IconButton key={name} onClick={() => setIcon(name)}>
                  <Icon size={20} strokeWidth={1.5} />
                </IconButton>
              );
            })}
          </IconGrid>

          {/* うまく行かなかったので無効にしておく */}
          {false && (
            <>
              <Label>背景色</Label>
              <HorizontalBox>
                <ColorInput
                  type="color"
                  defaultValue={workflow.color ?? '#ffffff'}
                  onBlur={(event) => {
                    const color = event.currentTarget.value;
                    setColor(color);
                  }}
                />
                &nbsp;
                {typeof workflow.color === 'string' && workflow.color}
                &nbsp;
                <MinimumButton
                  style={{visibility: typeof workflow.color === 'string' ? 'visible' : 'hidden'}}
                  onClick={() => setColor('')}>
                  <X size={12} />
                </MinimumButton>
              </HorizontalBox>
            </>
          )}
        </div>
      );
    },
  });

  if (workflow.canEdit && !preview) {
    return node;
  }

  return button;
}

const ColorInput = styled.input`
  -webkit-appearance: none;
  border: 1px solid ${colors.borderDark};
  width: 28px;
  height: 20px;
  border-radius: 3px;
  padding: 3px;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
  }
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(28px, 1fr));
  grid-gap: 6px;
`;

const IconButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-color: white;
  border-radius: 3px;
  color: ${colors.darkText};

  &:active,
  &:focus {
    appearance: none;
    padding: 0;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

const SelectedIconButton = styled(IconButton)`
  padding: 0;
  box-shadow: 0 0 0 2px ${colors.accent};
`;
