import {Fragment} from 'react';
import {MenuItem} from '../../../base/components/OptionsMenu/OptionsMenu';
import {useDetailsMenu} from '../../../base/components/OptionsMenu/hooks';

export const WorkflowMenu = () => {
  const menu = useDetailsMenu({
    menu: (close) => {
      return (
        <Fragment>
          <MenuItem
            onClick={() => {
              alert('実装されていません');
              close();
            }}>
            ワークフロー名を変更…
          </MenuItem>
          <MenuItem
            onClick={async () => {
              alert('実装されていません');
              close();
            }}>
            ワークフローを削除…
          </MenuItem>
        </Fragment>
      );
    },
  });

  return <Fragment>{menu.node}</Fragment>;
};
