import {css} from '@emotion/css';
import {ReactNode} from 'react';
import {colors, fontSizes, fontWeights} from 'theme';
import {NavLink} from 'react-router-dom';

interface Props {
  children: ReactNode;
  active?: string;
  to?: string;
  onClick?: JSX.IntrinsicElements['button']['onClick'];
  disabled?: boolean;
  exact?: boolean;
}

export function NaviBlock(props: Props) {
  const {to, active, onClick, exact, ...rest} = props;
  const className = css`
    margin: 0;
    padding: 0 4px;
    height: 34px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    border-radius: 5px;
    font-weight: ${fontWeights.normal};
    cursor: pointer;
    overflow: hidden;
    border: none;
    text-align: left;
    appearance: none;
    font-size: ${fontSizes.base};
    -webkit-font-smoothing: subpixel-antialiased;

    &,
    &:link,
    &:visited {
      color: ${colors.blackText};
      background-color: transparent;
    }

    &:hover {
      background-color: ${colors.background};
      color: ${colors.blackText};
    }

    :focus:not(:focus-visible) {
      outline: none;
    }

    &:active {
      background-color: ${colors.darkBackground};
      color: ${colors.blackText};
    }
  `;

  if (typeof to === 'string') {
    return <NavLink className={className} activeClassName={activeClassName} to={to} exact={exact} {...rest} />;
  }

  return <button className={className} onClick={onClick} {...rest} />;
}

const activeClassName = css`
  &,
  &:link,
  &:visited {
    color: ${colors.blackText};
  }

  &:hover {
    background-color: ${colors.darkBackground};
    color: ${colors.blackText};
  }
`;
