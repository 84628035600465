import {WorkflowFragment} from '../../../__generated__/WorkflowFragment';
import {CtaButton} from '../../../base/components/Button/Button';
import fileSize from 'file-size';
import {CustomFormRunner} from './CustomFormRunner';
import {HorizontalBox, Space, SpringSpace} from '../../../base/components/HorizontalBox/HorizontalBox';
import {Helmet} from 'react-helmet-async';
import {EyeCatch} from './EyeCatch';
import {useRunWorkflow} from './workflowHooks';
import {WorkflowStatus} from '../../../__generated__/globalTypes';
import {ArrowRightCircle, CheckCircle, Info, Circle} from 'react-feather';
import {DocumentEditor} from '../../../base/components/DocumentEditor/DocumentEditor';
import {StepPanel} from './StepPanel';
import {colors, fontSizes, fontWeights} from 'theme';
import {WorkflowIcon} from './WorkflowIcon';
import {css} from '@emotion/css';
import {TimeAgo} from '../../../base/components/TimeAgo';
import {useRef, useMemo, useLayoutEffect, useState} from 'react';
import styled from '@emotion/styled';

interface PreviewProps {
  workflow: WorkflowFragment;
}

export function WorkflowPreview(props: PreviewProps) {
  const {workflow} = props;
  const {runWorkflow, loading} = useRunWorkflow(workflow);

  const workflowStartButton = (
    <CtaButton
      variant="green"
      onClick={() => runWorkflow()}
      disabled={loading}
      style={{fontWeight: fontWeights.semiBold}}>
      <ArrowRightCircle size={16} strokeWidth={2} />
      <Space width="4px" />
      ワークフローを開始
    </CtaButton>
  );

  const noteStyle = css`
    color: ${colors.superLightText};
    font-size: ${fontSizes.sm};
  `;

  return (
    <>
      <Helmet>
        <title>{workflow.name}</title>
      </Helmet>

      <div
        className={css`
          position: fixed;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          padding: 0 8px 0 16px;
          width: calc((100% - 680px) / 2);
          line-height: 2;
          overflow: hidden;

          @media screen and (max-width: 1000px) {
            display: none;
          }
        `}>
        <span
          className={css`
            color: ${colors.superLightText};
            display: block;
            font-size: ${fontSizes.sm};
            font-weight: ${fontWeights.bold};
          `}>
          ステップ
        </span>
        <TOC workflow={workflow} />
      </div>

      <div
        className={css`
          width: 680px;
          margin: 48px auto 54px auto;
        `}>
        {false && (
          <>
            {' '}
            {/* とりあえず隠しとく */}
            <HorizontalBox>
              <WorkflowIcon workflow={workflow} size={32} preview />
              <Space />
              <h1
                className={css`
                  font-size: ${fontSizes.xl2};
                  font-weight: ${fontWeights.semiBold};
                `}>
                {workflow.name}
              </h1>

              <SpringSpace />
              {workflow.status === WorkflowStatus.published && workflowStartButton}
            </HorizontalBox>
            <Space height="8px" />
            <HorizontalBox>
              <HorizontalBox alignItems="center" className={noteStyle}>
                <CheckCircle size={16} />
                <Space width="4px" />
                <span
                  className={css`
                    font-weight: ${fontWeights.bold};
                  `}>
                  {workflow.steps.length}ステップ
                </span>
              </HorizontalBox>
              <Space width="16px" />
              <HorizontalBox alignItems="center" className={noteStyle}>
                <ArrowRightCircle size={16} />
                <Space width="4px" />
                <span
                  className={css`
                    font-weight: ${fontWeights.bold};
                  `}>
                  {workflow.startCount}回開始
                </span>
              </HorizontalBox>
              <Space width="16px" />
              <HorizontalBox alignItems="center" className={noteStyle}>
                <Info size={16} />
                <Space width="4px" />
                <span
                  className={css`
                    font-weight: ${fontWeights.bold};
                  `}>
                  <TimeAgo>{workflow.updatedAt}</TimeAgo>に更新
                </span>
                <Space width="8px" />
                <span
                  className={css`
                    font-weight: ${fontWeights.bold};
                  `}>
                  <TimeAgo>{workflow.createdAt}</TimeAgo>に作成
                </span>
              </HorizontalBox>
            </HorizontalBox>
            <EyeCatch workflow={workflow} />
          </>
        )}

        {workflow.steps.map((step) => {
          return (
            <div
              key={step.id}
              id={step.id}
              data-step-id={step.id}
              className="step-element"
              style={{scrollMarginTop: '68px', margin: '60px 0'}}>
              <StepPanel step={step} workflow={workflow} preview />
            </div>
          );
        })}
      </div>
    </>
  );
}

export const StepPreview = styled((props: {className?: string; step: WorkflowFragment['steps'][0]; index: number}) => {
  const {className, step, index} = props;
  return (
    <div className={className}>
      <StepTitle>
        <Index>{index + 1}.</Index>
        {step.name}
      </StepTitle>

      {typeof step.description === 'string' && <DocumentEditor readOnly defaultValue={step.description} />}

      {step.description === null && <p />}

      {step.form && <CustomFormRunner step={step} preview={true} />}

      <ul>
        {step.files.map((file) => {
          return (
            <li key={file.id}>
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                {file.fileName} ({fileSize(file.fileSize, {spacer: ''}).human('si')})
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
})`
  scroll-margin-top: 56px;
  margin: 24px 0;
`;

const Index = styled.span`
  margin-left: -86px;
  margin-right: 6px;
  display: inline-block;
  width: 80px;
  text-align: right;
  color: ${colors.superLightText};
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeights.bold};
`;

const StepTitle = styled.h2`
  font-size: ${fontSizes.xl};
  color: ${colors.blackText};
  font-weight: ${fontWeights.bold};
  margin: 4px 0 12px 0;
`;

interface TOCProps {
  workflow: WorkflowFragment;
}

function TOC({workflow}: TOCProps) {
  const intersectionState = useIntersectionState(workflow);

  return (
    <>
      {workflow.steps.map((step) => {
        const isCurrent = intersectionState.currentId === step.id;

        return (
          <div
            key={step.id}
            className={css`
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              display: flex;
              align-items: center;

              background-repeat: no-repeat;
              background-position: 4px top;
              background-size: 2px 100%;
              background-image: linear-gradient(to right, ${colors.border} 0px, ${colors.border} 0px);

              &:first-of-type {
                background-position: 4px 15px;
                background-size: 2px 100%;
              }
              &:last-of-type {
                background-position: 4px 0;
                background-size: 2px 50%;
              }
              &:first-of-type:last-of-type {
                background: none;
              }
            `}>
            <Circle
              size={10}
              strokeWidth={4}
              stroke={isCurrent ? colors.darkText : 'white'}
              fill={isCurrent ? 'white' : colors.border}
              style={{flexShrink: 0}}
            />
            <Space width="8px" />
            <a
              href={'#' + step.id}
              className={css`
                font-weight: ${fontWeights.bold};
                font-size: ${fontSizes.base};
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &,
                &:link,
                &:visited {
                  color: ${isCurrent ? colors.blackText : colors.superLightText};
                }
                &:hover {
                  color: ${colors.blackText};
                }
              `}>
              {step.name}
            </a>
          </div>
        );
      })}
    </>
  );
}

function useIntersectionState(workflow: WorkflowFragment) {
  const [state, setState] = useState<{currentId?: string}>({});
  const ratioMapRef = useRef<Record<string, number>>({});

  const observer = useMemo(() => {
    const ratioMap = ratioMapRef.current;
    let requested = false;

    return new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const {target} = entry;
          if (target instanceof HTMLDivElement) {
            const id = target.dataset.stepId!;
            ratioMap[id] = entry.intersectionRatio;
          }
        }

        if (!requested) {
          requestAnimationFrame(() => {
            const first = Object.keys(ratioMap)
              .map((id) => {
                const ratio = ratioMap[id];
                return {id, ratio};
              })
              .reduce((left, right) => (left.ratio >= right.ratio ? left : right));

            setState({
              currentId: first.id,
            });
            requested = false;
          });
          requested = true;
        }
      },
      {
        threshold: [0, 0.5, 1],
        rootMargin: '0px',
      }
    );
  }, [setState]);

  useLayoutEffect(() => {
    const elements = document.querySelectorAll('.step-element');
    for (const element of elements) {
      observer.observe(element);
    }

    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return state;
}
