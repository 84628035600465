import gql from 'graphql-tag';
import {userFragment} from './fragments';

export const fetchWorkspaceQuery = gql`
  query FetchWorkspace($id: ID!) {
    workspace(id: $id) {
      id
      name
      role
      members {
        totalCount
        items {
          role
          user {
            ...UserFragment
          }
        }
      }
      owner {
        ...UserFragment
      }
    }
  }

  ${userFragment}
`;

export const fetchWorkspaceUsageQuery = gql`
  query FetchWorkspaceUsage($id: ID!) {
    workspace(id: $id) {
      id
      name
      usage {
        totalUploadedFileSize
        memberCount
      }
    }
  }
`;
