import {SyntheticEvent, useState} from 'react';
import {Label, TextInput, Field} from '../../../../base/components/Form/Form';
import {DarkButton} from '../../../../base/components/Button/Button';
import {ErrorBlock} from '../../../../base/components/ErrorBlock/ErrorBlock';
import {useModalDialog} from '../../../../base/components/ModalDialog/ModalDialog';
import {Loading} from '../../../common/Loading';
import {closeAccountMutation} from '../../../../gqls/accountMutations';
import {tw} from 'twind';
import {WarningAlert} from '../../../../base/components/Alert/Alert';
import {useAppMutation} from '../../../../base/hooks/useAppMutation';
import {CloseAccount, CloseAccountVariables} from '../../../../__generated__/CloseAccount';
import {useToast} from '../../../../base/components/Toast/Toast';
import {Header, HeaderBlock} from '../styles';
import * as React from 'react';

export const CloseAccountRoute = () => {

  return (
    <>
      <h1 className={tw`font-semiBold text-xl mb-6`}>アカウントの閉鎖</h1>

      <HeaderBlock>
        <Header>閉鎖</Header>
      </HeaderBlock>

      <WarningAlert>
        <span className={tw`text-sm`}>
          アカウントを閉鎖すると、このワークスペースにログインすることができなくなります。
        </span>
      </WarningAlert>

      <CloseForm />
    </>
  );
};

function CloseForm() {
  const [state, setState] = useState<{password: string; errors: string[]}>({
    password: '',
    errors: [],
  });

  const modalDialog = useModalDialog();

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    await close(state.password.trim());
  };

  const onChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    setState((prev) => ({...prev, password: input.value}));
  };

  const toast = useToast();

  const [closeAccount, {loading}] = useAppMutation<CloseAccount, CloseAccountVariables>(closeAccountMutation, {
    async onCompleted() {
      await modalDialog.alert({
        title: 'アカウントが閉鎖されました',
        message: 'あなたのアカウントが閉鎖されました。ログアウトします。',
      });

      window.location.href = '/login';
    },
    onError() {
      toast.error('アカウントの閉鎖に失敗しました');
    },
  });
  const close = (password: string) => closeAccount({variables: {password}});

  return (
    <form onSubmit={onSubmit}>
      {loading && <Loading />}
      <Field>
        <Label>パスワード</Label>

        <TextInput
          type="password"
          value={state.password}
          name="password"
          onChange={onChange}
          autoComplete="current-password"
          size={50}
          placeholder="******"
          disabled={loading}
        />
      </Field>

      <ErrorBlock errors={state.errors} />

      <div>
        <DarkButton disabled={state.errors.length > 0 || loading || state.password.length === 0}>
          アカウントを閉鎖
        </DarkButton>
      </div>
    </form>
  );
}
