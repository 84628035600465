import * as React from 'react';
import {HelpCircle} from 'react-feather';
import {Tooltip, Placement} from '../Tooltip/Tooltip';

interface Props {
  children?: React.ReactNode;
  placement?: Placement;
}

export function HelpMark(props: Props) {
  const {children, placement} = props;

  return (
    <Tooltip text={children} placement={placement}>
      <HelpCircle size={16} style={{cursor: 'pointer'}} strokeWidth={1.5} />
    </Tooltip>
  );
}
