import {Redirect} from 'react-router-dom';
import {useAppQuery} from '../base/hooks/useAppQuery';
import {fetchSelfQuery} from '../gqls/selfQueries';
import {FetchSelf} from '../__generated__/FetchSelf';
import {useCreateWorkspace} from './common/useCreateWorkspace';

// ワークスペースを選択していない状態なので、`/workspaces/:id`にリダイレクトする
export function RootRoute() {
  const createWorkspace = useCreateWorkspace();
  const {data, loading} = useAppQuery<FetchSelf>(fetchSelfQuery, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.self.workspaces.length === 0) {
        createWorkspace.run();
      }
    },
  });

  if (loading || !data) {
    return null;
  }

  if (data.self.workspaces.length === 0) {
    return null;
  }

  const workspaceId = data.self.workspaces[0].id;

  return <Redirect to={`/workspaces/${workspaceId}`} />;
}
