import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {colors, fontSizes, fontWeights} from 'theme';

export const ToastBlock = styled.div<{mode: string}>`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 42px;
  border-radius: 8px;
  background-color: ${({mode}) => (mode === 'error' ? colors.darkDangerBackground : colors.blackText)};
  color: white;
  line-height: 42px;
  padding: 0 12px;
  width: max-content;
  max-width: 430px;
  z-index: 200;
  box-shadow: 0 1px 3px 0 rgba(27, 44, 75, 0.1);
  display: inline-flex;
  align-items: center;
`;

export const toastInvisibleStyle = css`
  transform: translateY(900%);
  opacity: 0;
`;

export const toastStyle = css`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 42px;
  border-radius: 8px;
  background-color: ${colors.blackText};
  color: white;
  line-height: 42px;
  padding: 0 12px;
  width: max-content;
  max-width: 430px;
  transform: translateY(900%);
  opacity: 0;
  z-index: 200;
  box-shadow: 0 1px 3px 0 rgba(27, 44, 75, 0.1);
  display: inline-flex;
  align-items: center;
`;

export const toastVisibleStyle = css`
  transform: translateY(0);
  opacity: 1;
`;

export const toastEnterStyle = css`
  transform: translateY(20%) translateZ(0);
  opacity: 0;
`;

export const toastEnterActiveStyle = css`
  transition: all 180ms ease-in-out;
  transform: translateY(0) translateZ(0);
  opacity: 1;
`;

export const toastExitStyle = css`
  transform: translateY(0) translateZ(0);
`;

export const toastExitActiveStyle = css`
  transition: all 180ms ease-in-out;
  transform: translateY(20%) translateZ(0);
  opacity: 0;
`;

export const toastTextStyle = css`
  font-size: ${fontSizes.base};
  font-weight: ${fontWeights.bold};
  line-height: 42px;
  flex-grow: 1;
`;

export const buttonStyle = css`
  font-size: ${fontSizes.base};
  font-weight: ${fontWeights.bold};
  color: white;
  opacity: 0.8;
  display: inline-block;
  float: right;
  text-align: right;
  line-height: 42px;
  cursor: pointer;
  user-select: none;

  &:active {
    opacity: 0.6;
  }
`;
