import {useRef, useMemo, useState, useEffect, useCallback} from 'react';

interface UseDraftParams<Source, Draft> {
  source: Source;
  createDraft(source: Source): Draft;
  save(draftState: Draft, source: Source): void;
}

type UseDraftReturn<Draft> = {
  state: Draft; // state
  setState: (state: Draft | ((prev: Draft) => Draft)) => void; // setState
  save: () => void; // restore
  isDirty: boolean;
  discard: () => void;
};

export function useDraft<Source, Draft>(params: UseDraftParams<Source, Draft>): UseDraftReturn<Draft> {
  const {source} = params;
  const paramsRef = useCurrentRef<UseDraftParams<Source, Draft>>(params);

  const derivedState = useMemo(() => paramsRef.current.createDraft(source), [source, paramsRef]);
  const derivedStateRef = useCurrentRef(derivedState);
  const [draftState, setDraftState] = useState<Draft>(derivedState);
  const draftStateRef = useCurrentRef<Draft>(draftState);

  useEffect(() => {
    setDraftState(derivedState);
  }, [derivedState]);

  const isDirty = derivedState !== draftState;

  const isDirtyRef = useCurrentRef<boolean>(isDirty);

  const save = useCallback(() => {
    if (isDirtyRef.current) {
      paramsRef.current.save(draftStateRef.current, paramsRef.current.source);
      isDirtyRef.current = false;
    }
  }, [paramsRef, draftStateRef, isDirtyRef]);

  const discard = useCallback(() => {
    if (isDirtyRef.current) {
      setDraftState(derivedStateRef.current);
    }
  }, [isDirtyRef, derivedStateRef]);

  return {
    state: draftState,
    setState: setDraftState,
    save,
    isDirty,
    discard,
  };
}

interface LensParams<Source, LensState> {
  source: Source;
  getState: (source: Source) => LensState;
  setState: (state: LensState, source: Source) => void;
}

interface LensReturn<State> {
  state: State;
  setState(state: State): void;
}

export function useLens<Source, LensState>(params: LensParams<Source, LensState>): LensReturn<LensState> {
  const paramsRef = useCurrentRef(params);

  const state = useMemo(() => paramsRef.current.getState(params.source), [params.source, paramsRef]);

  const setState = useCallback(
    (state) => {
      paramsRef.current.setState(state, paramsRef.current.source);
    },
    [paramsRef]
  );

  return {
    state,
    setState,
  };
}

function useCurrentRef<T>(value: T): React.MutableRefObject<T> {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
}

export const doNothing: (...params: any[]) => void = () => {};
