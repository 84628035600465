import * as React from 'react';
import {Fragment, ReactNode} from 'react';
// @ts-ignore
import {CSSTransition} from 'react-transition-group';
import {
  buttonStyle,
  toastEnterActiveStyle,
  toastEnterStyle,
  toastExitActiveStyle,
  toastExitStyle,
  toastTextStyle,
  toastVisibleStyle,
  ToastBlock,
  toastInvisibleStyle,
} from './style';
import {Space} from '../HorizontalBox/HorizontalBox';
import {X} from 'react-feather';

type ToastOptions = {
  onClick?: () => void;
  buttonText?: string;
  mode?: 'error';
};

export const ToastContext = React.createContext<Toast>({
  message() {
    throw Error('The context value is not provided');
  },
  error() {
    throw Error('The context value is not provided');
  },
});

const {Provider} = ToastContext;

type ProviderState = {
  show: boolean;
  message: string;
  buttonText: ReactNode;
  onClick: () => void;
  mode: string;
};

export type Toast = {
  message(message: string, params?: ToastOptions): void;
  error(message: string): void;
};

export function useToast(): Toast {
  return React.useContext(ToastContext);
}

export class ToastProvider extends React.Component<{children: React.ReactNode}, ProviderState> {
  state = {
    show: false,
    message: 'メッセージです',
    buttonText: (
      <>
        <X size={16} strokeWidth={2.5} />
      </>
    ),
    mode: '',
    onClick: () => {},
  };

  nodeRef = React.createRef<HTMLDivElement>();

  hideTimeoutId: any;
  showTimeoutId: any;

  contextValue: Toast = {
    error: (message) => {
      this.show(message, {mode: 'error'});
    },
    message: (message, options: ToastOptions = {}) => {
      this.show(message, options);
    },
  };

  show = (message: string, {onClick = () => {}, buttonText, mode}: ToastOptions = {}) => {
    const show = () => {
      if (this.hideTimeoutId) {
        clearTimeout(this.hideTimeoutId);
      }

      this.hideTimeoutId = setTimeout(() => {
        this.hideTimeoutId = null;
        this.setState({show: false});
      }, 4000);

      this.setState({
        onClick,
        message,
        show: true,
        mode: mode ?? '',
        buttonText: typeof buttonText === 'string' ? buttonText : this.state.buttonText,
      });
    };

    if (!this.state.show) {
      show();
    } else {
      this.setState({show: false});

      if (this.showTimeoutId) {
        clearTimeout(this.showTimeoutId);
      }

      this.showTimeoutId = setTimeout(() => {
        this.showTimeoutId = null;
        show();
      }, 500);
    }
  };

  handleClick = () => {
    clearTimeout(this.hideTimeoutId);
    this.setState({show: false});
    this.state.onClick();
  };

  onEntered = () => {
    this.nodeRef.current!.classList.add(toastVisibleStyle);
  };

  onExited = () => {
    this.nodeRef.current!.classList.remove(toastVisibleStyle);
  };

  render() {
    const duration = 180;

    const classNames = {
      enter: toastEnterStyle,
      enterActive: toastEnterActiveStyle,
      exit: toastExitStyle,
      exitActive: toastExitActiveStyle,
    };

    const {show, buttonText, message, mode} = this.state;
    const {children} = this.props;

    return (
      <Fragment>
        <CSSTransition
          in={show}
          nodeRef={this.nodeRef}
          timeout={duration}
          classNames={classNames}
          onEntered={this.onEntered}
          onExited={this.onExited}>
          <ToastBlock className={toastInvisibleStyle} ref={this.nodeRef} mode={mode}>
            <div className={toastTextStyle}>{message}</div>
            <Space width="32px" />
            <span className={buttonStyle} onClick={this.handleClick}>
              {buttonText}
            </span>
          </ToastBlock>
        </CSSTransition>

        <Provider value={this.contextValue}>{children}</Provider>
      </Fragment>
    );
  }
}
