import {ReactNode} from 'react';
import {DialogContent, DialogTitle, Footer, ModalDialog} from '../Dialog/ModalDialog';
import {LargeButton, LargeDarkButton} from '../Button/Button';
import {HorizontalBox} from '../HorizontalBox/HorizontalBox';
import {AlertTriangle} from 'react-feather';

interface ConfirmDialogProps {
  title?: string;
  message: ReactNode;
  close: (result: boolean) => void;
  icon?: ReactNode;
}

export function ConfirmDialog({title, message, close, icon = <AlertTriangle strokeWidth={1.5} />}: ConfirmDialogProps) {
  return (
    <ModalDialog>
      <DialogTitle>
        <HorizontalBox>
          {icon}
          &nbsp;
          {title || ''}
        </HorizontalBox>
      </DialogTitle>

      <DialogContent>{message}</DialogContent>

      <Footer>
        <LargeButton onClick={() => close(false)}>キャンセル</LargeButton>
        &nbsp;
        <LargeDarkButton onClick={() => close(true)}>はい</LargeDarkButton>
      </Footer>
    </ModalDialog>
  );
}
